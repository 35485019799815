import { emptySplitApi as api } from "./emptyApi";
const injectedRtkApi = api.injectEndpoints({
    endpoints: (build) => ({
        getConfig: build.query<GetConfigApiResponse, GetConfigApiArg>({
            query: () => ({ url: `/api/config` }),
        }),
        getAdminConfig: build.query<GetAdminConfigApiResponse, GetAdminConfigApiArg>({
            query: () => ({ url: `/api/config/admin` }),
        }),
        healthcheck: build.query<HealthcheckApiResponse, HealthcheckApiArg>({
            query: () => ({ url: `/api/healthcheck` }),
        }),
        migrateImageUrls: build.query<MigrateImageUrlsApiResponse, MigrateImageUrlsApiArg>({
            query: () => ({ url: `/api/image-url-migration` }),
        }),
        createStudent: build.mutation<CreateStudentApiResponse, CreateStudentApiArg>({
            query: (queryArg) => ({ url: `/api/v1/students`, method: "POST", body: queryArg.createStudentRequestBody }),
        }),
        updateStudent: build.mutation<UpdateStudentApiResponse, UpdateStudentApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/students`,
                method: "PATCH",
                body: queryArg.updateStudentRequestBody,
            }),
        }),
        getPrivateStudent: build.query<GetPrivateStudentApiResponse, GetPrivateStudentApiArg>({
            query: (queryArg) => ({ url: `/api/v1/students/private/${queryArg.studentId}` }),
        }),
        getPublicStudent: build.query<GetPublicStudentApiResponse, GetPublicStudentApiArg>({
            query: (queryArg) => ({ url: `/api/v1/students/public/${queryArg.studentId}` }),
        }),
        getMyPublicRequests: build.query<GetMyPublicRequestsApiResponse, GetMyPublicRequestsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/students/publicRequests/${queryArg.studentId}` }),
        }),
        updateFavoriteCourses: build.mutation<UpdateFavoriteCoursesApiResponse, UpdateFavoriteCoursesApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/students/favorite-courses`,
                method: "PATCH",
                body: queryArg.updateFavoriteCoursesRequestBody,
            }),
        }),
        updateCourseBrowsingHistory: build.mutation<
            UpdateCourseBrowsingHistoryApiResponse,
            UpdateCourseBrowsingHistoryApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/students/browsing-history`,
                method: "PATCH",
                body: queryArg.updateCourseBrowsingHistoryRequestBody,
            }),
        }),
        deleteStudent: build.mutation<DeleteStudentApiResponse, DeleteStudentApiArg>({
            query: (queryArg) => ({ url: `/api/v1/students/${queryArg.studentId}`, method: "DELETE" }),
        }),
        getReservedClasses: build.query<GetReservedClassesApiResponse, GetReservedClassesApiArg>({
            query: (queryArg) => ({ url: `/api/v1/students/reserved-classes/${queryArg.studentId}` }),
        }),
        getClassesCompleted: build.query<GetClassesCompletedApiResponse, GetClassesCompletedApiArg>({
            query: (queryArg) => ({ url: `/api/v1/students/classes-completed/${queryArg.studentId}` }),
        }),
        getAllFavoriteCourses: build.query<GetAllFavoriteCoursesApiResponse, GetAllFavoriteCoursesApiArg>({
            query: (queryArg) => ({ url: `/api/v1/students/favorite/all/${queryArg.studentId}` }),
        }),
        getFavoriteCourses: build.query<GetFavoriteCoursesApiResponse, GetFavoriteCoursesApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/students/favorite/${queryArg.studentId}`,
                params: { courseType: queryArg.courseType },
            }),
        }),
        getCourseBrowsingHistory: build.query<GetCourseBrowsingHistoryApiResponse, GetCourseBrowsingHistoryApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/students/browsing-history/${queryArg.studentId}`,
                params: { courseType: queryArg.courseType },
            }),
        }),
        getPastCourses: build.query<GetPastCoursesApiResponse, GetPastCoursesApiArg>({
            query: (queryArg) => ({ url: `/api/v1/students/past-courses/${queryArg.studentId}` }),
        }),
        getStudentCheckedAnnouncementIds: build.query<
            GetStudentCheckedAnnouncementIdsApiResponse,
            GetStudentCheckedAnnouncementIdsApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/students/checked-announcement-ids/${queryArg.studentId}` }),
        }),
        updateStudentCheckedAnnouncements: build.mutation<
            UpdateStudentCheckedAnnouncementsApiResponse,
            UpdateStudentCheckedAnnouncementsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/students/checked-announcements/${queryArg.studentId}/${queryArg.announcementId}`,
                method: "PATCH",
            }),
        }),
        createCourse: build.mutation<CreateCourseApiResponse, CreateCourseApiArg>({
            query: (queryArg) => ({ url: `/api/v1/courses`, method: "POST", body: queryArg.createCourseRequestBody }),
        }),
        updateCourse: build.mutation<UpdateCourseApiResponse, UpdateCourseApiArg>({
            query: (queryArg) => ({ url: `/api/v1/courses`, method: "PATCH", body: queryArg.updateCourseRequestBody }),
        }),
        deleteCourse: build.mutation<DeleteCourseApiResponse, DeleteCourseApiArg>({
            query: (queryArg) => ({ url: `/api/v1/courses/${queryArg.courseId}`, method: "DELETE" }),
        }),
        withdrawFromClass: build.mutation<WithdrawFromClassApiResponse, WithdrawFromClassApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/classes/withdraw`,
                method: "POST",
                body: queryArg.withdrawFromClassRequestBody,
            }),
        }),
        reserveShortCourse: build.mutation<ReserveShortCourseApiResponse, ReserveShortCourseApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/classes/reserve/short`,
                method: "POST",
                body: queryArg.reserveShortCourseRequestBody,
            }),
        }),
        reserveRegularCourse: build.mutation<ReserveRegularCourseApiResponse, ReserveRegularCourseApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/classes/reserve/regular`,
                method: "POST",
                body: queryArg.reserveRegularCourseRequestBody,
            }),
        }),
        closeApplication: build.mutation<CloseApplicationApiResponse, CloseApplicationApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/classes/close-application`,
                method: "PATCH",
                body: queryArg.closeApplicationRequestBody,
            }),
        }),
        getClassByClassId: build.query<GetClassByClassIdApiResponse, GetClassByClassIdApiArg>({
            query: (queryArg) => ({ url: `/api/v1/classes/${queryArg.classId}` }),
        }),
        applyForUpdateMonthlyFee: build.mutation<ApplyForUpdateMonthlyFeeApiResponse, ApplyForUpdateMonthlyFeeApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/classes/apply-for-update-monthly-fee`,
                method: "POST",
                body: queryArg.applyForUpdateMonthlyFeeRequestBody,
            }),
        }),
        applyForTemporaryUpdateMonthlyFee: build.mutation<
            ApplyForTemporaryUpdateMonthlyFeeApiResponse,
            ApplyForTemporaryUpdateMonthlyFeeApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/classes/apply-for-temporary-update-monthly-fee`,
                method: "POST",
                body: queryArg.applyForTemporaryUpdateMonthlyFeeRequestBody,
            }),
        }),
        disapproveUpdateMonthlyFee: build.mutation<
            DisapproveUpdateMonthlyFeeApiResponse,
            DisapproveUpdateMonthlyFeeApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/classes/disapprove-update-monthly-fee`,
                method: "POST",
                body: queryArg.disapproveUpdateMonthlyFeeRequestBody,
            }),
        }),
        approveUpdateMonthlyFee: build.mutation<ApproveUpdateMonthlyFeeApiResponse, ApproveUpdateMonthlyFeeApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/classes/approve-update-monthly-fee`,
                method: "POST",
                body: queryArg.approveUpdateMonthlyFeeRequestBody,
            }),
        }),
        disapproveTemporaryUpdateMonthlyFee: build.mutation<
            DisapproveTemporaryUpdateMonthlyFeeApiResponse,
            DisapproveTemporaryUpdateMonthlyFeeApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/classes/disapprove-temporary-update-monthly-fee`,
                method: "POST",
                body: queryArg.disapproveTemporaryUpdateMonthlyFeeRequestBody,
            }),
        }),
        approveTemporaryUpdateMonthlyFee: build.mutation<
            ApproveTemporaryUpdateMonthlyFeeApiResponse,
            ApproveTemporaryUpdateMonthlyFeeApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/classes/approve-temporary-update-monthly-fee`,
                method: "POST",
                body: queryArg.approveTemporaryUpdateMonthlyFeeRequestBody,
            }),
        }),
        reportToCompleteClass: build.mutation<ReportToCompleteClassApiResponse, ReportToCompleteClassApiArg>({
            query: (queryArg) => ({ url: `/api/v1/classes/${queryArg.classId}/report`, method: "POST" }),
        }),
        updateMemo: build.mutation<UpdateMemoApiResponse, UpdateMemoApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/classes/memo`,
                method: "PATCH",
                body: queryArg.updateMemoRequestBody,
            }),
        }),
        updateClassName: build.mutation<UpdateClassNameApiResponse, UpdateClassNameApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/classes/class-name`,
                method: "PATCH",
                body: queryArg.updateClassNameRequestBody,
            }),
        }),
        addClass: build.mutation<AddClassApiResponse, AddClassApiArg>({
            query: (queryArg) => ({ url: `/api/v1/classes/add`, method: "POST", body: queryArg.addClassRequestBody }),
        }),
        getCourseForCourseDetails: build.query<GetCourseForCourseDetailsApiResponse, GetCourseForCourseDetailsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/courses/${queryArg.courseId}/for-course-details` }),
        }),
        getCourseForUpdatingCourse: build.query<
            GetCourseForUpdatingCourseApiResponse,
            GetCourseForUpdatingCourseApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/courses/${queryArg.courseId}/for-updating-course` }),
        }),
        getCourseWithValidLessons: build.query<GetCourseWithValidLessonsApiResponse, GetCourseWithValidLessonsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/courses/valid/${queryArg.courseId}` }),
        }),
        getCourseForCourseManagement: build.query<
            GetCourseForCourseManagementApiResponse,
            GetCourseForCourseManagementApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/courses/${queryArg.courseId}/management` }),
        }),
        getLatestShortCourses: build.query<GetLatestShortCoursesApiResponse, GetLatestShortCoursesApiArg>({
            query: (queryArg) => ({ url: `/api/v1/courses/latest/short`, params: { howMany: queryArg.howMany } }),
        }),
        getLatestRegularCourses: build.query<GetLatestRegularCoursesApiResponse, GetLatestRegularCoursesApiArg>({
            query: (queryArg) => ({ url: `/api/v1/courses/latest/regular`, params: { howMany: queryArg.howMany } }),
        }),
        getApproachingDeadlineShortCourses: build.query<
            GetApproachingDeadlineShortCoursesApiResponse,
            GetApproachingDeadlineShortCoursesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/courses/approaching-deadline/short`,
                params: { howMany: queryArg.howMany },
            }),
        }),
        getApproachingDeadlineRegularCourses: build.query<
            GetApproachingDeadlineRegularCoursesApiResponse,
            GetApproachingDeadlineRegularCoursesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/courses/approaching-deadline/regular`,
                params: { howMany: queryArg.howMany },
            }),
        }),
        getCourseSearchResults: build.query<GetCourseSearchResultsApiResponse, GetCourseSearchResultsApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/courses/search`,
                params: {
                    orderBy: queryArg.orderBy,
                    keyword: queryArg.keyword,
                    courseType: queryArg.courseType,
                    university: queryArg.university,
                    faculty: queryArg.faculty,
                    department: queryArg.department,
                    rankNumber: queryArg.rankNumber,
                    level: queryArg.level,
                    category: queryArg.category,
                    subCategory: queryArg.subCategory,
                    studentId: queryArg.studentId,
                    take: queryArg.take,
                    skip: queryArg.skip,
                },
            }),
        }),
        getCourseFavoriteCount: build.query<GetCourseFavoriteCountApiResponse, GetCourseFavoriteCountApiArg>({
            query: (queryArg) => ({ url: `/api/v1/courses/favorite-count/${queryArg.courseId}` }),
        }),
        getRecommendedShortCourses: build.query<
            GetRecommendedShortCoursesApiResponse,
            GetRecommendedShortCoursesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/courses/recommended/short/${queryArg.studentId}`,
                params: { howMany: queryArg.howMany },
            }),
        }),
        getRecommendedRegularCourses: build.query<
            GetRecommendedRegularCoursesApiResponse,
            GetRecommendedRegularCoursesApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/courses/recommended/regular/${queryArg.studentId}`,
                params: { howMany: queryArg.howMany },
            }),
        }),
        createDraftCourse: build.mutation<CreateDraftCourseApiResponse, CreateDraftCourseApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/draftCourses`,
                method: "POST",
                body: queryArg.createDraftCourseRequestBody,
            }),
        }),
        updateDraftCourse: build.mutation<UpdateDraftCourseApiResponse, UpdateDraftCourseApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/draftCourses`,
                method: "PATCH",
                body: queryArg.updateDraftCourseRequestBody,
            }),
        }),
        getMyDraftCourses: build.query<GetMyDraftCoursesApiResponse, GetMyDraftCoursesApiArg>({
            query: (queryArg) => ({ url: `/api/v1/draftCourses/my`, params: { teacherId: queryArg.teacherId } }),
        }),
        getDraftCourse: build.query<GetDraftCourseApiResponse, GetDraftCourseApiArg>({
            query: (queryArg) => ({ url: `/api/v1/draftCourses/${queryArg.draftCourseId}` }),
        }),
        deleteDraftCourse: build.mutation<DeleteDraftCourseApiResponse, DeleteDraftCourseApiArg>({
            query: (queryArg) => ({ url: `/api/v1/draftCourses/${queryArg.draftCourseId}`, method: "DELETE" }),
        }),
        deleteLesson: build.mutation<DeleteLessonApiResponse, DeleteLessonApiArg>({
            query: (queryArg) => ({ url: `/api/v1/lessons/${queryArg.lessonId}`, method: "DELETE" }),
        }),
        getStudentLessons: build.query<GetStudentLessonsApiResponse, GetStudentLessonsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/lessons/student/${queryArg.studentId}` }),
        }),
        getTeacherLessons: build.query<GetTeacherLessonsApiResponse, GetTeacherLessonsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/lessons/teacher/${queryArg.teacherId}` }),
        }),
        writeSimultaneousLessonsCount: build.mutation<
            WriteSimultaneousLessonsCountApiResponse,
            WriteSimultaneousLessonsCountApiArg
        >({
            query: () => ({ url: `/api/v1/lessons/write`, method: "POST" }),
        }),
        addLessonToClass: build.mutation<AddLessonToClassApiResponse, AddLessonToClassApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/lessons`,
                method: "POST",
                body: queryArg.addLessonToClassRequestBody,
            }),
        }),
        updateLesson: build.mutation<UpdateLessonApiResponse, UpdateLessonApiArg>({
            query: (queryArg) => ({ url: `/api/v1/lessons`, method: "PATCH", body: queryArg.updateLessonRequestBody }),
        }),
        createReview: build.mutation<CreateReviewApiResponse, CreateReviewApiArg>({
            query: (queryArg) => ({ url: `/api/v1/reviews`, method: "POST", body: queryArg.createReviewRequestBody }),
        }),
        getReviewsByCourseId: build.query<GetReviewsByCourseIdApiResponse, GetReviewsByCourseIdApiArg>({
            query: (queryArg) => ({ url: `/api/v1/reviews/by-courseId/${queryArg.courseId}` }),
        }),
        getReviewsByStudentId: build.query<GetReviewsByStudentIdApiResponse, GetReviewsByStudentIdApiArg>({
            query: (queryArg) => ({ url: `/api/v1/reviews/by-studentId/${queryArg.studentId}` }),
        }),
        getLatestReviews: build.query<GetLatestReviewsApiResponse, GetLatestReviewsApiArg>({
            query: () => ({ url: `/api/v1/reviews/latest` }),
        }),
        completeStudentToDo: build.mutation<CompleteStudentToDoApiResponse, CompleteStudentToDoApiArg>({
            query: (queryArg) => ({ url: `/api/v1/student-to-dos/${queryArg.studentToDoId}`, method: "PATCH" }),
        }),
        getStudentToDos: build.query<GetStudentToDosApiResponse, GetStudentToDosApiArg>({
            query: (queryArg) => ({ url: `/api/v1/student-to-dos/${queryArg.studentId}` }),
        }),
        updateTeacherToDo: build.mutation<UpdateTeacherToDoApiResponse, UpdateTeacherToDoApiArg>({
            query: (queryArg) => ({ url: `/api/v1/teacher-to-dos/${queryArg.teacherToDoId}`, method: "PATCH" }),
        }),
        deleteTeacherToDos: build.mutation<DeleteTeacherToDosApiResponse, DeleteTeacherToDosApiArg>({
            query: (queryArg) => ({ url: `/api/v1/teacher-to-dos/${queryArg.teacherToDoId}`, method: "DELETE" }),
        }),
        getTeacherToDos: build.query<GetTeacherToDosApiResponse, GetTeacherToDosApiArg>({
            query: (queryArg) => ({ url: `/api/v1/teacher-to-dos/${queryArg.teacherId}` }),
        }),
        createTeacher: build.mutation<CreateTeacherApiResponse, CreateTeacherApiArg>({
            query: (queryArg) => ({ url: `/api/v1/teachers`, method: "POST", body: queryArg.createTeacherRequestBody }),
        }),
        updateTeacher: build.mutation<UpdateTeacherApiResponse, UpdateTeacherApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/teachers`,
                method: "PATCH",
                body: queryArg.updateTeacherRequestBody,
            }),
        }),
        linkZoomAccount: build.mutation<LinkZoomAccountApiResponse, LinkZoomAccountApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/teachers/link/zoom`,
                method: "POST",
                body: queryArg.linkZoomAccountRequestBody,
            }),
        }),
        getPrivateTeacher: build.query<GetPrivateTeacherApiResponse, GetPrivateTeacherApiArg>({
            query: (queryArg) => ({ url: `/api/v1/teachers/private/${queryArg.teacherId}` }),
        }),
        getPublicTeacher: build.query<GetPublicTeacherApiResponse, GetPublicTeacherApiArg>({
            query: (queryArg) => ({ url: `/api/v1/teachers/public/${queryArg.teacherId}` }),
        }),
        updateFavoritePublicRequests: build.mutation<
            UpdateFavoritePublicRequestsApiResponse,
            UpdateFavoritePublicRequestsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/teachers/favorite-publicRequest`,
                method: "PATCH",
                body: queryArg.updateFavoritePublicRequestsRequestBody,
            }),
        }),
        updatePublicRequestBrowsingHistory: build.mutation<
            UpdatePublicRequestBrowsingHistoryApiResponse,
            UpdatePublicRequestBrowsingHistoryApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/teachers/browsing-history`,
                method: "PATCH",
                body: queryArg.updatePublicRequestBrowsingHistoryRequestBody,
            }),
        }),
        deleteTeacher: build.mutation<DeleteTeacherApiResponse, DeleteTeacherApiArg>({
            query: (queryArg) => ({ url: `/api/v1/teachers/${queryArg.teacherId}`, method: "DELETE" }),
        }),
        getMyCoursesByTeacherId: build.query<GetMyCoursesByTeacherIdApiResponse, GetMyCoursesByTeacherIdApiArg>({
            query: (queryArg) => ({ url: `/api/v1/teachers/my-courses/${queryArg.teacherId}` }),
        }),
        getMyProposalsByTeacherId: build.query<GetMyProposalsByTeacherIdApiResponse, GetMyProposalsByTeacherIdApiArg>({
            query: (queryArg) => ({ url: `/api/v1/teachers/my-proposals/${queryArg.teacherId}` }),
        }),
        getCoursesOfTargetTeacher: build.query<GetCoursesOfTargetTeacherApiResponse, GetCoursesOfTargetTeacherApiArg>({
            query: (queryArg) => ({ url: `/api/v1/teachers/active-courses/${queryArg.teacherId}` }),
        }),
        getAllFavoritePublicRequests: build.query<
            GetAllFavoritePublicRequestsApiResponse,
            GetAllFavoritePublicRequestsApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/teachers/favorite/all/${queryArg.teacherId}` }),
        }),
        getFavoritePublicRequests: build.query<GetFavoritePublicRequestsApiResponse, GetFavoritePublicRequestsApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/teachers/favorite/${queryArg.teacherId}`,
                params: { courseType: queryArg.courseType },
            }),
        }),
        getPublicRequestBrowsingHistory: build.query<
            GetPublicRequestBrowsingHistoryApiResponse,
            GetPublicRequestBrowsingHistoryApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/teachers/browsing-history/${queryArg.teacherId}`,
                params: { courseType: queryArg.courseType },
            }),
        }),
        getTeacherSearchResults: build.query<GetTeacherSearchResultsApiResponse, GetTeacherSearchResultsApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/teachers/search`,
                params: {
                    orderBy: queryArg.orderBy,
                    keyword: queryArg.keyword,
                    university: queryArg.university,
                    faculty: queryArg.faculty,
                    department: queryArg.department,
                    rankNumber: queryArg.rankNumber,
                    level: queryArg.level,
                    category: queryArg.category,
                    subCategory: queryArg.subCategory,
                    take: queryArg.take,
                    skip: queryArg.skip,
                },
            }),
        }),
        getLatestTeachers: build.query<GetLatestTeachersApiResponse, GetLatestTeachersApiArg>({
            query: () => ({ url: `/api/v1/teachers/latest` }),
        }),
        getTeacherCheckedAnnouncementIds: build.query<
            GetTeacherCheckedAnnouncementIdsApiResponse,
            GetTeacherCheckedAnnouncementIdsApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/teachers/checked-announcement-ids/${queryArg.teacherId}` }),
        }),
        updateTeacherCheckedAnnouncements: build.mutation<
            UpdateTeacherCheckedAnnouncementsApiResponse,
            UpdateTeacherCheckedAnnouncementsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/teachers/checked-announcements/${queryArg.teacherId}/${queryArg.announcementId}`,
                method: "PATCH",
            }),
        }),
        createTeacherIdentification: build.mutation<
            CreateTeacherIdentificationApiResponse,
            CreateTeacherIdentificationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/teacher-identifications`,
                method: "POST",
                body: queryArg.createTeacherIdentificationRequestBody,
            }),
        }),
        approveTeacherIdentification: build.mutation<
            ApproveTeacherIdentificationApiResponse,
            ApproveTeacherIdentificationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/teacher-identifications/approve`,
                method: "POST",
                body: queryArg.body,
            }),
        }),
        disapproveTeacherIdentification: build.mutation<
            DisapproveTeacherIdentificationApiResponse,
            DisapproveTeacherIdentificationApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/teacher-identifications/disapprove`,
                method: "POST",
                body: queryArg.disapproveTeacherIdentificationRequestBody,
            }),
        }),
        getTeacherIdentificationsUnderReview: build.query<
            GetTeacherIdentificationsUnderReviewApiResponse,
            GetTeacherIdentificationsUnderReviewApiArg
        >({
            query: () => ({ url: `/api/v1/teacher-identifications/all` }),
        }),
        getTeacherIdentification: build.query<GetTeacherIdentificationApiResponse, GetTeacherIdentificationApiArg>({
            query: (queryArg) => ({ url: `/api/v1/teacher-identifications/${queryArg.teacherIdentificationId}` }),
        }),
        getExistUser: build.query<GetExistUserApiResponse, GetExistUserApiArg>({
            query: (queryArg) => ({ url: `/api/v1/users/exist/${queryArg.userId}` }),
        }),
        getPublicUser: build.query<GetPublicUserApiResponse, GetPublicUserApiArg>({
            query: (queryArg) => ({ url: `/api/v1/users/public/${queryArg.userId}` }),
        }),
        getPrivateUser: build.query<GetPrivateUserApiResponse, GetPrivateUserApiArg>({
            query: (queryArg) => ({ url: `/api/v1/users/private/${queryArg.userId}` }),
        }),
        updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
            query: (queryArg) => ({ url: `/api/v1/users`, method: "PATCH", body: queryArg.updateUserRequestBody }),
        }),
        deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
            query: (queryArg) => ({ url: `/api/v1/users/${queryArg.userId}`, method: "DELETE" }),
        }),
        getPushNotificationsSetting: build.query<
            GetPushNotificationsSettingApiResponse,
            GetPushNotificationsSettingApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/users/push-notifications-setting/${queryArg.userId}` }),
        }),
        getEmailNotificationsSetting: build.query<
            GetEmailNotificationsSettingApiResponse,
            GetEmailNotificationsSettingApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/users/email-notifications-setting/${queryArg.userId}` }),
        }),
        getStudentAnnouncements: build.query<GetStudentAnnouncementsApiResponse, GetStudentAnnouncementsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/announcements/student/${queryArg.studentId}` }),
        }),
        getTeacherAnnouncements: build.query<GetTeacherAnnouncementsApiResponse, GetTeacherAnnouncementsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/announcements/teacher/${queryArg.teacherId}` }),
        }),
        createStudentMessage: build.mutation<CreateStudentMessageApiResponse, CreateStudentMessageApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/messages/student`,
                method: "POST",
                body: queryArg.createStudentMessageRequestBody,
            }),
        }),
        createTeacherMessage: build.mutation<CreateTeacherMessageApiResponse, CreateTeacherMessageApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/messages/teacher`,
                method: "POST",
                body: queryArg.createTeacherMessageRequestBody,
            }),
        }),
        getStudentMessagesList: build.query<GetStudentMessagesListApiResponse, GetStudentMessagesListApiArg>({
            query: (queryArg) => ({ url: `/api/v1/messages/student/${queryArg.studentId}` }),
        }),
        getTeacherMessagesList: build.query<GetTeacherMessagesListApiResponse, GetTeacherMessagesListApiArg>({
            query: (queryArg) => ({ url: `/api/v1/messages/teacher/${queryArg.teacherId}` }),
        }),
        getMessagesByStudentIdAndTeacherId: build.query<
            GetMessagesByStudentIdAndTeacherIdApiResponse,
            GetMessagesByStudentIdAndTeacherIdApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/messages`,
                params: { studentId: queryArg.studentId, teacherId: queryArg.teacherId },
            }),
        }),
        createPublicRequest: build.mutation<CreatePublicRequestApiResponse, CreatePublicRequestApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/publicRequests`,
                method: "POST",
                body: queryArg.createPublicRequestRequestBody,
            }),
        }),
        updatePublicRequest: build.mutation<UpdatePublicRequestApiResponse, UpdatePublicRequestApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/publicRequests`,
                method: "PATCH",
                body: queryArg.updatePublicRequestRequestBody,
            }),
        }),
        getLatestPublicRequestsForShortCourse: build.query<
            GetLatestPublicRequestsForShortCourseApiResponse,
            GetLatestPublicRequestsForShortCourseApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/publicRequests/latest/short`,
                params: { howMany: queryArg.howMany },
            }),
        }),
        getLatestPublicRequestsForRegularCourse: build.query<
            GetLatestPublicRequestsForRegularCourseApiResponse,
            GetLatestPublicRequestsForRegularCourseApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/publicRequests/latest/regular`,
                params: { howMany: queryArg.howMany },
            }),
        }),
        getApproachingDeadlinePublicRequestsForShortCourse: build.query<
            GetApproachingDeadlinePublicRequestsForShortCourseApiResponse,
            GetApproachingDeadlinePublicRequestsForShortCourseApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/publicRequests/approaching-deadline/short`,
                params: { howMany: queryArg.howMany },
            }),
        }),
        getApproachingDeadlinePublicRequestsForRegularCourse: build.query<
            GetApproachingDeadlinePublicRequestsForRegularCourseApiResponse,
            GetApproachingDeadlinePublicRequestsForRegularCourseApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/publicRequests/approaching-deadline/regular`,
                params: { howMany: queryArg.howMany },
            }),
        }),
        getPublicRequest: build.query<GetPublicRequestApiResponse, GetPublicRequestApiArg>({
            query: (queryArg) => ({ url: `/api/v1/publicRequests/${queryArg.publicRequestId}` }),
        }),
        createDraftPublicRequest: build.mutation<CreateDraftPublicRequestApiResponse, CreateDraftPublicRequestApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/draftPublicRequests`,
                method: "POST",
                body: queryArg.createDraftPublicRequestRequestBody,
            }),
        }),
        updateDraftPublicRequest: build.mutation<UpdateDraftPublicRequestApiResponse, UpdateDraftPublicRequestApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/draftPublicRequests`,
                method: "PATCH",
                body: queryArg.updateDraftPublicRequestRequestBody,
            }),
        }),
        getMyDraftPublicRequests: build.query<GetMyDraftPublicRequestsApiResponse, GetMyDraftPublicRequestsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/draftPublicRequests/my`, params: { studentId: queryArg.studentId } }),
        }),
        getDraftPublicRequest: build.query<GetDraftPublicRequestApiResponse, GetDraftPublicRequestApiArg>({
            query: (queryArg) => ({ url: `/api/v1/draftPublicRequests/${queryArg.draftPublicRequestId}` }),
        }),
        deleteDraftPublicRequest: build.mutation<DeleteDraftPublicRequestApiResponse, DeleteDraftPublicRequestApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/draftPublicRequests/${queryArg.draftPublicRequestId}`,
                method: "DELETE",
            }),
        }),
        createProposal: build.mutation<CreateProposalApiResponse, CreateProposalApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/proposals`,
                method: "POST",
                body: queryArg.createProposalRequestBody,
            }),
        }),
        updateProposal: build.mutation<UpdateProposalApiResponse, UpdateProposalApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/proposals`,
                method: "PATCH",
                body: queryArg.updateProposalRequestBody,
            }),
        }),
        getProposal: build.query<GetProposalApiResponse, GetProposalApiArg>({
            query: (queryArg) => ({ url: `/api/v1/proposals/${queryArg.proposalId}` }),
        }),
        deleteProposal: build.mutation<DeleteProposalApiResponse, DeleteProposalApiArg>({
            query: (queryArg) => ({ url: `/api/v1/proposals/${queryArg.proposalId}`, method: "DELETE" }),
        }),
        reserveProposedShortCourse: build.mutation<
            ReserveProposedShortCourseApiResponse,
            ReserveProposedShortCourseApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/proposals/reserve/proposed/short`,
                method: "POST",
                body: queryArg.reserveProposedShortCourseRequestBody,
            }),
        }),
        reserveProposedRegularCourse: build.mutation<
            ReserveProposedRegularCourseApiResponse,
            ReserveProposedRegularCourseApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/proposals/reserve/proposed/regular`,
                method: "POST",
                body: queryArg.reserveProposedRegularCourseRequestBody,
            }),
        }),
        getPublicRequestSearchResults: build.query<
            GetPublicRequestSearchResultsApiResponse,
            GetPublicRequestSearchResultsApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/publicRequests/search/result`,
                params: {
                    orderBy: queryArg.orderBy,
                    keyword: queryArg.keyword,
                    courseType: queryArg.courseType,
                    level: queryArg.level,
                    category: queryArg.category,
                    subCategory: queryArg.subCategory,
                    teacherId: queryArg.teacherId,
                    take: queryArg.take,
                    skip: queryArg.skip,
                },
            }),
        }),
        getPublicRequestFavoriteCount: build.query<
            GetPublicRequestFavoriteCountApiResponse,
            GetPublicRequestFavoriteCountApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/publicRequests/favorite-count/${queryArg.publicRequestId}` }),
        }),
        createDummyPublicRequests: build.mutation<
            CreateDummyPublicRequestsApiResponse,
            CreateDummyPublicRequestsApiArg
        >({
            query: () => ({ url: `/api/v1/publicRequests/dummy`, method: "POST" }),
        }),
        getEducationalBackgroundsByInitial: build.query<
            GetEducationalBackgroundsByInitialApiResponse,
            GetEducationalBackgroundsByInitialApiArg
        >({
            query: (queryArg) => ({ url: `/api/v1/educational-backgrounds`, params: { initial: queryArg.initial } }),
        }),
        getPointsHistory: build.query<GetPointsHistoryApiResponse, GetPointsHistoryApiArg>({
            query: (queryArg) => ({ url: `/api/v1/point-records/user/${queryArg.userId}` }),
        }),
        getProceedsRecords: build.query<GetProceedsRecordsApiResponse, GetProceedsRecordsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/proceeds-records/${queryArg.teacherId}` }),
        }),
        createStandardAccount: build.mutation<CreateStandardAccountApiResponse, CreateStandardAccountApiArg>({
            query: (queryArg) => ({ url: `/api/v1/stripe/account/${queryArg.userId}`, method: "POST" }),
        }),
        createBankAccount: build.mutation<CreateBankAccountApiResponse, CreateBankAccountApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/stripe/bank-account`,
                method: "POST",
                body: queryArg.createBankAccountRequestBody,
            }),
        }),
        getBankAccountList: build.query<GetBankAccountListApiResponse, GetBankAccountListApiArg>({
            query: (queryArg) => ({ url: `/api/v1/stripe/bank-account/${queryArg.teacherId}` }),
        }),
        payout: build.mutation<PayoutApiResponse, PayoutApiArg>({
            query: (queryArg) => ({ url: `/api/v1/stripe/payout`, method: "POST", body: queryArg.payoutRequestBody }),
        }),
        getStripeAccount: build.query<GetStripeAccountApiResponse, GetStripeAccountApiArg>({
            query: (queryArg) => ({ url: `/api/v1/stripe/account/${queryArg.teacherId}` }),
        }),
        updateStripeAccountForConnect: build.mutation<
            UpdateStripeAccountForConnectApiResponse,
            UpdateStripeAccountForConnectApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/stripe/account`,
                method: "PATCH",
                body: queryArg.updateStripeAccountForConnectRequestBody,
            }),
        }),
        resubmitVerificationDocument: build.mutation<
            ResubmitVerificationDocumentApiResponse,
            ResubmitVerificationDocumentApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/stripe/account/verification-document`,
                method: "PATCH",
                body: queryArg.resubmitVerificationDocumentRequestBody,
            }),
        }),
        getBalanceTransactions: build.query<GetBalanceTransactionsApiResponse, GetBalanceTransactionsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/stripe/balance-transactions/${queryArg.teacherId}` }),
        }),
        getPaymentIntent: build.query<GetPaymentIntentApiResponse, GetPaymentIntentApiArg>({
            query: (queryArg) => ({ url: `/api/v1/stripe/payment-intent/${queryArg.paymentIntentId}` }),
        }),
        createJwt: build.mutation<CreateJwtApiResponse, CreateJwtApiArg>({
            query: (queryArg) => ({ url: `/api/v1/JWT`, method: "POST", body: queryArg.jwtRequestBody }),
        }),
        getDataFromJwt: build.query<GetDataFromJwtApiResponse, GetDataFromJwtApiArg>({
            query: (queryArg) => ({ url: `/api/v1/JWT`, params: { token: queryArg.token } }),
        }),
        createAdminJwt: build.mutation<CreateAdminJwtApiResponse, CreateAdminJwtApiArg>({
            query: (queryArg) => ({ url: `/api/v1/AdminJWT`, method: "POST", body: queryArg.adminJwtRequestBody }),
        }),
        getDataFromAdminJwt: build.query<GetDataFromAdminJwtApiResponse, GetDataFromAdminJwtApiArg>({
            query: (queryArg) => ({ url: `/api/v1/AdminJWT`, params: { token: queryArg.token } }),
        }),
        createEducationalBackgrounds: build.mutation<
            CreateEducationalBackgroundsApiResponse,
            CreateEducationalBackgroundsApiArg
        >({
            query: () => ({ url: `/api/v1/educationalBackgrounds`, method: "POST" }),
        }),
        getZoomUserList: build.query<GetZoomUserListApiResponse, GetZoomUserListApiArg>({
            query: () => ({ url: `/api/v1/zoom/user-list` }),
        }),
        getZoomUser: build.query<GetZoomUserApiResponse, GetZoomUserApiArg>({
            query: (queryArg) => ({ url: `/api/v1/zoom/users/${queryArg.zoomUserId}` }),
        }),
        deleteZoomUser: build.mutation<DeleteZoomUserApiResponse, DeleteZoomUserApiArg>({
            query: (queryArg) => ({ url: `/api/v1/zoom/users/${queryArg.zoomUserId}`, method: "DELETE" }),
        }),
        createSubjects: build.mutation<CreateSubjectsApiResponse, CreateSubjectsApiArg>({
            query: () => ({ url: `/api/v1/subjects`, method: "POST" }),
        }),
        getSubjects: build.query<GetSubjectsApiResponse, GetSubjectsApiArg>({
            query: () => ({ url: `/api/v1/subjects` }),
        }),
        getPresignedUrlForGet: build.query<GetPresignedUrlForGetApiResponse, GetPresignedUrlForGetApiArg>({
            query: (queryArg) => ({ url: `/api/v1/s3`, params: { objectKey: queryArg.objectKey } }),
        }),
        uploadFiles: build.mutation<UploadFilesApiResponse, UploadFilesApiArg>({
            query: () => ({ url: `/api/v1/upload`, method: "POST" }),
        }),
        createSetUpIntent: build.mutation<CreateSetUpIntentApiResponse, CreateSetUpIntentApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/stripe/payment-intent-set-up`,
                method: "POST",
                body: queryArg.createSetUpIntentRequestBody,
            }),
        }),
        getPaymentMethods: build.query<GetPaymentMethodsApiResponse, GetPaymentMethodsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/stripe/card-info-list/${queryArg.studentId}` }),
        }),
        updateDefaultPaymentMethod: build.mutation<
            UpdateDefaultPaymentMethodApiResponse,
            UpdateDefaultPaymentMethodApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/stripe/default-payment-method`,
                method: "PATCH",
                body: queryArg.updateDefaultPaymentMethodRequestBody,
            }),
        }),
        detachPaymentMethod: build.mutation<DetachPaymentMethodApiResponse, DetachPaymentMethodApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/stripe/payment-method/${queryArg.paymentMethodId}`,
                method: "DELETE",
            }),
        }),
        getDefaultPaymentMethod: build.query<GetDefaultPaymentMethodApiResponse, GetDefaultPaymentMethodApiArg>({
            query: (queryArg) => ({ url: `/api/v1/stripe/default-payment-method/${queryArg.studentId}` }),
        }),
        makeLatestCreditCardDefault: build.mutation<
            MakeLatestCreditCardDefaultApiResponse,
            MakeLatestCreditCardDefaultApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/stripe/latest-credit-card/default`,
                method: "PATCH",
                body: queryArg.makeLatestPaymentMethodDefaultRequestBody,
            }),
        }),
        deleteBankAccount: build.mutation<DeleteBankAccountApiResponse, DeleteBankAccountApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/stripe/bank_account`,
                method: "DELETE",
                body: queryArg.deleteBankAccountRequestBody,
            }),
        }),
        updateDefaultBankAccount: build.mutation<UpdateDefaultBankAccountApiResponse, UpdateDefaultBankAccountApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/stripe/bank_account/default`,
                method: "PATCH",
                body: queryArg.updateDefaultBankAccountRequestBody,
            }),
        }),
        uploadFilesToStripe: build.mutation<UploadFilesToStripeApiResponse, UploadFilesToStripeApiArg>({
            query: () => ({ url: `/api/v1/stripe/upload`, method: "POST" }),
        }),
        getBalance: build.query<GetBalanceApiResponse, GetBalanceApiArg>({
            query: (queryArg) => ({ url: `/api/v1/stripe/balance/${queryArg.teacherId}` }),
        }),
        getBankInfoList: build.query<GetBankInfoListApiResponse, GetBankInfoListApiArg>({
            query: () => ({ url: `/api/v1/csv/bank-info` }),
        }),
        getAddressInfoList: build.query<GetAddressInfoListApiResponse, GetAddressInfoListApiArg>({
            query: () => ({ url: `/api/v1/csv/address-info-list` }),
        }),
        toKana: build.query<ToKanaApiResponse, ToKanaApiArg>({
            query: (queryArg) => ({ url: `/api/v1/to-kana`, params: { keyword: queryArg.keyword } }),
        }),
        getPrivateRequests: build.query<GetPrivateRequestsApiResponse, GetPrivateRequestsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/private-requests/${queryArg.studentId}` }),
        }),
        createPrivateRequest: build.mutation<CreatePrivateRequestApiResponse, CreatePrivateRequestApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/private-requests`,
                method: "POST",
                body: queryArg.createPrivateRequestRequestBody,
            }),
        }),
        approvePrivateRequest: build.mutation<ApprovePrivateRequestApiResponse, ApprovePrivateRequestApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/private-requests/approve`,
                method: "POST",
                body: queryArg.approvePrivateRequestRequestBody,
            }),
        }),
        disapprovePrivateRequest: build.mutation<DisapprovePrivateRequestApiResponse, DisapprovePrivateRequestApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/private-requests/disapprove`,
                method: "POST",
                body: queryArg.disapprovePrivateRequestRequestBody,
            }),
        }),
        deletePrivateRequest: build.mutation<DeletePrivateRequestApiResponse, DeletePrivateRequestApiArg>({
            query: (queryArg) => ({ url: `/api/v1/private-requests/${queryArg.privateRequestId}`, method: "DELETE" }),
        }),
        rejectPayingForCourse: build.mutation<RejectPayingForCourseApiResponse, RejectPayingForCourseApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/private-requests/reject/paying-for-course`,
                method: "POST",
                body: queryArg.rejectPayingForCourseRequestBody,
            }),
        }),
        updateNotificationsSetting: build.mutation<
            UpdateNotificationsSettingApiResponse,
            UpdateNotificationsSettingApiArg
        >({
            query: (queryArg) => ({
                url: `/api/v1/notifications-setting`,
                method: "POST",
                body: queryArg.updateNotificationsSettingRequestBody,
            }),
        }),
        invalidateTags: build.mutation<InvalidateTagsApiResponse, InvalidateTagsApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/invalidate-tags`,
                method: "POST",
                params: { targetAccountType: queryArg.targetAccountType },
            }),
        }),
        scheduleJobs: build.mutation<ScheduleJobsApiResponse, ScheduleJobsApiArg>({
            query: () => ({ url: `/api/v1/node-scheduler`, method: "POST" }),
        }),
        getIndividualChat: build.query<GetIndividualChatApiResponse, GetIndividualChatApiArg>({
            query: (queryArg) => ({ url: `/api/v1/chat/individual/${queryArg.studentId}/${queryArg.teacherId}` }),
        }),
        getGroupChat: build.query<GetGroupChatApiResponse, GetGroupChatApiArg>({
            query: (queryArg) => ({ url: `/api/v1/chat/group/${queryArg.chatId}` }),
        }),
        getStudentChats: build.query<GetStudentChatsApiResponse, GetStudentChatsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/chats/student/${queryArg.studentId}` }),
        }),
        getTeacherChats: build.query<GetTeacherChatsApiResponse, GetTeacherChatsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/chats/teacher/${queryArg.teacherId}` }),
        }),
        updateReadStatus: build.mutation<UpdateReadStatusApiResponse, UpdateReadStatusApiArg>({
            query: (queryArg) => ({ url: `/api/v1/read-statuses/${queryArg.readStatusId}`, method: "POST" }),
        }),
        updateBaseSchedules: build.mutation<UpdateBaseSchedulesApiResponse, UpdateBaseSchedulesApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/base-schedules/update`,
                method: "POST",
                body: queryArg.updateBaseSchedulesRequestBody,
            }),
        }),
        createBanner: build.mutation<CreateBannerApiResponse, CreateBannerApiArg>({
            query: (queryArg) => ({ url: `/api/v1/banners`, method: "POST", body: queryArg.createBannerRequestBody }),
        }),
        updateBanner: build.mutation<UpdateBannerApiResponse, UpdateBannerApiArg>({
            query: (queryArg) => ({ url: `/api/v1/banners`, method: "PATCH", body: queryArg.updateBannerRequestBody }),
        }),
        getBanners: build.query<GetBannersApiResponse, GetBannersApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/banners`,
                params: { targetType: queryArg.targetType, deviceType: queryArg.deviceType },
            }),
        }),
        getBanner: build.query<GetBannerApiResponse, GetBannerApiArg>({
            query: (queryArg) => ({ url: `/api/v1/banners/${queryArg.bannerId}` }),
        }),
        deleteBanner: build.mutation<DeleteBannerApiResponse, DeleteBannerApiArg>({
            query: (queryArg) => ({ url: `/api/v1/banners/${queryArg.bannerId}`, method: "DELETE" }),
        }),
        createNewsRecord: build.mutation<CreateNewsRecordApiResponse, CreateNewsRecordApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/newsRecords`,
                method: "POST",
                body: queryArg.createNewsRecordRequestBody,
            }),
        }),
        updateNewsRecord: build.mutation<UpdateNewsRecordApiResponse, UpdateNewsRecordApiArg>({
            query: (queryArg) => ({
                url: `/api/v1/newsRecords`,
                method: "PATCH",
                body: queryArg.updateNewsRecordRequestBody,
            }),
        }),
        getNewsRecords: build.query<GetNewsRecordsApiResponse, GetNewsRecordsApiArg>({
            query: (queryArg) => ({ url: `/api/v1/newsRecords`, params: { targetType: queryArg.targetType } }),
        }),
        getNewsRecord: build.query<GetNewsRecordApiResponse, GetNewsRecordApiArg>({
            query: (queryArg) => ({ url: `/api/v1/newsRecords/${queryArg.newsRecordId}` }),
        }),
        deleteNewsRecord: build.mutation<DeleteNewsRecordApiResponse, DeleteNewsRecordApiArg>({
            query: (queryArg) => ({ url: `/api/v1/newsRecords/${queryArg.newsRecordId}`, method: "DELETE" }),
        }),
        getArticles: build.query<GetArticlesApiResponse, GetArticlesApiArg>({
            query: (queryArg) => ({ url: `/api/v1/articles`, params: { targetType: queryArg.targetType } }),
        }),
        basicAuth: build.query<BasicAuthApiResponse, BasicAuthApiArg>({
            query: () => ({ url: `/api/v1/basic-auth` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as autogenApi };
export type GetConfigApiResponse = /** status 200 Ok */ ConfigurationResponse;
export type GetConfigApiArg = void;
export type GetAdminConfigApiResponse = /** status 200 Ok */ AdminConfigurationResponse;
export type GetAdminConfigApiArg = void;
export type HealthcheckApiResponse = /** status 200 Ok */ {
    backend: boolean;
};
export type HealthcheckApiArg = void;
export type MigrateImageUrlsApiResponse = /** status 200 Ok */ "ok";
export type MigrateImageUrlsApiArg = void;
export type CreateStudentApiResponse = /** status 200 Ok */ PrivateStudentResponse;
export type CreateStudentApiArg = {
    createStudentRequestBody: CreateStudentRequestBody;
};
export type UpdateStudentApiResponse = /** status 200 Ok */ PrivateStudentResponse;
export type UpdateStudentApiArg = {
    updateStudentRequestBody: UpdateStudentRequestBody;
};
export type GetPrivateStudentApiResponse = /** status 200 Ok */ PrivateStudentResponse;
export type GetPrivateStudentApiArg = {
    studentId: string;
};
export type GetPublicStudentApiResponse = /** status 200 Ok */ PublicStudentResponse;
export type GetPublicStudentApiArg = {
    studentId: string;
};
export type GetMyPublicRequestsApiResponse = /** status 200 Ok */ PublicRequestResponse[];
export type GetMyPublicRequestsApiArg = {
    studentId: string;
};
export type UpdateFavoriteCoursesApiResponse = /** status 200 Ok */ boolean;
export type UpdateFavoriteCoursesApiArg = {
    updateFavoriteCoursesRequestBody: UpdateFavoriteCoursesRequestBody;
};
export type UpdateCourseBrowsingHistoryApiResponse = unknown;
export type UpdateCourseBrowsingHistoryApiArg = {
    updateCourseBrowsingHistoryRequestBody: UpdateCourseBrowsingHistoryRequestBody;
};
export type DeleteStudentApiResponse = unknown;
export type DeleteStudentApiArg = {
    studentId: string;
};
export type GetReservedClassesApiResponse = /** status 200 Ok */ ClassResponse[];
export type GetReservedClassesApiArg = {
    studentId: string;
};
export type GetClassesCompletedApiResponse = /** status 200 Ok */ ClassResponse[];
export type GetClassesCompletedApiArg = {
    studentId: string;
};
export type GetAllFavoriteCoursesApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetAllFavoriteCoursesApiArg = {
    studentId: string;
};
export type GetFavoriteCoursesApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetFavoriteCoursesApiArg = {
    studentId: string;
    courseType?: CourseType;
};
export type GetCourseBrowsingHistoryApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetCourseBrowsingHistoryApiArg = {
    studentId: string;
    courseType?: CourseType;
};
export type GetPastCoursesApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetPastCoursesApiArg = {
    studentId: string;
};
export type GetStudentCheckedAnnouncementIdsApiResponse = /** status 200 Ok */ NominalTypingAnnouncementString[];
export type GetStudentCheckedAnnouncementIdsApiArg = {
    studentId: string;
};
export type UpdateStudentCheckedAnnouncementsApiResponse = unknown;
export type UpdateStudentCheckedAnnouncementsApiArg = {
    studentId: string;
    announcementId: string;
};
export type CreateCourseApiResponse = /** status 200 Ok */ CourseResponse;
export type CreateCourseApiArg = {
    createCourseRequestBody: CreateCourseRequestBody;
};
export type UpdateCourseApiResponse = /** status 200 Ok */ CourseResponse;
export type UpdateCourseApiArg = {
    updateCourseRequestBody: UpdateCourseRequestBody;
};
export type DeleteCourseApiResponse = unknown;
export type DeleteCourseApiArg = {
    courseId: string;
};
export type WithdrawFromClassApiResponse = unknown;
export type WithdrawFromClassApiArg = {
    withdrawFromClassRequestBody: WithdrawFromClassRequestBody;
};
export type ReserveShortCourseApiResponse = unknown;
export type ReserveShortCourseApiArg = {
    reserveShortCourseRequestBody: ReserveShortCourseRequestBody;
};
export type ReserveRegularCourseApiResponse = unknown;
export type ReserveRegularCourseApiArg = {
    reserveRegularCourseRequestBody: ReserveRegularCourseRequestBody;
};
export type CloseApplicationApiResponse = unknown;
export type CloseApplicationApiArg = {
    closeApplicationRequestBody: CloseApplicationRequestBody;
};
export type GetClassByClassIdApiResponse = /** status 200 Ok */ ClassResponse;
export type GetClassByClassIdApiArg = {
    classId: string;
};
export type ApplyForUpdateMonthlyFeeApiResponse = unknown;
export type ApplyForUpdateMonthlyFeeApiArg = {
    applyForUpdateMonthlyFeeRequestBody: ApplyForUpdateMonthlyFeeRequestBody;
};
export type ApplyForTemporaryUpdateMonthlyFeeApiResponse = unknown;
export type ApplyForTemporaryUpdateMonthlyFeeApiArg = {
    applyForTemporaryUpdateMonthlyFeeRequestBody: ApplyForTemporaryUpdateMonthlyFeeRequestBody;
};
export type DisapproveUpdateMonthlyFeeApiResponse = unknown;
export type DisapproveUpdateMonthlyFeeApiArg = {
    disapproveUpdateMonthlyFeeRequestBody: DisapproveUpdateMonthlyFeeRequestBody;
};
export type ApproveUpdateMonthlyFeeApiResponse = unknown;
export type ApproveUpdateMonthlyFeeApiArg = {
    approveUpdateMonthlyFeeRequestBody: ApproveUpdateMonthlyFeeRequestBody;
};
export type DisapproveTemporaryUpdateMonthlyFeeApiResponse = unknown;
export type DisapproveTemporaryUpdateMonthlyFeeApiArg = {
    disapproveTemporaryUpdateMonthlyFeeRequestBody: DisapproveTemporaryUpdateMonthlyFeeRequestBody;
};
export type ApproveTemporaryUpdateMonthlyFeeApiResponse = unknown;
export type ApproveTemporaryUpdateMonthlyFeeApiArg = {
    approveTemporaryUpdateMonthlyFeeRequestBody: ApproveTemporaryUpdateMonthlyFeeRequestBody;
};
export type ReportToCompleteClassApiResponse = unknown;
export type ReportToCompleteClassApiArg = {
    classId: string;
};
export type UpdateMemoApiResponse = unknown;
export type UpdateMemoApiArg = {
    updateMemoRequestBody: UpdateMemoRequestBody;
};
export type UpdateClassNameApiResponse = unknown;
export type UpdateClassNameApiArg = {
    updateClassNameRequestBody: UpdateClassNameRequestBody;
};
export type AddClassApiResponse = unknown;
export type AddClassApiArg = {
    addClassRequestBody: AddClassRequestBody;
};
export type GetCourseForCourseDetailsApiResponse = /** status 200 Ok */ CourseResponse;
export type GetCourseForCourseDetailsApiArg = {
    courseId: string;
};
export type GetCourseForUpdatingCourseApiResponse = /** status 200 Ok */ CourseResponse;
export type GetCourseForUpdatingCourseApiArg = {
    courseId: string;
};
export type GetCourseWithValidLessonsApiResponse = /** status 200 Ok */ CourseResponse;
export type GetCourseWithValidLessonsApiArg = {
    courseId: string;
};
export type GetCourseForCourseManagementApiResponse = /** status 200 Ok */ CourseResponse;
export type GetCourseForCourseManagementApiArg = {
    courseId: string;
};
export type GetLatestShortCoursesApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetLatestShortCoursesApiArg = {
    howMany: number;
};
export type GetLatestRegularCoursesApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetLatestRegularCoursesApiArg = {
    howMany: number;
};
export type GetApproachingDeadlineShortCoursesApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetApproachingDeadlineShortCoursesApiArg = {
    howMany: number;
};
export type GetApproachingDeadlineRegularCoursesApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetApproachingDeadlineRegularCoursesApiArg = {
    howMany: number;
};
export type GetCourseSearchResultsApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetCourseSearchResultsApiArg = {
    orderBy?: OrderBy;
    keyword?: string;
    courseType?: CourseType;
    university?: string;
    faculty?: string;
    department?: string;
    rankNumber?: string;
    level?: string;
    category?: string;
    subCategory?: string;
    studentId?: string;
    take?: number;
    skip?: number;
};
export type GetCourseFavoriteCountApiResponse = /** status 200 Ok */ number;
export type GetCourseFavoriteCountApiArg = {
    courseId: string;
};
export type GetRecommendedShortCoursesApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetRecommendedShortCoursesApiArg = {
    studentId: string;
    howMany: number;
};
export type GetRecommendedRegularCoursesApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetRecommendedRegularCoursesApiArg = {
    studentId: string;
    howMany: number;
};
export type CreateDraftCourseApiResponse = /** status 200 Ok */ DraftCourseResponse;
export type CreateDraftCourseApiArg = {
    createDraftCourseRequestBody: CreateDraftCourseRequestBody;
};
export type UpdateDraftCourseApiResponse = /** status 200 Ok */ DraftCourseResponse;
export type UpdateDraftCourseApiArg = {
    updateDraftCourseRequestBody: UpdateDraftCourseRequestBody;
};
export type GetMyDraftCoursesApiResponse = /** status 200 Ok */ DraftCourseResponse[];
export type GetMyDraftCoursesApiArg = {
    teacherId: string;
};
export type GetDraftCourseApiResponse = /** status 200 Ok */ DraftCourseResponse;
export type GetDraftCourseApiArg = {
    draftCourseId: string;
};
export type DeleteDraftCourseApiResponse = unknown;
export type DeleteDraftCourseApiArg = {
    draftCourseId: string;
};
export type DeleteLessonApiResponse = unknown;
export type DeleteLessonApiArg = {
    lessonId: string;
};
export type GetStudentLessonsApiResponse = /** status 200 Ok */ LessonResponse[];
export type GetStudentLessonsApiArg = {
    studentId: string;
};
export type GetTeacherLessonsApiResponse = /** status 200 Ok */ LessonResponse[];
export type GetTeacherLessonsApiArg = {
    teacherId: string;
};
export type WriteSimultaneousLessonsCountApiResponse = unknown;
export type WriteSimultaneousLessonsCountApiArg = void;
export type AddLessonToClassApiResponse = unknown;
export type AddLessonToClassApiArg = {
    addLessonToClassRequestBody: AddLessonToClassRequestBody;
};
export type UpdateLessonApiResponse = unknown;
export type UpdateLessonApiArg = {
    updateLessonRequestBody: UpdateLessonRequestBody;
};
export type CreateReviewApiResponse = unknown;
export type CreateReviewApiArg = {
    createReviewRequestBody: CreateReviewRequestBody;
};
export type GetReviewsByCourseIdApiResponse = /** status 200 Ok */ ReviewResponse[];
export type GetReviewsByCourseIdApiArg = {
    courseId: string;
};
export type GetReviewsByStudentIdApiResponse = /** status 200 Ok */ ReviewResponse[];
export type GetReviewsByStudentIdApiArg = {
    studentId: string;
};
export type GetLatestReviewsApiResponse = /** status 200 Ok */ ReviewResponse[];
export type GetLatestReviewsApiArg = void;
export type CompleteStudentToDoApiResponse = /** status 200 Ok */ StudentToDoResponse;
export type CompleteStudentToDoApiArg = {
    studentToDoId: string;
};
export type GetStudentToDosApiResponse = /** status 200 Ok */ StudentToDoResponse[];
export type GetStudentToDosApiArg = {
    studentId: string;
};
export type UpdateTeacherToDoApiResponse = /** status 200 Ok */ TeacherToDoResponse;
export type UpdateTeacherToDoApiArg = {
    teacherToDoId: string;
};
export type DeleteTeacherToDosApiResponse = unknown;
export type DeleteTeacherToDosApiArg = {
    teacherToDoId: string;
};
export type GetTeacherToDosApiResponse = /** status 200 Ok */ TeacherToDoResponse[];
export type GetTeacherToDosApiArg = {
    teacherId: string;
};
export type CreateTeacherApiResponse = /** status 200 Ok */ PrivateTeacherResponse;
export type CreateTeacherApiArg = {
    createTeacherRequestBody: CreateTeacherRequestBody;
};
export type UpdateTeacherApiResponse = /** status 200 Ok */ PrivateTeacherResponse;
export type UpdateTeacherApiArg = {
    updateTeacherRequestBody: UpdateTeacherRequestBody;
};
export type LinkZoomAccountApiResponse = unknown;
export type LinkZoomAccountApiArg = {
    linkZoomAccountRequestBody: LinkZoomAccountRequestBody;
};
export type GetPrivateTeacherApiResponse = /** status 200 Ok */ PrivateTeacherResponse;
export type GetPrivateTeacherApiArg = {
    teacherId: string;
};
export type GetPublicTeacherApiResponse = /** status 200 Ok */ PublicTeacherResponse;
export type GetPublicTeacherApiArg = {
    teacherId: string;
};
export type UpdateFavoritePublicRequestsApiResponse = /** status 200 Ok */ boolean;
export type UpdateFavoritePublicRequestsApiArg = {
    updateFavoritePublicRequestsRequestBody: UpdateFavoritePublicRequestsRequestBody;
};
export type UpdatePublicRequestBrowsingHistoryApiResponse = unknown;
export type UpdatePublicRequestBrowsingHistoryApiArg = {
    updatePublicRequestBrowsingHistoryRequestBody: UpdatePublicRequestBrowsingHistoryRequestBody;
};
export type DeleteTeacherApiResponse = unknown;
export type DeleteTeacherApiArg = {
    teacherId: string;
};
export type GetMyCoursesByTeacherIdApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetMyCoursesByTeacherIdApiArg = {
    teacherId: string;
};
export type GetMyProposalsByTeacherIdApiResponse = /** status 200 Ok */ ProposalResponse[];
export type GetMyProposalsByTeacherIdApiArg = {
    teacherId: string;
};
export type GetCoursesOfTargetTeacherApiResponse = /** status 200 Ok */ CourseResponse[];
export type GetCoursesOfTargetTeacherApiArg = {
    teacherId: string;
};
export type GetAllFavoritePublicRequestsApiResponse = /** status 200 Ok */ PublicRequestResponse[];
export type GetAllFavoritePublicRequestsApiArg = {
    teacherId: string;
};
export type GetFavoritePublicRequestsApiResponse = /** status 200 Ok */ PublicRequestResponse[];
export type GetFavoritePublicRequestsApiArg = {
    teacherId: string;
    courseType?: CourseType;
};
export type GetPublicRequestBrowsingHistoryApiResponse = /** status 200 Ok */ PublicRequestResponse[];
export type GetPublicRequestBrowsingHistoryApiArg = {
    teacherId: string;
    courseType?: CourseType;
};
export type GetTeacherSearchResultsApiResponse = /** status 200 Ok */ PublicTeacherResponse[];
export type GetTeacherSearchResultsApiArg = {
    orderBy?: OrderBy;
    keyword?: string;
    university?: string;
    faculty?: string;
    department?: string;
    rankNumber?: string;
    level?: string;
    category?: string;
    subCategory?: string;
    take?: number;
    skip?: number;
};
export type GetLatestTeachersApiResponse = /** status 200 Ok */ PublicTeacherResponse[];
export type GetLatestTeachersApiArg = void;
export type GetTeacherCheckedAnnouncementIdsApiResponse = /** status 200 Ok */ NominalTypingAnnouncementString[];
export type GetTeacherCheckedAnnouncementIdsApiArg = {
    teacherId: string;
};
export type UpdateTeacherCheckedAnnouncementsApiResponse = unknown;
export type UpdateTeacherCheckedAnnouncementsApiArg = {
    teacherId: string;
    announcementId: string;
};
export type CreateTeacherIdentificationApiResponse = /** status 200 Ok */ TeacherIdentificationResponse;
export type CreateTeacherIdentificationApiArg = {
    createTeacherIdentificationRequestBody: CreateTeacherIdentificationRequestBody;
};
export type ApproveTeacherIdentificationApiResponse = unknown;
export type ApproveTeacherIdentificationApiArg = {
    body: {
        teacherIdentificationId: string;
    };
};
export type DisapproveTeacherIdentificationApiResponse = unknown;
export type DisapproveTeacherIdentificationApiArg = {
    disapproveTeacherIdentificationRequestBody: DisapproveTeacherIdentificationRequestBody;
};
export type GetTeacherIdentificationsUnderReviewApiResponse = /** status 200 Ok */ TeacherIdentificationResponse[];
export type GetTeacherIdentificationsUnderReviewApiArg = void;
export type GetTeacherIdentificationApiResponse = /** status 200 Ok */ TeacherIdentificationResponse;
export type GetTeacherIdentificationApiArg = {
    teacherIdentificationId: string;
};
export type GetExistUserApiResponse = /** status 200 Ok */ boolean;
export type GetExistUserApiArg = {
    userId: string;
};
export type GetPublicUserApiResponse = /** status 200 Ok */ PublicUserResponse;
export type GetPublicUserApiArg = {
    userId: string;
};
export type GetPrivateUserApiResponse = /** status 200 Ok */ PrivateUserResponse | null;
export type GetPrivateUserApiArg = {
    userId: string;
};
export type UpdateUserApiResponse = /** status 200 Ok */ PrivateUserResponse;
export type UpdateUserApiArg = {
    updateUserRequestBody: UpdateUserRequestBody;
};
export type DeleteUserApiResponse = unknown;
export type DeleteUserApiArg = {
    userId: string;
};
export type GetPushNotificationsSettingApiResponse = /** status 200 Ok */ PushNotificationsSettingResponse;
export type GetPushNotificationsSettingApiArg = {
    userId: string;
};
export type GetEmailNotificationsSettingApiResponse = /** status 200 Ok */ EmailNotificationsSettingResponse;
export type GetEmailNotificationsSettingApiArg = {
    userId: string;
};
export type GetStudentAnnouncementsApiResponse = /** status 200 Ok */ AnnouncementResponse[];
export type GetStudentAnnouncementsApiArg = {
    studentId: string;
};
export type GetTeacherAnnouncementsApiResponse = /** status 200 Ok */ AnnouncementResponse[];
export type GetTeacherAnnouncementsApiArg = {
    teacherId: string;
};
export type CreateStudentMessageApiResponse = /** status 200 Ok */ MessageResponse;
export type CreateStudentMessageApiArg = {
    createStudentMessageRequestBody: CreateStudentMessageRequestBody;
};
export type CreateTeacherMessageApiResponse = /** status 200 Ok */ MessageResponse;
export type CreateTeacherMessageApiArg = {
    createTeacherMessageRequestBody: CreateTeacherMessageRequestBody;
};
export type GetStudentMessagesListApiResponse = /** status 200 Ok */ MessageResponse[][];
export type GetStudentMessagesListApiArg = {
    studentId: string;
};
export type GetTeacherMessagesListApiResponse = /** status 200 Ok */ MessageResponse[][];
export type GetTeacherMessagesListApiArg = {
    teacherId: string;
};
export type GetMessagesByStudentIdAndTeacherIdApiResponse = /** status 200 Ok */ MessageResponse[];
export type GetMessagesByStudentIdAndTeacherIdApiArg = {
    studentId: string;
    teacherId: string;
};
export type CreatePublicRequestApiResponse = /** status 200 Ok */ PublicRequestResponse;
export type CreatePublicRequestApiArg = {
    createPublicRequestRequestBody: CreatePublicRequestRequestBody;
};
export type UpdatePublicRequestApiResponse = /** status 200 Ok */ PublicRequestResponse;
export type UpdatePublicRequestApiArg = {
    updatePublicRequestRequestBody: UpdatePublicRequestRequestBody;
};
export type GetLatestPublicRequestsForShortCourseApiResponse = /** status 200 Ok */ PublicRequestResponse[];
export type GetLatestPublicRequestsForShortCourseApiArg = {
    howMany: number;
};
export type GetLatestPublicRequestsForRegularCourseApiResponse = /** status 200 Ok */ PublicRequestResponse[];
export type GetLatestPublicRequestsForRegularCourseApiArg = {
    howMany: number;
};
export type GetApproachingDeadlinePublicRequestsForShortCourseApiResponse =
    /** status 200 Ok */ PublicRequestResponse[];
export type GetApproachingDeadlinePublicRequestsForShortCourseApiArg = {
    howMany: number;
};
export type GetApproachingDeadlinePublicRequestsForRegularCourseApiResponse =
    /** status 200 Ok */ PublicRequestResponse[];
export type GetApproachingDeadlinePublicRequestsForRegularCourseApiArg = {
    howMany: number;
};
export type GetPublicRequestApiResponse = /** status 200 Ok */ PublicRequestResponse;
export type GetPublicRequestApiArg = {
    publicRequestId: string;
};
export type CreateDraftPublicRequestApiResponse = /** status 200 Ok */ DraftPublicRequestResponse;
export type CreateDraftPublicRequestApiArg = {
    createDraftPublicRequestRequestBody: CreateDraftPublicRequestRequestBody;
};
export type UpdateDraftPublicRequestApiResponse = /** status 200 Ok */ DraftPublicRequestResponse;
export type UpdateDraftPublicRequestApiArg = {
    updateDraftPublicRequestRequestBody: UpdateDraftPublicRequestRequestBody;
};
export type GetMyDraftPublicRequestsApiResponse = /** status 200 Ok */ DraftPublicRequestResponse[];
export type GetMyDraftPublicRequestsApiArg = {
    studentId: string;
};
export type GetDraftPublicRequestApiResponse = /** status 200 Ok */ DraftPublicRequestResponse;
export type GetDraftPublicRequestApiArg = {
    draftPublicRequestId: string;
};
export type DeleteDraftPublicRequestApiResponse = unknown;
export type DeleteDraftPublicRequestApiArg = {
    draftPublicRequestId: string;
};
export type CreateProposalApiResponse = unknown;
export type CreateProposalApiArg = {
    createProposalRequestBody: CreateProposalRequestBody;
};
export type UpdateProposalApiResponse = unknown;
export type UpdateProposalApiArg = {
    updateProposalRequestBody: UpdateProposalRequestBody;
};
export type GetProposalApiResponse = /** status 200 Ok */ ProposalResponse;
export type GetProposalApiArg = {
    proposalId: string;
};
export type DeleteProposalApiResponse = unknown;
export type DeleteProposalApiArg = {
    proposalId: string;
};
export type ReserveProposedShortCourseApiResponse = unknown;
export type ReserveProposedShortCourseApiArg = {
    reserveProposedShortCourseRequestBody: ReserveProposedShortCourseRequestBody;
};
export type ReserveProposedRegularCourseApiResponse = unknown;
export type ReserveProposedRegularCourseApiArg = {
    reserveProposedRegularCourseRequestBody: ReserveProposedRegularCourseRequestBody;
};
export type GetPublicRequestSearchResultsApiResponse = /** status 200 Ok */ PublicRequestResponse[];
export type GetPublicRequestSearchResultsApiArg = {
    orderBy?: OrderBy;
    keyword?: string;
    courseType?: CourseType;
    level?: string;
    category?: string;
    subCategory?: string;
    teacherId?: string;
    take?: number;
    skip?: number;
};
export type GetPublicRequestFavoriteCountApiResponse = /** status 200 Ok */ number;
export type GetPublicRequestFavoriteCountApiArg = {
    publicRequestId: string;
};
export type CreateDummyPublicRequestsApiResponse = unknown;
export type CreateDummyPublicRequestsApiArg = void;
export type GetEducationalBackgroundsByInitialApiResponse = /** status 200 Ok */ EducationalBackgroundResponse[];
export type GetEducationalBackgroundsByInitialApiArg = {
    initial: string;
};
export type GetPointsHistoryApiResponse = /** status 200 Ok */ PointsRecordResponse[];
export type GetPointsHistoryApiArg = {
    userId: string;
};
export type GetProceedsRecordsApiResponse = /** status 200 Ok */ ProceedsRecordResponse[];
export type GetProceedsRecordsApiArg = {
    teacherId: string;
};
export type CreateStandardAccountApiResponse = /** status 200 Ok */ string;
export type CreateStandardAccountApiArg = {
    userId: string;
};
export type CreateBankAccountApiResponse = unknown;
export type CreateBankAccountApiArg = {
    createBankAccountRequestBody: CreateBankAccountRequestBody;
};
export type GetBankAccountListApiResponse = /** status 200 Ok */ StripeBankAccount[];
export type GetBankAccountListApiArg = {
    teacherId: string;
};
export type PayoutApiResponse = unknown;
export type PayoutApiArg = {
    payoutRequestBody: PayoutRequestBody;
};
export type GetStripeAccountApiResponse = /** status 200 Ok */ StripeAccount | null;
export type GetStripeAccountApiArg = {
    teacherId: string;
};
export type UpdateStripeAccountForConnectApiResponse = unknown;
export type UpdateStripeAccountForConnectApiArg = {
    updateStripeAccountForConnectRequestBody: UpdateStripeAccountForConnectRequestBody;
};
export type ResubmitVerificationDocumentApiResponse = unknown;
export type ResubmitVerificationDocumentApiArg = {
    resubmitVerificationDocumentRequestBody: ResubmitVerificationDocumentRequestBody;
};
export type GetBalanceTransactionsApiResponse = /** status 200 Ok */ StripeBalanceTransaction[];
export type GetBalanceTransactionsApiArg = {
    teacherId: string;
};
export type GetPaymentIntentApiResponse = /** status 200 Ok */ StripePaymentIntent;
export type GetPaymentIntentApiArg = {
    paymentIntentId: string;
};
export type CreateJwtApiResponse = /** status 200 Ok */ string;
export type CreateJwtApiArg = {
    jwtRequestBody: JwtRequestBody;
};
export type GetDataFromJwtApiResponse = /** status 200 Ok */ JwtRequestBody;
export type GetDataFromJwtApiArg = {
    token: string;
};
export type CreateAdminJwtApiResponse = /** status 200 Ok */ string;
export type CreateAdminJwtApiArg = {
    adminJwtRequestBody: AdminJwtRequestBody;
};
export type GetDataFromAdminJwtApiResponse = /** status 200 Ok */ AdminJwtRequestBody;
export type GetDataFromAdminJwtApiArg = {
    token: string;
};
export type CreateEducationalBackgroundsApiResponse = unknown;
export type CreateEducationalBackgroundsApiArg = void;
export type GetZoomUserListApiResponse = /** status 200 Ok */ any;
export type GetZoomUserListApiArg = void;
export type GetZoomUserApiResponse = /** status 200 Ok */ any;
export type GetZoomUserApiArg = {
    zoomUserId: string;
};
export type DeleteZoomUserApiResponse = unknown;
export type DeleteZoomUserApiArg = {
    zoomUserId: string;
};
export type CreateSubjectsApiResponse = /** status 200 Ok */ SubjectResponse[];
export type CreateSubjectsApiArg = void;
export type GetSubjectsApiResponse = /** status 200 Ok */ SubjectResponse[];
export type GetSubjectsApiArg = void;
export type GetPresignedUrlForGetApiResponse = /** status 200 Ok */ GetPreSignedUrlResponse;
export type GetPresignedUrlForGetApiArg = {
    objectKey: string;
};
export type UploadFilesApiResponse = /** status 200 Ok */ string[];
export type UploadFilesApiArg = void;
export type CreateSetUpIntentApiResponse = /** status 200 Ok */ string | null;
export type CreateSetUpIntentApiArg = {
    createSetUpIntentRequestBody: CreateSetUpIntentRequestBody;
};
export type GetPaymentMethodsApiResponse = /** status 200 Ok */ StripePaymentMethod[];
export type GetPaymentMethodsApiArg = {
    studentId: string;
};
export type UpdateDefaultPaymentMethodApiResponse = unknown;
export type UpdateDefaultPaymentMethodApiArg = {
    updateDefaultPaymentMethodRequestBody: UpdateDefaultPaymentMethodRequestBody;
};
export type DetachPaymentMethodApiResponse = unknown;
export type DetachPaymentMethodApiArg = {
    paymentMethodId: string;
};
export type GetDefaultPaymentMethodApiResponse = /** status 200 Ok */ PaymentMethod | null;
export type GetDefaultPaymentMethodApiArg = {
    studentId: string;
};
export type MakeLatestCreditCardDefaultApiResponse = unknown;
export type MakeLatestCreditCardDefaultApiArg = {
    makeLatestPaymentMethodDefaultRequestBody: MakeLatestPaymentMethodDefaultRequestBody;
};
export type DeleteBankAccountApiResponse = unknown;
export type DeleteBankAccountApiArg = {
    deleteBankAccountRequestBody: DeleteBankAccountRequestBody;
};
export type UpdateDefaultBankAccountApiResponse = unknown;
export type UpdateDefaultBankAccountApiArg = {
    updateDefaultBankAccountRequestBody: UpdateDefaultBankAccountRequestBody;
};
export type UploadFilesToStripeApiResponse = /** status 200 Ok */ string;
export type UploadFilesToStripeApiArg = void;
export type GetBalanceApiResponse = /** status 200 Ok */ StripeBalance | null;
export type GetBalanceApiArg = {
    teacherId: string;
};
export type GetBankInfoListApiResponse = /** status 200 Ok */ BankInfo[];
export type GetBankInfoListApiArg = void;
export type GetAddressInfoListApiResponse = /** status 200 Ok */ AddressInfo[];
export type GetAddressInfoListApiArg = void;
export type ToKanaApiResponse = /** status 200 Ok */ string;
export type ToKanaApiArg = {
    keyword: string;
};
export type GetPrivateRequestsApiResponse = /** status 200 Ok */ PrivateRequestResponse[];
export type GetPrivateRequestsApiArg = {
    studentId: string;
};
export type CreatePrivateRequestApiResponse = unknown;
export type CreatePrivateRequestApiArg = {
    createPrivateRequestRequestBody: CreatePrivateRequestRequestBody;
};
export type ApprovePrivateRequestApiResponse = unknown;
export type ApprovePrivateRequestApiArg = {
    approvePrivateRequestRequestBody: ApprovePrivateRequestRequestBody;
};
export type DisapprovePrivateRequestApiResponse = unknown;
export type DisapprovePrivateRequestApiArg = {
    disapprovePrivateRequestRequestBody: DisapprovePrivateRequestRequestBody;
};
export type DeletePrivateRequestApiResponse = unknown;
export type DeletePrivateRequestApiArg = {
    privateRequestId: string;
};
export type RejectPayingForCourseApiResponse = unknown;
export type RejectPayingForCourseApiArg = {
    rejectPayingForCourseRequestBody: RejectPayingForCourseRequestBody;
};
export type UpdateNotificationsSettingApiResponse = unknown;
export type UpdateNotificationsSettingApiArg = {
    updateNotificationsSettingRequestBody: UpdateNotificationsSettingRequestBody;
};
export type InvalidateTagsApiResponse = unknown;
export type InvalidateTagsApiArg = {
    targetAccountType: string;
};
export type ScheduleJobsApiResponse = unknown;
export type ScheduleJobsApiArg = void;
export type GetIndividualChatApiResponse = /** status 200 Ok */ ChatResponse;
export type GetIndividualChatApiArg = {
    studentId: string;
    teacherId: string;
};
export type GetGroupChatApiResponse = /** status 200 Ok */ ChatResponse;
export type GetGroupChatApiArg = {
    chatId: string;
};
export type GetStudentChatsApiResponse = /** status 200 Ok */ ChatResponse[];
export type GetStudentChatsApiArg = {
    studentId: string;
};
export type GetTeacherChatsApiResponse = /** status 200 Ok */ ChatResponse[];
export type GetTeacherChatsApiArg = {
    teacherId: string;
};
export type UpdateReadStatusApiResponse = unknown;
export type UpdateReadStatusApiArg = {
    readStatusId: string;
};
export type UpdateBaseSchedulesApiResponse = unknown;
export type UpdateBaseSchedulesApiArg = {
    updateBaseSchedulesRequestBody: UpdateBaseSchedulesRequestBody;
};
export type CreateBannerApiResponse = unknown;
export type CreateBannerApiArg = {
    createBannerRequestBody: CreateBannerRequestBody;
};
export type UpdateBannerApiResponse = unknown;
export type UpdateBannerApiArg = {
    updateBannerRequestBody: UpdateBannerRequestBody;
};
export type GetBannersApiResponse = /** status 200 Ok */ BannerResponse[];
export type GetBannersApiArg = {
    targetType?: BannerTargetType;
    deviceType?: DeviceType;
};
export type GetBannerApiResponse = /** status 200 Ok */ BannerResponse;
export type GetBannerApiArg = {
    bannerId: string;
};
export type DeleteBannerApiResponse = unknown;
export type DeleteBannerApiArg = {
    bannerId: string;
};
export type CreateNewsRecordApiResponse = unknown;
export type CreateNewsRecordApiArg = {
    createNewsRecordRequestBody: CreateNewsRecordRequestBody;
};
export type UpdateNewsRecordApiResponse = unknown;
export type UpdateNewsRecordApiArg = {
    updateNewsRecordRequestBody: UpdateNewsRecordRequestBody;
};
export type GetNewsRecordsApiResponse = /** status 200 Ok */ NewsRecordResponse[];
export type GetNewsRecordsApiArg = {
    targetType?: NewsTargetType;
};
export type GetNewsRecordApiResponse = /** status 200 Ok */ NewsRecordResponse;
export type GetNewsRecordApiArg = {
    newsRecordId: string;
};
export type DeleteNewsRecordApiResponse = unknown;
export type DeleteNewsRecordApiArg = {
    newsRecordId: string;
};
export type GetArticlesApiResponse = /** status 200 Ok */ ArticleResponse[];
export type GetArticlesApiArg = {
    targetType?: ArticleTargetType;
};
export type BasicAuthApiResponse = /** status 200 Ok */ {
    message: string;
};
export type BasicAuthApiArg = void;
export type ConfigurationResponse = {
    firebase: {
        vapidKey: string;
    };
    stripe: {
        stripePublishableKey: string;
    };
    cognito: {
        region: string;
        webClientId: string;
        userPoolId: string;
    };
};
export type AdminConfigurationResponse = {
    cognito: {
        region: string;
        webClientId: string;
        userPoolId: string;
    };
};
export type NominalTypingStudentString = {
    _type: "Student";
} & string;
export type NominalTypingUserString = {
    _type: "User";
} & string;
export type NominalTypingTeacherString = {
    _type: "Teacher";
} & string;
export type NominalTypingSubjectString = {
    _type: "Subject";
} & string;
export type Level = "elementarySchool" | "juniorHighSchool" | "highSchool" | "university";
export type SubjectResponse = {
    subjectId: NominalTypingSubjectString;
    level: Level;
    category?: string;
    subCategory?: string;
};
export type PrivateStudentResponse = {
    studentId: NominalTypingStudentString;
    userId: NominalTypingUserString;
    teacherId?: NominalTypingTeacherString;
    nickname: string;
    favoriteSubjects?: SubjectResponse[];
    stripeCustomerId?: string;
    iconImageUrl?: string;
    iconImageObjectKey?: string;
};
export type Gender = "male" | "female" | "other" | "noAnswer";
export type CreateStudentRequestBody = {
    studentBody: {
        iconImageUrl?: string;
        favoriteSubjectIds?: string[];
        nickname: string;
    };
    userBody: {
        isGradePublic: boolean;
        grade: string;
        isPrefecturePublic: boolean;
        prefecture: string;
        isGenderPublic: boolean;
        gender: Gender;
        isBirthDatePublic: boolean;
        birthDate: string;
        lastNameKana: string;
        firstNameKana: string;
        lastName: string;
        firstName: string;
        email: string;
        userId: string;
    };
    userId: string;
};
export type PartialCreateStudentRequestBodyAtUserBody = {
    userId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    firstNameKana?: string;
    lastNameKana?: string;
    birthDate?: string;
    isBirthDatePublic?: boolean;
    gender?: Gender;
    isGenderPublic?: boolean;
    prefecture?: string;
    isPrefecturePublic?: boolean;
    grade?: string;
    isGradePublic?: boolean;
};
export type PartialCreateStudentRequestBodyAtStudentBody = {
    nickname?: string;
    favoriteSubjectIds?: string[];
    iconImageUrl?: string;
};
export type UpdateStudentRequestBody = {
    userBody: PartialCreateStudentRequestBodyAtUserBody;
    userId: string;
    studentBody: PartialCreateStudentRequestBodyAtStudentBody & {
        stripeCustomerId?: string;
    };
    studentId: string;
};
export type PublicStudentResponse = {
    studentId: NominalTypingStudentString;
    userId: NominalTypingUserString;
    nickname: string;
    favoriteSubjects?: SubjectResponse[];
    iconImageUrl?: string;
    iconImageObjectKey?: string;
    isWithdrawn?: boolean;
};
export type NominalTypingPublicRequestString = {
    _type: "PublicRequest";
} & string;
export type NominalTypingDraftPublicRequestString = {
    _type: "DraftPublicRequest";
} & string;
export type CourseType = "short" | "regular";
export type DraftPublicRequestResponse = {
    draftPublicRequestId: NominalTypingDraftPublicRequestString;
    publicRequest?: PublicRequestResponse;
    courseType: CourseType;
    student?: PublicStudentResponse;
    title: string;
    publicRequestDescription: string;
    minPrice: number;
    maxPrice: number;
    applyingDeadline: string;
    scheduleDescription: string;
    firstDateDescription?: string;
    additionalDescription?: string;
    subjects: SubjectResponse[];
    createdAt: string;
    updatedAt?: string;
};
export type PublicRequestStatus = "recruiting" | "recruitmentClosed" | "closed";
export type NominalTypingProposalString = {
    _type: "Proposal";
} & string;
export type NominalTypingEducationalBackgroundString = {
    _type: "EducationalBackground";
} & string;
export type EducationalBackgroundResponse = {
    educationalBackgroundId: NominalTypingEducationalBackgroundString;
    university: string;
    faculty?: string;
    department?: string;
};
export type TeacherStatsResponse = {
    howManyReviews: number;
    averageReviewScore: number;
};
export type TeacherIdentificationStatus = "unset" | "underReview" | "approved" | "disapproved";
export type PublicTeacherResponse = {
    teacherId: NominalTypingTeacherString;
    userId: NominalTypingUserString;
    nickname: string;
    backgroundDescription?: string;
    catchCopy?: string;
    contentDescription?: string;
    educationalBackground?: EducationalBackgroundResponse;
    backgroundImageUrl?: string;
    backgroundImageObjectKey?: string;
    iconImageUrl?: string;
    iconImageObjectKey?: string;
    rankNumber: number;
    totalAchievements: number;
    stats?: TeacherStatsResponse;
    teachableSubjects: SubjectResponse[];
    identificationStatus: TeacherIdentificationStatus;
    isEducationalBackgroundVerified: boolean;
    isEnrolled?: boolean;
};
export type NominalTypingMessageString = {
    _type: "Message";
} & string;
export type MessageType = "text" | "file" | "personalMeeting";
export type MessageResponse = {
    messageId: NominalTypingMessageString;
    messageType: MessageType;
    teacher?: PublicTeacherResponse;
    student?: PublicStudentResponse;
    text?: string;
    fileUrls: string[];
    fileObjectKeys: string[];
    personalMeetingUrl?: string;
    createdAt: string;
    updatedAt?: string;
};
export type NominalTypingClassString = {
    _type: "Class";
} & string;
export type NominalTypingChatString = {
    _type: "Chat";
} & string;
export type NominalTypingSubscriptionString = {
    _type: "Subscription";
} & string;
export type SubscriptionStatus = "disapproved" | "beforeApproval" | "ongoing" | "canceled";
export type SubscriptionResponse = {
    subscriptionId: NominalTypingSubscriptionString;
    stripeSubscriptionId?: string;
    status: SubscriptionStatus;
    student?: PublicStudentResponse;
    class?: ClassResponse;
    amount: number;
    askingAmount?: number;
    startDate: string;
    newStartDate?: string;
    createdAt: string;
    updatedAt: string;
};
export type NominalTypingPrivateRequestString = {
    _type: "PrivateRequest";
} & string;
export type NominalTypingBaseScheduleString = {
    _type: "BaseSchedule";
} & string;
export type RepetitionUnit = "day" | "week" | "month";
export type BaseScheduleResponse = {
    baseScheduleId: NominalTypingBaseScheduleString;
    classId?: NominalTypingClassString;
    proposalId?: NominalTypingProposalString;
    repetitionUnitNumber: number;
    repetitionUnit: RepetitionUnit;
    howManyTimes: number;
    dayOfWeekIndexes: number[];
    startHour?: number;
    startMinute?: number;
    howManyMinutes: number;
    createdAt: string;
    updatedAt: string;
};
export type PrivateRequestResponse = {
    privateRequestId: NominalTypingPrivateRequestString;
    student?: PublicStudentResponse;
    message: string;
    baseSchedules?: BaseScheduleResponse[];
    firstDateDescription: string;
    lastDateDescription?: string;
    approvalDeadline: string;
    isApproved?: boolean;
    estimatedMonthlyFee?: number;
    confirmedMonthlyFee?: number;
    confirmedFirstDate?: string;
    createdAt: string;
};
export type NominalTypingCourseString = {
    _type: "Course";
} & string;
export type LessonResponse = {
    lessonId: NominalTypingCourseString;
    class?: ClassResponse;
    startTime: string;
    endTime: string;
    meetingId?: string;
    startUrl?: string;
    joinUrl?: string;
    isHeld: boolean;
    isDeleted: boolean;
    createdAt?: string;
};
export type ClassStatus =
    | "recruiting"
    | "recruitmentClosed"
    | "closed"
    | "ongoing"
    | "draft"
    | "existApplication"
    | "completed";
export type NominalTypingDraftCourseString = {
    _type: "DraftCourse";
} & string;
export type NominalTypingAvailableTimeString = {
    _type: "AvailableTime";
} & string;
export type AvailableTimeResponse = {
    availableTimeId: NominalTypingAvailableTimeString;
    courseId?: NominalTypingCourseString;
    dayOfWeekIndex: number;
    isActive: boolean;
    startHour: number | null;
    startMinute: number | null;
    endHour: number | null;
    endMinute: number | null;
    createdAt: string;
    updatedAt: string;
};
export type DraftCourseResponse = {
    draftCourseId: NominalTypingDraftCourseString;
    course?: CourseResponse;
    courseType?: CourseType;
    title?: string;
    subjects: SubjectResponse[];
    courseDescription?: string;
    targetDescription?: string;
    teachingMaterial?: string;
    thumbnailUrl?: string;
    thumbnailObjectKey?: string;
    additionalDescription?: string;
    availableTimes?: AvailableTimeResponse[];
    createdAt: string;
    updatedAt?: string;
    classes: ClassResponse[];
};
export type NominalTypingReviewString = {
    _type: "Review";
} & string;
export type PublicUserResponse = {
    userId: NominalTypingUserString;
    teacherId?: NominalTypingTeacherString;
    studentId?: NominalTypingStudentString;
    birthDate: string;
    isBirthDatePublic: boolean;
    gender: Gender;
    isGenderPublic: boolean;
    prefecture: string;
    isPrefecturePublic: boolean;
    grade: string;
    isGradePublic: boolean;
};
export type ReviewResponse = {
    reviewId: NominalTypingReviewString;
    title: string;
    score: number;
    comment: string;
    anonymous: boolean;
    course?: CourseResponse;
    student?: PublicStudentResponse;
    user?: PublicUserResponse;
    evaluationPeriod: string;
    createdAt: string;
};
export type CourseResponse = {
    courseId: NominalTypingCourseString;
    stripeProductId?: string;
    courseType: CourseType;
    title?: string;
    draftCourse?: DraftCourseResponse;
    subjects: SubjectResponse[];
    reviews: ReviewResponse[];
    courseDescription?: string;
    targetDescription?: string;
    teachingMaterial?: string;
    thumbnailUrl?: string;
    thumbnailObjectKey?: string;
    imageUrls: string[];
    imageObjectKeys: string[];
    additionalDescription?: string;
    availableTimes?: AvailableTimeResponse[];
    createdAt: string;
    updatedAt?: string;
    classes: ClassResponse[];
    privateRequest?: PrivateRequestResponse;
    teacher?: PublicTeacherResponse;
    favoriteCount: number;
    publicRequest?: PublicRequestResponse;
};
export type NominalTypingStudentToDoString = {
    _type: "StudentToDo";
} & string;
export type NominalTypingSubscriptionScheduleString = {
    _type: "SubscriptionSchedule";
} & string;
export type SubscriptionScheduleResponse = {
    subscriptionScheduleId: NominalTypingSubscriptionScheduleString;
    subscription?: SubscriptionResponse;
    stripeSubscriptionScheduleId?: string;
    amount: number;
    isApproved?: boolean;
    isCanceled: boolean;
    startDate: string;
    endDate: string;
    createdAt: string;
    updatedAt: string;
};
export type StudentToDoType =
    | "overallEvaluation"
    | "regularEvaluation"
    | "payForCourse"
    | "approveUpdateMonthlyFee"
    | "approveTemporaryUpdateMonthlyFee";
export type StudentToDoResponse = {
    studentToDoId: NominalTypingStudentToDoString;
    studentId: NominalTypingStudentString;
    class?: ClassResponse;
    subscription?: SubscriptionResponse;
    subscriptionSchedule?: SubscriptionScheduleResponse;
    lesson?: LessonResponse;
    publicRequestId?: NominalTypingPublicRequestString;
    toDoType: StudentToDoType;
    isCompleted: boolean;
    isCanceled: boolean;
    teacher?: PublicTeacherResponse;
    evaluationPeriod?: string;
    deadline?: string;
    createdAt: string;
};
export type NominalTypingTeacherToDoString = {
    _type: "TeacherToDo";
} & string;
export type TeacherToDoResponse = {
    teacherToDoId: NominalTypingTeacherToDoString;
    teacherId: NominalTypingTeacherString;
    class?: ClassResponse;
    lessonId?: NominalTypingCourseString;
    publicRequestId?: NominalTypingPublicRequestString;
    toDoType: string;
    isCompleted: boolean;
    student?: PublicStudentResponse;
    createdAt: string;
};
export type ClassResponse = {
    classId: NominalTypingClassString;
    className: string;
    chatId?: NominalTypingChatString;
    subscription?: SubscriptionResponse;
    applyingDeadline?: string;
    pricePerHour?: number;
    pricePerPayment?: number;
    privateRequest?: PrivateRequestResponse;
    baseSchedules?: BaseScheduleResponse[];
    maxDaysInAWeek?: number;
    maxMinutesPerLesson?: number;
    maxParticipants?: number;
    minParticipants?: number;
    lessons: LessonResponse[];
    status: ClassStatus;
    participants: PublicStudentResponse[];
    course?: CourseResponse;
    studentToDos: StudentToDoResponse[];
    teacherToDos: TeacherToDoResponse[];
    proposal?: ProposalResponse;
    isAutoGeneratingEnabled: boolean;
    firstDate?: string;
    memo?: string;
    createdAt: string;
    updatedAt?: string;
};
export type ProposalResponse = {
    proposalId: NominalTypingProposalString;
    courseType: CourseType;
    content: string;
    teacher: PublicTeacherResponse;
    messages?: MessageResponse[];
    class?: ClassResponse;
    baseSchedules?: BaseScheduleResponse[];
    startDate?: string;
    publicRequest?: PublicRequestResponse;
    createdAt: string;
};
export type PublicRequestResponse = {
    publicRequestId: NominalTypingPublicRequestString;
    draftPublicRequest?: DraftPublicRequestResponse;
    courseType: CourseType;
    status: PublicRequestStatus;
    student?: PublicStudentResponse;
    title: string;
    publicRequestDescription: string;
    minPrice: number;
    maxPrice: number;
    applyingDeadline: string;
    scheduleDescription: string;
    firstDateDescription?: string;
    additionalDescription?: string;
    subjects: SubjectResponse[];
    proposals?: ProposalResponse[];
    favoriteCount: number;
    createdAt: string;
    updatedAt?: string;
};
export type UpdateFavoriteCoursesRequestBody = {
    courseId: string;
    studentId: string;
};
export type UpdateCourseBrowsingHistoryRequestBody = {
    courseId: string;
    studentId: string;
};
export type NominalTypingAnnouncementString = {
    _type: "Announcement";
} & string;
export type CreateAvailableTimeRequestParams = {
    endMinute: number | null;
    endHour: number | null;
    startMinute: number | null;
    startHour: number | null;
    isActive: boolean;
    dayOfWeekIndex: number;
};
export type CreateLessonRequestParams = {
    endTime: string;
    startTime: string;
};
export type CreateClassRequestBody = {
    pricePerPayment?: number;
    pricePerHour: number;
    minParticipants?: number;
    maxParticipants?: number;
    maxMinutesPerLesson?: number;
    maxDaysInAWeek?: number;
    lessons?: CreateLessonRequestParams[];
    applyingDeadline?: string;
};
export type CreateCourseRequestBody = {
    publicRequestId?: string;
    availableTimes?: CreateAvailableTimeRequestParams[];
    createClassBody: CreateClassRequestBody;
    additionalDescription?: string;
    imageUrls?: string[];
    teachingMaterial?: string;
    targetDescription: string;
    courseDescription: string;
    subjectIds: string[];
    title: string;
    courseType: CourseType;
    teacherId: string;
    courseId: string;
};
export type PartialOmitCreateCourseRequestBodyCourseIdOrCreateClassesBodiesOrAvailableTimes = {
    teacherId?: string;
    courseType?: CourseType;
    title?: string;
    subjectIds?: string[];
    courseDescription?: string;
    targetDescription?: string;
    teachingMaterial?: string;
    imageUrls?: string[];
    additionalDescription?: string;
    createClassBody?: CreateClassRequestBody;
    publicRequestId?: string;
};
export type UpdateAvailableTimeRequestBody = CreateAvailableTimeRequestParams & {
    availableTimeId: string;
};
export type UpdateLessonRequestBody = CreateLessonRequestParams & {
    lessonId?: string;
};
export type UpdateClassRequestBody = {
    pricePerHour: number;
    minParticipants?: number;
    maxParticipants?: number;
    maxMinutesPerLesson?: number;
    maxDaysInAWeek?: number;
    participantIds: string[];
    deletedLessonIds: string[];
    lessons: UpdateLessonRequestBody[];
    applyingDeadline?: string;
    teacherId: string;
    classId: string;
};
export type UpdateCourseRequestBody =
    PartialOmitCreateCourseRequestBodyCourseIdOrCreateClassesBodiesOrAvailableTimes & {
        availableTimes?: UpdateAvailableTimeRequestBody[];
        updateClassesBodies?: UpdateClassRequestBody[];
        stripeProductId?: string;
        courseId: string;
    };
export type WithdrawFromClassRequestBody = {
    message: string;
    studentId: string;
    classId: string;
};
export type ReserveShortCourseRequestBody = {
    updatedAt?: string;
    paymentMethodId?: string;
    paymentMethodType: string;
    amount: number;
    pointsAmount: number;
    studentId: string;
    classId: string;
    courseId: string;
};
export type ReserveRegularCourseRequestBody = {
    updatedAt?: string;
    paymentMethodId: string;
    firstDate: string;
    studentToDoId: string;
    amount: number;
    pointsAmount: number;
    studentId: string;
    classId: string;
    courseId: string;
};
export type CloseApplicationRequestBody = {
    classId: string;
    courseId: string;
};
export type ApplyForUpdateMonthlyFeeRequestBody = {
    startDate: string;
    monthlyFee: number;
    classId: string;
};
export type ApplyForTemporaryUpdateMonthlyFeeRequestBody = {
    endDate: string;
    startDate: string;
    monthlyFee: number;
    classId: string;
};
export type DisapproveUpdateMonthlyFeeRequestBody = {
    studentToDoId: string;
    message: string;
    subscriptionId: string;
};
export type ApproveUpdateMonthlyFeeRequestBody = {
    studentToDoId: string;
    subscriptionId: string;
};
export type DisapproveTemporaryUpdateMonthlyFeeRequestBody = {
    studentToDoId: string;
    message: string;
    subscriptionScheduleId: string;
};
export type ApproveTemporaryUpdateMonthlyFeeRequestBody = {
    studentToDoId: string;
    subscriptionScheduleId: string;
};
export type UpdateMemoRequestBody = {
    memo: string;
    classId: string;
};
export type UpdateClassNameRequestBody = {
    className: string;
    classId: string;
};
export type AddClassRequestBody = {
    pricePerPayment?: number;
    pricePerHour: number;
    minParticipants?: number;
    maxParticipants?: number;
    maxMinutesPerLesson?: number;
    maxDaysInAWeek?: number;
    lessons?: CreateLessonRequestParams[];
    applyingDeadline?: string;
    availableTimes?: CreateAvailableTimeRequestParams[];
    courseId: string;
};
export type OrderBy = "latest" | "approaching" | "recommended" | "rank" | "averageReviewScore";
export type CreateDraftCourseRequestBody = {
    availableTimes?: (CreateAvailableTimeRequestParams | UpdateAvailableTimeRequestBody)[];
    additionalDescription?: string;
    imageUrls?: string[];
    teachingMaterial?: string;
    targetDescription?: string;
    courseDescription?: string;
    subjectIds?: string[];
    title?: string;
    courseType?: CourseType;
    courseId?: string;
    teacherId: string;
    draftCourseId: string;
};
export type PartialOmitCreateDraftCourseRequestBodyDraftCourseIdOrCreateClassesBodiesOrAvailableTimes = {
    courseId?: string;
    teacherId?: string;
    courseType?: CourseType;
    title?: string;
    subjectIds?: string[];
    courseDescription?: string;
    targetDescription?: string;
    teachingMaterial?: string;
    imageUrls?: string[];
    additionalDescription?: string;
};
export type UpdateDraftCourseRequestBody =
    PartialOmitCreateDraftCourseRequestBodyDraftCourseIdOrCreateClassesBodiesOrAvailableTimes & {
        availableTimes?: UpdateAvailableTimeRequestBody[];
        draftCourseId: string;
    };
export type AddLessonToClassRequestBody = {
    endTime: string;
    startTime: string;
    classId: string;
};
export type CreateReviewRequestBody = {
    teacherId: string;
    studentToDoId: string;
    evaluationPeriod: string;
    anonymous: boolean;
    score: number;
    comment: string;
    title: string;
    studentId: string;
    classId: string;
    courseId: string;
};
export type ZoomAccountStatus = "inactive" | "pending" | "active";
export type PrivateTeacherResponse = {
    teacherId: NominalTypingTeacherString;
    userId: NominalTypingUserString;
    nickname: string;
    backgroundDescription?: string;
    catchCopy?: string;
    contentDescription?: string;
    educationalBackground?: EducationalBackgroundResponse;
    stripeAccountId?: string;
    backgroundImageUrl?: string;
    backgroundImageObjectKey?: string;
    iconImageUrl?: string;
    iconImageObjectKey?: string;
    rankNumber: number;
    totalAchievements: number;
    stats?: TeacherStatsResponse;
    teachableSubjects?: SubjectResponse[];
    identificationStatus: TeacherIdentificationStatus;
    isEducationalBackgroundVerified: boolean;
    zoomAccountStatus: ZoomAccountStatus;
    isEnrolled?: boolean;
    totalProceeds: number;
};
export type CreateTeacherRequestBody = {
    teacherBody: {
        teachableSubjectIds: string[];
        iconImageUrl?: string;
        backgroundImageUrl?: string;
        educationalBackgroundId?: string;
        contentDescription: string;
        catchCopy: string;
        backgroundDescription: string;
        nickname: string;
    };
    userBody: {
        isGradePublic: boolean;
        grade: string;
        isPrefecturePublic: boolean;
        prefecture: string;
        isGenderPublic: boolean;
        gender: Gender;
        isBirthDatePublic: boolean;
        birthDate: string;
        lastNameKana: string;
        firstNameKana: string;
        lastName: string;
        firstName: string;
        email: string;
        userId: string;
    };
    userId: string;
};
export type PartialCreateTeacherRequestBodyAtUserBody = {
    userId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    firstNameKana?: string;
    lastNameKana?: string;
    birthDate?: string;
    isBirthDatePublic?: boolean;
    gender?: Gender;
    isGenderPublic?: boolean;
    prefecture?: string;
    isPrefecturePublic?: boolean;
    grade?: string;
    isGradePublic?: boolean;
};
export type PartialCreateTeacherRequestBodyAtTeacherBody = {
    nickname?: string;
    backgroundDescription?: string;
    catchCopy?: string;
    contentDescription?: string;
    educationalBackgroundId?: string;
    backgroundImageUrl?: string;
    iconImageUrl?: string;
    teachableSubjectIds?: string[];
};
export type UpdateTeacherRequestBody = {
    userBody: PartialCreateTeacherRequestBodyAtUserBody;
    userId: string;
    teacherBody: PartialCreateTeacherRequestBodyAtTeacherBody & {
        teachableSubjectIds: string[];
        educationalBackgroundId?: string;
    };
    teacherId: string;
};
export type LinkZoomAccountRequestBody = {
    email: string;
    teacherId: string;
};
export type UpdateFavoritePublicRequestsRequestBody = {
    publicRequestId: string;
    teacherId: string;
};
export type UpdatePublicRequestBrowsingHistoryRequestBody = {
    publicRequestId: string;
    teacherId: string;
};
export type TeacherIdentificationResponse = {
    teacherIdentificationId: string;
    teacherId: NominalTypingTeacherString;
    identificationStatus: TeacherIdentificationStatus;
    idCardImageUrl: string;
    idCardImageObjectKey?: string;
    studentCardImageUrl?: string;
    studentCardImageObjectKey?: string;
};
export type CreateTeacherIdentificationRequestBody = {
    studentCardImageUrl?: string;
    idCardImageUrl: string;
    teacherId: string;
};
export type DisapproveTeacherIdentificationRequestBody = {
    reason: string;
    teacherIdentificationId: string;
};
export type NominalTypingPushNotificationsSettingString = {
    _type: "PushNotificationsSetting";
} & string;
export type PushNotificationsSettingResponse = {
    pushNotificationsSettingId: NominalTypingPushNotificationsSettingString;
    allowNotifications: boolean;
    message: boolean;
    favorite: boolean;
    information: boolean;
    recommendation: boolean;
    maintenance: boolean;
    review: boolean;
    newCourse: boolean;
    newPublicRequest: boolean;
};
export type NominalTypingEmailNotificationsSettingString = {
    _type: "EmailNotificationsSetting";
} & string;
export type EmailNotificationsSettingResponse = {
    emailNotificationsSettingId: NominalTypingEmailNotificationsSettingString;
    allowNotifications: boolean;
    message: boolean;
    favorite: boolean;
    information: boolean;
    recommendation: boolean;
    maintenance: boolean;
    review: boolean;
    newCourse: boolean;
    newPublicRequest: boolean;
};
export type PrivateUserResponse = {
    userId: NominalTypingUserString;
    teacherId?: NominalTypingTeacherString;
    studentId?: NominalTypingStudentString;
    email: string;
    firstName: string;
    lastName: string;
    firstNameKana: string;
    lastNameKana: string;
    pointsBalance: number;
    birthDate: string;
    isBirthDatePublic: boolean;
    gender: Gender;
    isGenderPublic: boolean;
    prefecture: string;
    isPrefecturePublic: boolean;
    grade: string;
    isGradePublic: boolean;
    pushNotificationsSetting?: PushNotificationsSettingResponse;
    emailNotificationsSetting?: EmailNotificationsSettingResponse;
};
export type PartialCreateUserRequestBody = {
    userId?: string;
    email?: string;
    firstName?: string;
    lastName?: string;
    firstNameKana?: string;
    lastNameKana?: string;
    birthDate?: string;
    isBirthDatePublic?: boolean;
    gender?: Gender;
    isGenderPublic?: boolean;
    prefecture?: string;
    isPrefecturePublic?: boolean;
    grade?: string;
    isGradePublic?: boolean;
};
export type UpdateUserRequestBody = PartialCreateUserRequestBody & {
    FCMToken?: string;
    teacherId?: string;
    studentId?: string;
    userId: string;
};
export type AnnouncementResponse = {
    announcementId: NominalTypingAnnouncementString;
    title: string;
    body?: string;
    targetType: string;
    studentId?: NominalTypingStudentString;
    teacherId?: NominalTypingTeacherString;
    linkTo?: string;
    buttonContent?: string;
    iconImageUrl?: string;
    iconImageObjectKey?: string;
    createdAt: string;
};
export type ChatType = "individual" | "group";
export type CreateStudentMessageRequestBody = {
    fileUrls?: string[];
    text?: string;
    studentId: string;
    chatType: ChatType;
    chatId: string;
    messageType: MessageType;
};
export type CreateTeacherMessageRequestBody = {
    fileUrls?: string[];
    text?: string;
    teacherId: string;
    chatType: ChatType;
    chatId: string;
    messageType: MessageType;
};
export type CreatePublicRequestRequestBody = {
    isPublic: boolean;
    subjectIds: string[];
    additionalDescription?: string;
    firstDateDescription?: string;
    scheduleDescription: string;
    applyingDeadline: string;
    maxPrice: number;
    minPrice: number;
    publicRequestDescription: string;
    courseType: CourseType;
    title: string;
    studentId: string;
};
export type PartialCreatePublicRequestRequestBody = {
    studentId?: string;
    title?: string;
    courseType?: CourseType;
    publicRequestDescription?: string;
    minPrice?: number;
    maxPrice?: number;
    applyingDeadline?: string;
    scheduleDescription?: string;
    firstDateDescription?: string;
    additionalDescription?: string;
    subjectIds?: string[];
    isPublic?: boolean;
};
export type UpdatePublicRequestRequestBody = PartialCreatePublicRequestRequestBody & {
    publicRequestId: string;
};
export type CreateDraftPublicRequestRequestBody = {
    subjectIds: string[];
    additionalDescription?: string;
    firstDateDescription?: string;
    scheduleDescription: string;
    applyingDeadline: string;
    maxPrice: number;
    minPrice: number;
    publicRequestDescription: string;
    courseType: CourseType;
    title: string;
    studentId: string;
    publicRequestId?: string;
};
export type PartialCreateDraftPublicRequestRequestBody = {
    publicRequestId?: string;
    studentId?: string;
    title?: string;
    courseType?: CourseType;
    publicRequestDescription?: string;
    minPrice?: number;
    maxPrice?: number;
    applyingDeadline?: string;
    scheduleDescription?: string;
    firstDateDescription?: string;
    additionalDescription?: string;
    subjectIds?: string[];
};
export type UpdateDraftPublicRequestRequestBody = PartialCreateDraftPublicRequestRequestBody & {
    draftPublicRequestId: string;
};
export type CreateBaseScheduleParams = {
    isActive: boolean;
    howManyMinutes: number;
    startMinute?: number;
    startHour?: number;
    dayOfWeekIndexes: number[];
    howManyTimes: number;
    repetitionUnit: RepetitionUnit;
    repetitionUnitNumber: number;
};
export type CreateProposalRequestBody = {
    createBaseScheduleParamsList?: CreateBaseScheduleParams[];
    createClassParams: CreateClassRequestBody;
    startDate?: string;
    publicRequestId: string;
    teacherId: string;
    content: string;
};
export type UpdateProposalRequestBody = {
    createBaseScheduleParamsList?: CreateBaseScheduleParams[];
    createClassParams: CreateClassRequestBody;
    startDate?: string;
    content: string;
    publicRequestId: string;
    teacherId: string;
    proposalId: string;
};
export type ReserveProposedShortCourseRequestBody = {
    paymentMethodId?: string;
    paymentMethodType: string;
    amount: number;
    pointsAmount: number;
    studentId: string;
    proposalId: string;
};
export type ReserveProposedRegularCourseRequestBody = {
    paymentMethodId: string;
    amount: number;
    pointsAmount: number;
    studentId: string;
    proposalId: string;
};
export type NominalTypingPointsRecordString = {
    _type: "PointsRecord";
} & string;
export type PointsRecordType = "payment" | "campaign" | "fromAdmin";
export type PointsRecordResponse = {
    pointsRecordId: NominalTypingPointsRecordString;
    class?: ClassResponse;
    user: PublicUserResponse;
    amount: number;
    type: PointsRecordType;
    createdAt: string;
};
export type NominalTypingProceedsRecordString = {
    _type: "ProceedsRecord";
} & string;
export type ProceedsRecordType = "fee" | "proratedFee" | "conversion" | "transfer" | "compensation";
export type ProceedsRecordResponse = {
    proceedsRecordId: NominalTypingProceedsRecordString;
    type: ProceedsRecordType;
    amount: number;
    teacher?: PublicTeacherResponse;
    class?: ClassResponse;
    createdAt: string;
};
export type StripeExternalAccountUpdateParamsAccountHolderType = "company" | "individual";
export type CreateBankAccountRequestBody = {
    routingNumber: string;
    accountNumber: string;
    accountHolderType: StripeExternalAccountUpdateParamsAccountHolderType;
    accountHolderName: string;
    currency: string;
    country: string;
    teacherId: string;
};
export type StripeAddress = {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
};
export type AccountBusinessProfile = {
    mcc: string | null;
    name: string | null;
    product_description?: string | null;
    support_address: StripeAddress | null;
    support_email: string | null;
    support_phone: string | null;
    support_url: string | null;
    url: string | null;
};
export type AccountBusinessType = "company" | "government_entity" | "individual" | "non_profit";
export type CapabilitiesAcssDebitPayments = "active" | "inactive" | "pending";
export type CapabilitiesAffirmPayments = "active" | "inactive" | "pending";
export type CapabilitiesAfterpayClearpayPayments = "active" | "inactive" | "pending";
export type CapabilitiesAuBecsDebitPayments = "active" | "inactive" | "pending";
export type CapabilitiesBacsDebitPayments = "active" | "inactive" | "pending";
export type CapabilitiesBancontactPayments = "active" | "inactive" | "pending";
export type CapabilitiesBankTransferPayments = "active" | "inactive" | "pending";
export type CapabilitiesBlikPayments = "active" | "inactive" | "pending";
export type CapabilitiesBoletoPayments = "active" | "inactive" | "pending";
export type CapabilitiesCardIssuing = "active" | "inactive" | "pending";
export type CapabilitiesCardPayments = "active" | "inactive" | "pending";
export type CapabilitiesCartesBancairesPayments = "active" | "inactive" | "pending";
export type CapabilitiesEpsPayments = "active" | "inactive" | "pending";
export type CapabilitiesFpxPayments = "active" | "inactive" | "pending";
export type CapabilitiesGiropayPayments = "active" | "inactive" | "pending";
export type CapabilitiesGrabpayPayments = "active" | "inactive" | "pending";
export type CapabilitiesIdealPayments = "active" | "inactive" | "pending";
export type CapabilitiesJcbPayments = "active" | "inactive" | "pending";
export type CapabilitiesKlarnaPayments = "active" | "inactive" | "pending";
export type CapabilitiesKonbiniPayments = "active" | "inactive" | "pending";
export type CapabilitiesLegacyPayments = "active" | "inactive" | "pending";
export type CapabilitiesLinkPayments = "active" | "inactive" | "pending";
export type CapabilitiesOxxoPayments = "active" | "inactive" | "pending";
export type CapabilitiesP24Payments = "active" | "inactive" | "pending";
export type CapabilitiesPaynowPayments = "active" | "inactive" | "pending";
export type CapabilitiesPromptpayPayments = "active" | "inactive" | "pending";
export type CapabilitiesSepaDebitPayments = "active" | "inactive" | "pending";
export type CapabilitiesSofortPayments = "active" | "inactive" | "pending";
export type CapabilitiesTaxReportingUs1099K = "active" | "inactive" | "pending";
export type CapabilitiesTaxReportingUs1099Misc = "active" | "inactive" | "pending";
export type CapabilitiesTransfers = "active" | "inactive" | "pending";
export type CapabilitiesTreasury = "active" | "inactive" | "pending";
export type CapabilitiesUsBankAccountAchPayments = "active" | "inactive" | "pending";
export type AccountCapabilities = {
    acss_debit_payments?: CapabilitiesAcssDebitPayments;
    affirm_payments?: CapabilitiesAffirmPayments;
    afterpay_clearpay_payments?: CapabilitiesAfterpayClearpayPayments;
    au_becs_debit_payments?: CapabilitiesAuBecsDebitPayments;
    bacs_debit_payments?: CapabilitiesBacsDebitPayments;
    bancontact_payments?: CapabilitiesBancontactPayments;
    bank_transfer_payments?: CapabilitiesBankTransferPayments;
    blik_payments?: CapabilitiesBlikPayments;
    boleto_payments?: CapabilitiesBoletoPayments;
    card_issuing?: CapabilitiesCardIssuing;
    card_payments?: CapabilitiesCardPayments;
    cartes_bancaires_payments?: CapabilitiesCartesBancairesPayments;
    eps_payments?: CapabilitiesEpsPayments;
    fpx_payments?: CapabilitiesFpxPayments;
    giropay_payments?: CapabilitiesGiropayPayments;
    grabpay_payments?: CapabilitiesGrabpayPayments;
    ideal_payments?: CapabilitiesIdealPayments;
    jcb_payments?: CapabilitiesJcbPayments;
    klarna_payments?: CapabilitiesKlarnaPayments;
    konbini_payments?: CapabilitiesKonbiniPayments;
    legacy_payments?: CapabilitiesLegacyPayments;
    link_payments?: CapabilitiesLinkPayments;
    oxxo_payments?: CapabilitiesOxxoPayments;
    p24_payments?: CapabilitiesP24Payments;
    paynow_payments?: CapabilitiesPaynowPayments;
    promptpay_payments?: CapabilitiesPromptpayPayments;
    sepa_debit_payments?: CapabilitiesSepaDebitPayments;
    sofort_payments?: CapabilitiesSofortPayments;
    tax_reporting_us_1099_k?: CapabilitiesTaxReportingUs1099K;
    tax_reporting_us_1099_misc?: CapabilitiesTaxReportingUs1099Misc;
    transfers?: CapabilitiesTransfers;
    treasury?: CapabilitiesTreasury;
    us_bank_account_ach_payments?: CapabilitiesUsBankAccountAchPayments;
};
export type CompanyAddressKana = {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
    town: string | null;
};
export type CompanyAddressKanji = {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
    town: string | null;
};
export type CompanyOwnershipDeclaration = {
    date: number | null;
    ip: string | null;
    user_agent: string | null;
};
export type CompanyStructure =
    | "free_zone_establishment"
    | "free_zone_llc"
    | "government_instrumentality"
    | "governmental_unit"
    | "incorporated_non_profit"
    | "limited_liability_partnership"
    | "llc"
    | "multi_member_llc"
    | "private_company"
    | "private_corporation"
    | "private_partnership"
    | "public_company"
    | "public_corporation"
    | "public_partnership"
    | "single_member_llc"
    | "sole_establishment"
    | "sole_proprietorship"
    | "tax_exempt_government_instrumentality"
    | "unincorporated_association"
    | "unincorporated_non_profit";
export type StripeMetadata = {
    [key: string]: string;
};
export type StripeFileLink = {
    id: string;
    object: "file_link";
    created: number;
    expired: boolean;
    expires_at: number | null;
    file: string | StripeFile;
    livemode: boolean;
    metadata: StripeMetadata;
    url: string | null;
};
export type ApiListStripeFileLink = {
    object: "list";
    data: StripeFileLink[];
    has_more: boolean;
    url: string;
};
export type FilePurpose =
    | "account_requirement"
    | "additional_verification"
    | "business_icon"
    | "business_logo"
    | "customer_signature"
    | "dispute_evidence"
    | "document_provider_identity_document"
    | "finance_report_run"
    | "identity_document"
    | "identity_document_downloadable"
    | "pci_document"
    | "selfie"
    | "sigma_scheduled_query"
    | "tax_document_user_upload"
    | "terminal_reader_splashscreen";
export type StripeFile = {
    id: string;
    object: "file";
    created: number;
    expires_at: number | null;
    filename: string | null;
    links?: ApiListStripeFileLink | null;
    purpose: FilePurpose;
    size: number;
    title: string | null;
    type: string | null;
    url: string | null;
};
export type VerificationDocument = {
    back: (string | StripeFile) | null;
    details: string | null;
    details_code: string | null;
    front: (string | StripeFile) | null;
};
export type CompanyVerification = {
    document: VerificationDocument;
};
export type AccountCompany = {
    address?: StripeAddress;
    address_kana?: CompanyAddressKana | null;
    address_kanji?: CompanyAddressKanji | null;
    directors_provided?: boolean;
    executives_provided?: boolean;
    name?: string | null;
    name_kana?: string | null;
    name_kanji?: string | null;
    owners_provided?: boolean;
    ownership_declaration?: CompanyOwnershipDeclaration | null;
    phone?: string | null;
    structure?: CompanyStructure;
    tax_id_provided?: boolean;
    tax_id_registrar?: string;
    vat_id_provided?: boolean;
    verification?: CompanyVerification | null;
};
export type ControllerType = "account" | "application";
export type AccountController = {
    is_controller?: boolean;
    type: ControllerType;
};
export type CardAvailablePayoutMethod = "instant" | "standard";
export type SettingsReconciliationMode = "automatic" | "manual";
export type CashBalanceSettings = {
    reconciliation_mode: SettingsReconciliationMode;
};
export type StripeCashBalance = {
    object: "cash_balance";
    available: {
        [key: string]: number;
    } | null;
    customer: string;
    livemode: boolean;
    settings: CashBalanceSettings;
};
export type AccountDebitSource = {
    object: "account";
    id: string;
};
export type BankAccountAvailablePayoutMethod = "instant" | "standard";
export type StripeDeletedCustomer = {
    id: string;
    object: "customer";
    deleted: true;
};
export type BankAccount = {
    id: string;
    object: "bank_account";
    account?: (string | StripeAccount) | null;
    account_holder_name: string | null;
    account_holder_type: string | null;
    account_type: string | null;
    available_payout_methods?: BankAccountAvailablePayoutMethod[] | null;
    bank_name: string | null;
    country: string;
    currency: string;
    customer?: (string | StripeCustomer | StripeDeletedCustomer) | null;
    default_for_currency?: boolean | null;
    deleted?: any;
    fingerprint: string | null;
    last4: string;
    metadata?: StripeMetadata | null;
    routing_number: string | null;
    status: string;
};
export type Card = {
    id: string;
    object: "card";
    account?: (string | StripeAccount) | null;
    address_city: string | null;
    address_country: string | null;
    address_line1: string | null;
    address_line1_check: string | null;
    address_line2: string | null;
    address_state: string | null;
    address_zip: string | null;
    address_zip_check: string | null;
    available_payout_methods?: CardAvailablePayoutMethod[] | null;
    brand: string;
    country: string | null;
    currency?: string | null;
    customer?: (string | StripeCustomer | StripeDeletedCustomer) | null;
    cvc_check: string | null;
    default_for_currency?: boolean | null;
    deleted?: any;
    description?: string;
    dynamic_last4: string | null;
    exp_month: number;
    exp_year: number;
    fingerprint?: string | null;
    funding: string;
    iin?: string;
    issuer?: string;
    last4: string;
    metadata: StripeMetadata | null;
    name: string | null;
    status?: string | null;
    tokenization_method: string | null;
};
export type SourceAchCreditTransfer = {
    account_number?: string | null;
    bank_name?: string | null;
    fingerprint?: string | null;
    refund_account_holder_name?: string | null;
    refund_account_holder_type?: string | null;
    refund_routing_number?: string | null;
    routing_number?: string | null;
    swift_code?: string | null;
};
export type SourceAchDebit = {
    bank_name?: string | null;
    country?: string | null;
    fingerprint?: string | null;
    last4?: string | null;
    routing_number?: string | null;
    type?: string | null;
};
export type SourceAcssDebit = {
    bank_address_city?: string | null;
    bank_address_line_1?: string | null;
    bank_address_line_2?: string | null;
    bank_address_postal_code?: string | null;
    bank_name?: string | null;
    category?: string | null;
    country?: string | null;
    fingerprint?: string | null;
    last4?: string | null;
    routing_number?: string | null;
};
export type SourceAlipay = {
    data_string?: string | null;
    native_url?: string | null;
    statement_descriptor?: string | null;
};
export type SourceAuBecsDebit = {
    bsb_number?: string | null;
    fingerprint?: string | null;
    last4?: string | null;
};
export type SourceBancontact = {
    bank_code?: string | null;
    bank_name?: string | null;
    bic?: string | null;
    iban_last4?: string | null;
    preferred_language?: string | null;
    statement_descriptor?: string | null;
};
export type SourceCard = {
    address_line1_check?: string | null;
    address_zip_check?: string | null;
    brand?: string | null;
    country?: string | null;
    cvc_check?: string | null;
    description?: string;
    dynamic_last4?: string | null;
    exp_month?: number | null;
    exp_year?: number | null;
    fingerprint?: string;
    funding?: string | null;
    iin?: string;
    issuer?: string;
    last4?: string | null;
    name?: string | null;
    three_d_secure?: string;
    tokenization_method?: string | null;
};
export type SourceCardPresent = {
    application_cryptogram?: string;
    application_preferred_name?: string;
    authorization_code?: string | null;
    authorization_response_code?: string;
    brand?: string | null;
    country?: string | null;
    cvm_type?: string;
    data_type?: string | null;
    dedicated_file_name?: string;
    description?: string;
    emv_auth_data?: string;
    evidence_customer_signature?: string | null;
    evidence_transaction_certificate?: string | null;
    exp_month?: number | null;
    exp_year?: number | null;
    fingerprint?: string;
    funding?: string | null;
    iin?: string;
    issuer?: string;
    last4?: string | null;
    pos_device_id?: string | null;
    pos_entry_mode?: string;
    read_method?: string | null;
    reader?: string | null;
    terminal_verification_results?: string;
    transaction_status_information?: string;
};
export type SourceCodeVerification = {
    attempts_remaining: number;
    status: string;
};
export type SourceEps = {
    reference?: string | null;
    statement_descriptor?: string | null;
};
export type SourceGiropay = {
    bank_code?: string | null;
    bank_name?: string | null;
    bic?: string | null;
    statement_descriptor?: string | null;
};
export type SourceIdeal = {
    bank?: string | null;
    bic?: string | null;
    iban_last4?: string | null;
    statement_descriptor?: string | null;
};
export type SourceKlarna = {
    background_image_url?: string;
    client_token?: string | null;
    first_name?: string;
    last_name?: string;
    locale?: string;
    logo_url?: string;
    page_title?: string;
    pay_later_asset_urls_descriptive?: string;
    pay_later_asset_urls_standard?: string;
    pay_later_name?: string;
    pay_later_redirect_url?: string;
    pay_now_asset_urls_descriptive?: string;
    pay_now_asset_urls_standard?: string;
    pay_now_name?: string;
    pay_now_redirect_url?: string;
    pay_over_time_asset_urls_descriptive?: string;
    pay_over_time_asset_urls_standard?: string;
    pay_over_time_name?: string;
    pay_over_time_redirect_url?: string;
    payment_method_categories?: string;
    purchase_country?: string;
    purchase_type?: string;
    redirect_url?: string;
    shipping_delay?: number;
    shipping_first_name?: string;
    shipping_last_name?: string;
};
export type SourceMultibanco = {
    entity?: string | null;
    reference?: string | null;
    refund_account_holder_address_city?: string | null;
    refund_account_holder_address_country?: string | null;
    refund_account_holder_address_line1?: string | null;
    refund_account_holder_address_line2?: string | null;
    refund_account_holder_address_postal_code?: string | null;
    refund_account_holder_address_state?: string | null;
    refund_account_holder_name?: string | null;
    refund_iban?: string | null;
};
export type SourceOwner = {
    address: StripeAddress | null;
    email: string | null;
    name: string | null;
    phone: string | null;
    verified_address: StripeAddress | null;
    verified_email: string | null;
    verified_name: string | null;
    verified_phone: string | null;
};
export type SourceP24 = {
    reference?: string | null;
};
export type SourceReceiver = {
    address: string | null;
    amount_charged: number;
    amount_received: number;
    amount_returned: number;
    refund_attributes_method: string;
    refund_attributes_status: string;
};
export type SourceRedirect = {
    failure_reason: string | null;
    return_url: string;
    status: string;
    url: string;
};
export type SourceSepaCreditTransfer = {
    bank_name?: string | null;
    bic?: string | null;
    iban?: string | null;
    refund_account_holder_address_city?: string | null;
    refund_account_holder_address_country?: string | null;
    refund_account_holder_address_line1?: string | null;
    refund_account_holder_address_line2?: string | null;
    refund_account_holder_address_postal_code?: string | null;
    refund_account_holder_address_state?: string | null;
    refund_account_holder_name?: string | null;
    refund_iban?: string | null;
};
export type SourceSepaDebit = {
    bank_code?: string | null;
    branch_code?: string | null;
    country?: string | null;
    fingerprint?: string | null;
    last4?: string | null;
    mandate_reference?: string | null;
    mandate_url?: string | null;
};
export type SourceSofort = {
    bank_code?: string | null;
    bank_name?: string | null;
    bic?: string | null;
    country?: string | null;
    iban_last4?: string | null;
    preferred_language?: string | null;
    statement_descriptor?: string | null;
};
export type SourceOrderItem = {
    amount: number | null;
    currency: string | null;
    description: string | null;
    parent: string | null;
    quantity?: number;
    type: string | null;
};
export type SourceOrderShipping = {
    address?: StripeAddress;
    carrier?: string | null;
    name?: string;
    phone?: string | null;
    tracking_number?: string | null;
};
export type SourceSourceOrder = {
    amount: number;
    currency: string;
    email?: string;
    items: SourceOrderItem[] | null;
    shipping?: SourceOrderShipping;
};
export type SourceThreeDSecure = {
    address_line1_check?: string | null;
    address_zip_check?: string | null;
    authenticated?: boolean | null;
    brand?: string | null;
    card?: string | null;
    country?: string | null;
    customer?: string | null;
    cvc_check?: string | null;
    description?: string;
    dynamic_last4?: string | null;
    exp_month?: number | null;
    exp_year?: number | null;
    fingerprint?: string;
    funding?: string | null;
    iin?: string;
    issuer?: string;
    last4?: string | null;
    name?: string | null;
    three_d_secure?: string;
    tokenization_method?: string | null;
};
export type SourceType =
    | "ach_credit_transfer"
    | "ach_debit"
    | "acss_debit"
    | "alipay"
    | "au_becs_debit"
    | "bancontact"
    | "card"
    | "card_present"
    | "eps"
    | "giropay"
    | "ideal"
    | "klarna"
    | "multibanco"
    | "p24"
    | "sepa_credit_transfer"
    | "sepa_debit"
    | "sofort"
    | "three_d_secure"
    | "wechat";
export type SourceWechat = {
    prepay_id?: string;
    qr_code_url?: string | null;
    statement_descriptor?: string;
};
export type Source = {
    id: string;
    object: "source";
    ach_credit_transfer?: SourceAchCreditTransfer;
    ach_debit?: SourceAchDebit;
    acss_debit?: SourceAcssDebit;
    alipay?: SourceAlipay;
    amount: number | null;
    au_becs_debit?: SourceAuBecsDebit;
    bancontact?: SourceBancontact;
    card?: SourceCard;
    card_present?: SourceCardPresent;
    client_secret: string;
    code_verification?: SourceCodeVerification;
    created: number;
    currency: string | null;
    customer?: string;
    eps?: SourceEps;
    flow: string;
    giropay?: SourceGiropay;
    ideal?: SourceIdeal;
    klarna?: SourceKlarna;
    livemode: boolean;
    metadata: StripeMetadata | null;
    multibanco?: SourceMultibanco;
    owner: SourceOwner | null;
    p24?: SourceP24;
    receiver?: SourceReceiver;
    redirect?: SourceRedirect;
    sepa_credit_transfer?: SourceSepaCreditTransfer;
    sepa_debit?: SourceSepaDebit;
    sofort?: SourceSofort;
    source_order?: SourceSourceOrder;
    statement_descriptor: string | null;
    status: string;
    three_d_secure?: SourceThreeDSecure;
    type: SourceType;
    usage: string | null;
    wechat?: SourceWechat;
};
export type StripeCustomerSource = AccountDebitSource | BankAccount | Card | Source;
export type CouponAppliesTo = {
    products: string[];
};
export type CouponCurrencyOptions = {
    amount_off: number;
};
export type CouponDuration = "forever" | "once" | "repeating";
export type StripeCoupon = {
    id: string;
    object: "coupon";
    amount_off: number | null;
    applies_to?: CouponAppliesTo;
    created: number;
    currency: string | null;
    currency_options?: {
        [key: string]: CouponCurrencyOptions;
    };
    deleted?: any;
    duration: CouponDuration;
    duration_in_months: number | null;
    livemode: boolean;
    max_redemptions: number | null;
    metadata: StripeMetadata | null;
    name: string | null;
    percent_off: number | null;
    redeem_by: number | null;
    times_redeemed: number;
    valid: boolean;
};
export type RestrictionsCurrencyOptions = {
    minimum_amount: number;
};
export type PromotionCodeRestrictions = {
    currency_options?: {
        [key: string]: RestrictionsCurrencyOptions;
    };
    first_time_transaction: boolean;
    minimum_amount: number | null;
    minimum_amount_currency: string | null;
};
export type StripePromotionCode = {
    id: string;
    object: "promotion_code";
    active: boolean;
    code: string;
    coupon: StripeCoupon;
    created: number;
    customer: (string | StripeCustomer | StripeDeletedCustomer) | null;
    expires_at: number | null;
    livemode: boolean;
    max_redemptions: number | null;
    metadata: StripeMetadata | null;
    restrictions: PromotionCodeRestrictions;
    times_redeemed: number;
};
export type StripeDiscount = {
    id: string;
    object: "discount";
    checkout_session: string | null;
    coupon: StripeCoupon;
    customer: (string | StripeCustomer | StripeDeletedCustomer) | null;
    deleted?: any;
    end: number | null;
    invoice: string | null;
    invoice_item: string | null;
    promotion_code: (string | StripePromotionCode) | null;
    start: number;
    subscription: string | null;
};
export type InvoiceSettingsCustomField = {
    name: string;
    value: string;
};
export type PaymentMethodAcssDebit = {
    bank_name: string | null;
    fingerprint: string | null;
    institution_number: string | null;
    last4: string | null;
    transit_number: string | null;
};
export type PaymentMethodAffirm = {};
export type PaymentMethodAfterpayClearpay = {};
export type PaymentMethodAlipay = {};
export type PaymentMethodAuBecsDebit = {
    bsb_number: string | null;
    fingerprint: string | null;
    last4: string | null;
};
export type PaymentMethodBacsDebit = {
    fingerprint: string | null;
    last4: string | null;
    sort_code: string | null;
};
export type PaymentMethodBancontact = {};
export type PaymentMethodBillingDetails = {
    address: StripeAddress | null;
    email: string | null;
    name: string | null;
    phone: string | null;
};
export type PaymentMethodBlik = {};
export type PaymentMethodBoleto = {
    tax_id: string;
};
export type CardChecks = {
    address_line1_check: string | null;
    address_postal_code_check: string | null;
    cvc_check: string | null;
};
export type CardNetworks = {
    available: string[];
    preferred: string | null;
};
export type CardThreeDSecureUsage = {
    supported: boolean;
};
export type WalletAmexExpressCheckout = {};
export type WalletApplePay = {};
export type WalletGooglePay = {};
export type WalletMasterpass = {
    billing_address: StripeAddress | null;
    email: string | null;
    name: string | null;
    shipping_address: StripeAddress | null;
};
export type WalletSamsungPay = {};
export type WalletType =
    | "amex_express_checkout"
    | "apple_pay"
    | "google_pay"
    | "masterpass"
    | "samsung_pay"
    | "visa_checkout";
export type WalletVisaCheckout = {
    billing_address: StripeAddress | null;
    email: string | null;
    name: string | null;
    shipping_address: StripeAddress | null;
};
export type CardWallet = {
    amex_express_checkout?: WalletAmexExpressCheckout;
    apple_pay?: WalletApplePay;
    dynamic_last4: string | null;
    google_pay?: WalletGooglePay;
    masterpass?: WalletMasterpass;
    samsung_pay?: WalletSamsungPay;
    type: WalletType;
    visa_checkout?: WalletVisaCheckout;
};
export type PaymentMethodCard = {
    brand: string;
    checks: CardChecks | null;
    country: string | null;
    description?: string | null;
    exp_month: number;
    exp_year: number;
    fingerprint?: string | null;
    funding: string;
    iin?: string | null;
    issuer?: string | null;
    last4: string;
    networks: CardNetworks | null;
    three_d_secure_usage: CardThreeDSecureUsage | null;
    wallet: CardWallet | null;
};
export type PaymentMethodCardPresent = {};
export type PaymentMethodCustomerBalance = {};
export type EpsBank =
    | "arzte_und_apotheker_bank"
    | "austrian_anadi_bank_ag"
    | "bank_austria"
    | "bankhaus_carl_spangler"
    | "bankhaus_schelhammer_und_schattera_ag"
    | "bawag_psk_ag"
    | "bks_bank_ag"
    | "brull_kallmus_bank_ag"
    | "btv_vier_lander_bank"
    | "capital_bank_grawe_gruppe_ag"
    | "deutsche_bank_ag"
    | "dolomitenbank"
    | "easybank_ag"
    | "erste_bank_und_sparkassen"
    | "hypo_alpeadriabank_international_ag"
    | "hypo_bank_burgenland_aktiengesellschaft"
    | "hypo_noe_lb_fur_niederosterreich_u_wien"
    | "hypo_oberosterreich_salzburg_steiermark"
    | "hypo_tirol_bank_ag"
    | "hypo_vorarlberg_bank_ag"
    | "marchfelder_bank"
    | "oberbank_ag"
    | "raiffeisen_bankengruppe_osterreich"
    | "schoellerbank_ag"
    | "sparda_bank_wien"
    | "volksbank_gruppe"
    | "volkskreditbank_ag"
    | "vr_bank_braunau";
export type PaymentMethodEps = {
    bank: EpsBank | null;
};
export type FpxAccountHolderType = "company" | "individual";
export type FpxBank =
    | "affin_bank"
    | "agrobank"
    | "alliance_bank"
    | "ambank"
    | "bank_islam"
    | "bank_muamalat"
    | "bank_of_china"
    | "bank_rakyat"
    | "bsn"
    | "cimb"
    | "deutsche_bank"
    | "hong_leong_bank"
    | "hsbc"
    | "kfh"
    | "maybank2e"
    | "maybank2u"
    | "ocbc"
    | "pb_enterprise"
    | "public_bank"
    | "rhb"
    | "standard_chartered"
    | "uob";
export type PaymentMethodFpx = {
    account_holder_type: FpxAccountHolderType | null;
    bank: FpxBank;
};
export type PaymentMethodGiropay = {};
export type PaymentMethodGrabpay = {};
export type IdealBank =
    | "abn_amro"
    | "asn_bank"
    | "bunq"
    | "handelsbanken"
    | "ing"
    | "knab"
    | "moneyou"
    | "rabobank"
    | "regiobank"
    | "revolut"
    | "sns_bank"
    | "triodos_bank"
    | "van_lanschot";
export type IdealBic =
    | "ABNANL2A"
    | "ASNBNL21"
    | "BUNQNL2A"
    | "FVLBNL22"
    | "HANDNL2A"
    | "INGBNL2A"
    | "KNABNL2H"
    | "MOYONL21"
    | "RABONL2U"
    | "RBRBNL21"
    | "REVOLT21"
    | "SNSBNL2A"
    | "TRIONL2U";
export type PaymentMethodIdeal = {
    bank: IdealBank | null;
    bic: IdealBic | null;
};
export type PaymentMethodInteracPresent = {};
export type KlarnaDob = {
    day: number | null;
    month: number | null;
    year: number | null;
};
export type PaymentMethodKlarna = {
    dob: KlarnaDob | null;
};
export type PaymentMethodKonbini = {};
export type PaymentMethodLink = {
    email: string | null;
    persistent_token?: string;
};
export type PaymentMethodOxxo = {};
export type P24Bank =
    | "alior_bank"
    | "bank_millennium"
    | "bank_nowy_bfg_sa"
    | "bank_pekao_sa"
    | "banki_spbdzielcze"
    | "blik"
    | "bnp_paribas"
    | "boz"
    | "citi_handlowy"
    | "credit_agricole"
    | "envelobank"
    | "etransfer_pocztowy24"
    | "getin_bank"
    | "ideabank"
    | "ing"
    | "inteligo"
    | "mbank_mtransfer"
    | "nest_przelew"
    | "noble_pay"
    | "pbac_z_ipko"
    | "plus_bank"
    | "santander_przelew24"
    | "tmobile_usbugi_bankowe"
    | "toyota_bank"
    | "volkswagen_bank";
export type PaymentMethodP24 = {
    bank: P24Bank | null;
};
export type PaymentMethodPaynow = {};
export type PaymentMethodPix = {};
export type PaymentMethodPromptpay = {};
export type PaymentMethodRadarOptions = {
    session?: string;
};
export type ChargeAlternateStatementDescriptors = {
    kana?: string;
    kanji?: string;
};
export type StripeApplication = {
    id: string;
    object: "application";
    deleted?: any;
    name: string | null;
};
export type BalanceTransactionFeeDetail = {
    amount: number;
    application: string | null;
    currency: string;
    description: string | null;
    type: string;
};
export type StripeConnectCollectionTransfer = {
    id: string;
    object: "connect_collection_transfer";
    amount: number;
    currency: string;
    destination: string | StripeAccount;
    livemode: boolean;
};
export type DisputeEvidence = {
    access_activity_log: string | null;
    billing_address: string | null;
    cancellation_policy: (string | StripeFile) | null;
    cancellation_policy_disclosure: string | null;
    cancellation_rebuttal: string | null;
    customer_communication: (string | StripeFile) | null;
    customer_email_address: string | null;
    customer_name: string | null;
    customer_purchase_ip: string | null;
    customer_signature: (string | StripeFile) | null;
    duplicate_charge_documentation: (string | StripeFile) | null;
    duplicate_charge_explanation: string | null;
    duplicate_charge_id: string | null;
    product_description: string | null;
    receipt: (string | StripeFile) | null;
    refund_policy: (string | StripeFile) | null;
    refund_policy_disclosure: string | null;
    refund_refusal_explanation: string | null;
    service_date: string | null;
    service_documentation: (string | StripeFile) | null;
    shipping_address: string | null;
    shipping_carrier: string | null;
    shipping_date: string | null;
    shipping_documentation: (string | StripeFile) | null;
    shipping_tracking_number: string | null;
    uncategorized_file: (string | StripeFile) | null;
    uncategorized_text: string | null;
};
export type DisputeEvidenceDetails = {
    due_by: number | null;
    has_evidence: boolean;
    past_due: boolean;
    submission_count: number;
};
export type AmountDetailsTip = {
    amount?: number;
};
export type PaymentIntentAmountDetails = {
    tip?: AmountDetailsTip;
};
export type PaymentIntentAutomaticPaymentMethods = {
    enabled: boolean;
};
export type PaymentIntentCancellationReason =
    | "abandoned"
    | "automatic"
    | "duplicate"
    | "failed_invoice"
    | "fraudulent"
    | "requested_by_customer"
    | "void_invoice";
export type PaymentIntentCaptureMethod = "automatic" | "manual";
export type ApiListStripeCharge = {
    object: "list";
    data: StripeCharge[];
    has_more: boolean;
    url: string;
};
export type PaymentIntentConfirmationMethod = "automatic" | "manual";
export type TaxIdType =
    | "ae_trn"
    | "au_abn"
    | "au_arn"
    | "bg_uic"
    | "br_cnpj"
    | "br_cpf"
    | "ca_bn"
    | "ca_gst_hst"
    | "ca_pst_bc"
    | "ca_pst_mb"
    | "ca_pst_sk"
    | "ca_qst"
    | "ch_vat"
    | "cl_tin"
    | "eg_tin"
    | "es_cif"
    | "eu_oss_vat"
    | "eu_vat"
    | "gb_vat"
    | "ge_vat"
    | "hk_br"
    | "hu_tin"
    | "id_npwp"
    | "il_vat"
    | "in_gst"
    | "is_vat"
    | "jp_cn"
    | "jp_rn"
    | "jp_trn"
    | "ke_pin"
    | "kr_brn"
    | "li_uid"
    | "mx_rfc"
    | "my_frp"
    | "my_itn"
    | "my_sst"
    | "no_vat"
    | "nz_gst"
    | "ph_tin"
    | "ru_inn"
    | "ru_kpp"
    | "sa_vat"
    | "sg_gst"
    | "sg_uen"
    | "si_tin"
    | "th_vat"
    | "tr_tin"
    | "tw_vat"
    | "ua_vat"
    | "unknown"
    | "us_ein"
    | "za_vat";
export type VerificationStatus = "pending" | "unavailable" | "unverified" | "verified";
export type TaxIdVerification = {
    status: VerificationStatus;
    verified_address: string | null;
    verified_name: string | null;
};
export type StripeTaxId = {
    id: string;
    object: "tax_id";
    country: string | null;
    created: number;
    customer: (string | StripeCustomer) | null;
    deleted?: any;
    livemode: boolean;
    type: TaxIdType;
    value: string;
    verification: TaxIdVerification | null;
};
export type StripeDeletedTaxId = {
    id: string;
    object: "tax_id";
    deleted: true;
};
export type StripeDeletedApplication = {
    id: string;
    object: "application";
    deleted: true;
    name: string | null;
};
export type AutomaticTaxStatus = "complete" | "failed" | "requires_location_inputs";
export type InvoiceAutomaticTax = {
    enabled: boolean;
    status: AutomaticTaxStatus | null;
};
export type InvoiceBillingReason =
    | "automatic_pending_invoice_item_invoice"
    | "manual"
    | "quote_accept"
    | "subscription"
    | "subscription_create"
    | "subscription_cycle"
    | "subscription_threshold"
    | "subscription_update"
    | "upcoming";
export type InvoiceCollectionMethod = "charge_automatically" | "send_invoice";
export type InvoiceCustomField = {
    name: string;
    value: string;
};
export type InvoiceCustomerShipping = {
    address?: StripeAddress;
    carrier?: string | null;
    name?: string;
    phone?: string | null;
    tracking_number?: string | null;
};
export type InvoiceCustomerTaxExempt = "exempt" | "none" | "reverse";
export type CustomerTaxIdType =
    | "ae_trn"
    | "au_abn"
    | "au_arn"
    | "bg_uic"
    | "br_cnpj"
    | "br_cpf"
    | "ca_bn"
    | "ca_gst_hst"
    | "ca_pst_bc"
    | "ca_pst_mb"
    | "ca_pst_sk"
    | "ca_qst"
    | "ch_vat"
    | "cl_tin"
    | "eg_tin"
    | "es_cif"
    | "eu_oss_vat"
    | "eu_vat"
    | "gb_vat"
    | "ge_vat"
    | "hk_br"
    | "hu_tin"
    | "id_npwp"
    | "il_vat"
    | "in_gst"
    | "is_vat"
    | "jp_cn"
    | "jp_rn"
    | "jp_trn"
    | "ke_pin"
    | "kr_brn"
    | "li_uid"
    | "mx_rfc"
    | "my_frp"
    | "my_itn"
    | "my_sst"
    | "no_vat"
    | "nz_gst"
    | "ph_tin"
    | "ru_inn"
    | "ru_kpp"
    | "sa_vat"
    | "sg_gst"
    | "sg_uen"
    | "si_tin"
    | "th_vat"
    | "tr_tin"
    | "tw_vat"
    | "ua_vat"
    | "unknown"
    | "us_ein"
    | "za_vat";
export type InvoiceCustomerTaxId = {
    type: CustomerTaxIdType;
    value: string | null;
};
export type TaxRateTaxType = "gst" | "hst" | "jct" | "pst" | "qst" | "rst" | "sales_tax" | "vat";
export type StripeTaxRate = {
    id: string;
    object: "tax_rate";
    active: boolean;
    country: string | null;
    created: number;
    description: string | null;
    display_name: string;
    inclusive: boolean;
    jurisdiction: string | null;
    livemode: boolean;
    metadata: StripeMetadata | null;
    percentage: number;
    state: string | null;
    tax_type: TaxRateTaxType | null;
};
export type StripeDeletedDiscount = {
    id: string;
    object: "discount";
    checkout_session: string | null;
    coupon: StripeCoupon;
    customer: (string | StripeCustomer | StripeDeletedCustomer) | null;
    deleted: true;
    invoice: string | null;
    invoice_item: string | null;
    promotion_code: (string | StripePromotionCode) | null;
    start: number;
    subscription: string | null;
};
export type InvoiceFromInvoice = {
    action: string;
    invoice: string | StripeInvoice;
};
export type SetupIntentCancellationReason = "abandoned" | "duplicate" | "requested_by_customer";
export type SetupIntentFlowDirection = "inbound" | "outbound";
export type LastSetupErrorType = "api_error" | "card_error" | "idempotency_error" | "invalid_request_error";
export type SetupIntentLastSetupError = {
    charge?: string;
    code?: string;
    decline_code?: string;
    doc_url?: string;
    message?: string;
    param?: string;
    payment_intent?: StripePaymentIntent;
    payment_method?: StripePaymentMethod;
    payment_method_type?: string;
    request_log_url?: string;
    setup_intent?: StripeSetupIntent;
    source?: StripeCustomerSource;
    type: LastSetupErrorType;
};
export type SetupAttemptFlowDirection = "inbound" | "outbound";
export type PaymentMethodDetailsAcssDebit = {};
export type PaymentMethodDetailsAuBecsDebit = {};
export type PaymentMethodDetailsBacsDebit = {};
export type CustomerAcceptanceOffline = {};
export type CustomerAcceptanceOnline = {
    ip_address: string | null;
    user_agent: string | null;
};
export type CustomerAcceptanceType = "offline" | "online";
export type MandateCustomerAcceptance = {
    accepted_at: number | null;
    offline?: CustomerAcceptanceOffline;
    online?: CustomerAcceptanceOnline;
    type: CustomerAcceptanceType;
};
export type MandateMultiUse = {};
export type OffSessionInterval = "day" | "month" | "week" | "year";
export type BlikOffSession = {
    amount: number | null;
    currency: string | null;
    interval: OffSessionInterval | null;
    interval_count: number | null;
};
export type BlikType = "off_session" | "on_session";
export type PaymentMethodDetailsBlik = {
    expires_after: number | null;
    off_session?: BlikOffSession;
    type: BlikType | null;
};
export type PaymentMethodDetailsCard = {};
export type PaymentMethodDetailsLink = {};
export type PaymentMethodDetailsSepaDebit = {
    reference: string;
    url: string;
};
export type PaymentMethodDetailsUsBankAccount = {};
export type MandatePaymentMethodDetails = {
    acss_debit?: PaymentMethodDetailsAcssDebit;
    au_becs_debit?: PaymentMethodDetailsAuBecsDebit;
    bacs_debit?: PaymentMethodDetailsBacsDebit;
    blik?: PaymentMethodDetailsBlik;
    card?: PaymentMethodDetailsCard;
    link?: PaymentMethodDetailsLink;
    sepa_debit?: PaymentMethodDetailsSepaDebit;
    type: string;
    us_bank_account?: PaymentMethodDetailsUsBankAccount;
};
export type MandateSingleUse = {
    amount: number;
    currency: string;
};
export type MandateStatus = "active" | "inactive" | "pending";
export type MandateType = "multi_use" | "single_use";
export type StripeMandate = {
    id: string;
    object: "mandate";
    customer_acceptance: MandateCustomerAcceptance;
    livemode: boolean;
    multi_use?: MandateMultiUse;
    payment_method: string | StripePaymentMethod;
    payment_method_details: MandatePaymentMethodDetails;
    single_use?: MandateSingleUse;
    status: MandateStatus;
    type: MandateType;
};
export type BancontactPreferredLanguage = "de" | "en" | "fr" | "nl";
export type PaymentMethodDetailsBancontact = {
    bank_code: string | null;
    bank_name: string | null;
    bic: string | null;
    generated_sepa_debit: (string | StripePaymentMethod) | null;
    generated_sepa_debit_mandate: (string | StripeMandate) | null;
    iban_last4: string | null;
    preferred_language: BancontactPreferredLanguage | null;
    verified_name: string | null;
};
export type PaymentMethodDetailsBoleto = {};
export type PaymentMethodDetailsCardPresent = {
    generated_card: (string | StripePaymentMethod) | null;
};
export type PaymentMethodDetailsIdeal = {
    bank: IdealBank | null;
    bic: IdealBic | null;
    generated_sepa_debit: (string | StripePaymentMethod) | null;
    generated_sepa_debit_mandate: (string | StripeMandate) | null;
    iban_last4: string | null;
    verified_name: string | null;
};
export type PaymentMethodDetailsKlarna = {};
export type SofortPreferredLanguage = "de" | "en" | "fr" | "nl";
export type PaymentMethodDetailsSofort = {
    bank_code: string | null;
    bank_name: string | null;
    bic: string | null;
    generated_sepa_debit: (string | StripePaymentMethod) | null;
    generated_sepa_debit_mandate: (string | StripeMandate) | null;
    iban_last4: string | null;
    preferred_language: SofortPreferredLanguage | null;
    verified_name: string | null;
};
export type SetupAttemptPaymentMethodDetails = {
    acss_debit?: PaymentMethodDetailsAcssDebit;
    au_becs_debit?: PaymentMethodDetailsAuBecsDebit;
    bacs_debit?: PaymentMethodDetailsBacsDebit;
    bancontact?: PaymentMethodDetailsBancontact;
    blik?: PaymentMethodDetailsBlik;
    boleto?: PaymentMethodDetailsBoleto;
    card?: PaymentMethodDetailsCard;
    card_present?: PaymentMethodDetailsCardPresent;
    ideal?: PaymentMethodDetailsIdeal;
    klarna?: PaymentMethodDetailsKlarna;
    link?: PaymentMethodDetailsLink;
    sepa_debit?: PaymentMethodDetailsSepaDebit;
    sofort?: PaymentMethodDetailsSofort;
    type: string;
    us_bank_account?: PaymentMethodDetailsUsBankAccount;
};
export type SetupErrorType = "api_error" | "card_error" | "idempotency_error" | "invalid_request_error";
export type SetupAttemptSetupError = {
    charge?: string;
    code?: string;
    decline_code?: string;
    doc_url?: string;
    message?: string;
    param?: string;
    payment_intent?: StripePaymentIntent;
    payment_method?: StripePaymentMethod;
    payment_method_type?: string;
    request_log_url?: string;
    setup_intent?: StripeSetupIntent;
    source?: StripeCustomerSource;
    type: SetupErrorType;
};
export type StripeSetupAttempt = {
    id: string;
    object: "setup_attempt";
    application: (string | StripeApplication) | null;
    attach_to_self?: boolean;
    created: number;
    customer: (string | StripeCustomer | StripeDeletedCustomer) | null;
    flow_directions: SetupAttemptFlowDirection[] | null;
    livemode: boolean;
    on_behalf_of: (string | StripeAccount) | null;
    payment_method: string | StripePaymentMethod;
    payment_method_details: SetupAttemptPaymentMethodDetails;
    setup_error: SetupAttemptSetupError | null;
    setup_intent: string | StripeSetupIntent;
    status: string;
    usage: string;
};
export type NextActionRedirectToUrl = {
    return_url: string | null;
    url: string | null;
};
export type NextActionUseStripeSdk = {};
export type VerifyWithMicrodepositsMicrodepositType = "amounts" | "descriptor_code";
export type NextActionVerifyWithMicrodeposits = {
    arrival_date: number;
    hosted_verification_url: string;
    microdeposit_type: VerifyWithMicrodepositsMicrodepositType | null;
};
export type SetupIntentNextAction = {
    redirect_to_url?: NextActionRedirectToUrl;
    type: string;
    use_stripe_sdk?: NextActionUseStripeSdk;
    verify_with_microdeposits?: NextActionVerifyWithMicrodeposits;
};
export type AcssDebitCurrency = "cad" | "usd";
export type MandateOptionsDefaultFor = "invoice" | "subscription";
export type MandateOptionsPaymentSchedule = "combined" | "interval" | "sporadic";
export type MandateOptionsTransactionType = "business" | "personal";
export type AcssDebitMandateOptions = {
    custom_mandate_url?: string;
    default_for?: MandateOptionsDefaultFor[];
    interval_description: string | null;
    payment_schedule: MandateOptionsPaymentSchedule | null;
    transaction_type: MandateOptionsTransactionType | null;
};
export type AcssDebitVerificationMethod = "automatic" | "instant" | "microdeposits";
export type PaymentMethodOptionsAcssDebit = {
    currency: AcssDebitCurrency | null;
    mandate_options?: AcssDebitMandateOptions;
    verification_method?: AcssDebitVerificationMethod;
};
export type MandateOptionsOffSession = {
    amount: number | null;
    currency: string | null;
    interval: OffSessionInterval | null;
    interval_count: number | null;
};
export type MandateOptionsType = "off_session" | "on_session";
export type BlikMandateOptions = {
    expires_after: number | null;
    off_session?: MandateOptionsOffSession;
    type: MandateOptionsType | null;
};
export type PaymentMethodOptionsBlik = {
    mandate_options?: BlikMandateOptions;
};
export type MandateOptionsAmountType = "fixed" | "maximum";
export type MandateOptionsInterval = "day" | "month" | "sporadic" | "week" | "year";
export type CardMandateOptions = {
    amount: number;
    amount_type: MandateOptionsAmountType;
    currency: string;
    description: string | null;
    end_date: number | null;
    interval: MandateOptionsInterval;
    interval_count: number | null;
    reference: string;
    start_date: number;
    supported_types: "india"[] | null;
};
export type CardNetwork =
    | "amex"
    | "cartes_bancaires"
    | "diners"
    | "discover"
    | "interac"
    | "jcb"
    | "mastercard"
    | "unionpay"
    | "unknown"
    | "visa";
export type CardRequestThreeDSecure = "any" | "automatic" | "challenge_only";
export type PaymentMethodOptionsCard = {
    mandate_options: CardMandateOptions | null;
    network: CardNetwork | null;
    request_three_d_secure: CardRequestThreeDSecure | null;
};
export type PaymentMethodOptionsLink = {
    persistent_token: string | null;
};
export type SepaDebitMandateOptions = {};
export type PaymentMethodOptionsSepaDebit = {
    mandate_options?: SepaDebitMandateOptions;
};
export type FinancialConnectionsPermission = "balances" | "ownership" | "payment_method" | "transactions";
export type UsBankAccountFinancialConnections = {
    permissions?: FinancialConnectionsPermission[];
    return_url?: string;
};
export type UsBankAccountVerificationMethod = "automatic" | "instant" | "microdeposits";
export type PaymentMethodOptionsUsBankAccount = {
    financial_connections?: UsBankAccountFinancialConnections;
    verification_method?: UsBankAccountVerificationMethod;
};
export type SetupIntentPaymentMethodOptions = {
    acss_debit?: PaymentMethodOptionsAcssDebit;
    blik?: PaymentMethodOptionsBlik;
    card?: PaymentMethodOptionsCard;
    link?: PaymentMethodOptionsLink;
    sepa_debit?: PaymentMethodOptionsSepaDebit;
    us_bank_account?: PaymentMethodOptionsUsBankAccount;
};
export type SetupIntentStatus =
    | "canceled"
    | "processing"
    | "requires_action"
    | "requires_confirmation"
    | "requires_payment_method"
    | "succeeded";
export type StripeSetupIntent = {
    id: string;
    object: "setup_intent";
    application: (string | StripeApplication) | null;
    attach_to_self?: boolean;
    cancellation_reason: SetupIntentCancellationReason | null;
    client_secret: string | null;
    created: number;
    customer: (string | StripeCustomer | StripeDeletedCustomer) | null;
    description: string | null;
    flow_directions: SetupIntentFlowDirection[] | null;
    last_setup_error: SetupIntentLastSetupError | null;
    latest_attempt: (string | StripeSetupAttempt) | null;
    livemode: boolean;
    mandate: (string | StripeMandate) | null;
    metadata: StripeMetadata | null;
    next_action: SetupIntentNextAction | null;
    on_behalf_of: (string | StripeAccount) | null;
    payment_method: (string | StripePaymentMethod) | null;
    payment_method_options: SetupIntentPaymentMethodOptions | null;
    payment_method_types: string[];
    single_use_mandate: (string | StripeMandate) | null;
    status: SetupIntentStatus;
    usage: string;
};
export type LastFinalizationErrorType = "api_error" | "card_error" | "idempotency_error" | "invalid_request_error";
export type InvoiceLastFinalizationError = {
    charge?: string;
    code?: string;
    decline_code?: string;
    doc_url?: string;
    message?: string;
    param?: string;
    payment_intent?: StripePaymentIntent;
    payment_method?: StripePaymentMethod;
    payment_method_type?: string;
    request_log_url?: string;
    setup_intent?: StripeSetupIntent;
    source?: StripeCustomerSource;
    type: LastFinalizationErrorType;
};
export type InvoiceLineItemDiscountAmount = {
    amount: number;
    discount: string | StripeDiscount | StripeDeletedDiscount;
};
export type InvoiceLineItemPeriod = {
    end: number;
    start: number;
};
export type PlanAggregateUsage = "last_during_period" | "last_ever" | "max" | "sum";
export type PlanBillingScheme = "per_unit" | "tiered";
export type PlanInterval = "day" | "month" | "week" | "year";
export type PriceBillingScheme = "per_unit" | "tiered";
export type CurrencyOptionsCustomUnitAmount = {
    maximum: number | null;
    minimum: number | null;
    preset: number | null;
};
export type CurrencyOptionsTaxBehavior = "exclusive" | "inclusive" | "unspecified";
export type CurrencyOptionsTier = {
    flat_amount: number | null;
    flat_amount_decimal: string | null;
    unit_amount: number | null;
    unit_amount_decimal: string | null;
    up_to: number | null;
};
export type PriceCurrencyOptions = {
    custom_unit_amount: CurrencyOptionsCustomUnitAmount | null;
    tax_behavior: CurrencyOptionsTaxBehavior | null;
    tiers?: CurrencyOptionsTier[];
    unit_amount: number | null;
    unit_amount_decimal: string | null;
};
export type PriceCustomUnitAmount = {
    maximum: number | null;
    minimum: number | null;
    preset: number | null;
};
export type StripeDeletedProduct = {
    id: string;
    object: "product";
    deleted: true;
};
export type RecurringAggregateUsage = "last_during_period" | "last_ever" | "max" | "sum";
export type RecurringInterval = "day" | "month" | "week" | "year";
export type RecurringUsageType = "licensed" | "metered";
export type PriceRecurring = {
    aggregate_usage: RecurringAggregateUsage | null;
    interval: RecurringInterval;
    interval_count: number;
    trial_period_days: number | null;
    usage_type: RecurringUsageType;
};
export type PriceTaxBehavior = "exclusive" | "inclusive" | "unspecified";
export type PriceTier = {
    flat_amount: number | null;
    flat_amount_decimal: string | null;
    unit_amount: number | null;
    unit_amount_decimal: string | null;
    up_to: number | null;
};
export type PriceTiersMode = "graduated" | "volume";
export type TransformQuantityRound = "down" | "up";
export type PriceTransformQuantity = {
    divide_by: number;
    round: TransformQuantityRound;
};
export type PriceType = "one_time" | "recurring";
export type StripePrice = {
    id: string;
    object: "price";
    active: boolean;
    billing_scheme: PriceBillingScheme;
    created: number;
    currency: string;
    currency_options?: {
        [key: string]: PriceCurrencyOptions;
    };
    custom_unit_amount: PriceCustomUnitAmount | null;
    deleted?: any;
    livemode: boolean;
    lookup_key: string | null;
    metadata: StripeMetadata;
    nickname: string | null;
    product: string | StripeProduct | StripeDeletedProduct;
    recurring: PriceRecurring | null;
    tax_behavior: PriceTaxBehavior | null;
    tiers?: PriceTier[];
    tiers_mode: PriceTiersMode | null;
    transform_quantity: PriceTransformQuantity | null;
    type: PriceType;
    unit_amount: number | null;
    unit_amount_decimal: string | null;
};
export type ProductPackageDimensions = {
    height: number;
    length: number;
    weight: number;
    width: number;
};
export type StripeTaxCode = {
    id: string;
    object: "tax_code";
    description: string;
    name: string;
};
export type ProductType = "good" | "service";
export type StripeProduct = {
    id: string;
    object: "product";
    active: boolean;
    attributes: string[] | null;
    caption?: string | null;
    created: number;
    deactivate_on?: string[];
    default_price?: (string | StripePrice) | null;
    deleted?: any;
    description: string | null;
    images: string[];
    livemode: boolean;
    metadata: StripeMetadata;
    name: string;
    package_dimensions: ProductPackageDimensions | null;
    shippable: boolean | null;
    statement_descriptor?: string | null;
    tax_code: (string | StripeTaxCode) | null;
    type: ProductType;
    unit_label?: string | null;
    updated: number;
    url: string | null;
};
export type PlanTier = {
    flat_amount: number | null;
    flat_amount_decimal: string | null;
    unit_amount: number | null;
    unit_amount_decimal: string | null;
    up_to: number | null;
};
export type PlanTiersMode = "graduated" | "volume";
export type TransformUsageRound = "down" | "up";
export type PlanTransformUsage = {
    divide_by: number;
    round: TransformUsageRound;
};
export type PlanUsageType = "licensed" | "metered";
export type StripePlan = {
    id: string;
    object: "plan";
    active: boolean;
    aggregate_usage: PlanAggregateUsage | null;
    amount: number | null;
    amount_decimal: string | null;
    billing_scheme: PlanBillingScheme;
    created: number;
    currency: string;
    deleted?: any;
    interval: PlanInterval;
    interval_count: number;
    livemode: boolean;
    metadata: StripeMetadata | null;
    nickname: string | null;
    product: (string | StripeProduct | StripeDeletedProduct) | null;
    tiers?: PlanTier[];
    tiers_mode: PlanTiersMode | null;
    transform_usage: PlanTransformUsage | null;
    trial_period_days: number | null;
    usage_type: PlanUsageType;
};
export type ProrationDetailsCreditedItems = {
    invoice: string;
    invoice_line_items: string[];
};
export type InvoiceLineItemProrationDetails = {
    credited_items: ProrationDetailsCreditedItems | null;
};
export type InvoiceLineItemTaxAmount = {
    amount: number;
    inclusive: boolean;
    tax_rate: string | StripeTaxRate;
};
export type InvoiceLineItemType = "invoiceitem" | "subscription";
export type StripeInvoiceLineItem = {
    id: string;
    object: "line_item";
    amount: number;
    amount_excluding_tax: number | null;
    currency: string;
    description: string | null;
    discount_amounts: InvoiceLineItemDiscountAmount[] | null;
    discountable: boolean;
    discounts: (string | StripeDiscount)[] | null;
    invoice_item?: string;
    livemode: boolean;
    metadata: StripeMetadata;
    period: InvoiceLineItemPeriod;
    plan: StripePlan | null;
    price: StripePrice | null;
    proration: boolean;
    proration_details: InvoiceLineItemProrationDetails | null;
    quantity: number | null;
    subscription: string | null;
    subscription_item?: string;
    tax_amounts?: InvoiceLineItemTaxAmount[];
    tax_rates?: StripeTaxRate[];
    type: InvoiceLineItemType;
    unit_amount_excluding_tax: string | null;
};
export type ApiListStripeInvoiceLineItem = {
    object: "list";
    data: StripeInvoiceLineItem[];
    has_more: boolean;
    url: string;
};
export type PaymentMethodOptionsBancontact = {
    preferred_language: BancontactPreferredLanguage;
};
export type EuBankTransferCountry = "DE" | "ES" | "FR" | "IE" | "NL";
export type BankTransferEuBankTransfer = {
    country: EuBankTransferCountry;
};
export type CustomerBalanceBankTransfer = {
    eu_bank_transfer?: BankTransferEuBankTransfer;
    type: string | null;
};
export type PaymentMethodOptionsCustomerBalance = {
    bank_transfer?: CustomerBalanceBankTransfer;
    funding_type: "bank_transfer" | null;
};
export type PaymentMethodOptionsKonbini = {};
export type PaymentSettingsPaymentMethodOptions = {
    acss_debit: PaymentMethodOptionsAcssDebit | null;
    bancontact: PaymentMethodOptionsBancontact | null;
    card: PaymentMethodOptionsCard | null;
    customer_balance: PaymentMethodOptionsCustomerBalance | null;
    konbini: PaymentMethodOptionsKonbini | null;
    us_bank_account: PaymentMethodOptionsUsBankAccount | null;
};
export type PaymentSettingsPaymentMethodType =
    | "ach_credit_transfer"
    | "ach_debit"
    | "acss_debit"
    | "au_becs_debit"
    | "bacs_debit"
    | "bancontact"
    | "boleto"
    | "card"
    | "customer_balance"
    | "fpx"
    | "giropay"
    | "grabpay"
    | "ideal"
    | "konbini"
    | "link"
    | "paynow"
    | "promptpay"
    | "sepa_credit_transfer"
    | "sepa_debit"
    | "sofort"
    | "us_bank_account"
    | "wechat_pay";
export type InvoicePaymentSettings = {
    default_mandate: string | null;
    payment_method_options: PaymentSettingsPaymentMethodOptions | null;
    payment_method_types: PaymentSettingsPaymentMethodType[] | null;
};
export type QuoteAutomaticTax = {
    enabled: boolean;
    status: AutomaticTaxStatus | null;
};
export type QuoteCollectionMethod = "charge_automatically" | "send_invoice";
export type BreakdownDiscount = {
    amount: number;
    discount: StripeDiscount;
};
export type BreakdownTax = {
    amount: number;
    rate: StripeTaxRate;
};
export type TotalDetailsBreakdown = {
    discounts: BreakdownDiscount[];
    taxes: BreakdownTax[];
};
export type RecurringTotalDetails = {
    amount_discount: number;
    amount_shipping: number | null;
    amount_tax: number;
    breakdown?: TotalDetailsBreakdown;
};
export type ComputedRecurring = {
    amount_subtotal: number;
    amount_total: number;
    interval: RecurringInterval;
    interval_count: number;
    total_details: RecurringTotalDetails;
};
export type LineItemDiscount = {
    amount: number;
    discount: StripeDiscount;
};
export type LineItemTax = {
    amount: number;
    rate: StripeTaxRate;
};
export type StripeLineItem = {
    id: string;
    object: "item";
    amount_discount: number;
    amount_subtotal: number;
    amount_tax: number;
    amount_total: number;
    currency: string;
    description: string;
    discounts?: LineItemDiscount[];
    price: StripePrice | null;
    product?: string | StripeProduct | StripeDeletedProduct;
    quantity: number | null;
    taxes?: LineItemTax[];
};
export type ApiListStripeLineItem = {
    object: "list";
    data: StripeLineItem[];
    has_more: boolean;
    url: string;
};
export type UpfrontTotalDetails = {
    amount_discount: number;
    amount_shipping: number | null;
    amount_tax: number;
    breakdown?: TotalDetailsBreakdown;
};
export type ComputedUpfront = {
    amount_subtotal: number;
    amount_total: number;
    line_items?: ApiListStripeLineItem;
    total_details: UpfrontTotalDetails;
};
export type QuoteComputed = {
    recurring: ComputedRecurring | null;
    upfront: ComputedUpfront;
};
export type QuoteFromQuote = {
    is_revision: boolean;
    quote: string | StripeQuote;
};
export type StripeDeletedInvoice = {
    id: string;
    object: "invoice";
    deleted: true;
};
export type QuoteInvoiceSettings = {
    days_until_due: number | null;
};
export type QuoteStatus = "accepted" | "canceled" | "draft" | "open";
export type QuoteStatusTransitions = {
    accepted_at: number | null;
    canceled_at: number | null;
    finalized_at: number | null;
};
export type SubscriptionAutomaticTax = {
    enabled: boolean;
};
export type SubscriptionBillingThresholds = {
    amount_gte: number | null;
    reset_billing_cycle_anchor: boolean | null;
};
export type SubscriptionCollectionMethod = "charge_automatically" | "send_invoice";
export type SubscriptionItemBillingThresholds = {
    usage_gte: number | null;
};
export type StripeSubscriptionItem = {
    id: string;
    object: "subscription_item";
    billing_thresholds: SubscriptionItemBillingThresholds | null;
    created: number;
    deleted?: any;
    metadata: StripeMetadata;
    plan: StripePlan;
    price: StripePrice;
    quantity?: number;
    subscription: string;
    tax_rates: StripeTaxRate[] | null;
};
export type ApiListStripeSubscriptionItem = {
    object: "list";
    data: StripeSubscriptionItem[];
    has_more: boolean;
    url: string;
};
export type PauseCollectionBehavior = "keep_as_draft" | "mark_uncollectible" | "void";
export type SubscriptionPauseCollection = {
    behavior: PauseCollectionBehavior;
    resumes_at: number | null;
};
export type PaymentSettingsSaveDefaultPaymentMethod = "off" | "on_subscription";
export type SubscriptionPaymentSettings = {
    payment_method_options: PaymentSettingsPaymentMethodOptions | null;
    payment_method_types: PaymentSettingsPaymentMethodType[] | null;
    save_default_payment_method: PaymentSettingsSaveDefaultPaymentMethod | null;
};
export type PendingInvoiceItemIntervalInterval = "day" | "month" | "week" | "year";
export type SubscriptionPendingInvoiceItemInterval = {
    interval: PendingInvoiceItemIntervalInterval;
    interval_count: number;
};
export type SubscriptionPendingUpdate = {
    billing_cycle_anchor: number | null;
    expires_at: number;
    subscription_items: StripeSubscriptionItem[] | null;
    trial_end: number | null;
    trial_from_plan: boolean | null;
};
export type SubscriptionScheduleCurrentPhase = {
    end_date: number;
    start_date: number;
};
export type DefaultSettingsAutomaticTax = {
    enabled: boolean;
};
export type DefaultSettingsBillingCycleAnchor = "automatic" | "phase_start";
export type DefaultSettingsBillingThresholds = {
    amount_gte: number | null;
    reset_billing_cycle_anchor: boolean | null;
};
export type DefaultSettingsCollectionMethod = "charge_automatically" | "send_invoice";
export type DefaultSettingsInvoiceSettings = {
    days_until_due: number | null;
};
export type DefaultSettingsTransferData = {
    amount_percent: number | null;
    destination: string | StripeAccount;
};
export type SubscriptionScheduleDefaultSettings = {
    application_fee_percent: number | null;
    automatic_tax?: DefaultSettingsAutomaticTax;
    billing_cycle_anchor: DefaultSettingsBillingCycleAnchor;
    billing_thresholds: DefaultSettingsBillingThresholds | null;
    collection_method: DefaultSettingsCollectionMethod | null;
    default_payment_method: (string | StripePaymentMethod) | null;
    description: string | null;
    invoice_settings: DefaultSettingsInvoiceSettings | null;
    on_behalf_of: (string | StripeAccount) | null;
    transfer_data: DefaultSettingsTransferData | null;
};
export type SubscriptionScheduleEndBehavior = "cancel" | "none" | "release" | "renew";
export type StripeDeletedPrice = {
    id: string;
    object: "price";
    deleted: true;
};
export type PhaseAddInvoiceItem = {
    price: string | StripePrice | StripeDeletedPrice;
    quantity: number | null;
    tax_rates?: StripeTaxRate[] | null;
};
export type PhaseAutomaticTax = {
    enabled: boolean;
};
export type PhaseBillingCycleAnchor = "automatic" | "phase_start";
export type PhaseBillingThresholds = {
    amount_gte: number | null;
    reset_billing_cycle_anchor: boolean | null;
};
export type PhaseCollectionMethod = "charge_automatically" | "send_invoice";
export type StripeDeletedCoupon = {
    id: string;
    object: "coupon";
    deleted: true;
};
export type PhaseInvoiceSettings = {
    days_until_due: number | null;
};
export type ItemBillingThresholds = {
    usage_gte: number | null;
};
export type StripeDeletedPlan = {
    id: string;
    object: "plan";
    deleted: true;
};
export type PhaseItem = {
    billing_thresholds: ItemBillingThresholds | null;
    plan: string | StripePlan | StripeDeletedPlan;
    price: string | StripePrice | StripeDeletedPrice;
    quantity?: number;
    tax_rates?: StripeTaxRate[] | null;
};
export type PhaseProrationBehavior = "always_invoice" | "create_prorations" | "none";
export type PhaseTransferData = {
    amount_percent: number | null;
    destination: string | StripeAccount;
};
export type SubscriptionSchedulePhase = {
    add_invoice_items: PhaseAddInvoiceItem[];
    application_fee_percent: number | null;
    automatic_tax?: PhaseAutomaticTax;
    billing_cycle_anchor: PhaseBillingCycleAnchor | null;
    billing_thresholds: PhaseBillingThresholds | null;
    collection_method: PhaseCollectionMethod | null;
    coupon: (string | StripeCoupon | StripeDeletedCoupon) | null;
    currency: string;
    default_payment_method: (string | StripePaymentMethod) | null;
    default_tax_rates?: StripeTaxRate[] | null;
    description: string | null;
    end_date: number;
    invoice_settings: PhaseInvoiceSettings | null;
    items: PhaseItem[];
    metadata: StripeMetadata | null;
    on_behalf_of: (string | StripeAccount) | null;
    proration_behavior: PhaseProrationBehavior;
    start_date: number;
    transfer_data: PhaseTransferData | null;
    trial_end: number | null;
};
export type SubscriptionScheduleStatus = "active" | "canceled" | "completed" | "not_started" | "released";
export type TestClockStatus = "advancing" | "internal_failure" | "ready";
export type StripeTestHelpersTestClock = {
    id: string;
    object: "test_helpers.test_clock";
    created: number;
    deleted?: any;
    deletes_after: number;
    frozen_time: number;
    livemode: boolean;
    name: string | null;
    status: TestClockStatus;
};
export type StripeSubscriptionSchedule = {
    id: string;
    object: "subscription_schedule";
    application: (string | StripeApplication | StripeDeletedApplication) | null;
    canceled_at: number | null;
    completed_at: number | null;
    created: number;
    current_phase: SubscriptionScheduleCurrentPhase | null;
    customer: string | StripeCustomer | StripeDeletedCustomer;
    default_settings: SubscriptionScheduleDefaultSettings;
    end_behavior: SubscriptionScheduleEndBehavior;
    livemode: boolean;
    metadata: StripeMetadata | null;
    phases: SubscriptionSchedulePhase[];
    released_at: number | null;
    released_subscription: string | null;
    status: SubscriptionScheduleStatus;
    subscription: (string | StripeSubscription) | null;
    test_clock: (string | StripeTestHelpersTestClock) | null;
};
export type SubscriptionStatus2 =
    | "active"
    | "canceled"
    | "incomplete"
    | "incomplete_expired"
    | "past_due"
    | "trialing"
    | "unpaid";
export type SubscriptionTransferData = {
    amount_percent: number | null;
    destination: string | StripeAccount;
};
export type StripeSubscription = {
    id: string;
    object: "subscription";
    application: (string | StripeApplication | StripeDeletedApplication) | null;
    application_fee_percent: number | null;
    automatic_tax: SubscriptionAutomaticTax;
    billing_cycle_anchor: number;
    billing_thresholds: SubscriptionBillingThresholds | null;
    cancel_at: number | null;
    cancel_at_period_end: boolean;
    canceled_at: number | null;
    collection_method: SubscriptionCollectionMethod;
    created: number;
    currency: string;
    current_period_end: number;
    current_period_start: number;
    customer: string | StripeCustomer | StripeDeletedCustomer;
    days_until_due: number | null;
    default_payment_method: (string | StripePaymentMethod) | null;
    default_source: (string | StripeCustomerSource) | null;
    default_tax_rates?: StripeTaxRate[] | null;
    description: string | null;
    discount: StripeDiscount | null;
    ended_at: number | null;
    items: ApiListStripeSubscriptionItem;
    latest_invoice: (string | StripeInvoice) | null;
    livemode: boolean;
    metadata: StripeMetadata;
    next_pending_invoice_item_invoice: number | null;
    on_behalf_of: (string | StripeAccount) | null;
    pause_collection: SubscriptionPauseCollection | null;
    payment_settings: SubscriptionPaymentSettings | null;
    pending_invoice_item_interval: SubscriptionPendingInvoiceItemInterval | null;
    pending_setup_intent: (string | StripeSetupIntent) | null;
    pending_update: SubscriptionPendingUpdate | null;
    schedule: (string | StripeSubscriptionSchedule) | null;
    start_date: number;
    status: SubscriptionStatus2;
    test_clock: (string | StripeTestHelpersTestClock) | null;
    transfer_data: SubscriptionTransferData | null;
    trial_end: number | null;
    trial_start: number | null;
};
export type QuoteSubscriptionData = {
    description: string | null;
    effective_date: number | null;
    trial_period_days: number | null;
};
export type QuoteTotalDetails = {
    amount_discount: number;
    amount_shipping: number | null;
    amount_tax: number;
    breakdown?: TotalDetailsBreakdown;
};
export type QuoteTransferData = {
    amount: number | null;
    amount_percent: number | null;
    destination: string | StripeAccount;
};
export type StripeQuote = {
    id: string;
    object: "quote";
    amount_subtotal: number;
    amount_total: number;
    application: (string | StripeApplication | StripeDeletedApplication) | null;
    application_fee_amount: number | null;
    application_fee_percent: number | null;
    automatic_tax: QuoteAutomaticTax;
    collection_method: QuoteCollectionMethod;
    computed: QuoteComputed;
    created: number;
    currency: string | null;
    customer: (string | StripeCustomer | StripeDeletedCustomer) | null;
    default_tax_rates?: (string | StripeTaxRate)[];
    description: string | null;
    discounts: (string | StripeDiscount)[];
    expires_at: number;
    footer: string | null;
    from_quote: QuoteFromQuote | null;
    header: string | null;
    invoice: (string | StripeInvoice | StripeDeletedInvoice) | null;
    invoice_settings: QuoteInvoiceSettings | null;
    line_items?: ApiListStripeLineItem;
    livemode: boolean;
    metadata: StripeMetadata;
    number: string | null;
    on_behalf_of: (string | StripeAccount) | null;
    status: QuoteStatus;
    status_transitions: QuoteStatusTransitions;
    subscription: (string | StripeSubscription) | null;
    subscription_data: QuoteSubscriptionData;
    subscription_schedule: (string | StripeSubscriptionSchedule) | null;
    test_clock: (string | StripeTestHelpersTestClock) | null;
    total_details: QuoteTotalDetails;
    transfer_data: QuoteTransferData | null;
};
export type InvoiceRenderingOptions = {
    amount_tax_display: string | null;
};
export type InvoiceStatus = "deleted" | "draft" | "open" | "paid" | "uncollectible" | "void";
export type InvoiceStatusTransitions = {
    finalized_at: number | null;
    marked_uncollectible_at: number | null;
    paid_at: number | null;
    voided_at: number | null;
};
export type ThresholdReasonItemReason = {
    line_item_ids: string[];
    usage_gte: number;
};
export type InvoiceThresholdReason = {
    amount_gte: number | null;
    item_reasons: ThresholdReasonItemReason[];
};
export type InvoiceTotalDiscountAmount = {
    amount: number;
    discount: string | StripeDiscount | StripeDeletedDiscount;
};
export type InvoiceTotalTaxAmount = {
    amount: number;
    inclusive: boolean;
    tax_rate: string | StripeTaxRate;
};
export type InvoiceTransferData = {
    amount: number | null;
    destination: string | StripeAccount;
};
export type StripeInvoice = {
    id?: string;
    object: "invoice";
    account_country: string | null;
    account_name: string | null;
    account_tax_ids: (string | StripeTaxId | StripeDeletedTaxId)[] | null;
    amount_due: number;
    amount_paid: number;
    amount_remaining: number;
    application: (string | StripeApplication | StripeDeletedApplication) | null;
    application_fee_amount: number | null;
    attempt_count: number;
    attempted: boolean;
    auto_advance?: boolean;
    automatic_tax: InvoiceAutomaticTax;
    billing_reason: InvoiceBillingReason | null;
    charge: (string | StripeCharge) | null;
    collection_method: InvoiceCollectionMethod;
    created: number;
    currency: string;
    custom_fields: InvoiceCustomField[] | null;
    customer: (string | StripeCustomer | StripeDeletedCustomer) | null;
    customer_address: StripeAddress | null;
    customer_email: string | null;
    customer_name: string | null;
    customer_phone: string | null;
    customer_shipping: InvoiceCustomerShipping | null;
    customer_tax_exempt: InvoiceCustomerTaxExempt | null;
    customer_tax_ids?: InvoiceCustomerTaxId[] | null;
    default_payment_method: (string | StripePaymentMethod) | null;
    default_source: (string | StripeCustomerSource) | null;
    default_tax_rates: StripeTaxRate[];
    deleted?: any;
    description: string | null;
    discount: StripeDiscount | null;
    discounts: (string | StripeDiscount | StripeDeletedDiscount)[] | null;
    due_date: number | null;
    ending_balance: number | null;
    footer: string | null;
    from_invoice: InvoiceFromInvoice | null;
    hosted_invoice_url?: string | null;
    invoice_pdf?: string | null;
    last_finalization_error: InvoiceLastFinalizationError | null;
    latest_revision: (string | StripeInvoice) | null;
    lines: ApiListStripeInvoiceLineItem;
    livemode: boolean;
    metadata: StripeMetadata | null;
    next_payment_attempt: number | null;
    number: string | null;
    on_behalf_of: (string | StripeAccount) | null;
    paid: boolean;
    paid_out_of_band: boolean;
    payment_intent: (string | StripePaymentIntent) | null;
    payment_settings: InvoicePaymentSettings;
    period_end: number;
    period_start: number;
    post_payment_credit_notes_amount: number;
    pre_payment_credit_notes_amount: number;
    quote: (string | StripeQuote) | null;
    receipt_number: string | null;
    rendering_options: InvoiceRenderingOptions | null;
    starting_balance: number;
    statement_descriptor: string | null;
    status: InvoiceStatus | null;
    status_transitions: InvoiceStatusTransitions;
    subscription: (string | StripeSubscription) | null;
    subscription_proration_date?: number;
    subtotal: number;
    subtotal_excluding_tax: number | null;
    tax: number | null;
    test_clock: (string | StripeTestHelpersTestClock) | null;
    threshold_reason?: InvoiceThresholdReason;
    total: number;
    total_discount_amounts: InvoiceTotalDiscountAmount[] | null;
    total_excluding_tax: number | null;
    total_tax_amounts: InvoiceTotalTaxAmount[];
    transfer_data: InvoiceTransferData | null;
    webhooks_delivered_at: number | null;
};
export type LastPaymentErrorType = "api_error" | "card_error" | "idempotency_error" | "invalid_request_error";
export type PaymentIntentLastPaymentError = {
    charge?: string;
    code?: string;
    decline_code?: string;
    doc_url?: string;
    message?: string;
    param?: string;
    payment_intent?: StripePaymentIntent;
    payment_method?: StripePaymentMethod;
    payment_method_type?: string;
    request_log_url?: string;
    setup_intent?: StripeSetupIntent;
    source?: StripeCustomerSource;
    type: LastPaymentErrorType;
};
export type NextActionAlipayHandleRedirect = {
    native_data: string | null;
    native_url: string | null;
    return_url: string | null;
    url: string | null;
};
export type NextActionBoletoDisplayDetails = {
    expires_at: number | null;
    hosted_voucher_url: string | null;
    number: string | null;
    pdf: string | null;
};
export type NextActionCardAwaitNotification = {
    charge_attempt_at: number | null;
    customer_approval_required: boolean | null;
};
export type FinancialAddressIban = {
    account_holder_name: string;
    bic: string;
    country: string;
    iban: string;
};
export type FinancialAddressSortCode = {
    account_holder_name: string;
    account_number: string;
    sort_code: string;
};
export type FinancialAddressSpei = {
    bank_code: string;
    bank_name: string;
    clabe: string;
};
export type FinancialAddressSupportedNetwork = "bacs" | "fps" | "sepa" | "spei" | "zengin";
export type FinancialAddressType = "iban" | "sort_code" | "spei" | "zengin";
export type FinancialAddressZengin = {
    account_holder_name: string | null;
    account_number: string | null;
    account_type: string | null;
    bank_code: string | null;
    bank_name: string | null;
    branch_code: string | null;
    branch_name: string | null;
};
export type DisplayBankTransferInstructionsFinancialAddress = {
    iban?: FinancialAddressIban;
    sort_code?: FinancialAddressSortCode;
    spei?: FinancialAddressSpei;
    supported_networks?: FinancialAddressSupportedNetwork[];
    type: FinancialAddressType;
    zengin?: FinancialAddressZengin;
};
export type DisplayBankTransferInstructionsType =
    | "eu_bank_transfer"
    | "gb_bank_transfer"
    | "jp_bank_transfer"
    | "mx_bank_transfer";
export type NextActionDisplayBankTransferInstructions = {
    amount_remaining: number | null;
    currency: string | null;
    financial_addresses?: DisplayBankTransferInstructionsFinancialAddress[];
    hosted_instructions_url: string | null;
    reference: string | null;
    type: DisplayBankTransferInstructionsType;
};
export type StoresFamilymart = {
    confirmation_number?: string;
    payment_code: string;
};
export type StoresLawson = {
    confirmation_number?: string;
    payment_code: string;
};
export type StoresMinistop = {
    confirmation_number?: string;
    payment_code: string;
};
export type StoresSeicomart = {
    confirmation_number?: string;
    payment_code: string;
};
export type KonbiniDisplayDetailsStores = {
    familymart: StoresFamilymart | null;
    lawson: StoresLawson | null;
    ministop: StoresMinistop | null;
    seicomart: StoresSeicomart | null;
};
export type NextActionKonbiniDisplayDetails = {
    expires_at: number;
    hosted_voucher_url: string | null;
    stores: KonbiniDisplayDetailsStores;
};
export type NextActionOxxoDisplayDetails = {
    expires_after: number | null;
    hosted_voucher_url: string | null;
    number: string | null;
};
export type NextActionPaynowDisplayQrCode = {
    data: string;
    image_url_png: string;
    image_url_svg: string;
};
export type NextActionPixDisplayQrCode = {
    data?: string;
    expires_at?: number;
    hosted_instructions_url?: string;
    image_url_png?: string;
    image_url_svg?: string;
};
export type NextActionPromptpayDisplayQrCode = {
    data: string;
    hosted_instructions_url: string;
    image_url_png: string;
    image_url_svg: string;
};
export type NextActionWechatPayDisplayQrCode = {
    data: string;
    image_data_url: string;
    image_url_png: string;
    image_url_svg: string;
};
export type NextActionWechatPayRedirectToAndroidApp = {
    app_id: string;
    nonce_str: string;
    package: string;
    partner_id: string;
    prepay_id: string;
    sign: string;
    timestamp: string;
};
export type NextActionWechatPayRedirectToIosApp = {
    native_url: string;
};
export type PaymentIntentNextAction = {
    alipay_handle_redirect?: NextActionAlipayHandleRedirect;
    boleto_display_details?: NextActionBoletoDisplayDetails;
    card_await_notification?: NextActionCardAwaitNotification;
    display_bank_transfer_instructions?: NextActionDisplayBankTransferInstructions;
    konbini_display_details?: NextActionKonbiniDisplayDetails;
    oxxo_display_details?: NextActionOxxoDisplayDetails;
    paynow_display_qr_code?: NextActionPaynowDisplayQrCode;
    pix_display_qr_code?: NextActionPixDisplayQrCode;
    promptpay_display_qr_code?: NextActionPromptpayDisplayQrCode;
    redirect_to_url?: NextActionRedirectToUrl;
    type: string;
    use_stripe_sdk?: NextActionUseStripeSdk;
    verify_with_microdeposits?: NextActionVerifyWithMicrodeposits;
    wechat_pay_display_qr_code?: NextActionWechatPayDisplayQrCode;
    wechat_pay_redirect_to_android_app?: NextActionWechatPayRedirectToAndroidApp;
    wechat_pay_redirect_to_ios_app?: NextActionWechatPayRedirectToIosApp;
};
export type PaymentMethodOptionsAffirm = {
    capture_method?: "manual";
    setup_future_usage?: "none";
};
export type PaymentMethodOptionsAfterpayClearpay = {
    capture_method?: "manual";
    reference: string | null;
    setup_future_usage?: "none";
};
export type AlipaySetupFutureUsage = "none" | "off_session";
export type PaymentMethodOptionsAlipay = {
    setup_future_usage?: AlipaySetupFutureUsage;
};
export type AuBecsDebitSetupFutureUsage = "none" | "off_session" | "on_session";
export type PaymentMethodOptionsAuBecsDebit = {
    setup_future_usage?: AuBecsDebitSetupFutureUsage;
};
export type BacsDebitSetupFutureUsage = "none" | "off_session" | "on_session";
export type PaymentMethodOptionsBacsDebit = {
    setup_future_usage?: BacsDebitSetupFutureUsage;
};
export type BoletoSetupFutureUsage = "none" | "off_session" | "on_session";
export type PaymentMethodOptionsBoleto = {
    expires_after_days: number;
    setup_future_usage?: BoletoSetupFutureUsage;
};
export type PaymentMethodOptionsCardPresent = {
    request_extended_authorization: boolean | null;
    request_incremental_authorization_support: boolean | null;
};
export type PaymentMethodOptionsEps = {
    setup_future_usage?: "none";
};
export type PaymentMethodOptionsFpx = {
    setup_future_usage?: "none";
};
export type PaymentMethodOptionsGiropay = {
    setup_future_usage?: "none";
};
export type PaymentMethodOptionsGrabpay = {
    setup_future_usage?: "none";
};
export type IdealSetupFutureUsage = "none" | "off_session";
export type PaymentMethodOptionsIdeal = {
    setup_future_usage?: IdealSetupFutureUsage;
};
export type PaymentMethodOptionsInteracPresent = {};
export type PaymentMethodOptionsKlarna = {
    capture_method?: "manual";
    preferred_locale: string | null;
    setup_future_usage?: "none";
};
export type PaymentMethodOptionsOxxo = {
    expires_after_days: number;
    setup_future_usage?: "none";
};
export type PaymentMethodOptionsP24 = {
    setup_future_usage?: "none";
};
export type PaymentMethodOptionsPaynow = {
    setup_future_usage?: "none";
};
export type PaymentMethodOptionsPix = {
    expires_after_seconds: number | null;
    expires_at: number | null;
    setup_future_usage?: "none";
};
export type PaymentMethodOptionsPromptpay = {
    setup_future_usage?: "none";
};
export type SofortSetupFutureUsage = "none" | "off_session";
export type PaymentMethodOptionsSofort = {
    preferred_language: SofortPreferredLanguage | null;
    setup_future_usage?: SofortSetupFutureUsage;
};
export type WechatPayClient = "android" | "ios" | "web";
export type PaymentMethodOptionsWechatPay = {
    app_id: string | null;
    client: WechatPayClient | null;
    setup_future_usage?: "none";
};
export type PaymentIntentPaymentMethodOptions = {
    acss_debit?: PaymentMethodOptionsAcssDebit;
    affirm?: PaymentMethodOptionsAffirm;
    afterpay_clearpay?: PaymentMethodOptionsAfterpayClearpay;
    alipay?: PaymentMethodOptionsAlipay;
    au_becs_debit?: PaymentMethodOptionsAuBecsDebit;
    bacs_debit?: PaymentMethodOptionsBacsDebit;
    bancontact?: PaymentMethodOptionsBancontact;
    blik?: PaymentMethodOptionsBlik;
    boleto?: PaymentMethodOptionsBoleto;
    card?: PaymentMethodOptionsCard;
    card_present?: PaymentMethodOptionsCardPresent;
    customer_balance?: PaymentMethodOptionsCustomerBalance;
    eps?: PaymentMethodOptionsEps;
    fpx?: PaymentMethodOptionsFpx;
    giropay?: PaymentMethodOptionsGiropay;
    grabpay?: PaymentMethodOptionsGrabpay;
    ideal?: PaymentMethodOptionsIdeal;
    interac_present?: PaymentMethodOptionsInteracPresent;
    klarna?: PaymentMethodOptionsKlarna;
    konbini?: PaymentMethodOptionsKonbini;
    link?: PaymentMethodOptionsLink;
    oxxo?: PaymentMethodOptionsOxxo;
    p24?: PaymentMethodOptionsP24;
    paynow?: PaymentMethodOptionsPaynow;
    pix?: PaymentMethodOptionsPix;
    promptpay?: PaymentMethodOptionsPromptpay;
    sepa_debit?: PaymentMethodOptionsSepaDebit;
    sofort?: PaymentMethodOptionsSofort;
    us_bank_account?: PaymentMethodOptionsUsBankAccount;
    wechat_pay?: PaymentMethodOptionsWechatPay;
};
export type CardCustomerNotification = {
    approval_requested: boolean | null;
    completes_at: number | null;
};
export type ProcessingCard = {
    customer_notification?: CardCustomerNotification;
};
export type PaymentIntentProcessing = {
    card?: ProcessingCard;
    type: "card";
};
export type ReviewClosedReason = "approved" | "disputed" | "redacted" | "refunded" | "refunded_as_fraud";
export type ReviewIpAddressLocation = {
    city: string | null;
    country: string | null;
    latitude: number | null;
    longitude: number | null;
    region: string | null;
};
export type ReviewOpenedReason = "manual" | "rule";
export type ReviewSession = {
    browser: string | null;
    device: string | null;
    platform: string | null;
    version: string | null;
};
export type StripeReview = {
    id: string;
    object: "review";
    billing_zip: string | null;
    charge: (string | StripeCharge) | null;
    closed_reason: ReviewClosedReason | null;
    created: number;
    ip_address: string | null;
    ip_address_location: ReviewIpAddressLocation | null;
    livemode: boolean;
    open: boolean;
    opened_reason: ReviewOpenedReason;
    payment_intent?: string | StripePaymentIntent;
    reason: string;
    session: ReviewSession | null;
};
export type PaymentIntentSetupFutureUsage = "off_session" | "on_session";
export type PaymentIntentShipping = {
    address?: StripeAddress;
    carrier?: string | null;
    name?: string;
    phone?: string | null;
    tracking_number?: string | null;
};
export type StripeDeletedBankAccount = {
    id: string;
    object: "bank_account";
    currency?: string | null;
    deleted: true;
};
export type StripeDeletedCard = {
    id: string;
    object: "card";
    currency?: string | null;
    deleted: true;
};
export type PaymentIntentStatus =
    | "canceled"
    | "processing"
    | "requires_action"
    | "requires_capture"
    | "requires_confirmation"
    | "requires_payment_method"
    | "succeeded";
export type PaymentIntentTransferData = {
    amount?: number;
    destination: string | StripeAccount;
};
export type StripePaymentIntent = {
    id: string;
    object: "payment_intent";
    amount: number;
    amount_capturable: number;
    amount_details?: PaymentIntentAmountDetails;
    amount_received: number;
    application: (string | StripeApplication) | null;
    application_fee_amount: number | null;
    automatic_payment_methods: PaymentIntentAutomaticPaymentMethods | null;
    canceled_at: number | null;
    cancellation_reason: PaymentIntentCancellationReason | null;
    capture_method: PaymentIntentCaptureMethod;
    charges?: ApiListStripeCharge;
    client_secret: string | null;
    confirmation_method: PaymentIntentConfirmationMethod;
    created: number;
    currency: string;
    customer: (string | StripeCustomer | StripeDeletedCustomer) | null;
    description: string | null;
    invoice: (string | StripeInvoice) | null;
    last_payment_error: PaymentIntentLastPaymentError | null;
    livemode: boolean;
    metadata: StripeMetadata;
    next_action: PaymentIntentNextAction | null;
    on_behalf_of: (string | StripeAccount) | null;
    payment_method: (string | StripePaymentMethod) | null;
    payment_method_options: PaymentIntentPaymentMethodOptions | null;
    payment_method_types: string[];
    processing: PaymentIntentProcessing | null;
    receipt_email: string | null;
    review: (string | StripeReview) | null;
    setup_future_usage: PaymentIntentSetupFutureUsage | null;
    shipping: PaymentIntentShipping | null;
    source: (string | StripeCustomerSource | StripeDeletedBankAccount | StripeDeletedCard) | null;
    statement_descriptor: string | null;
    statement_descriptor_suffix: string | null;
    status: PaymentIntentStatus;
    transfer_data: PaymentIntentTransferData | null;
    transfer_group: string | null;
};
export type DisputeStatus =
    | "charge_refunded"
    | "lost"
    | "needs_response"
    | "under_review"
    | "warning_closed"
    | "warning_needs_response"
    | "warning_under_review"
    | "won";
export type StripeDispute = {
    id: string;
    object: "dispute";
    amount: number;
    balance_transactions: StripeBalanceTransaction[];
    charge: string | StripeCharge;
    created: number;
    currency: string;
    evidence: DisputeEvidence;
    evidence_details: DisputeEvidenceDetails;
    is_charge_refundable: boolean;
    livemode: boolean;
    metadata: StripeMetadata;
    network_reason_code?: string | null;
    payment_intent: (string | StripePaymentIntent) | null;
    reason: string;
    status: DisputeStatus;
};
export type StripeFeeRefund = {
    id: string;
    object: "fee_refund";
    amount: number;
    balance_transaction: (string | StripeBalanceTransaction) | null;
    created: number;
    currency: string;
    fee: string | StripeApplicationFee;
    metadata: StripeMetadata | null;
};
export type AuthorizationAmountDetails = {
    atm_fee: number | null;
};
export type AuthorizationAuthorizationMethod = "chip" | "contactless" | "keyed_in" | "online" | "swipe";
export type CardCancellationReason = "design_rejected" | "lost" | "stolen";
export type CardholderBilling = {
    address: StripeAddress;
};
export type CardholderCompany = {
    tax_id_provided: boolean;
};
export type IndividualDob = {
    day: number | null;
    month: number | null;
    year: number | null;
};
export type IndividualVerification = {
    document: VerificationDocument | null;
};
export type CardholderIndividual = {
    dob: IndividualDob | null;
    first_name: string;
    last_name: string;
    verification: IndividualVerification | null;
};
export type RequirementsDisabledReason = "listed" | "rejected.listed" | "under_review";
export type RequirementsPastDue =
    | "company.tax_id"
    | "individual.dob.day"
    | "individual.dob.month"
    | "individual.dob.year"
    | "individual.first_name"
    | "individual.last_name"
    | "individual.verification.document";
export type CardholderRequirements = {
    disabled_reason: RequirementsDisabledReason | null;
    past_due: RequirementsPastDue[] | null;
};
export type SpendingControlsAllowedCategory =
    | "ac_refrigeration_repair"
    | "accounting_bookkeeping_services"
    | "advertising_services"
    | "agricultural_cooperative"
    | "airlines_air_carriers"
    | "airports_flying_fields"
    | "ambulance_services"
    | "amusement_parks_carnivals"
    | "antique_reproductions"
    | "antique_shops"
    | "aquariums"
    | "architectural_surveying_services"
    | "art_dealers_and_galleries"
    | "artists_supply_and_craft_shops"
    | "auto_and_home_supply_stores"
    | "auto_body_repair_shops"
    | "auto_paint_shops"
    | "auto_service_shops"
    | "automated_cash_disburse"
    | "automated_fuel_dispensers"
    | "automobile_associations"
    | "automotive_parts_and_accessories_stores"
    | "automotive_tire_stores"
    | "bail_and_bond_payments"
    | "bakeries"
    | "bands_orchestras"
    | "barber_and_beauty_shops"
    | "betting_casino_gambling"
    | "bicycle_shops"
    | "billiard_pool_establishments"
    | "boat_dealers"
    | "boat_rentals_and_leases"
    | "book_stores"
    | "books_periodicals_and_newspapers"
    | "bowling_alleys"
    | "bus_lines"
    | "business_secretarial_schools"
    | "buying_shopping_services"
    | "cable_satellite_and_other_pay_television_and_radio"
    | "camera_and_photographic_supply_stores"
    | "candy_nut_and_confectionery_stores"
    | "car_and_truck_dealers_new_used"
    | "car_and_truck_dealers_used_only"
    | "car_rental_agencies"
    | "car_washes"
    | "carpentry_services"
    | "carpet_upholstery_cleaning"
    | "caterers"
    | "charitable_and_social_service_organizations_fundraising"
    | "chemicals_and_allied_products"
    | "child_care_services"
    | "childrens_and_infants_wear_stores"
    | "chiropodists_podiatrists"
    | "chiropractors"
    | "cigar_stores_and_stands"
    | "civic_social_fraternal_associations"
    | "cleaning_and_maintenance"
    | "clothing_rental"
    | "colleges_universities"
    | "commercial_equipment"
    | "commercial_footwear"
    | "commercial_photography_art_and_graphics"
    | "commuter_transport_and_ferries"
    | "computer_network_services"
    | "computer_programming"
    | "computer_repair"
    | "computer_software_stores"
    | "computers_peripherals_and_software"
    | "concrete_work_services"
    | "construction_materials"
    | "consulting_public_relations"
    | "correspondence_schools"
    | "cosmetic_stores"
    | "counseling_services"
    | "country_clubs"
    | "courier_services"
    | "court_costs"
    | "credit_reporting_agencies"
    | "cruise_lines"
    | "dairy_products_stores"
    | "dance_hall_studios_schools"
    | "dating_escort_services"
    | "dentists_orthodontists"
    | "department_stores"
    | "detective_agencies"
    | "digital_goods_applications"
    | "digital_goods_games"
    | "digital_goods_large_volume"
    | "digital_goods_media"
    | "direct_marketing_catalog_merchant"
    | "direct_marketing_combination_catalog_and_retail_merchant"
    | "direct_marketing_inbound_telemarketing"
    | "direct_marketing_insurance_services"
    | "direct_marketing_other"
    | "direct_marketing_outbound_telemarketing"
    | "direct_marketing_subscription"
    | "direct_marketing_travel"
    | "discount_stores"
    | "doctors"
    | "door_to_door_sales"
    | "drapery_window_covering_and_upholstery_stores"
    | "drinking_places"
    | "drug_stores_and_pharmacies"
    | "drugs_drug_proprietaries_and_druggist_sundries"
    | "dry_cleaners"
    | "durable_goods"
    | "duty_free_stores"
    | "eating_places_restaurants"
    | "educational_services"
    | "electric_razor_stores"
    | "electrical_parts_and_equipment"
    | "electrical_services"
    | "electronics_repair_shops"
    | "electronics_stores"
    | "elementary_secondary_schools"
    | "employment_temp_agencies"
    | "equipment_rental"
    | "exterminating_services"
    | "family_clothing_stores"
    | "fast_food_restaurants"
    | "financial_institutions"
    | "fines_government_administrative_entities"
    | "fireplace_fireplace_screens_and_accessories_stores"
    | "floor_covering_stores"
    | "florists"
    | "florists_supplies_nursery_stock_and_flowers"
    | "freezer_and_locker_meat_provisioners"
    | "fuel_dealers_non_automotive"
    | "funeral_services_crematories"
    | "furniture_home_furnishings_and_equipment_stores_except_appliances"
    | "furniture_repair_refinishing"
    | "furriers_and_fur_shops"
    | "general_services"
    | "gift_card_novelty_and_souvenir_shops"
    | "glass_paint_and_wallpaper_stores"
    | "glassware_crystal_stores"
    | "golf_courses_public"
    | "government_services"
    | "grocery_stores_supermarkets"
    | "hardware_equipment_and_supplies"
    | "hardware_stores"
    | "health_and_beauty_spas"
    | "hearing_aids_sales_and_supplies"
    | "heating_plumbing_a_c"
    | "hobby_toy_and_game_shops"
    | "home_supply_warehouse_stores"
    | "hospitals"
    | "hotels_motels_and_resorts"
    | "household_appliance_stores"
    | "industrial_supplies"
    | "information_retrieval_services"
    | "insurance_default"
    | "insurance_underwriting_premiums"
    | "intra_company_purchases"
    | "jewelry_stores_watches_clocks_and_silverware_stores"
    | "landscaping_services"
    | "laundries"
    | "laundry_cleaning_services"
    | "legal_services_attorneys"
    | "luggage_and_leather_goods_stores"
    | "lumber_building_materials_stores"
    | "manual_cash_disburse"
    | "marinas_service_and_supplies"
    | "masonry_stonework_and_plaster"
    | "massage_parlors"
    | "medical_and_dental_labs"
    | "medical_dental_ophthalmic_and_hospital_equipment_and_supplies"
    | "medical_services"
    | "membership_organizations"
    | "mens_and_boys_clothing_and_accessories_stores"
    | "mens_womens_clothing_stores"
    | "metal_service_centers"
    | "miscellaneous"
    | "miscellaneous_apparel_and_accessory_shops"
    | "miscellaneous_auto_dealers"
    | "miscellaneous_business_services"
    | "miscellaneous_food_stores"
    | "miscellaneous_general_merchandise"
    | "miscellaneous_general_services"
    | "miscellaneous_home_furnishing_specialty_stores"
    | "miscellaneous_publishing_and_printing"
    | "miscellaneous_recreation_services"
    | "miscellaneous_repair_shops"
    | "miscellaneous_specialty_retail"
    | "mobile_home_dealers"
    | "motion_picture_theaters"
    | "motor_freight_carriers_and_trucking"
    | "motor_homes_dealers"
    | "motor_vehicle_supplies_and_new_parts"
    | "motorcycle_shops_and_dealers"
    | "motorcycle_shops_dealers"
    | "music_stores_musical_instruments_pianos_and_sheet_music"
    | "news_dealers_and_newsstands"
    | "non_fi_money_orders"
    | "non_fi_stored_value_card_purchase_load"
    | "nondurable_goods"
    | "nurseries_lawn_and_garden_supply_stores"
    | "nursing_personal_care"
    | "office_and_commercial_furniture"
    | "opticians_eyeglasses"
    | "optometrists_ophthalmologist"
    | "orthopedic_goods_prosthetic_devices"
    | "osteopaths"
    | "package_stores_beer_wine_and_liquor"
    | "paints_varnishes_and_supplies"
    | "parking_lots_garages"
    | "passenger_railways"
    | "pawn_shops"
    | "pet_shops_pet_food_and_supplies"
    | "petroleum_and_petroleum_products"
    | "photo_developing"
    | "photographic_photocopy_microfilm_equipment_and_supplies"
    | "photographic_studios"
    | "picture_video_production"
    | "piece_goods_notions_and_other_dry_goods"
    | "plumbing_heating_equipment_and_supplies"
    | "political_organizations"
    | "postal_services_government_only"
    | "precious_stones_and_metals_watches_and_jewelry"
    | "professional_services"
    | "public_warehousing_and_storage"
    | "quick_copy_repro_and_blueprint"
    | "railroads"
    | "real_estate_agents_and_managers_rentals"
    | "record_stores"
    | "recreational_vehicle_rentals"
    | "religious_goods_stores"
    | "religious_organizations"
    | "roofing_siding_sheet_metal"
    | "secretarial_support_services"
    | "security_brokers_dealers"
    | "service_stations"
    | "sewing_needlework_fabric_and_piece_goods_stores"
    | "shoe_repair_hat_cleaning"
    | "shoe_stores"
    | "small_appliance_repair"
    | "snowmobile_dealers"
    | "special_trade_services"
    | "specialty_cleaning"
    | "sporting_goods_stores"
    | "sporting_recreation_camps"
    | "sports_and_riding_apparel_stores"
    | "sports_clubs_fields"
    | "stamp_and_coin_stores"
    | "stationary_office_supplies_printing_and_writing_paper"
    | "stationery_stores_office_and_school_supply_stores"
    | "swimming_pools_sales"
    | "t_ui_travel_germany"
    | "tailors_alterations"
    | "tax_payments_government_agencies"
    | "tax_preparation_services"
    | "taxicabs_limousines"
    | "telecommunication_equipment_and_telephone_sales"
    | "telecommunication_services"
    | "telegraph_services"
    | "tent_and_awning_shops"
    | "testing_laboratories"
    | "theatrical_ticket_agencies"
    | "timeshares"
    | "tire_retreading_and_repair"
    | "tolls_bridge_fees"
    | "tourist_attractions_and_exhibits"
    | "towing_services"
    | "trailer_parks_campgrounds"
    | "transportation_services"
    | "travel_agencies_tour_operators"
    | "truck_stop_iteration"
    | "truck_utility_trailer_rentals"
    | "typesetting_plate_making_and_related_services"
    | "typewriter_stores"
    | "u_s_federal_government_agencies_or_departments"
    | "uniforms_commercial_clothing"
    | "used_merchandise_and_secondhand_stores"
    | "utilities"
    | "variety_stores"
    | "veterinary_services"
    | "video_amusement_game_supplies"
    | "video_game_arcades"
    | "video_tape_rental_stores"
    | "vocational_trade_schools"
    | "watch_jewelry_repair"
    | "welding_repair"
    | "wholesale_clubs"
    | "wig_and_toupee_stores"
    | "wires_money_orders"
    | "womens_accessory_and_specialty_shops"
    | "womens_ready_to_wear_stores"
    | "wrecking_and_salvage_yards";
export type SpendingControlsBlockedCategory =
    | "ac_refrigeration_repair"
    | "accounting_bookkeeping_services"
    | "advertising_services"
    | "agricultural_cooperative"
    | "airlines_air_carriers"
    | "airports_flying_fields"
    | "ambulance_services"
    | "amusement_parks_carnivals"
    | "antique_reproductions"
    | "antique_shops"
    | "aquariums"
    | "architectural_surveying_services"
    | "art_dealers_and_galleries"
    | "artists_supply_and_craft_shops"
    | "auto_and_home_supply_stores"
    | "auto_body_repair_shops"
    | "auto_paint_shops"
    | "auto_service_shops"
    | "automated_cash_disburse"
    | "automated_fuel_dispensers"
    | "automobile_associations"
    | "automotive_parts_and_accessories_stores"
    | "automotive_tire_stores"
    | "bail_and_bond_payments"
    | "bakeries"
    | "bands_orchestras"
    | "barber_and_beauty_shops"
    | "betting_casino_gambling"
    | "bicycle_shops"
    | "billiard_pool_establishments"
    | "boat_dealers"
    | "boat_rentals_and_leases"
    | "book_stores"
    | "books_periodicals_and_newspapers"
    | "bowling_alleys"
    | "bus_lines"
    | "business_secretarial_schools"
    | "buying_shopping_services"
    | "cable_satellite_and_other_pay_television_and_radio"
    | "camera_and_photographic_supply_stores"
    | "candy_nut_and_confectionery_stores"
    | "car_and_truck_dealers_new_used"
    | "car_and_truck_dealers_used_only"
    | "car_rental_agencies"
    | "car_washes"
    | "carpentry_services"
    | "carpet_upholstery_cleaning"
    | "caterers"
    | "charitable_and_social_service_organizations_fundraising"
    | "chemicals_and_allied_products"
    | "child_care_services"
    | "childrens_and_infants_wear_stores"
    | "chiropodists_podiatrists"
    | "chiropractors"
    | "cigar_stores_and_stands"
    | "civic_social_fraternal_associations"
    | "cleaning_and_maintenance"
    | "clothing_rental"
    | "colleges_universities"
    | "commercial_equipment"
    | "commercial_footwear"
    | "commercial_photography_art_and_graphics"
    | "commuter_transport_and_ferries"
    | "computer_network_services"
    | "computer_programming"
    | "computer_repair"
    | "computer_software_stores"
    | "computers_peripherals_and_software"
    | "concrete_work_services"
    | "construction_materials"
    | "consulting_public_relations"
    | "correspondence_schools"
    | "cosmetic_stores"
    | "counseling_services"
    | "country_clubs"
    | "courier_services"
    | "court_costs"
    | "credit_reporting_agencies"
    | "cruise_lines"
    | "dairy_products_stores"
    | "dance_hall_studios_schools"
    | "dating_escort_services"
    | "dentists_orthodontists"
    | "department_stores"
    | "detective_agencies"
    | "digital_goods_applications"
    | "digital_goods_games"
    | "digital_goods_large_volume"
    | "digital_goods_media"
    | "direct_marketing_catalog_merchant"
    | "direct_marketing_combination_catalog_and_retail_merchant"
    | "direct_marketing_inbound_telemarketing"
    | "direct_marketing_insurance_services"
    | "direct_marketing_other"
    | "direct_marketing_outbound_telemarketing"
    | "direct_marketing_subscription"
    | "direct_marketing_travel"
    | "discount_stores"
    | "doctors"
    | "door_to_door_sales"
    | "drapery_window_covering_and_upholstery_stores"
    | "drinking_places"
    | "drug_stores_and_pharmacies"
    | "drugs_drug_proprietaries_and_druggist_sundries"
    | "dry_cleaners"
    | "durable_goods"
    | "duty_free_stores"
    | "eating_places_restaurants"
    | "educational_services"
    | "electric_razor_stores"
    | "electrical_parts_and_equipment"
    | "electrical_services"
    | "electronics_repair_shops"
    | "electronics_stores"
    | "elementary_secondary_schools"
    | "employment_temp_agencies"
    | "equipment_rental"
    | "exterminating_services"
    | "family_clothing_stores"
    | "fast_food_restaurants"
    | "financial_institutions"
    | "fines_government_administrative_entities"
    | "fireplace_fireplace_screens_and_accessories_stores"
    | "floor_covering_stores"
    | "florists"
    | "florists_supplies_nursery_stock_and_flowers"
    | "freezer_and_locker_meat_provisioners"
    | "fuel_dealers_non_automotive"
    | "funeral_services_crematories"
    | "furniture_home_furnishings_and_equipment_stores_except_appliances"
    | "furniture_repair_refinishing"
    | "furriers_and_fur_shops"
    | "general_services"
    | "gift_card_novelty_and_souvenir_shops"
    | "glass_paint_and_wallpaper_stores"
    | "glassware_crystal_stores"
    | "golf_courses_public"
    | "government_services"
    | "grocery_stores_supermarkets"
    | "hardware_equipment_and_supplies"
    | "hardware_stores"
    | "health_and_beauty_spas"
    | "hearing_aids_sales_and_supplies"
    | "heating_plumbing_a_c"
    | "hobby_toy_and_game_shops"
    | "home_supply_warehouse_stores"
    | "hospitals"
    | "hotels_motels_and_resorts"
    | "household_appliance_stores"
    | "industrial_supplies"
    | "information_retrieval_services"
    | "insurance_default"
    | "insurance_underwriting_premiums"
    | "intra_company_purchases"
    | "jewelry_stores_watches_clocks_and_silverware_stores"
    | "landscaping_services"
    | "laundries"
    | "laundry_cleaning_services"
    | "legal_services_attorneys"
    | "luggage_and_leather_goods_stores"
    | "lumber_building_materials_stores"
    | "manual_cash_disburse"
    | "marinas_service_and_supplies"
    | "masonry_stonework_and_plaster"
    | "massage_parlors"
    | "medical_and_dental_labs"
    | "medical_dental_ophthalmic_and_hospital_equipment_and_supplies"
    | "medical_services"
    | "membership_organizations"
    | "mens_and_boys_clothing_and_accessories_stores"
    | "mens_womens_clothing_stores"
    | "metal_service_centers"
    | "miscellaneous"
    | "miscellaneous_apparel_and_accessory_shops"
    | "miscellaneous_auto_dealers"
    | "miscellaneous_business_services"
    | "miscellaneous_food_stores"
    | "miscellaneous_general_merchandise"
    | "miscellaneous_general_services"
    | "miscellaneous_home_furnishing_specialty_stores"
    | "miscellaneous_publishing_and_printing"
    | "miscellaneous_recreation_services"
    | "miscellaneous_repair_shops"
    | "miscellaneous_specialty_retail"
    | "mobile_home_dealers"
    | "motion_picture_theaters"
    | "motor_freight_carriers_and_trucking"
    | "motor_homes_dealers"
    | "motor_vehicle_supplies_and_new_parts"
    | "motorcycle_shops_and_dealers"
    | "motorcycle_shops_dealers"
    | "music_stores_musical_instruments_pianos_and_sheet_music"
    | "news_dealers_and_newsstands"
    | "non_fi_money_orders"
    | "non_fi_stored_value_card_purchase_load"
    | "nondurable_goods"
    | "nurseries_lawn_and_garden_supply_stores"
    | "nursing_personal_care"
    | "office_and_commercial_furniture"
    | "opticians_eyeglasses"
    | "optometrists_ophthalmologist"
    | "orthopedic_goods_prosthetic_devices"
    | "osteopaths"
    | "package_stores_beer_wine_and_liquor"
    | "paints_varnishes_and_supplies"
    | "parking_lots_garages"
    | "passenger_railways"
    | "pawn_shops"
    | "pet_shops_pet_food_and_supplies"
    | "petroleum_and_petroleum_products"
    | "photo_developing"
    | "photographic_photocopy_microfilm_equipment_and_supplies"
    | "photographic_studios"
    | "picture_video_production"
    | "piece_goods_notions_and_other_dry_goods"
    | "plumbing_heating_equipment_and_supplies"
    | "political_organizations"
    | "postal_services_government_only"
    | "precious_stones_and_metals_watches_and_jewelry"
    | "professional_services"
    | "public_warehousing_and_storage"
    | "quick_copy_repro_and_blueprint"
    | "railroads"
    | "real_estate_agents_and_managers_rentals"
    | "record_stores"
    | "recreational_vehicle_rentals"
    | "religious_goods_stores"
    | "religious_organizations"
    | "roofing_siding_sheet_metal"
    | "secretarial_support_services"
    | "security_brokers_dealers"
    | "service_stations"
    | "sewing_needlework_fabric_and_piece_goods_stores"
    | "shoe_repair_hat_cleaning"
    | "shoe_stores"
    | "small_appliance_repair"
    | "snowmobile_dealers"
    | "special_trade_services"
    | "specialty_cleaning"
    | "sporting_goods_stores"
    | "sporting_recreation_camps"
    | "sports_and_riding_apparel_stores"
    | "sports_clubs_fields"
    | "stamp_and_coin_stores"
    | "stationary_office_supplies_printing_and_writing_paper"
    | "stationery_stores_office_and_school_supply_stores"
    | "swimming_pools_sales"
    | "t_ui_travel_germany"
    | "tailors_alterations"
    | "tax_payments_government_agencies"
    | "tax_preparation_services"
    | "taxicabs_limousines"
    | "telecommunication_equipment_and_telephone_sales"
    | "telecommunication_services"
    | "telegraph_services"
    | "tent_and_awning_shops"
    | "testing_laboratories"
    | "theatrical_ticket_agencies"
    | "timeshares"
    | "tire_retreading_and_repair"
    | "tolls_bridge_fees"
    | "tourist_attractions_and_exhibits"
    | "towing_services"
    | "trailer_parks_campgrounds"
    | "transportation_services"
    | "travel_agencies_tour_operators"
    | "truck_stop_iteration"
    | "truck_utility_trailer_rentals"
    | "typesetting_plate_making_and_related_services"
    | "typewriter_stores"
    | "u_s_federal_government_agencies_or_departments"
    | "uniforms_commercial_clothing"
    | "used_merchandise_and_secondhand_stores"
    | "utilities"
    | "variety_stores"
    | "veterinary_services"
    | "video_amusement_game_supplies"
    | "video_game_arcades"
    | "video_tape_rental_stores"
    | "vocational_trade_schools"
    | "watch_jewelry_repair"
    | "welding_repair"
    | "wholesale_clubs"
    | "wig_and_toupee_stores"
    | "wires_money_orders"
    | "womens_accessory_and_specialty_shops"
    | "womens_ready_to_wear_stores"
    | "wrecking_and_salvage_yards";
export type SpendingLimitCategory =
    | "ac_refrigeration_repair"
    | "accounting_bookkeeping_services"
    | "advertising_services"
    | "agricultural_cooperative"
    | "airlines_air_carriers"
    | "airports_flying_fields"
    | "ambulance_services"
    | "amusement_parks_carnivals"
    | "antique_reproductions"
    | "antique_shops"
    | "aquariums"
    | "architectural_surveying_services"
    | "art_dealers_and_galleries"
    | "artists_supply_and_craft_shops"
    | "auto_and_home_supply_stores"
    | "auto_body_repair_shops"
    | "auto_paint_shops"
    | "auto_service_shops"
    | "automated_cash_disburse"
    | "automated_fuel_dispensers"
    | "automobile_associations"
    | "automotive_parts_and_accessories_stores"
    | "automotive_tire_stores"
    | "bail_and_bond_payments"
    | "bakeries"
    | "bands_orchestras"
    | "barber_and_beauty_shops"
    | "betting_casino_gambling"
    | "bicycle_shops"
    | "billiard_pool_establishments"
    | "boat_dealers"
    | "boat_rentals_and_leases"
    | "book_stores"
    | "books_periodicals_and_newspapers"
    | "bowling_alleys"
    | "bus_lines"
    | "business_secretarial_schools"
    | "buying_shopping_services"
    | "cable_satellite_and_other_pay_television_and_radio"
    | "camera_and_photographic_supply_stores"
    | "candy_nut_and_confectionery_stores"
    | "car_and_truck_dealers_new_used"
    | "car_and_truck_dealers_used_only"
    | "car_rental_agencies"
    | "car_washes"
    | "carpentry_services"
    | "carpet_upholstery_cleaning"
    | "caterers"
    | "charitable_and_social_service_organizations_fundraising"
    | "chemicals_and_allied_products"
    | "child_care_services"
    | "childrens_and_infants_wear_stores"
    | "chiropodists_podiatrists"
    | "chiropractors"
    | "cigar_stores_and_stands"
    | "civic_social_fraternal_associations"
    | "cleaning_and_maintenance"
    | "clothing_rental"
    | "colleges_universities"
    | "commercial_equipment"
    | "commercial_footwear"
    | "commercial_photography_art_and_graphics"
    | "commuter_transport_and_ferries"
    | "computer_network_services"
    | "computer_programming"
    | "computer_repair"
    | "computer_software_stores"
    | "computers_peripherals_and_software"
    | "concrete_work_services"
    | "construction_materials"
    | "consulting_public_relations"
    | "correspondence_schools"
    | "cosmetic_stores"
    | "counseling_services"
    | "country_clubs"
    | "courier_services"
    | "court_costs"
    | "credit_reporting_agencies"
    | "cruise_lines"
    | "dairy_products_stores"
    | "dance_hall_studios_schools"
    | "dating_escort_services"
    | "dentists_orthodontists"
    | "department_stores"
    | "detective_agencies"
    | "digital_goods_applications"
    | "digital_goods_games"
    | "digital_goods_large_volume"
    | "digital_goods_media"
    | "direct_marketing_catalog_merchant"
    | "direct_marketing_combination_catalog_and_retail_merchant"
    | "direct_marketing_inbound_telemarketing"
    | "direct_marketing_insurance_services"
    | "direct_marketing_other"
    | "direct_marketing_outbound_telemarketing"
    | "direct_marketing_subscription"
    | "direct_marketing_travel"
    | "discount_stores"
    | "doctors"
    | "door_to_door_sales"
    | "drapery_window_covering_and_upholstery_stores"
    | "drinking_places"
    | "drug_stores_and_pharmacies"
    | "drugs_drug_proprietaries_and_druggist_sundries"
    | "dry_cleaners"
    | "durable_goods"
    | "duty_free_stores"
    | "eating_places_restaurants"
    | "educational_services"
    | "electric_razor_stores"
    | "electrical_parts_and_equipment"
    | "electrical_services"
    | "electronics_repair_shops"
    | "electronics_stores"
    | "elementary_secondary_schools"
    | "employment_temp_agencies"
    | "equipment_rental"
    | "exterminating_services"
    | "family_clothing_stores"
    | "fast_food_restaurants"
    | "financial_institutions"
    | "fines_government_administrative_entities"
    | "fireplace_fireplace_screens_and_accessories_stores"
    | "floor_covering_stores"
    | "florists"
    | "florists_supplies_nursery_stock_and_flowers"
    | "freezer_and_locker_meat_provisioners"
    | "fuel_dealers_non_automotive"
    | "funeral_services_crematories"
    | "furniture_home_furnishings_and_equipment_stores_except_appliances"
    | "furniture_repair_refinishing"
    | "furriers_and_fur_shops"
    | "general_services"
    | "gift_card_novelty_and_souvenir_shops"
    | "glass_paint_and_wallpaper_stores"
    | "glassware_crystal_stores"
    | "golf_courses_public"
    | "government_services"
    | "grocery_stores_supermarkets"
    | "hardware_equipment_and_supplies"
    | "hardware_stores"
    | "health_and_beauty_spas"
    | "hearing_aids_sales_and_supplies"
    | "heating_plumbing_a_c"
    | "hobby_toy_and_game_shops"
    | "home_supply_warehouse_stores"
    | "hospitals"
    | "hotels_motels_and_resorts"
    | "household_appliance_stores"
    | "industrial_supplies"
    | "information_retrieval_services"
    | "insurance_default"
    | "insurance_underwriting_premiums"
    | "intra_company_purchases"
    | "jewelry_stores_watches_clocks_and_silverware_stores"
    | "landscaping_services"
    | "laundries"
    | "laundry_cleaning_services"
    | "legal_services_attorneys"
    | "luggage_and_leather_goods_stores"
    | "lumber_building_materials_stores"
    | "manual_cash_disburse"
    | "marinas_service_and_supplies"
    | "masonry_stonework_and_plaster"
    | "massage_parlors"
    | "medical_and_dental_labs"
    | "medical_dental_ophthalmic_and_hospital_equipment_and_supplies"
    | "medical_services"
    | "membership_organizations"
    | "mens_and_boys_clothing_and_accessories_stores"
    | "mens_womens_clothing_stores"
    | "metal_service_centers"
    | "miscellaneous"
    | "miscellaneous_apparel_and_accessory_shops"
    | "miscellaneous_auto_dealers"
    | "miscellaneous_business_services"
    | "miscellaneous_food_stores"
    | "miscellaneous_general_merchandise"
    | "miscellaneous_general_services"
    | "miscellaneous_home_furnishing_specialty_stores"
    | "miscellaneous_publishing_and_printing"
    | "miscellaneous_recreation_services"
    | "miscellaneous_repair_shops"
    | "miscellaneous_specialty_retail"
    | "mobile_home_dealers"
    | "motion_picture_theaters"
    | "motor_freight_carriers_and_trucking"
    | "motor_homes_dealers"
    | "motor_vehicle_supplies_and_new_parts"
    | "motorcycle_shops_and_dealers"
    | "motorcycle_shops_dealers"
    | "music_stores_musical_instruments_pianos_and_sheet_music"
    | "news_dealers_and_newsstands"
    | "non_fi_money_orders"
    | "non_fi_stored_value_card_purchase_load"
    | "nondurable_goods"
    | "nurseries_lawn_and_garden_supply_stores"
    | "nursing_personal_care"
    | "office_and_commercial_furniture"
    | "opticians_eyeglasses"
    | "optometrists_ophthalmologist"
    | "orthopedic_goods_prosthetic_devices"
    | "osteopaths"
    | "package_stores_beer_wine_and_liquor"
    | "paints_varnishes_and_supplies"
    | "parking_lots_garages"
    | "passenger_railways"
    | "pawn_shops"
    | "pet_shops_pet_food_and_supplies"
    | "petroleum_and_petroleum_products"
    | "photo_developing"
    | "photographic_photocopy_microfilm_equipment_and_supplies"
    | "photographic_studios"
    | "picture_video_production"
    | "piece_goods_notions_and_other_dry_goods"
    | "plumbing_heating_equipment_and_supplies"
    | "political_organizations"
    | "postal_services_government_only"
    | "precious_stones_and_metals_watches_and_jewelry"
    | "professional_services"
    | "public_warehousing_and_storage"
    | "quick_copy_repro_and_blueprint"
    | "railroads"
    | "real_estate_agents_and_managers_rentals"
    | "record_stores"
    | "recreational_vehicle_rentals"
    | "religious_goods_stores"
    | "religious_organizations"
    | "roofing_siding_sheet_metal"
    | "secretarial_support_services"
    | "security_brokers_dealers"
    | "service_stations"
    | "sewing_needlework_fabric_and_piece_goods_stores"
    | "shoe_repair_hat_cleaning"
    | "shoe_stores"
    | "small_appliance_repair"
    | "snowmobile_dealers"
    | "special_trade_services"
    | "specialty_cleaning"
    | "sporting_goods_stores"
    | "sporting_recreation_camps"
    | "sports_and_riding_apparel_stores"
    | "sports_clubs_fields"
    | "stamp_and_coin_stores"
    | "stationary_office_supplies_printing_and_writing_paper"
    | "stationery_stores_office_and_school_supply_stores"
    | "swimming_pools_sales"
    | "t_ui_travel_germany"
    | "tailors_alterations"
    | "tax_payments_government_agencies"
    | "tax_preparation_services"
    | "taxicabs_limousines"
    | "telecommunication_equipment_and_telephone_sales"
    | "telecommunication_services"
    | "telegraph_services"
    | "tent_and_awning_shops"
    | "testing_laboratories"
    | "theatrical_ticket_agencies"
    | "timeshares"
    | "tire_retreading_and_repair"
    | "tolls_bridge_fees"
    | "tourist_attractions_and_exhibits"
    | "towing_services"
    | "trailer_parks_campgrounds"
    | "transportation_services"
    | "travel_agencies_tour_operators"
    | "truck_stop_iteration"
    | "truck_utility_trailer_rentals"
    | "typesetting_plate_making_and_related_services"
    | "typewriter_stores"
    | "u_s_federal_government_agencies_or_departments"
    | "uniforms_commercial_clothing"
    | "used_merchandise_and_secondhand_stores"
    | "utilities"
    | "variety_stores"
    | "veterinary_services"
    | "video_amusement_game_supplies"
    | "video_game_arcades"
    | "video_tape_rental_stores"
    | "vocational_trade_schools"
    | "watch_jewelry_repair"
    | "welding_repair"
    | "wholesale_clubs"
    | "wig_and_toupee_stores"
    | "wires_money_orders"
    | "womens_accessory_and_specialty_shops"
    | "womens_ready_to_wear_stores"
    | "wrecking_and_salvage_yards";
export type SpendingLimitInterval = "all_time" | "daily" | "monthly" | "per_authorization" | "weekly" | "yearly";
export type SpendingControlsSpendingLimit = {
    amount: number;
    categories: SpendingLimitCategory[] | null;
    interval: SpendingLimitInterval;
};
export type CardholderSpendingControls = {
    allowed_categories: SpendingControlsAllowedCategory[] | null;
    blocked_categories: SpendingControlsBlockedCategory[] | null;
    spending_limits: SpendingControlsSpendingLimit[] | null;
    spending_limits_currency: string | null;
};
export type CardholderStatus = "active" | "blocked" | "inactive";
export type CardholderType = "company" | "individual";
export type StripeIssuingCardholder = {
    id: string;
    object: "issuing.cardholder";
    billing: CardholderBilling;
    company: CardholderCompany | null;
    created: number;
    email: string | null;
    individual: CardholderIndividual | null;
    livemode: boolean;
    metadata: StripeMetadata;
    name: string;
    phone_number: string | null;
    requirements: CardholderRequirements;
    spending_controls: CardholderSpendingControls | null;
    status: CardholderStatus;
    type: CardholderType;
};
export type CardReplacementReason = "damaged" | "expired" | "lost" | "stolen";
export type ShippingCarrier = "dhl" | "fedex" | "royal_mail" | "usps";
export type ShippingCustoms = {
    eori_number: string | null;
};
export type ShippingService = "express" | "priority" | "standard";
export type ShippingStatus = "canceled" | "delivered" | "failure" | "pending" | "returned" | "shipped";
export type ShippingType = "bulk" | "individual";
export type CardShipping = {
    address: StripeAddress;
    carrier: ShippingCarrier | null;
    customs: ShippingCustoms | null;
    eta: number | null;
    name: string;
    phone_number: string | null;
    require_signature: boolean | null;
    service: ShippingService;
    status: ShippingStatus | null;
    tracking_number: string | null;
    tracking_url: string | null;
    type: ShippingType;
};
export type CardSpendingControls = {
    allowed_categories: SpendingControlsAllowedCategory[] | null;
    blocked_categories: SpendingControlsBlockedCategory[] | null;
    spending_limits: SpendingControlsSpendingLimit[] | null;
    spending_limits_currency: string | null;
};
export type CardStatus = "active" | "canceled" | "inactive";
export type CardType = "physical" | "virtual";
export type ApplePayIneligibleReason = "missing_agreement" | "missing_cardholder_contact" | "unsupported_region";
export type WalletsApplePay = {
    eligible: boolean;
    ineligible_reason: ApplePayIneligibleReason | null;
};
export type GooglePayIneligibleReason = "missing_agreement" | "missing_cardholder_contact" | "unsupported_region";
export type WalletsGooglePay = {
    eligible: boolean;
    ineligible_reason: GooglePayIneligibleReason | null;
};
export type CardWallets = {
    apple_pay: WalletsApplePay;
    google_pay: WalletsGooglePay;
    primary_account_identifier: string | null;
};
export type StripeIssuingCard = {
    id: string;
    object: "issuing.card";
    brand: string;
    cancellation_reason: CardCancellationReason | null;
    cardholder: StripeIssuingCardholder;
    created: number;
    currency: string;
    cvc?: string;
    exp_month: number;
    exp_year: number;
    financial_account?: string | null;
    last4: string;
    livemode: boolean;
    metadata: StripeMetadata;
    number?: string;
    replaced_by: (string | StripeIssuingCard) | null;
    replacement_for: (string | StripeIssuingCard) | null;
    replacement_reason: CardReplacementReason | null;
    shipping: CardShipping | null;
    spending_controls: CardSpendingControls;
    status: CardStatus;
    type: CardType;
    wallets: CardWallets | null;
};
export type AuthorizationMerchantData = {
    category: string;
    category_code: string;
    city: string | null;
    country: string | null;
    name: string | null;
    network_id: string;
    postal_code: string | null;
    state: string | null;
};
export type AuthorizationNetworkData = {
    acquiring_institution_id: string | null;
};
export type PendingRequestAmountDetails = {
    atm_fee: number | null;
};
export type AuthorizationPendingRequest = {
    amount: number;
    amount_details: PendingRequestAmountDetails | null;
    currency: string;
    is_amount_controllable: boolean;
    merchant_amount: number;
    merchant_currency: string;
};
export type RequestHistoryAmountDetails = {
    atm_fee: number | null;
};
export type RequestHistoryReason =
    | "account_disabled"
    | "card_active"
    | "card_inactive"
    | "cardholder_inactive"
    | "cardholder_verification_required"
    | "insufficient_funds"
    | "not_allowed"
    | "spending_controls"
    | "suspected_fraud"
    | "verification_failed"
    | "webhook_approved"
    | "webhook_declined"
    | "webhook_error"
    | "webhook_timeout";
export type AuthorizationRequestHistory = {
    amount: number;
    amount_details: RequestHistoryAmountDetails | null;
    approved: boolean;
    created: number;
    currency: string;
    merchant_amount: number;
    merchant_currency: string;
    reason: RequestHistoryReason;
    reason_message: string | null;
};
export type AuthorizationStatus = "closed" | "pending" | "reversed";
export type TransactionAmountDetails = {
    atm_fee: number | null;
};
export type DisputeTreasury = {
    debit_reversal: string | null;
    received_debit: string;
};
export type StripeIssuingDispute = {
    id: string;
    object: "issuing.dispute";
    amount: number;
    balance_transactions: StripeBalanceTransaction[] | null;
    created: number;
    currency: string;
    evidence: DisputeEvidence;
    livemode: boolean;
    metadata: StripeMetadata;
    status: DisputeStatus;
    transaction: string | StripeIssuingTransaction;
    treasury?: DisputeTreasury | null;
};
export type TransactionMerchantData = {
    category: string;
    category_code: string;
    city: string | null;
    country: string | null;
    name: string | null;
    network_id: string;
    postal_code: string | null;
    state: string | null;
};
export type FlightSegment = {
    arrival_airport_code: string | null;
    carrier: string | null;
    departure_airport_code: string | null;
    flight_number: string | null;
    service_class: string | null;
    stopover_allowed: boolean | null;
};
export type PurchaseDetailsFlight = {
    departure_at: number | null;
    passenger_name: string | null;
    refundable: boolean | null;
    segments: FlightSegment[] | null;
    travel_agency: string | null;
};
export type PurchaseDetailsFuel = {
    type: string;
    unit: string;
    unit_cost_decimal: string;
    volume_decimal: string | null;
};
export type PurchaseDetailsLodging = {
    check_in_at: number | null;
    nights: number | null;
};
export type PurchaseDetailsReceipt = {
    description: string | null;
    quantity: number | null;
    total: number | null;
    unit_cost: number | null;
};
export type TransactionPurchaseDetails = {
    flight: PurchaseDetailsFlight | null;
    fuel: PurchaseDetailsFuel | null;
    lodging: PurchaseDetailsLodging | null;
    receipt: PurchaseDetailsReceipt[] | null;
    reference: string | null;
};
export type TransactionTreasury = {
    received_credit: string | null;
    received_debit: string | null;
};
export type TransactionType = "capture" | "refund";
export type TransactionWallet = "apple_pay" | "google_pay" | "samsung_pay";
export type StripeIssuingTransaction = {
    id: string;
    object: "issuing.transaction";
    amount: number;
    amount_details: TransactionAmountDetails | null;
    authorization: (string | StripeIssuingAuthorization) | null;
    balance_transaction: (string | StripeBalanceTransaction) | null;
    card: string | StripeIssuingCard;
    cardholder: (string | StripeIssuingCardholder) | null;
    created: number;
    currency: string;
    dispute: (string | StripeIssuingDispute) | null;
    livemode: boolean;
    merchant_amount: number;
    merchant_currency: string;
    merchant_data: TransactionMerchantData;
    metadata: StripeMetadata;
    purchase_details: TransactionPurchaseDetails | null;
    treasury?: TransactionTreasury | null;
    type: TransactionType;
    wallet: TransactionWallet | null;
};
export type AuthorizationTreasury = {
    received_credits: string[];
    received_debits: string[];
    transaction: string | null;
};
export type VerificationDataAddressLine1Check = "match" | "mismatch" | "not_provided";
export type VerificationDataAddressPostalCodeCheck = "match" | "mismatch" | "not_provided";
export type VerificationDataCvcCheck = "match" | "mismatch" | "not_provided";
export type VerificationDataExpiryCheck = "match" | "mismatch" | "not_provided";
export type AuthorizationVerificationData = {
    address_line1_check: VerificationDataAddressLine1Check;
    address_postal_code_check: VerificationDataAddressPostalCodeCheck;
    cvc_check: VerificationDataCvcCheck;
    expiry_check: VerificationDataExpiryCheck;
};
export type StripeIssuingAuthorization = {
    id: string;
    object: "issuing.authorization";
    amount: number;
    amount_details: AuthorizationAmountDetails | null;
    approved: boolean;
    authorization_method: AuthorizationAuthorizationMethod;
    balance_transactions: StripeBalanceTransaction[];
    card: StripeIssuingCard;
    cardholder: (string | StripeIssuingCardholder) | null;
    created: number;
    currency: string;
    livemode: boolean;
    merchant_amount: number;
    merchant_currency: string;
    merchant_data: AuthorizationMerchantData;
    metadata: StripeMetadata;
    network_data: AuthorizationNetworkData | null;
    pending_request: AuthorizationPendingRequest | null;
    request_history: AuthorizationRequestHistory[];
    status: AuthorizationStatus;
    transactions: StripeIssuingTransaction[];
    treasury?: AuthorizationTreasury | null;
    verification_data: AuthorizationVerificationData;
    wallet: string | null;
};
export type PayoutType = "bank_account" | "card";
export type StripePayout = {
    id: string;
    object: "payout";
    amount: number;
    arrival_date: number;
    automatic: boolean;
    balance_transaction: (string | StripeBalanceTransaction) | null;
    created: number;
    currency: string;
    description: string | null;
    destination: (string | StripeBankAccount | StripeDeletedBankAccount | StripeCard | StripeDeletedCard) | null;
    failure_balance_transaction: (string | StripeBalanceTransaction) | null;
    failure_code: string | null;
    failure_message: string | null;
    livemode: boolean;
    metadata: StripeMetadata | null;
    method: string;
    original_payout: (string | StripePayout) | null;
    reversed_by: (string | StripePayout) | null;
    source_type: string;
    statement_descriptor: string | null;
    status: string;
    type: PayoutType;
};
export type StripePlatformTaxFee = {
    id: string;
    object: "platform_tax_fee";
    account: string;
    source_transaction: string;
    type: string;
};
export type DisplayDetailsEmailSent = {
    email_sent_at: number;
    email_sent_to: string;
};
export type NextActionDisplayDetails = {
    email_sent: DisplayDetailsEmailSent;
    expires_at: number;
};
export type RefundNextAction = {
    display_details: NextActionDisplayDetails | null;
    type: string;
};
export type RefundReason = "duplicate" | "expired_uncaptured_charge" | "fraudulent" | "requested_by_customer";
export type ApiListStripeTransferReversal = {
    object: "list";
    data: StripeTransferReversal[];
    has_more: boolean;
    url: string;
};
export type StripeTransfer = {
    id: string;
    object: "transfer";
    amount: number;
    amount_reversed: number;
    balance_transaction: (string | StripeBalanceTransaction) | null;
    created: number;
    currency: string;
    description: string | null;
    destination: (string | StripeAccount) | null;
    destination_payment?: string | StripeCharge;
    livemode: boolean;
    metadata: StripeMetadata;
    reversals: ApiListStripeTransferReversal;
    reversed: boolean;
    source_transaction: (string | StripeCharge) | null;
    source_type?: string;
    transfer_group: string | null;
};
export type StripeTransferReversal = {
    id: string;
    object: "transfer_reversal";
    amount: number;
    balance_transaction: (string | StripeBalanceTransaction) | null;
    created: number;
    currency: string;
    destination_payment_refund: (string | StripeRefund) | null;
    metadata: StripeMetadata | null;
    source_refund: (string | StripeRefund) | null;
    transfer: string | StripeTransfer;
};
export type StripeRefund = {
    id: string;
    object: "refund";
    amount: number;
    balance_transaction: (string | StripeBalanceTransaction) | null;
    charge: (string | StripeCharge) | null;
    created: number;
    currency: string;
    description?: string;
    failure_balance_transaction?: string | StripeBalanceTransaction;
    failure_reason?: string;
    instructions_email?: string;
    metadata: StripeMetadata | null;
    next_action?: RefundNextAction;
    payment_intent: (string | StripePaymentIntent) | null;
    reason: RefundReason | null;
    receipt_number: string | null;
    source_transfer_reversal: (string | StripeTransferReversal) | null;
    status: string | null;
    transfer_reversal: (string | StripeTransferReversal) | null;
};
export type StripeReserveTransaction = {
    id: string;
    object: "reserve_transaction";
    amount: number;
    currency: string;
    description: string | null;
};
export type StripeTaxDeductedAtSource = {
    id: string;
    object: "tax_deducted_at_source";
    period_end: number;
    period_start: number;
    tax_deduction_account_number: string;
};
export type StripeSource = {
    id: string;
    object: "source";
    ach_credit_transfer?: SourceAchCreditTransfer;
    ach_debit?: SourceAchDebit;
    acss_debit?: SourceAcssDebit;
    alipay?: SourceAlipay;
    amount: number | null;
    au_becs_debit?: SourceAuBecsDebit;
    bancontact?: SourceBancontact;
    card?: SourceCard;
    card_present?: SourceCardPresent;
    client_secret: string;
    code_verification?: SourceCodeVerification;
    created: number;
    currency: string | null;
    customer?: string;
    eps?: SourceEps;
    flow: string;
    giropay?: SourceGiropay;
    ideal?: SourceIdeal;
    klarna?: SourceKlarna;
    livemode: boolean;
    metadata: StripeMetadata | null;
    multibanco?: SourceMultibanco;
    owner: SourceOwner | null;
    p24?: SourceP24;
    receiver?: SourceReceiver;
    redirect?: SourceRedirect;
    sepa_credit_transfer?: SourceSepaCreditTransfer;
    sepa_debit?: SourceSepaDebit;
    sofort?: SourceSofort;
    source_order?: SourceSourceOrder;
    statement_descriptor: string | null;
    status: string;
    three_d_secure?: SourceThreeDSecure;
    type: SourceType;
    usage: string | null;
    wechat?: SourceWechat;
};
export type TopupStatus = "canceled" | "failed" | "pending" | "reversed" | "succeeded";
export type StripeTopup = {
    id: string;
    object: "topup";
    amount: number;
    balance_transaction: (string | StripeBalanceTransaction) | null;
    created: number;
    currency: string;
    description: string | null;
    expected_availability_date: number | null;
    failure_code: string | null;
    failure_message: string | null;
    livemode: boolean;
    metadata: StripeMetadata;
    source: StripeSource | null;
    statement_descriptor: string | null;
    status: TopupStatus;
    transfer_group: string | null;
};
export type BalanceTransactionType =
    | "adjustment"
    | "advance"
    | "advance_funding"
    | "anticipation_repayment"
    | "application_fee"
    | "application_fee_refund"
    | "charge"
    | "connect_collection_transfer"
    | "contribution"
    | "issuing_authorization_hold"
    | "issuing_authorization_release"
    | "issuing_dispute"
    | "issuing_transaction"
    | "payment"
    | "payment_failure_refund"
    | "payment_refund"
    | "payout"
    | "payout_cancel"
    | "payout_failure"
    | "refund"
    | "refund_failure"
    | "reserve_transaction"
    | "reserved_funds"
    | "stripe_fee"
    | "stripe_fx_fee"
    | "tax_fee"
    | "topup"
    | "topup_reversal"
    | "transfer"
    | "transfer_cancel"
    | "transfer_failure"
    | "transfer_refund";
export type StripeBalanceTransaction = {
    id: string;
    object: "balance_transaction";
    amount: number;
    available_on: number;
    created: number;
    currency: string;
    description: string | null;
    exchange_rate: number | null;
    fee: number;
    fee_details: BalanceTransactionFeeDetail[];
    net: number;
    reporting_category: string;
    source:
        | (
              | string
              | StripeApplicationFee
              | StripeCharge
              | StripeConnectCollectionTransfer
              | StripeDispute
              | StripeFeeRefund
              | StripeIssuingAuthorization
              | StripeIssuingDispute
              | StripeIssuingTransaction
              | StripePayout
              | StripePlatformTaxFee
              | StripeRefund
              | StripeReserveTransaction
              | StripeTaxDeductedAtSource
              | StripeTopup
              | StripeTransfer
              | StripeTransferReversal
          )
        | null;
    status: string;
    type: BalanceTransactionType;
};
export type ApiListStripeFeeRefund = {
    object: "list";
    data: StripeFeeRefund[];
    has_more: boolean;
    url: string;
};
export type StripeApplicationFee = {
    id: string;
    object: "application_fee";
    account: string | StripeAccount;
    amount: number;
    amount_refunded: number;
    application: string | StripeApplication;
    balance_transaction: (string | StripeBalanceTransaction) | null;
    charge: string | StripeCharge;
    created: number;
    currency: string;
    livemode: boolean;
    originating_transaction: (string | StripeCharge) | null;
    refunded: boolean;
    refunds: ApiListStripeFeeRefund;
};
export type ChargeBillingDetails = {
    address: StripeAddress | null;
    email: string | null;
    name: string | null;
    phone: string | null;
};
export type ChargeFraudDetails = {
    stripe_report?: string;
    user_report?: string;
};
export type Level3LineItem = {
    discount_amount: number | null;
    product_code: string;
    product_description: string;
    quantity: number | null;
    tax_amount: number | null;
    unit_cost: number | null;
};
export type ChargeLevel3 = {
    customer_reference?: string;
    line_items: Level3LineItem[];
    merchant_reference: string;
    shipping_address_zip?: string;
    shipping_amount?: number;
    shipping_from_zip?: string;
};
export type OutcomeRule = {
    action: string;
    id: string;
    predicate: string;
};
export type ChargeOutcome = {
    network_status: string | null;
    reason: string | null;
    risk_level?: string;
    risk_score?: number;
    rule?: string | OutcomeRule;
    seller_message: string | null;
    type: string;
};
export type PaymentMethodDetailsAchCreditTransfer = {
    account_number: string | null;
    bank_name: string | null;
    routing_number: string | null;
    swift_code: string | null;
};
export type AchDebitAccountHolderType = "company" | "individual";
export type PaymentMethodDetailsAchDebit = {
    account_holder_type: AchDebitAccountHolderType | null;
    bank_name: string | null;
    country: string | null;
    fingerprint: string | null;
    last4: string | null;
    routing_number: string | null;
};
export type PaymentMethodDetailsAffirm = {};
export type PaymentMethodDetailsAfterpayClearpay = {
    reference: string | null;
};
export type PaymentMethodDetailsAlipay = {
    buyer_id?: string;
    fingerprint: string | null;
    transaction_id: string | null;
};
export type PaymentMethodDetailsCustomerBalance = {};
export type PaymentMethodDetailsEps = {
    bank: EpsBank | null;
    verified_name: string | null;
};
export type PaymentMethodDetailsFpx = {
    account_holder_type: FpxAccountHolderType | null;
    bank: FpxBank;
    transaction_id: string | null;
};
export type PaymentMethodDetailsGiropay = {
    bank_code: string | null;
    bank_name: string | null;
    bic: string | null;
    verified_name: string | null;
};
export type PaymentMethodDetailsGrabpay = {
    transaction_id: string | null;
};
export type InteracPresentReadMethod =
    | "contact_emv"
    | "contactless_emv"
    | "contactless_magstripe_mode"
    | "magnetic_stripe_fallback"
    | "magnetic_stripe_track2";
export type ReceiptAccountType = "checking" | "savings" | "unknown";
export type InteracPresentReceipt = {
    account_type?: ReceiptAccountType;
    application_cryptogram: string | null;
    application_preferred_name: string | null;
    authorization_code: string | null;
    authorization_response_code: string | null;
    cardholder_verification_method: string | null;
    dedicated_file_name: string | null;
    terminal_verification_results: string | null;
    transaction_status_information: string | null;
};
export type PaymentMethodDetailsInteracPresent = {
    brand: string | null;
    cardholder_name: string | null;
    country: string | null;
    description?: string | null;
    emv_auth_data: string | null;
    exp_month: number;
    exp_year: number;
    fingerprint: string | null;
    funding: string | null;
    generated_card: string | null;
    iin?: string | null;
    issuer?: string | null;
    last4: string | null;
    network: string | null;
    preferred_locales: string[] | null;
    read_method: InteracPresentReadMethod | null;
    receipt: InteracPresentReceipt | null;
};
export type StoreChain = "familymart" | "lawson" | "ministop" | "seicomart";
export type KonbiniStore = {
    chain: StoreChain | null;
};
export type PaymentMethodDetailsKonbini = {
    store: KonbiniStore | null;
};
export type PaymentMethodDetailsMultibanco = {
    entity: string | null;
    reference: string | null;
};
export type PaymentMethodDetailsOxxo = {
    number: string | null;
};
export type PaymentMethodDetailsP24 = {
    bank: P24Bank | null;
    reference: string | null;
    verified_name: string | null;
};
export type PaymentMethodDetailsPaynow = {
    reference: string | null;
};
export type PaymentMethodDetailsPix = {
    bank_transaction_id?: string | null;
};
export type PaymentMethodDetailsPromptpay = {
    reference: string | null;
};
export type PaymentMethodDetailsSepaCreditTransfer = {
    bank_name: string | null;
    bic: string | null;
    iban: string | null;
};
export type PaymentMethodDetailsStripeAccount = {};
export type PaymentMethodDetailsWechat = {};
export type PaymentMethodDetailsWechatPay = {
    fingerprint: string | null;
    transaction_id: string | null;
};
export type ChargePaymentMethodDetails = {
    ach_credit_transfer?: PaymentMethodDetailsAchCreditTransfer;
    ach_debit?: PaymentMethodDetailsAchDebit;
    acss_debit?: PaymentMethodDetailsAcssDebit;
    affirm?: PaymentMethodDetailsAffirm;
    afterpay_clearpay?: PaymentMethodDetailsAfterpayClearpay;
    alipay?: PaymentMethodDetailsAlipay;
    au_becs_debit?: PaymentMethodDetailsAuBecsDebit;
    bacs_debit?: PaymentMethodDetailsBacsDebit;
    bancontact?: PaymentMethodDetailsBancontact;
    blik?: PaymentMethodDetailsBlik;
    boleto?: PaymentMethodDetailsBoleto;
    card?: PaymentMethodDetailsCard;
    card_present?: PaymentMethodDetailsCardPresent;
    customer_balance?: PaymentMethodDetailsCustomerBalance;
    eps?: PaymentMethodDetailsEps;
    fpx?: PaymentMethodDetailsFpx;
    giropay?: PaymentMethodDetailsGiropay;
    grabpay?: PaymentMethodDetailsGrabpay;
    ideal?: PaymentMethodDetailsIdeal;
    interac_present?: PaymentMethodDetailsInteracPresent;
    klarna?: PaymentMethodDetailsKlarna;
    konbini?: PaymentMethodDetailsKonbini;
    link?: PaymentMethodDetailsLink;
    multibanco?: PaymentMethodDetailsMultibanco;
    oxxo?: PaymentMethodDetailsOxxo;
    p24?: PaymentMethodDetailsP24;
    paynow?: PaymentMethodDetailsPaynow;
    pix?: PaymentMethodDetailsPix;
    promptpay?: PaymentMethodDetailsPromptpay;
    sepa_credit_transfer?: PaymentMethodDetailsSepaCreditTransfer;
    sepa_debit?: PaymentMethodDetailsSepaDebit;
    sofort?: PaymentMethodDetailsSofort;
    stripe_account?: PaymentMethodDetailsStripeAccount;
    type: string;
    us_bank_account?: PaymentMethodDetailsUsBankAccount;
    wechat?: PaymentMethodDetailsWechat;
    wechat_pay?: PaymentMethodDetailsWechatPay;
};
export type ChargeRadarOptions = {
    session?: string;
};
export type ApiListStripeRefund = {
    object: "list";
    data: StripeRefund[];
    has_more: boolean;
    url: string;
};
export type ChargeShipping = {
    address?: StripeAddress;
    carrier?: string | null;
    name?: string;
    phone?: string | null;
    tracking_number?: string | null;
};
export type ChargeStatus = "failed" | "pending" | "succeeded";
export type ChargeTransferData = {
    amount: number | null;
    destination: string | StripeAccount;
};
export type StripeCharge = {
    id: string;
    object: "charge";
    alternate_statement_descriptors?: ChargeAlternateStatementDescriptors;
    amount: number;
    amount_captured: number;
    amount_refunded: number;
    application: (string | StripeApplication) | null;
    application_fee: (string | StripeApplicationFee) | null;
    application_fee_amount: number | null;
    authorization_code?: string;
    balance_transaction: (string | StripeBalanceTransaction) | null;
    billing_details: ChargeBillingDetails;
    calculated_statement_descriptor: string | null;
    captured: boolean;
    created: number;
    currency: string;
    customer: (string | StripeCustomer | StripeDeletedCustomer) | null;
    description: string | null;
    destination: (string | StripeAccount) | null;
    dispute: (string | StripeDispute) | null;
    disputed: boolean;
    failure_balance_transaction: (string | StripeBalanceTransaction) | null;
    failure_code: string | null;
    failure_message: string | null;
    fraud_details: ChargeFraudDetails | null;
    invoice: (string | StripeInvoice) | null;
    level3?: ChargeLevel3;
    livemode: boolean;
    metadata: StripeMetadata;
    on_behalf_of: (string | StripeAccount) | null;
    outcome: ChargeOutcome | null;
    paid: boolean;
    payment_intent: (string | StripePaymentIntent) | null;
    payment_method: string | null;
    payment_method_details: ChargePaymentMethodDetails | null;
    radar_options?: ChargeRadarOptions;
    receipt_email: string | null;
    receipt_number: string | null;
    receipt_url: string | null;
    refunded: boolean;
    refunds?: ApiListStripeRefund;
    review: (string | StripeReview) | null;
    shipping: ChargeShipping | null;
    source: StripeCustomerSource | null;
    source_transfer: (string | StripeTransfer) | null;
    statement_descriptor: string | null;
    statement_descriptor_suffix: string | null;
    status: ChargeStatus;
    transfer?: string | StripeTransfer;
    transfer_data: ChargeTransferData | null;
    transfer_group: string | null;
};
export type SepaDebitGeneratedFrom = {
    charge: (string | StripeCharge) | null;
    setup_attempt: (string | StripeSetupAttempt) | null;
};
export type PaymentMethodSepaDebit = {
    bank_code: string | null;
    branch_code: string | null;
    country: string | null;
    fingerprint: string | null;
    generated_from: SepaDebitGeneratedFrom | null;
    last4: string | null;
};
export type PaymentMethodSofort = {
    country: string | null;
};
export type PaymentMethodType =
    | "acss_debit"
    | "affirm"
    | "afterpay_clearpay"
    | "alipay"
    | "au_becs_debit"
    | "bacs_debit"
    | "bancontact"
    | "blik"
    | "boleto"
    | "card"
    | "card_present"
    | "customer_balance"
    | "eps"
    | "fpx"
    | "giropay"
    | "grabpay"
    | "ideal"
    | "interac_present"
    | "klarna"
    | "konbini"
    | "link"
    | "oxxo"
    | "p24"
    | "paynow"
    | "pix"
    | "promptpay"
    | "sepa_debit"
    | "sofort"
    | "us_bank_account"
    | "wechat_pay";
export type UsBankAccountAccountHolderType = "company" | "individual";
export type UsBankAccountAccountType = "checking" | "savings";
export type NetworksSupported = "ach" | "us_domestic_wire";
export type UsBankAccountNetworks = {
    preferred: string | null;
    supported: NetworksSupported[];
};
export type PaymentMethodUsBankAccount = {
    account_holder_type: UsBankAccountAccountHolderType | null;
    account_type: UsBankAccountAccountType | null;
    bank_name: string | null;
    financial_connections_account?: string | null;
    fingerprint: string | null;
    last4: string | null;
    networks: UsBankAccountNetworks | null;
    routing_number: string | null;
};
export type PaymentMethodWechatPay = {};
export type StripePaymentMethod = {
    id: string;
    object: "payment_method";
    acss_debit?: PaymentMethodAcssDebit;
    affirm?: PaymentMethodAffirm;
    afterpay_clearpay?: PaymentMethodAfterpayClearpay;
    alipay?: PaymentMethodAlipay;
    au_becs_debit?: PaymentMethodAuBecsDebit;
    bacs_debit?: PaymentMethodBacsDebit;
    bancontact?: PaymentMethodBancontact;
    billing_details: PaymentMethodBillingDetails;
    blik?: PaymentMethodBlik;
    boleto?: PaymentMethodBoleto;
    card?: PaymentMethodCard;
    card_present?: PaymentMethodCardPresent;
    created: number;
    customer: (string | StripeCustomer) | null;
    customer_balance?: PaymentMethodCustomerBalance;
    eps?: PaymentMethodEps;
    fpx?: PaymentMethodFpx;
    giropay?: PaymentMethodGiropay;
    grabpay?: PaymentMethodGrabpay;
    ideal?: PaymentMethodIdeal;
    interac_present?: PaymentMethodInteracPresent;
    klarna?: PaymentMethodKlarna;
    konbini?: PaymentMethodKonbini;
    link?: PaymentMethodLink;
    livemode: boolean;
    metadata: StripeMetadata | null;
    oxxo?: PaymentMethodOxxo;
    p24?: PaymentMethodP24;
    paynow?: PaymentMethodPaynow;
    pix?: PaymentMethodPix;
    promptpay?: PaymentMethodPromptpay;
    radar_options?: PaymentMethodRadarOptions;
    sepa_debit?: PaymentMethodSepaDebit;
    sofort?: PaymentMethodSofort;
    type: PaymentMethodType;
    us_bank_account?: PaymentMethodUsBankAccount;
    wechat_pay?: PaymentMethodWechatPay;
};
export type InvoiceSettingsRenderingOptions = {
    amount_tax_display: string | null;
};
export type CustomerInvoiceSettings = {
    custom_fields: InvoiceSettingsCustomField[] | null;
    default_payment_method: (string | StripePaymentMethod) | null;
    footer: string | null;
    rendering_options: InvoiceSettingsRenderingOptions | null;
};
export type CustomerShipping = {
    address?: StripeAddress;
    carrier?: string | null;
    name?: string;
    phone?: string | null;
    tracking_number?: string | null;
};
export type ApiListStripeCustomerSource = {
    object: "list";
    data: StripeCustomerSource[];
    has_more: boolean;
    url: string;
};
export type ApiListStripeSubscription = {
    object: "list";
    data: StripeSubscription[];
    has_more: boolean;
    url: string;
};
export type TaxAutomaticTax = "failed" | "not_collecting" | "supported" | "unrecognized_location";
export type LocationSource = "billing_address" | "ip_address" | "payment_method" | "shipping_destination";
export type TaxLocation = {
    country: string;
    source: LocationSource;
    state: string | null;
};
export type CustomerTax = {
    automatic_tax: TaxAutomaticTax;
    ip_address: string | null;
    location: TaxLocation | null;
};
export type CustomerTaxExempt = "exempt" | "none" | "reverse";
export type ApiListStripeTaxId = {
    object: "list";
    data: StripeTaxId[];
    has_more: boolean;
    url: string;
};
export type StripeCustomer = {
    id: string;
    object: "customer";
    address?: StripeAddress | null;
    balance: number;
    cash_balance?: StripeCashBalance | null;
    created: number;
    currency?: string | null;
    default_source: (string | StripeCustomerSource) | null;
    deleted?: any;
    delinquent?: boolean | null;
    description: string | null;
    discount?: StripeDiscount | null;
    email: string | null;
    invoice_credit_balance?: {
        [key: string]: number;
    };
    invoice_prefix?: string | null;
    invoice_settings: CustomerInvoiceSettings;
    livemode: boolean;
    metadata: StripeMetadata;
    name?: string | null;
    next_invoice_sequence?: number;
    phone?: string | null;
    preferred_locales?: string[] | null;
    shipping: CustomerShipping | null;
    sources?: ApiListStripeCustomerSource;
    subscriptions?: ApiListStripeSubscription;
    tax?: CustomerTax;
    tax_exempt?: CustomerTaxExempt | null;
    tax_ids?: ApiListStripeTaxId;
    test_clock?: (string | StripeTestHelpersTestClock) | null;
};
export type StripeCard = {
    id: string;
    object: "card";
    account?: (string | StripeAccount) | null;
    address_city: string | null;
    address_country: string | null;
    address_line1: string | null;
    address_line1_check: string | null;
    address_line2: string | null;
    address_state: string | null;
    address_zip: string | null;
    address_zip_check: string | null;
    available_payout_methods?: CardAvailablePayoutMethod[] | null;
    brand: string;
    country: string | null;
    currency?: string | null;
    customer?: (string | StripeCustomer | StripeDeletedCustomer) | null;
    cvc_check: string | null;
    default_for_currency?: boolean | null;
    deleted?: any;
    description?: string;
    dynamic_last4: string | null;
    exp_month: number;
    exp_year: number;
    fingerprint?: string | null;
    funding: string;
    iin?: string;
    issuer?: string;
    last4: string;
    metadata: StripeMetadata | null;
    name: string | null;
    status?: string | null;
    tokenization_method: string | null;
};
export type ApiListStripeBankAccountOrStripeCard = {
    object: "list";
    data: (StripeBankAccount | StripeCard)[];
    has_more: boolean;
    url: string;
};
export type FutureRequirementsAlternative = {
    alternative_fields_due: string[];
    original_fields_due: string[];
};
export type ErrorCode =
    | "invalid_address_city_state_postal_code"
    | "invalid_dob_age_under_18"
    | "invalid_representative_country"
    | "invalid_street_address"
    | "invalid_tos_acceptance"
    | "invalid_value_other"
    | "verification_document_address_mismatch"
    | "verification_document_address_missing"
    | "verification_document_corrupt"
    | "verification_document_country_not_supported"
    | "verification_document_dob_mismatch"
    | "verification_document_duplicate_type"
    | "verification_document_expired"
    | "verification_document_failed_copy"
    | "verification_document_failed_greyscale"
    | "verification_document_failed_other"
    | "verification_document_failed_test_mode"
    | "verification_document_fraudulent"
    | "verification_document_id_number_mismatch"
    | "verification_document_id_number_missing"
    | "verification_document_incomplete"
    | "verification_document_invalid"
    | "verification_document_issue_or_expiry_date_missing"
    | "verification_document_manipulated"
    | "verification_document_missing_back"
    | "verification_document_missing_front"
    | "verification_document_name_mismatch"
    | "verification_document_name_missing"
    | "verification_document_nationality_mismatch"
    | "verification_document_not_readable"
    | "verification_document_not_signed"
    | "verification_document_not_uploaded"
    | "verification_document_photo_mismatch"
    | "verification_document_too_large"
    | "verification_document_type_not_supported"
    | "verification_failed_address_match"
    | "verification_failed_business_iec_number"
    | "verification_failed_document_match"
    | "verification_failed_id_number_match"
    | "verification_failed_keyed_identity"
    | "verification_failed_keyed_match"
    | "verification_failed_name_match"
    | "verification_failed_other"
    | "verification_failed_residential_address"
    | "verification_failed_tax_id_match"
    | "verification_failed_tax_id_not_issued"
    | "verification_missing_executives"
    | "verification_missing_owners"
    | "verification_requires_additional_memorandum_of_associations";
export type FutureRequirementsError = {
    code: ErrorCode;
    reason: string;
    requirement: string;
};
export type AccountFutureRequirements = {
    alternatives: FutureRequirementsAlternative[] | null;
    current_deadline: number | null;
    currently_due: string[] | null;
    disabled_reason: string | null;
    errors: FutureRequirementsError[] | null;
    eventually_due: string[] | null;
    past_due: string[] | null;
    pending_verification: string[] | null;
};
export type PersonAddressKana = {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
    town: string | null;
};
export type PersonAddressKanji = {
    city: string | null;
    country: string | null;
    line1: string | null;
    line2: string | null;
    postal_code: string | null;
    state: string | null;
    town: string | null;
};
export type PersonDob = {
    day: number | null;
    month: number | null;
    year: number | null;
};
export type PersonFutureRequirements = {
    alternatives: FutureRequirementsAlternative[] | null;
    currently_due: string[];
    errors: FutureRequirementsError[];
    eventually_due: string[];
    past_due: string[];
    pending_verification: string[];
};
export type PersonPoliticalExposure = "existing" | "none";
export type PersonRelationship = {
    director: boolean | null;
    executive: boolean | null;
    owner: boolean | null;
    percent_ownership: number | null;
    representative: boolean | null;
    title: string | null;
};
export type RequirementsAlternative = {
    alternative_fields_due: string[];
    original_fields_due: string[];
};
export type RequirementsError = {
    code: ErrorCode;
    reason: string;
    requirement: string;
};
export type PersonRequirements = {
    alternatives: RequirementsAlternative[] | null;
    currently_due: string[];
    errors: RequirementsError[];
    eventually_due: string[];
    past_due: string[];
    pending_verification: string[];
};
export type VerificationAdditionalDocument = {
    back: (string | StripeFile) | null;
    details: string | null;
    details_code: string | null;
    front: (string | StripeFile) | null;
};
export type PersonVerification = {
    additional_document?: VerificationAdditionalDocument | null;
    details?: string | null;
    details_code?: string | null;
    document?: VerificationDocument;
    status: string;
};
export type StripePerson = {
    id: string;
    object: "person";
    account: string;
    address?: StripeAddress;
    address_kana?: PersonAddressKana | null;
    address_kanji?: PersonAddressKanji | null;
    created: number;
    deleted?: any;
    dob?: PersonDob;
    email?: string | null;
    first_name?: string | null;
    first_name_kana?: string | null;
    first_name_kanji?: string | null;
    full_name_aliases?: string[];
    future_requirements?: PersonFutureRequirements | null;
    gender?: string | null;
    id_number_provided?: boolean;
    id_number_secondary_provided?: boolean;
    last_name?: string | null;
    last_name_kana?: string | null;
    last_name_kanji?: string | null;
    maiden_name?: string | null;
    metadata?: StripeMetadata;
    nationality?: string | null;
    phone?: string | null;
    political_exposure?: PersonPoliticalExposure;
    registered_address?: StripeAddress;
    relationship?: PersonRelationship;
    requirements?: PersonRequirements | null;
    ssn_last_4_provided?: boolean;
    verification?: PersonVerification;
};
export type AccountRequirements = {
    alternatives: RequirementsAlternative[] | null;
    current_deadline: number | null;
    currently_due: string[] | null;
    disabled_reason: string | null;
    errors: RequirementsError[] | null;
    eventually_due: string[] | null;
    past_due: string[] | null;
    pending_verification: string[] | null;
};
export type SettingsBacsDebitPayments = {
    display_name?: string;
};
export type SettingsBranding = {
    icon: (string | StripeFile) | null;
    logo: (string | StripeFile) | null;
    primary_color: string | null;
    secondary_color: string | null;
};
export type CardIssuingTosAcceptance = {
    date: number | null;
    ip: string | null;
    user_agent?: string;
};
export type SettingsCardIssuing = {
    tos_acceptance?: CardIssuingTosAcceptance;
};
export type CardPaymentsDeclineOn = {
    avs_failure: boolean;
    cvc_failure: boolean;
};
export type SettingsCardPayments = {
    decline_on?: CardPaymentsDeclineOn;
    statement_descriptor_prefix: string | null;
    statement_descriptor_prefix_kana: string | null;
    statement_descriptor_prefix_kanji: string | null;
};
export type SettingsDashboard = {
    display_name: string | null;
    timezone: string | null;
};
export type SettingsPayments = {
    statement_descriptor: string | null;
    statement_descriptor_kana: string | null;
    statement_descriptor_kanji: string | null;
    statement_descriptor_prefix_kana: string | null;
    statement_descriptor_prefix_kanji: string | null;
};
export type PayoutsSchedule = {
    delay_days: number;
    interval: string;
    monthly_anchor?: number;
    weekly_anchor?: string;
};
export type SettingsPayouts = {
    debit_negative_balances: boolean;
    schedule: PayoutsSchedule;
    statement_descriptor: string | null;
};
export type SettingsSepaDebitPayments = {
    creditor_id?: string;
};
export type TreasuryTosAcceptance = {
    date: number | null;
    ip: string | null;
    user_agent?: string;
};
export type SettingsTreasury = {
    tos_acceptance?: TreasuryTosAcceptance;
};
export type AccountSettings = {
    bacs_debit_payments?: SettingsBacsDebitPayments;
    branding: SettingsBranding;
    card_issuing?: SettingsCardIssuing;
    card_payments: SettingsCardPayments;
    dashboard: SettingsDashboard;
    payments: SettingsPayments;
    payouts?: SettingsPayouts;
    sepa_debit_payments?: SettingsSepaDebitPayments;
    treasury?: SettingsTreasury;
};
export type AccountTosAcceptance = {
    date?: number | null;
    ip?: string | null;
    service_agreement?: string;
    user_agent?: string | null;
};
export type AccountType = "custom" | "express" | "standard";
export type StripeAccount = {
    id: string;
    object: "account";
    business_profile?: AccountBusinessProfile | null;
    business_type?: AccountBusinessType | null;
    capabilities?: AccountCapabilities;
    charges_enabled: boolean;
    company?: AccountCompany;
    controller?: AccountController;
    country?: string;
    created?: number;
    default_currency?: string;
    deleted?: any;
    details_submitted: boolean;
    email: string | null;
    external_accounts?: ApiListStripeBankAccountOrStripeCard;
    future_requirements?: AccountFutureRequirements;
    individual?: StripePerson;
    metadata?: StripeMetadata;
    payouts_enabled: boolean;
    requirements?: AccountRequirements;
    settings?: AccountSettings | null;
    tos_acceptance?: AccountTosAcceptance;
    type: AccountType;
};
export type StripeBankAccount = {
    id: string;
    object: "bank_account";
    account?: (string | StripeAccount) | null;
    account_holder_name: string | null;
    account_holder_type: string | null;
    account_type: string | null;
    available_payout_methods?: BankAccountAvailablePayoutMethod[] | null;
    bank_name: string | null;
    country: string;
    currency: string;
    customer?: (string | StripeCustomer | StripeDeletedCustomer) | null;
    default_for_currency?: boolean | null;
    deleted?: any;
    fingerprint: string | null;
    last4: string;
    metadata?: StripeMetadata | null;
    routing_number: string | null;
    status: string;
};
export type PayoutRequestBody = {
    amount: number;
    bankAccountId: string;
    teacherId: string;
};
export type UpdateStripeAccountForConnectRequestBody = {
    fileId: string;
    phone: string;
    email: string;
    line2: string;
    line1: string;
    town: string;
    city: string;
    state: string;
    postalCode: string;
    teacherId: string;
};
export type ResubmitVerificationDocumentRequestBody = {
    fileId: string;
    teacherId: string;
};
export type JwtRequestBody = {
    teacherId: string | null;
    studentId: string | null;
    userId: string | null;
    userMode: string;
    login: boolean;
};
export type AdminJwtRequestBody = {
    memberId: string | null;
    userMode: string | null;
    login: boolean;
};
export type GetPreSignedUrlResponse = {
    preSignedUrl: string;
};
export type CreateSetUpIntentRequestBody = {
    studentId: string;
};
export type UpdateDefaultPaymentMethodRequestBody = {
    paymentMethodId?: string;
    paymentMethodType: string;
    studentId: string;
};
export type PartialOmitStripePaymentMethodId = {
    object?: "payment_method";
    acss_debit?: PaymentMethodAcssDebit;
    alipay?: PaymentMethodAlipay;
    au_becs_debit?: PaymentMethodAuBecsDebit;
    bancontact?: PaymentMethodBancontact;
    card?: PaymentMethodCard;
    card_present?: PaymentMethodCardPresent;
    eps?: PaymentMethodEps;
    giropay?: PaymentMethodGiropay;
    ideal?: PaymentMethodIdeal;
    klarna?: PaymentMethodKlarna;
    p24?: PaymentMethodP24;
    sepa_debit?: PaymentMethodSepaDebit;
    sofort?: PaymentMethodSofort;
    blik?: PaymentMethodBlik;
    bacs_debit?: PaymentMethodBacsDebit;
    boleto?: PaymentMethodBoleto;
    customer_balance?: PaymentMethodCustomerBalance;
    fpx?: PaymentMethodFpx;
    grabpay?: PaymentMethodGrabpay;
    konbini?: PaymentMethodKonbini;
    link?: PaymentMethodLink;
    paynow?: PaymentMethodPaynow;
    promptpay?: PaymentMethodPromptpay;
    us_bank_account?: PaymentMethodUsBankAccount;
    wechat_pay?: PaymentMethodWechatPay;
    affirm?: PaymentMethodAffirm;
    afterpay_clearpay?: PaymentMethodAfterpayClearpay;
    interac_present?: PaymentMethodInteracPresent;
    oxxo?: PaymentMethodOxxo;
    pix?: PaymentMethodPix;
    billing_details?: PaymentMethodBillingDetails;
    created?: number;
    customer?: (string | StripeCustomer) | null;
    livemode?: boolean;
    metadata?: StripeMetadata | null;
    radar_options?: PaymentMethodRadarOptions;
    type?: PaymentMethodType;
};
export type PaymentMethod = PartialOmitStripePaymentMethodId & {
    id: string | null;
    type: string;
};
export type MakeLatestPaymentMethodDefaultRequestBody = {
    studentId: string;
};
export type DeleteBankAccountRequestBody = {
    bankAccountId: string;
    teacherId: string;
};
export type UpdateDefaultBankAccountRequestBody = {
    bankAccountId: string;
    teacherId: string;
};
export type AvailableSourceTypes = {
    bank_account?: number;
    card?: number;
    fpx?: number;
};
export type BalanceAvailable = {
    amount: number;
    currency: string;
    source_types?: AvailableSourceTypes;
};
export type ConnectReservedSourceTypes = {
    bank_account?: number;
    card?: number;
    fpx?: number;
};
export type BalanceConnectReserved = {
    amount: number;
    currency: string;
    source_types?: ConnectReservedSourceTypes;
};
export type InstantAvailableSourceTypes = {
    bank_account?: number;
    card?: number;
    fpx?: number;
};
export type BalanceInstantAvailable = {
    amount: number;
    currency: string;
    source_types?: InstantAvailableSourceTypes;
};
export type IssuingAvailable = {
    amount: number;
    currency: string;
    source_types?: AvailableSourceTypes;
};
export type BalanceIssuing = {
    available: IssuingAvailable[];
};
export type PendingSourceTypes = {
    bank_account?: number;
    card?: number;
    fpx?: number;
};
export type BalancePending = {
    amount: number;
    currency: string;
    source_types?: PendingSourceTypes;
};
export type StripeBalance = {
    object: "balance";
    available: BalanceAvailable[];
    connect_reserved?: BalanceConnectReserved[];
    instant_available?: BalanceInstantAvailable[];
    issuing?: BalanceIssuing;
    livemode: boolean;
    pending: BalancePending[];
};
export type BankInfo = {
    japanPostCode: string;
    branchCode: string;
    bankCode: string;
    branchNameKana: string;
    branchName: string;
    bankShortNameKana: string;
    bankShortName: string;
    bankFullName: string;
};
export type AddressInfo = {
    line1Kana: string;
    cityKana: string;
    stateKana: string;
    line1: string;
    city: string;
    state: string;
    postalCode: string;
};
export type CreatePrivateRequestRequestBody = {
    baseSchedules: CreateBaseScheduleParams[];
    teacherId: string;
    estimatedMonthlyFee: number;
    lastDateDescription?: string;
    firstDateDescription: string;
    message: string;
    classId: string;
    studentId: string;
};
export type ApprovePrivateRequestRequestBody = {
    teacherToDoId: string;
    confirmedFirstDate: string;
    confirmedMonthlyFee: number;
    privateRequestId: string;
};
export type DisapprovePrivateRequestRequestBody = {
    teacherToDoId: string;
    message: string;
    privateRequestId: string;
};
export type RejectPayingForCourseRequestBody = {
    message: string;
    studentToDoId: string;
    privateRequestId: string;
};
export type UpdateEmailNotificationRequestBody = {
    newPublicRequest?: boolean;
    newCourse?: boolean;
    review?: boolean;
    maintenance?: boolean;
    recommendation?: boolean;
    information?: boolean;
    favorite?: boolean;
    message?: boolean;
    allowNotifications?: boolean;
    emailNotificationsSettingId: string;
};
export type UpdatePushNotificationRequestBody = {
    newPublicRequest?: boolean;
    newCourse?: boolean;
    review?: boolean;
    maintenance?: boolean;
    recommendation?: boolean;
    information?: boolean;
    favorite?: boolean;
    message?: boolean;
    allowNotifications?: boolean;
    pushNotificationsSettingId: string;
};
export type UpdateNotificationsSettingRequestBody = {
    emailNotificationsSetting: UpdateEmailNotificationRequestBody;
    pushNotificationsSetting: UpdatePushNotificationRequestBody;
};
export type NominalTypingReadStatusString = {
    _type: "ReadStatus";
} & string;
export type ReadStatusResponse = {
    readStatusId: NominalTypingReadStatusString;
    chatId: NominalTypingChatString;
    teacherId?: NominalTypingTeacherString;
    studentId?: NominalTypingStudentString;
    createdAt: string;
    updatedAt?: string;
};
export type ChatResponse = {
    chatId: NominalTypingChatString;
    chatType: ChatType;
    messages: MessageResponse[];
    class?: ClassResponse;
    teachers: PublicTeacherResponse[];
    students: PublicStudentResponse[];
    readStatuses: ReadStatusResponse[];
    createdAt: string;
    updatedAt?: string;
};
export type PickCreateBaseScheduleRequestBodyExcludeKeyofCreateBaseScheduleRequestBodyClassIdOrPrivateRequestId = {
    repetitionUnitNumber: number;
    repetitionUnit: RepetitionUnit;
    howManyTimes: number;
    dayOfWeekIndexes: number[];
    startHour?: number;
    startMinute?: number;
    howManyMinutes: number;
    isActive: boolean;
};
export type OmitCreateBaseScheduleRequestBodyClassIdOrPrivateRequestId =
    PickCreateBaseScheduleRequestBodyExcludeKeyofCreateBaseScheduleRequestBodyClassIdOrPrivateRequestId;
export type UpdateBaseSchedulesRequestBody = {
    baseSchedules: OmitCreateBaseScheduleRequestBodyClassIdOrPrivateRequestId[];
    classId: string;
};
export type BannerTargetType = "student" | "teacher" | "anonymous" | "everyone";
export type DeviceType = "PC" | "tablet" | "SP";
export type CreateBannerRequestBody = {
    endDate?: string;
    startDate: string;
    linkUrl?: string;
    bannerUrl: string;
    sort: number;
    title: string;
    targetType: BannerTargetType;
    deviceType: DeviceType;
};
export type PartialCreateBannerRequestBody = {
    deviceType?: DeviceType;
    targetType?: BannerTargetType;
    title?: string;
    sort?: number;
    bannerUrl?: string;
    linkUrl?: string;
    startDate?: string;
    endDate?: string;
};
export type UpdateBannerRequestBody = PartialCreateBannerRequestBody & {
    bannerId: string;
};
export type BannerResponse = {
    bannerId: string;
    deviceType: DeviceType;
    targetType: BannerTargetType;
    title: string;
    sort: number;
    bannerUrl: string;
    bannerObjectKey: string;
    linkUrl?: string;
    startDate: string;
    endDate?: string;
};
export type NewsTargetType = "student" | "teacher" | "anonymous" | "everyone";
export type CreateNewsRecordRequestBody = {
    body: string;
    title: string;
    targetType: NewsTargetType;
};
export type UpdateNewsRecordRequestBody = CreateNewsRecordRequestBody & {
    newsRecordId: string;
};
export type NominalTypingNewsRecordString = {
    _type: "NewsRecord";
} & string;
export type NewsRecordResponse = {
    newsRecordId: NominalTypingNewsRecordString;
    targetType: NewsTargetType;
    title: string;
    body: string;
    createdAt?: string;
    updatedAt?: string;
};
export type ArticleResponse = {
    articleId: number;
    title: string;
    link: string;
    date: string;
    thumbnailUrl?: string;
};
export type ArticleTargetType = "student" | "teacher" | "anonymous" | "everyone";
