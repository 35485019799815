import styles from "@/pages/Common/LP/index.module.scss";
import { LPTopContents } from "../LP/Top";
import { WhatsTrail } from "./WhatsTrail";
import { EasyToStart } from "./EasyToStart";
import { ThreeStepsSignUp } from "./ThreeStepsSignUp";
import { Achievements } from "./Achievements";
import { Background } from "./Background";
import { PostScript } from "./PostScript";
import { OverWrapper } from "@/components/OverWrapper";
import { Teachers } from "@/pageComponents/Common/LP/Teachers";
import { useMediaQuery } from "@mui/material";

export const AboutTrailContents: React.VFC = () => {
    const smUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:960px)");
    return (
        <OverWrapper
            className={styles.aboutContentsWrapper}
            style={{
                margin: mdUp ? "0 auto" : smUp ? "0 -16px" : "0 -10px",
                padding: mdUp ? "0 100px 30px" : smUp ? "0 16px" : "0 10px",
                width: mdUp ? "calc(100% - 200px)" : "100%",
            }}
        >
            <LPTopContents
                subCatchCopy="であなたに合った先生を見つけよう"
                strongPoints={[
                    {
                        emphasizedDescription: "ニーズに合った",
                        normalDescription: "最適な授業を受けられる",
                    },
                    {
                        emphasizedDescription: "予算は自由",
                        normalDescription: "に設定して先生を募集",
                    },
                    {
                        emphasizedDescription: "名大生",
                        normalDescription: "を中心に優秀な先生が多数在籍",
                    },
                ]}
            />
            <WhatsTrail />
            <Teachers />
            <EasyToStart />
            <Background />
            <ThreeStepsSignUp />
            <Achievements />
            {/* <Soon /> */}
            <PostScript />
        </OverWrapper>
    );
};
