import { memo, useCallback, useEffect, useState } from "react";
import { Modal } from "@/components/Modal";
import { DraftPublicRequestResponse } from "@/store/autogenApi";
import { ExistDraftPublicRequest } from "./ExistDraftPublicRequest";

interface Props {
    draftPublicRequestId: string | null;
    existPublicRequest: boolean;
    handleDiscardDraftButtonClick: () => void;
    handleUseDraftButtonClick: (draftPublicRequest: DraftPublicRequestResponse | undefined) => void;
}

export const UseDraftPublicRequestModal: React.VFC<Props> = memo(function UseDraftPublicRequestModal(props) {
    const [useDraftPublicRequestModalOpen, setUseDraftPublicRequestModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if (props.draftPublicRequestId !== null) {
            setUseDraftPublicRequestModalOpen(true);
        }
    }, [props.draftPublicRequestId]);

    const handleDiscardDraftButtonClick = useCallback(() => {
        setUseDraftPublicRequestModalOpen(false);
        props.handleDiscardDraftButtonClick();
    }, [props.handleDiscardDraftButtonClick]);

    const handleUseDraftButtonClick = useCallback(
        (draftPublicRequest: DraftPublicRequestResponse | undefined) => {
            setUseDraftPublicRequestModalOpen(false);
            props.handleUseDraftButtonClick(draftPublicRequest);
        },
        [props.handleUseDraftButtonClick],
    );

    if (props.draftPublicRequestId == null) {
        return null;
    }
    return (
        <Modal
            open={useDraftPublicRequestModalOpen}
            autoResize
            onClose={() => {
                // 何もしない
            }}
            style={{ maxWidth: "500px" }}
        >
            <ExistDraftPublicRequest
                existPublicRequest={Boolean(props.existPublicRequest)}
                draftPublicRequestId={props.draftPublicRequestId}
                handleDiscardDraftButtonClick={handleDiscardDraftButtonClick}
                handleUseDraftButtonClick={handleUseDraftButtonClick}
            />
        </Modal>
    );
});
