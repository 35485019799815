import { memo } from "react";

import styles from "./index.module.scss";

interface Props {
    background: string;
}

export const Background: React.VFC<Props> = memo(function Background(props) {
    return (
        <>
            <section className={styles.backgroundWrapper}>
                <h2 className={styles.backgroundTitle}>経歴</h2>
                <div className={styles.background}>{props.background}</div>
            </section>
        </>
    );
});
