import { memo } from "react";
import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";

interface Props {
    description?: string;
}

export const RequestDescription: React.VFC<Props> = memo((props) => {
    return (
        <div className={styles.detailWrapper}>
            <div className={styles.detailHeadingWrapper}>
                <span className={styles.detailHeading}>希望する講座内容</span>
            </div>
            <div className={styles.detail} style={{ overflowY: "auto", maxHeight: "none" }}>
                {props.description}
            </div>
        </div>
    );
});
