import { Button, Step, StepLabel, Stepper, ThemeProvider, createTheme } from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";
import { AiOutlineQuestionCircle, AiOutlineSchedule } from "react-icons/ai";
import { CgUserList } from "react-icons/cg";
import styles from "./index.module.scss";
import { ClassResponse, LessonResponse, PrivateUserResponse, PublicStudentResponse } from "@/store/autogenApi";
import { MdSchedule } from "react-icons/md";
import { UserInfoModal } from "@/components/UserInfoModal";
import { getBaseScheduleInfo } from "@/utils/BaseScheduleUtils";
import { getLessonTime } from "@/utils/LessonUtils";
import { getStatus } from "@/utils/ClassUtils";
import { FeeIcon, StatusIcon } from "@/globalIcons";
import { PlainButton } from "@/components/PlainButton";
import { WithdrawFromClassModal } from "./WithdrawFromClassModal";
import { getTime } from "@/utils/DateUtils";
import { JoinMeetingModal } from "./JoinMeetingModal";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    classResponse: ClassResponse;
    disableToJoin: boolean;
}

export const ClassInfo: React.VFC<Props> = memo(function ClassInfo(props) {
    const [modalTargetStudent, setModalTargetStudent] = useState<PublicStudentResponse | null>(null);
    const [validLessons, setValidLessons] = useState<LessonResponse[]>([]);
    const [targetLesson, setTargetLesson] = useState<LessonResponse | undefined>(undefined);
    const [withdrawModalOpen, setWithdrawModalOpen] = useState<boolean>(false);
    const [status, setStatus] = useState<string | undefined>(undefined);

    const [currentLessonsEl, setCurrentLessonsEl] = useState<HTMLElement | null>(null);

    const lessonsRef = useCallback((bodyEl: HTMLElement | null) => {
        setCurrentLessonsEl(bodyEl);
    }, []);

    useEffect(() => {
        const newStatus = getStatus(props.classResponse);
        setStatus(newStatus);
        const newValidLessons = props.classResponse.lessons.filter((lesson) => {
            const now = new Date();
            const endTime = new Date(lesson.endTime);
            return now < endTime;
        });
        setValidLessons(newValidLessons);
    }, [props.classResponse]);

    const getFormattedDate = useCallback((date: Date) => {
        const modifiedDate = new Date(date);
        const formatted_date =
            modifiedDate.getFullYear() +
            "-" +
            (modifiedDate.getMonth() + 1) +
            "-" +
            modifiedDate.getDate() +
            " " +
            ("00" + modifiedDate.getHours()).slice(-2) +
            ":" +
            ("00" + modifiedDate.getMinutes()).slice(-2);
        return formatted_date;
    }, []);

    const getJapaneseCourseType = useCallback((courseType: string) => {
        if (courseType === "short") {
            return "単発・短期講座";
        } else {
            return "定期講座";
        }
    }, []);

    const handleParticipantButtonClick = useCallback((student: PublicStudentResponse) => {
        setModalTargetStudent(student);
    }, []);

    const handleParticipantInfoModalClose = useCallback(() => {
        setModalTargetStudent(null);
    }, []);

    const handleLessonButtonClick = useCallback(
        (lesson: LessonResponse) => {
            if (props.disableToJoin) return;
            setTargetLesson(lesson);
        },
        [props.disableToJoin],
    );
    const handleJoinMeetingModalClose = useCallback(() => {
        setTargetLesson(undefined);
    }, []);

    const handleNoLongerAvailableButtonClick = useCallback(() => {
        window.open("/FAQDetails?type=cancelCourse");
    }, []);

    const handleWithdrawButtonClick = useCallback(() => {
        setWithdrawModalOpen(true);
    }, []);

    const handleWithdrawModalClose = useCallback(() => {
        setWithdrawModalOpen(false);
    }, []);

    return (
        <div className={styles.classInfo}>
            <div className={styles.statusWrapper}>
                <div className={styles.statusTitleWrapper}>
                    <StatusIcon className={styles.statusIcon} />
                    <div className={styles.statusTitle}>ステータス</div>
                </div>
                <div className={styles.statusBodyWrapper}>
                    <div className={styles.status}>{status}</div>
                    {status === "募集中" && (
                        <div className={styles.applyingDeadline}>
                            （締切：{getTime(props.classResponse.applyingDeadline)}）
                        </div>
                    )}
                </div>
            </div>
            {props.classResponse.course?.courseType === "regular" && props.classResponse.baseSchedules && (
                <div className={styles.baseScheduleWrapper}>
                    <div className={styles.baseScheduleTitleWrapper}>
                        <AiOutlineSchedule className={styles.baseScheduleIcon} />
                        <div className={styles.baseScheduleTitle}>基本スケジュール</div>
                    </div>
                    {props.classResponse.baseSchedules.length > 0 && (
                        <div className={styles.baseSchedule}>
                            {props.classResponse.baseSchedules.map((baseSchedule) => (
                                <div className={styles.baseScheduleItemWrapper} key={baseSchedule.baseScheduleId}>
                                    <div className={styles.baseScheduleItem}>{getBaseScheduleInfo(baseSchedule)}</div>
                                </div>
                            ))}
                        </div>
                    )}
                    <div className={styles.baseScheduleDescription}>
                        {props.classResponse.baseSchedules.length === 0
                            ? "基本スケジュールは設定されていません。"
                            : "基本スケジュールに従って毎日1か月後の授業予定が自動で生成されます。実際の要望と差分がある場合には、先生が手動で授業予定を修正します。"}
                    </div>
                </div>
            )}
            {props.classResponse.participants && (
                <div className={styles.participantsWrapper}>
                    <div className={styles.participantsTitleWrapper}>
                        <CgUserList className={styles.participantsIcon} />
                        <div className={styles.participantsTitle}>参加者</div>
                    </div>
                    {props.classResponse.participants.length === 0 ? (
                        <div className={styles.emptyParticipants}>参加者がいません</div>
                    ) : (
                        <>
                            <ul className={styles.participants}>
                                {props.classResponse.participants.map((participant) => {
                                    return (
                                        <li
                                            className={styles.participant}
                                            key={participant.studentId}
                                            onClick={() => {
                                                participant && handleParticipantButtonClick(participant);
                                            }}
                                        >
                                            <div className={styles.participantContentsWrapper}>
                                                <AvatarFromS3
                                                    className={styles.participantAvatar}
                                                    url={participant.iconImageUrl}
                                                    objectKey={participant.iconImageObjectKey}
                                                />
                                                <div
                                                    className={styles.participantNickname}
                                                    onClick={() => {
                                                        participant && handleParticipantButtonClick(participant);
                                                    }}
                                                >
                                                    {participant.nickname} さん
                                                </div>
                                                {participant.isWithdrawn && (
                                                    <div className={styles.isWithdrawn}>受講停止</div>
                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            {modalTargetStudent && (
                                <UserInfoModal
                                    openedTarget={modalTargetStudent}
                                    isChatVisible
                                    zIndex={1000}
                                    handleClose={handleParticipantInfoModalClose}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
            <div className={styles.scheduleWrapper}>
                <div className={styles.scheduleTitleWrapper}>
                    <MdSchedule className={styles.scheduleIcon} />
                    <div className={styles.scheduleTitle}>授業予定</div>
                    <Button className={styles.noLongerAvailableButton} onClick={handleNoLongerAvailableButtonClick}>
                        <AiOutlineQuestionCircle className={styles.questionIcon} />
                        <div className={styles.noLongerAvailable}>授業に参加できなくなった場合</div>
                    </Button>
                </div>
                {validLessons.length > 0 ? (
                    <ul
                        className={styles.lessons}
                        ref={lessonsRef}
                        style={{
                            overflowY: "auto",
                            maxHeight: "none",
                        }}
                    >
                        {validLessons.map((lesson) => {
                            return (
                                <li
                                    className={styles.lesson}
                                    key={lesson.lessonId}
                                    onClick={() => {
                                        handleLessonButtonClick(lesson);
                                    }}
                                >
                                    <div
                                        className={styles.time}
                                        style={{ pointerEvents: props.disableToJoin ? "none" : "auto" }}
                                    >
                                        {getLessonTime(lesson)}
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <div className={styles.noCourse}>授業予定はありません</div>
                )}
                {targetLesson && (
                    <JoinMeetingModal
                        joinMeetingModalOpen={targetLesson != undefined}
                        targetLesson={targetLesson}
                        handleJoinMeetingModalClose={handleJoinMeetingModalClose}
                    />
                )}
            </div>

            {props.classResponse.pricePerPayment ? (
                <div className={styles.feeWrapper}>
                    <div className={styles.feeTitleWrapper}>
                        <FeeIcon className={styles.feeIcon} />
                        <div className={styles.feeTitle}>授業料</div>
                    </div>
                    <div className={styles.fee}>
                        {props.classResponse.course?.courseType === "short" && "（授業全体で）"}¥
                        {new Intl.NumberFormat("ja-JP").format(props.classResponse.pricePerPayment)}
                        {props.classResponse.course?.courseType === "regular" && "/月"}
                    </div>
                </div>
            ) : (
                // 定期講座において支払い前の授業料が未定のとき
                <>
                    {props.classResponse.pricePerHour != undefined && (
                        <div className={styles.feeWrapper}>
                            <div className={styles.feeTitleWrapper}>
                                <FeeIcon className={styles.feeIcon} />
                                <div className={styles.feeTitle}>授業料（基準）</div>
                            </div>
                            <div className={styles.fee}>
                                ¥{new Intl.NumberFormat("ja-JP").format(props.classResponse.pricePerHour)}/時間
                            </div>
                        </div>
                    )}
                </>
            )}
            {props.classResponse.course.courseType === "regular" && (
                <div className={styles.withdrawWrapper}>
                    <div className={styles.withdrawDescription}>
                        依頼していた期間の授業が全て終了した場合、あるいは受講を取りやめたい場合は、下記のボタンから受講停止をしてください。
                        受講停止しない限り毎月の請求は継続されます。また、受講停止をすると日割計算によって一部の授業料が返金されます。
                    </div>
                    <PlainButton text="受講停止" redColor noHorizontalPadding handleClick={handleWithdrawButtonClick} />
                    <WithdrawFromClassModal
                        modalOpen={withdrawModalOpen}
                        targetClass={props.classResponse}
                        handleModalClose={handleWithdrawModalClose}
                    />
                </div>
            )}
        </div>
    );
});
