import { Button } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import {
    CreateBaseScheduleParams,
    CreateLessonRequestParams,
    LessonResponse,
    ProposalResponse,
} from "@/store/autogenApi";
import { StartDate } from "./items/StartDate";
import { Schedules } from "./items/Schedules";
import { PricePerHour } from "./items/PricePerHour";
import { Message } from "./items/Message";
import { Processing } from "@/components/Processing";

interface Props {
    message: string;
    messageValidation: boolean;
    pricePerHour: number;
    pricePerPayment: number;
    courseType: string;
    isChecked: boolean;
    startDate: Date | null;
    startDateValidation: boolean;
    confirmModalOpen?: boolean;
    proposalModalStep: number;
    isProcessing: boolean;
    hasError: boolean;
    isCompleted: boolean;
    existingLessons: LessonResponse[];
    lessons: CreateLessonRequestParams[];
    lessonsDuplicateValidation: boolean;
    otherCourseLessonDuplicateValidation: boolean;
    baseSchedules: CreateBaseScheduleParams[];
    targetProposal: ProposalResponse | undefined;
    handleBaseScheduleDeleteButtonClick: (idx: number) => void;
    handleBaseSchedulesChange: (newBaseSchedules: CreateBaseScheduleParams[]) => void;
    handleLessonsChange: (newLessons: CreateLessonRequestParams[]) => void;
    getUnit: () => "講座全体で" | "1時間あたり" | undefined;
    handleMessageChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handlePricePerHourChange: (
        e: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => void;
    handleStartDateChange: (date: Date | null) => void;
    handleNextButtonClick: () => void;
    setProposalModalStep: React.Dispatch<React.SetStateAction<number>>;
    handleClose: () => void;
    handleConfirmButtonClick: () => void;
}

export const ProposalModalContents: React.VFC<Props> = memo(function ProposalModalContents(props) {
    const [maxModalHeight, setMaxModalHeight] = useState<number>(0);

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const changeModalHeight = useCallback(() => {
        const innerHeight = window.innerHeight;
        setMaxModalHeight(innerHeight * 0.75);
    }, []);
    return (
        <div className={styles.modalContents} style={{ maxHeight: maxModalHeight }}>
            {props.hasError ? (
                <div className={styles.errorWrapper}>
                    <div className={styles.error}>処理中にエラーが発生しました。やり直してください。</div>
                    <Button className={styles.closeButton} onClick={props.handleClose}>
                        閉じる
                    </Button>
                </div>
            ) : (
                <>
                    {props.isCompleted ? (
                        <div className={styles.completeWrapper}>
                            <div className={styles.complete}>
                                提案が完了しました！生徒さんからの連絡をお待ちください。
                            </div>
                            <Button className={styles.closeButton} onClick={props.handleClose}>
                                閉じる
                            </Button>
                        </div>
                    ) : (
                        <>
                            {props.proposalModalStep === 1 ? (
                                <>
                                    <div className={styles.applicationTitle}>
                                        提案{props.targetProposal ? "更新" : "作成"}
                                    </div>
                                    <div className={styles.inputList}>
                                        <Schedules
                                            isChecked={props.isChecked}
                                            courseType={props.courseType}
                                            price={props.pricePerHour}
                                            lessons={props.lessons}
                                            existingLessons={props.existingLessons}
                                            baseSchedules={props.baseSchedules}
                                            handleBaseScheduleDeleteButtonClick={
                                                props.handleBaseScheduleDeleteButtonClick
                                            }
                                            handleBaseSchedulesChange={props.handleBaseSchedulesChange}
                                            handleLessonsChange={props.handleLessonsChange}
                                            lessonsDuplicateValidation={props.lessonsDuplicateValidation}
                                            otherCourseLessonDuplicateValidation={
                                                props.otherCourseLessonDuplicateValidation
                                            }
                                        />
                                        {((props.courseType === "short" && props.lessons.length >= 1) ||
                                            (props.courseType === "regular" && props.baseSchedules.length >= 1)) && (
                                            <PricePerHour
                                                courseType={props.courseType}
                                                isChecked={props.isChecked}
                                                lessons={props.lessons}
                                                baseSchedules={props.baseSchedules}
                                                pricePerHour={props.pricePerHour}
                                                pricePerPayment={props.pricePerPayment}
                                                handleChange={props.handlePricePerHourChange}
                                                getUnit={props.getUnit}
                                            />
                                        )}
                                        {props.courseType === "regular" && (
                                            <StartDate
                                                isChecked={props.isChecked}
                                                startDate={props.startDate}
                                                validation={props.startDateValidation}
                                                handleChange={props.handleStartDateChange}
                                            />
                                        )}
                                        <Message
                                            isChecked={props.isChecked}
                                            message={props.message}
                                            validation={props.messageValidation}
                                            handleChange={props.handleMessageChange}
                                        />
                                    </div>
                                    <div className={styles.buttonsWrapper}>
                                        <Button
                                            className={styles.backButton}
                                            onClick={() => {
                                                props.handleClose();
                                            }}
                                        >
                                            閉じる
                                        </Button>
                                        <Button className={styles.confirmButton} onClick={props.handleNextButtonClick}>
                                            確認
                                        </Button>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={styles.noticeMessage}>
                                        以下の内容で提案を確定してもよろしいですか？
                                    </div>
                                    <div className={styles.inputList}>
                                        <Schedules
                                            isConfirmMode
                                            isChecked={props.isChecked}
                                            courseType={props.courseType}
                                            handleBaseScheduleDeleteButtonClick={
                                                props.handleBaseScheduleDeleteButtonClick
                                            }
                                            handleBaseSchedulesChange={props.handleBaseSchedulesChange}
                                            handleLessonsChange={props.handleLessonsChange}
                                            baseSchedules={props.baseSchedules}
                                            price={props.pricePerHour}
                                            lessons={props.lessons}
                                            existingLessons={props.existingLessons}
                                            lessonsDuplicateValidation={props.lessonsDuplicateValidation}
                                            otherCourseLessonDuplicateValidation={
                                                props.otherCourseLessonDuplicateValidation
                                            }
                                        />
                                        <PricePerHour
                                            courseType={props.courseType}
                                            isChecked={props.isChecked}
                                            pricePerHour={props.pricePerHour}
                                            pricePerPayment={props.pricePerPayment}
                                            lessons={props.lessons}
                                            baseSchedules={props.baseSchedules}
                                            isConfirmMode
                                            handleChange={props.handlePricePerHourChange}
                                            getUnit={props.getUnit}
                                        />
                                        {props.courseType === "regular" && (
                                            <StartDate
                                                isChecked={props.isChecked}
                                                startDate={props.startDate}
                                                validation={props.startDateValidation}
                                                handleChange={props.handleStartDateChange}
                                                isConfirmMode
                                            />
                                        )}
                                        <Message
                                            isChecked={props.isChecked}
                                            message={props.message}
                                            validation={props.messageValidation}
                                            handleChange={props.handleMessageChange}
                                            isConfirmMode
                                        />
                                    </div>
                                    <div className={styles.buttonsWrapper}>
                                        <Button
                                            className={styles.backButton}
                                            onClick={() => {
                                                props.setProposalModalStep(1);
                                            }}
                                        >
                                            戻る
                                        </Button>
                                        <Button
                                            className={styles.confirmButton}
                                            onClick={props.handleConfirmButtonClick}
                                        >
                                            {props.isProcessing ? <Processing /> : "確定"}
                                        </Button>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    );
});
