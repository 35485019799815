import { memo, useMemo } from "react";
import { Box, Button as MuiButton } from "@mui/material";
import { Processing } from "../Processing";

export type ButtonColor = "navy" | "red";

interface Props {
    color: ButtonColor;
    colorInverted?: boolean;
    loading?: boolean;
    disabled?: boolean;
    centered?: boolean;
    hasBorder?: boolean;
    width?: string;
    size?: "xs" | "sm" | "md" | "lg" | "xl";
    sx?: React.CSSProperties;
    text?: string;
    icon?: React.ReactNode;
    children?: React.ReactNode;
    handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const Button: React.VFC<Props> = memo(function NavyButton(props) {
    const color = useMemo(() => {
        if (props.colorInverted) {
            switch (props.color) {
                case "navy":
                    return "#305077 !important";
                case "red":
                    return "#f50057 !important";
                default:
                    return "#305077 !important";
            }
        }
        return "#FFF !important";
    }, [props.color]);
    const backgroundColor = useMemo(() => {
        if (props.colorInverted) return "#FFF !important";
        switch (props.color) {
            case "navy":
                return "#305077 !important";
            case "red":
                return "#f50057 !important";
            default:
                return "#305077 !important";
        }
    }, [props.color]);
    const fontSize = useMemo(() => {
        switch (props.size) {
            case "xs":
                return "12px";
            case "sm":
                return "13px";
            case "md":
                return "14px";
            case "lg":
                return "15px";
            case "xl":
                return "16px";
            default:
                return "14px";
        }
    }, [props.size]);
    const borderSx: React.CSSProperties = useMemo(() => {
        if (!props.hasBorder) return {};
        return {
            border: `1px solid ${color}`,
            boxShadow: "none",
        };
    }, [color]);
    return (
        <MuiButton
            onClick={props.handleClick}
            disabled={props.loading || props.disabled}
            sx={{
                display: "block !important",
                border: "1px solid $mainColor",
                fontWeight: "bold",
                padding: "6px 8px",
                color,
                backgroundColor,
                margin: props.centered ? "0 auto" : "0",
                boxShadow: "0 2px 6px #AAA",
                "&:hover": {
                    opacity: "0.7 !important",
                    backgroundColor: backgroundColor,
                },
                "&:disabled": {
                    backgroundColor: "#AAA !important",
                },
                ...props.sx,
                ...borderSx,
            }}
        >
            {props.loading ? (
                <Processing />
            ) : props.text ? (
                props.icon ? (
                    <Box sx={{ fontSize, fontWeight: "bold", display: "flex", alignItems: "center", gap: "2px" }}>
                        {props.icon}
                        <Box sx={{ marginBottom: "1px" }}>{props.text}</Box>
                    </Box>
                ) : (
                    <Box sx={{ fontSize, fontWeight: "bold" }}>{props.text}</Box>
                )
            ) : (
                props.children
            )}
        </MuiButton>
    );
});
