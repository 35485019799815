import styles from "./index.module.scss";

import { memo, useCallback, useEffect, useState } from "react";
import { Modal } from "@/components/Modal";
import { ButtonPair } from "@/components/ButtonPair";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";
import { Processing } from "@/components/Processing";
import { CourseType } from "@/store/autogenApi";
import { useApplyForUpdateMonthlyFeeMutation } from "@/store/hooks/classes";
import { useApplyForTemporaryUpdateMonthlyFeeMutation } from "@/store/hooks/classes";
import { EditModeContents } from "./EditModeContents";
import { MutationState } from "@/store/hooks/utils/mutationFunction";
import { dateToString, getOnlyDate } from "@/utils/DateUtils";

interface Props {
    modalOpen: boolean;
    targetClassId: string;
    courseType: CourseType;
    pricePerPayment: number;
    handleModalClose: () => void;
}

export const UpdateMonthlyFeeModal: React.VFC<Props> = memo(function UpdateMonthlyFeeModal(props) {
    const [isConfirmMode, setIsConfirmMode] = useState<boolean>(false);
    const [newPricePerPayment, setNewPricePerPayment] = useState<number | undefined>(undefined);
    const [startDate, setStartDate] = useState<Date | undefined>(undefined);
    const [endDate, setEndDate] = useState<Date | undefined>(undefined);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isTemporaryMode, setIsTemporaryMode] = useState<boolean | undefined>(undefined);

    const applyForUpdateMonthlyFee = useApplyForUpdateMonthlyFeeMutation();
    const applyForTemporaryUpdateMonthlyFee = useApplyForTemporaryUpdateMonthlyFeeMutation();

    useEffect(() => {
        setNewPricePerPayment(props.pricePerPayment);
    }, [props.pricePerPayment]);

    const handleNewPricePerPaymentChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        const value = Number(e.target.value);
        setNewPricePerPayment(value);
    }, []);

    const handleModalClose = useCallback(() => {
        setIsConfirmMode(false);
        setNewPricePerPayment(undefined);
        setStartDate(undefined);
        setEndDate(undefined);
        setIsTemporaryMode(undefined);
        props.handleModalClose();
    }, [props.handleModalClose]);

    const handleConfirmButtonClick = useCallback(async () => {
        if (!newPricePerPayment) return;
        if (isTemporaryMode === undefined) return;
        if (!startDate) return;
        let result: MutationState<unknown>;
        if (isTemporaryMode) {
            if (!endDate) return;
            setIsProcessing(true);
            result = await applyForTemporaryUpdateMonthlyFee({
                applyForTemporaryUpdateMonthlyFeeRequestBody: {
                    classId: props.targetClassId,
                    monthlyFee: newPricePerPayment,
                    startDate: dateToString(startDate),
                    endDate: dateToString(endDate),
                },
            });
        } else {
            setIsProcessing(true);
            result = await applyForUpdateMonthlyFee({
                applyForUpdateMonthlyFeeRequestBody: {
                    classId: props.targetClassId,
                    monthlyFee: newPricePerPayment,
                    startDate: dateToString(startDate),
                },
            });
        }
        const isSuccess = result.isSuccess;
        setIsProcessing(false);
        if (isSuccess) {
            toast(<ToastContents title={`月額更新申請完了`} isCompleted />);
        } else {
            toast(<ToastContents title={`月額更新申請失敗`} isFailed />);
        }
        handleModalClose();
    }, [
        handleModalClose,
        newPricePerPayment,
        props.targetClassId,
        applyForUpdateMonthlyFee,
        applyForTemporaryUpdateMonthlyFee,
        isTemporaryMode,
        startDate,
        endDate,
    ]);

    const handleIsConfirmModeChange = useCallback((newIsConfirmMode: boolean) => {
        setIsConfirmMode(newIsConfirmMode);
    }, []);

    const handleStartDateChange = useCallback((newStartDate: Date | undefined) => {
        setStartDate(newStartDate);
    }, []);

    const handleEndDateChange = useCallback((newEndDate: Date | undefined) => {
        setEndDate(newEndDate);
    }, []);

    const handleIsTemporaryModeChange = useCallback((newIsTemporaryMode: boolean) => {
        setIsTemporaryMode(newIsTemporaryMode);
    }, []);

    return (
        <Modal open={props.modalOpen} onClose={handleModalClose} style={{ maxWidth: "400px" }}>
            <div className={styles.updateMonthlyFeeModalContents}>
                {isConfirmMode ? (
                    <div className={styles.confirmModeContents}>
                        <div className={styles.confirmMessage}>
                            生徒さんが承認した場合、授業料が更新されます。下記の内容で授業料の更新依頼を出してよろしいですか？
                        </div>
                        <div className={styles.middleWrapper}>
                            <div className={styles.confirmedPriceWrapper}>
                                <div className={styles.priceTitle}>新しい月額：</div>
                                <div className={styles.priceValue}>
                                    <span className={styles.yen}>￥</span>
                                    <span className={styles.confirmedPrice}>{newPricePerPayment}</span>
                                    <span className={styles.unit}>/月</span>
                                </div>
                            </div>
                            <div className={styles.startDate}>開始日：{getOnlyDate(startDate)}</div>
                            {isTemporaryMode && <div className={styles.endDate}>終了日：{getOnlyDate(endDate)}</div>}
                        </div>
                        <ButtonPair
                            buttonColor="navy"
                            loading={isProcessing}
                            leftButtonText="キャンセル"
                            rightButtonText="確定"
                            handleLeftButtonClick={handleModalClose}
                            handleRightButtonClick={handleConfirmButtonClick}
                        />
                    </div>
                ) : (
                    <EditModeContents
                        newPricePerPayment={newPricePerPayment}
                        startDate={startDate}
                        endDate={endDate}
                        isTemporaryMode={isTemporaryMode}
                        handleStartDateChange={handleStartDateChange}
                        handleEndDateChange={handleEndDateChange}
                        handleModalClose={handleModalClose}
                        handleIsConfirmModeChange={handleIsConfirmModeChange}
                        handleIsTemporaryModeChange={handleIsTemporaryModeChange}
                        handleNewPricePerPaymentChange={handleNewPricePerPaymentChange}
                    />
                )}
            </div>
        </Modal>
    );
});
