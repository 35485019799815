import React, { memo, useCallback, useEffect, useState } from "react";

import { useSelector } from "react-redux";
import "./App.css";

import { Footer } from "./components/Footer/index";
import { Header } from "./components/Header/index";
import styles from "./index.module.scss";
import { AboutRequest } from "./pages/Common/AboutRequest";
import { AboutTeacher } from "./pages/Common/AboutTeacher";
import { AboutTrail } from "./pages/Common/AboutTrail";
import { CourseDetails } from "./pages/Common/CourseDetails";
import { CourseGuidelines } from "./pages/Common/CourseGuidelines";
import { CreateStripeAccount } from "./pages/Common/CreateStripeAccount";
import { FaqTop } from "./pages/Common/FAQ";
import { FAQCategory } from "./pages/Common/FAQCategory";
import { FAQDetails } from "./pages/Common/FAQDetails";
import { FAQSearchResult } from "./pages/Common/FAQSearchResult";
import { Inquiry } from "./pages/Common/Inquiry";
import { PrivacyPolicy } from "./pages/Common/PrivacyPolicy";
import { SignUp } from "./pages/Common/SignUp";
import { RequestDetails } from "./pages/Common/PublicRequestDetails";
import { ReviewSubmissionGuidelines } from "./pages/Common/ReviewSubmissionGuidelines";
import { Reviews } from "./pages/Common/Reviews";
import { SignIn } from "./pages/Common/SignIn";
import { TeacherDetails } from "./pages/Common/TeacherDetails";
import { TeacherGuidelines } from "./pages/Common/TeacherGuidelines";
import { TermsOfService } from "./pages/Common/TermsOfService";
import { TopPage } from "./pages/Common/TopPage";
import { Withdrawal } from "./pages/Common/Withdrawal";
import { CreatePublicRequest } from "./pages/Student/CreatePublicRequest";
import { InfoInput } from "./pages/Student/InfoInput";
import { Class } from "./pages/Student/Class";
import { Profile } from "./pages/Student/MyPage/Profile";
import { Announcements } from "./pages/Student/MyPage/Announcements";
import { MyPublicRequests } from "./pages/Student/MyPage/MyPublicRequests";
import { Favorite } from "./pages/Student/MyPage/Favorite";
import { History } from "./pages/Student/MyPage/History";
import { ChatsComponent } from "./pages/Student/MyPage/Chats";
import { Setting } from "./pages/Student/MyPage/Setting";
import { PaymentSetting } from "./pages/Student/MyPage/PaymentSetting";
import { PointsRecordHistory } from "./pages/Student/MyPage/PointsRecordHistory";
import { ReservedCourses } from "./pages/Student/MyPage/ReservedCourses";
import { LessonCalendar } from "./pages/Student/MyPage/LessonCalendar";
import { Payment } from "./pages/Student/Payment";
import { PointsCharge } from "./pages/Student/PointsCharge";
import { CourseManagement } from "./pages/Teacher/CourseManagement";
import { CreateCourse } from "./pages/Teacher/CreateCourse";
import { TeacherIdentification } from "./pages/Teacher/Identification";
import { TeacherInfoInput } from "./pages/Teacher/InfoInput";
import { TeacherProfile } from "./pages/Teacher/MyPage/Profile";
import { TeacherAnnouncements } from "./pages/Teacher/MyPage/Announcements";
import { ApplicationForTransfer } from "./pages/Teacher/MyPage/ApplicationForTransfer";
import { BankAccountSetting } from "./pages/Teacher/MyPage/BankAccountSetting";
import { MyCourses } from "./pages/Teacher/MyPage/MyCourses";
import { MyProposals } from "./pages/Teacher/MyPage/MyProposals";
import { TeacherFavorite } from "./pages/Teacher/MyPage/Favorite";
import { TeacherHistory } from "./pages/Teacher/MyPage/History";
import { TeacherChats } from "./pages/Teacher/MyPage/Chats";
import { TeacherSetting } from "./pages/Teacher/MyPage/Setting";
import { TeacherPointsRecordHistory } from "./pages/Teacher/MyPage/PointsRecordHistory";
import { TeacherLessonCalendar } from "./pages/Teacher/MyPage/LessonCalendar";
import { ZoomAuthenticating } from "./pages/Teacher/ZoomAuthenticating";
import { Forbidden } from "./protectedRouter/Forbidden";
import { ProtectedBrowserRouter, ProtectedSwitch, ProtectedRoute } from "./protectedRouter/ProtectedRouter";
import { RootState } from "./ducks";
import { LinkingZoomAccount } from "./pages/Teacher/LinkingZoomAccount";
import { CognitoUserAuthenticator } from "./components/Authentication";
import { PaymentForProposal } from "./pages/Student/PaymentForProposal";
import { IdentificationForStripe } from "./pages/Teacher/IdentificationForStripe";
import { SearchResults } from "./pages/Common/SearchResults";
import { useMediaQuery } from "@material-ui/core";
import { CompletedWithdrawal } from "./pages/Common/CompletedWithdrawal";
import { ToastContainer } from "react-toastify";
import { onForegroundMessage } from "./InitializeFirebase";

import "react-toastify/dist/ReactToastify.css";
import { MessagePayload } from "firebase/messaging";
import { useInvalidateTagsMutation } from "./store/hooks/invalidateTags";
import { ExternalTransmission } from "./pages/Common/ExternalTransmission";
import { MyDraftCourses } from "./pages/Teacher/MyPage/MyDraftCourses";
import { MyDraftPublicRequests } from "./pages/Student/MyPage/MyDraftPublicRequests";
import { NavigationProvider } from "./components/Navigation/NavigationContext";
import { ProceedsRecords } from "./pages/Teacher/MyPage/ProceedsRecords";
import { News } from "./pages/Common/News";
import { NewsList } from "./pages/Common/NewsList";
import { ThanksPayment } from "./pages/Student/ThanksPayment";
import { ThanksSignUp } from "./pages/Common/ThanksSignUp";

export const App: React.VFC = memo(function App() {
    const [currentBodyEl, setCurrentBodyEl] = useState<HTMLElement | null>(null);
    const invalidateTag = useInvalidateTagsMutation();

    useEffect(() => {
        (async () => {
            try {
                const payload = (await onForegroundMessage()) as MessagePayload;
                // if (!payload.notification) return;
                // const {
                //     notification: { title, body },
                // } = payload;
                // if (!title || !body) return;
                // toast(<ToastContents title={title} body={body} />);
                const { data } = payload;
                if (!data) return;
                const targetAccountType = data.targetAccountType;
                if (!targetAccountType) return;
                if (targetAccountType === "student") {
                    await invalidateTag({
                        targetAccountType: "student",
                    });
                } else if (targetAccountType === "teacher") {
                    await invalidateTag({
                        targetAccountType: "teacher",
                    });
                }
            } catch (err) {
                console.log("An error occured while retrieving foreground message. ", err);
            }
        })();
    }, []);

    const smUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:960px)");

    useEffect(() => {
        const body = document.getElementById("body");
        if (!body || typeof body.style.paddingBottom !== "string") return;
        const pathName = window.location.pathname;
        const currentPaddingBottom = parseInt(body.style.paddingBottom);
        const onResize = () => {
            const header = document.getElementById("header");
            const footer = document.getElementById("footer");
            if (!header || !footer) return;
            const headerHeight = header.offsetHeight;
            const footerHeight = footer.offsetHeight;
            const viewHeight = window.innerHeight;
            const minHeight = viewHeight - (headerHeight + footerHeight);
            body.style.minHeight = `${minHeight}px`;
        };
        const undoResize = () => {
            if (!currentPaddingBottom) return;
            body.style.paddingBottom = `${currentPaddingBottom}px`;
        };
        onResize();
        window.addEventListener("resize", onResize);
        return () => {
            window.removeEventListener("resize", onResize);
            undoResize();
        };
    }, [smUp, mdUp]);

    const target = useSelector((state: RootState) => state.jwt.userMode);

    const bodyRef = useCallback((BodyEl: HTMLElement | null) => {
        setCurrentBodyEl(BodyEl);
    }, []);
    return (
        <div className="App">
            <ProtectedBrowserRouter target={target}>
                <CognitoUserAuthenticator>
                    <NavigationProvider>
                        <Header currentBodyEl={currentBodyEl} />
                        <div className={styles.background}>
                            <div className={styles.body} id="body" ref={bodyRef}>
                                <ProtectedSwitch>
                                    <ProtectedRoute exact path="/" component={TopPage} modes={["everyone"]} />
                                    <ProtectedRoute path="/SignUp" component={SignUp} modes={["anonymous"]} />
                                    <ProtectedRoute path="/SignIn" component={SignIn} modes={["everyone"]} />
                                    <ProtectedRoute
                                        path="/InfoInput"
                                        component={InfoInput}
                                        modes={["anonymous"]}
                                        strict
                                    />
                                    <ProtectedRoute
                                        path="/ThanksSignUp"
                                        component={ThanksSignUp}
                                        modes={["anonymous"]}
                                        strict
                                    />
                                    <ProtectedRoute
                                        path="/SearchResults"
                                        component={SearchResults}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute path="/News/:newsRecordId" component={News} modes={["everyone"]} />
                                    <ProtectedRoute path="/NewsList" component={NewsList} modes={["everyone"]} />
                                    <ProtectedRoute
                                        path="/AboutTeacher"
                                        component={AboutTeacher}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute path="/AboutTrail" component={AboutTrail} modes={["everyone"]} />
                                    <ProtectedRoute
                                        path="/AboutRequest"
                                        component={AboutRequest}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute path="/MyPage/Profile" component={Profile} modes={["student"]} />
                                    <ProtectedRoute path="/MyPage/History" component={History} modes={["student"]} />
                                    <ProtectedRoute path="/MyPage/Favorite" component={Favorite} modes={["student"]} />
                                    <ProtectedRoute
                                        path="/MyPage/Chats"
                                        component={ChatsComponent}
                                        modes={["student"]}
                                    />
                                    <ProtectedRoute
                                        path="/MyPage/PointsRecordHistory"
                                        component={PointsRecordHistory}
                                        modes={["student"]}
                                    />
                                    <ProtectedRoute
                                        path="/MyPage/Announcements/:announcementId?"
                                        component={Announcements}
                                        modes={["student"]}
                                    />
                                    <ProtectedRoute
                                        path="/MyPage/MyPublicRequests"
                                        component={MyPublicRequests}
                                        modes={["student"]}
                                    />
                                    <ProtectedRoute
                                        path="/MyPage/MyDraftPublicRequests"
                                        component={MyDraftPublicRequests}
                                        modes={["student"]}
                                    />
                                    <ProtectedRoute
                                        path="/MyPage/ReservedCourses"
                                        component={ReservedCourses}
                                        modes={["student"]}
                                    />
                                    <ProtectedRoute
                                        path="/MyPage/LessonCalendar"
                                        component={LessonCalendar}
                                        modes={["student"]}
                                    />
                                    <ProtectedRoute
                                        path="/MyPage/PaymentSetting"
                                        component={PaymentSetting}
                                        modes={["student"]}
                                    />
                                    <ProtectedRoute path="/MyPage/Setting" component={Setting} modes={["student"]} />
                                    <ProtectedRoute
                                        path="/CourseDetails/:id"
                                        component={CourseDetails}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute
                                        path="/PublicRequestDetails/:id"
                                        component={RequestDetails}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute
                                        path="/PointsRecordHistory/:id"
                                        component={RequestDetails}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/Teacher/Identification"
                                        component={TeacherIdentification}
                                        modes={["teacher"]}
                                        strict
                                    />
                                    <ProtectedRoute
                                        exact
                                        path="/IdentificationForStripe"
                                        component={IdentificationForStripe}
                                        modes={["teacher"]}
                                        strict
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/InfoInput"
                                        component={TeacherInfoInput}
                                        modes={["anonymous"]}
                                        strict
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/Profile"
                                        component={TeacherProfile}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/History"
                                        component={TeacherHistory}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/Favorite"
                                        component={TeacherFavorite}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/Chats"
                                        component={TeacherChats}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/Announcements/:announcementId?"
                                        component={TeacherAnnouncements}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/Setting"
                                        component={TeacherSetting}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/CreateCourse/:currentCourseId?"
                                        component={CreateCourse}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/MyCourses"
                                        component={MyCourses}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/MyProposals"
                                        component={MyProposals}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/MyDraftCourses"
                                        component={MyDraftCourses}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/BankAccountSetting"
                                        component={BankAccountSetting}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/ApplicationForTransfer"
                                        component={ApplicationForTransfer}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/ProceedsRecords"
                                        component={ProceedsRecords}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/PointsRecordHistory"
                                        component={TeacherPointsRecordHistory}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/Teacher/MyPage/LessonCalendar"
                                        component={TeacherLessonCalendar}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/CourseManagement/:courseId/:classId?"
                                        component={CourseManagement}
                                        modes={["teacher"]}
                                    />
                                    <ProtectedRoute path="/Forbidden" component={Forbidden} modes={["everyone"]} />
                                    <ProtectedRoute
                                        path="/TeacherDetails/:teacherId"
                                        component={TeacherDetails}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute path="/FAQ" component={FaqTop} modes={["everyone"]} />
                                    <ProtectedRoute path="/FAQCategory" component={FAQCategory} modes={["everyone"]} />
                                    <ProtectedRoute path="/FAQDetails" component={FAQDetails} modes={["everyone"]} />
                                    <ProtectedRoute
                                        path="/FAQSearchResult"
                                        component={FAQSearchResult}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute path="/Inquiry" component={Inquiry} modes={["everyone"]} />
                                    {/* <ProtectedRoute
                                path="/AnnouncementDetail/Campaign20210828"
                                component={Campaign20210828}
                                modes={["everyone"]}
                            /> */}
                                    <ProtectedRoute
                                        path="/CreatePublicRequest/:currentRequestId?"
                                        component={CreatePublicRequest}
                                        modes={["anonymous", "student"]}
                                        strict
                                    />
                                    <ProtectedRoute
                                        path={"/Payment/:classId"}
                                        component={Payment}
                                        modes={["student"]}
                                        strict
                                    />
                                    <ProtectedRoute
                                        path={"/PaymentForProposal/:proposalId"}
                                        component={PaymentForProposal}
                                        modes={["student"]}
                                        strict
                                    />
                                    <ProtectedRoute
                                        path={"/ThanksPayment"}
                                        component={ThanksPayment}
                                        modes={["student"]}
                                        strict
                                    />
                                    <ProtectedRoute
                                        path={"/createStripeAccount"}
                                        component={CreateStripeAccount}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute
                                        path="/TermsOfService"
                                        component={TermsOfService}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute
                                        path="/ExternalTransmission"
                                        component={ExternalTransmission}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute
                                        path="/PrivacyPolicy"
                                        component={PrivacyPolicy}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute
                                        path="/ReviewSubmissionGuidelines"
                                        component={ReviewSubmissionGuidelines}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute
                                        path="/Reviews/:courseId"
                                        component={Reviews}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute
                                        path="/CourseGuidelines"
                                        component={CourseGuidelines}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute
                                        path="/TeacherGuidelines"
                                        component={TeacherGuidelines}
                                        modes={["everyone"]}
                                    />
                                    <ProtectedRoute
                                        path="/PointsCharge/:necessaryAmount?"
                                        component={PointsCharge}
                                        modes={["student"]}
                                    />
                                    <ProtectedRoute
                                        path="/ZoomAuthenticating"
                                        component={ZoomAuthenticating}
                                        modes={["anonymous"]}
                                    />
                                    <ProtectedRoute
                                        path="/Class/:classId"
                                        component={Class}
                                        modes={["student", "teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/LinkZoomAccount"
                                        component={LinkingZoomAccount}
                                        modes={["teacher"]}
                                        strict
                                    />
                                    <ProtectedRoute
                                        path="/Withdrawal"
                                        component={Withdrawal}
                                        modes={["student", "teacher"]}
                                    />
                                    <ProtectedRoute
                                        path="/CompletedWithdrawal"
                                        component={CompletedWithdrawal}
                                        modes={["everyone"]}
                                        strict
                                    />
                                </ProtectedSwitch>
                            </div>
                        </div>
                        <Footer />
                        <ToastContainer
                            position="top-right"
                            autoClose={5000}
                            hideProgressBar
                            newestOnTop={false}
                            closeOnClick
                            rtl={false}
                            pauseOnFocusLoss
                            draggable
                            pauseOnHover
                            theme="light"
                        />
                    </NavigationProvider>
                </CognitoUserAuthenticator>
            </ProtectedBrowserRouter>
        </div>
    );
});

export default App;
