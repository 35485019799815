import styles from "@/pages/Common/LP/index.module.scss";
import businessContestImage from "@/images/tongari.jpg";
import JFCImage from "@/images/JFC.png";
import newspaperImage from "@/images/newspaper.jpg";
import { Bold } from "@/components/atoms/Bold";
import { Box, useMediaQuery } from "@mui/material";
import { useCallback, useState } from "react";
import { Modal } from "@/components/Modal";
import { ClickableButton } from "../../LP/ClickableButton";

export const Achievements: React.VFC = () => {
    const [newspaperModalOpen, setNewspaperModalOpen] = useState<boolean>(false);
    const smUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:960px)");
    const handleNewspaperClick = useCallback(() => {
        if (!smUp) return;
        setNewspaperModalOpen(true);
    }, [smUp]);
    const handleModalClose = useCallback(() => {
        setNewspaperModalOpen(false);
    }, []);
    return (
        <div className={styles.achievementsWrapper}>
            <div className={styles.achievementsTitle}>実績</div>
            <div className={styles.achievementsDescription}>外部から多くの応援をいただいております。</div>
            <div className={styles.achievements}>
                <div className={styles.achievement}>
                    <div className={styles.achievementDescription}>
                        <Bold text="朝日新聞" />
                        様に取材いただきました！
                    </div>
                    <Box sx={{ position: "relative", maxWidth: "400px", margin: "0 auto" }}>
                        <img src={newspaperImage} className={styles.newspaperImage} onClick={handleNewspaperClick} />
                        {smUp && <ClickableButton handleClick={handleNewspaperClick} />}
                    </Box>
                    <Modal
                        open={newspaperModalOpen}
                        existCloseButton
                        autoResize
                        fade
                        style={{ borderRadius: "30px", padding: "0 20px" }}
                        onClose={handleModalClose}
                    >
                        <Box
                            component="img"
                            src={newspaperImage}
                            sx={{
                                display: "block",
                                width: { sm: "100%", md: "450px" },
                                margin: "20px auto",
                            }}
                        />
                    </Modal>
                </div>
                <div className={styles.achievement}>
                    <div className={styles.achievementDescription}>
                        <Bold text="日本政策金融公庫" />
                        様よりご融資を実行いただきました！
                    </div>
                    <img
                        src={JFCImage}
                        className={styles.achievementImage}
                        style={{ width: mdUp ? "80%" : smUp ? "40%" : "50%", margin: "10px auto 0" }}
                    />
                </div>
                <div className={styles.achievement}>
                    <div className={styles.achievementDescription}>
                        <Bold text="名古屋大学" />
                        主催の{mdUp && <br />}ビジネスプランコンテストで
                        <br />
                        <Bold text="大和証券賞" />
                        を受賞！
                    </div>
                    <img src={businessContestImage} className={styles.achievementImage} />
                </div>
            </div>
        </div>
    );
};
