import { memo, useCallback, useEffect } from "react";
import { useState } from "react";

import { Button, createTheme, Step, StepLabel, Stepper, ThemeProvider } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { logout } from "@/actions/logging";
import { RouterPrompt } from "@/components/RouterPrompt";
import { ProfileInput } from "@/pageComponents/Common/InfoInput/ProfileInput";
import { StudentProfileInput } from "@/pageComponents/Student/InfoInput/StudentInfoInput";
import { CreateStudentRequestBody, JwtRequestBody } from "@/store/autogenApi";

import styles from "./index.module.scss";
import { RootState } from "../../../ducks";

import { Auth } from "aws-amplify";
import { decodeJwt } from "@/ducks/jwt";
import { useNavigation } from "@/components/Navigation/NavigationContext";

const STEPS = [
    // "Zoom連携",
    "ユーザー情報（共通）入力",
    "生徒情報入力",
];

const theme = createTheme({
    overrides: {
        MuiStepper: {
            root: {
                padding: "10px 0",
                backgroundColor: "transparent",
            },
        },
        MuiStepIcon: {
            active: {
                color: "#305077 !important",
            },
            completed: {
                color: "#305077 !important",
            },
        },
        MuiStepLabel: {
            label: {
                color: "#305077 !important",
                fontWeight: "bold !important" as "bold",
                fontSize: "16px",
                "@media (max-width: 960px)": {
                    fontSize: "14px",
                },
                "@media (max-width: 600px)": {
                    fontSize: "14px",
                },
            },
        },
        MuiTypography: {
            body1: {
                fontSize: "16px",
                "@media (max-width: 960px)": {
                    fontSize: "14px",
                },
                "@media (max-width: 600px)": {
                    fontSize: "14px",
                },
            },
        },
        MuiSvgIcon: {
            root: {
                fontSize: "30px",
                "@media (max-width: 960px)": {
                    fontSize: "25px",
                },
                "@media (max-width: 600px)": {
                    fontSize: "25px",
                },
            },
        },
    },
});

export const InfoInput: React.VFC = memo(function InfoInput() {
    const [hasError, setHasError] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [createStudentRequestBody, setCreateStudentRequestBody] = useState<
        CreateStudentRequestBody["studentBody"] | undefined
    >(undefined);
    const [createUserRequestBody, setCreateUserRequestBody] = useState<
        CreateStudentRequestBody["userBody"] | undefined
    >(undefined);

    const userId = useSelector((state: RootState) => state.jwt.userId as string);

    const history = useHistory();

    useEffect(() => {
        if (!isCompleted) return;
        history.push("/ThanksSignUp?userType=student");
    }, [isCompleted]);

    useEffect(() => {
        if (!hasError) return;
        history.push("/SignUp");
    }, [hasError]);

    const dispatch = useDispatch();

    const { updateNavigationList } = useNavigation();

    const deleteUser = useCallback(async () => {
        const token = localStorage.getItem("token");
        const jwtRequestBody = decodeJwt(token) as JwtRequestBody;
        const userId = jwtRequestBody.userId;
        if (!userId) return;
        await Auth.deleteUser();
        await Auth.signOut();
        dispatch(logout());
        localStorage.clear();
    }, [dispatch]);

    useEffect(() => {
        updateNavigationList([]);
        const beforeUnloadFunc = async (e: BeforeUnloadEvent) => {
            e.preventDefault();
            e.returnValue = "";
        };
        window.addEventListener("beforeunload", beforeUnloadFunc);

        return () => {
            window.removeEventListener("beforeunload", beforeUnloadFunc);
        };
    }, []);

    useEffect(() => {
        return () => {
            deleteUser();
        };
    }, [deleteUser]);

    const handleStudentParamsChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement | { name?: string | undefined; value: unknown }>) => {
            if (e.target.name) {
                const name = e.target.name;
                const value = e.target.value as string;
                setCreateStudentRequestBody({
                    ...(createStudentRequestBody as CreateStudentRequestBody["studentBody"]),
                    [name]: value,
                });
            }
        },
        [createStudentRequestBody],
    );

    const nextStep = useCallback(() => {
        setActiveStep(activeStep + 1);
        window.scrollTo({
            top: 0,
            behavior: "auto",
        });
    }, [activeStep]);

    const previousStep = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep]);

    const handleOKButtonClick = useCallback(() => {
        (async () => {
            if (userId) {
                await Auth.deleteUser();
                dispatch(logout());
                localStorage.clear();
            }
        })();
        return true;
    }, [userId]);

    const handleCancelButtonClick = useCallback(() => {
        return false;
    }, []);

    const handleHasErrorChange = useCallback((hasError: boolean) => {
        setHasError(hasError);
    }, []);

    return (
        <>
            <RouterPrompt
                when={!hasError && !isCompleted}
                message={
                    "新規登録が未完了です。\n現在の入力内容及びアカウントデータは失われますが、このページを終了してもよろしいですか？"
                }
                onOK={handleOKButtonClick}
                onCancel={handleCancelButtonClick}
            />
            <div className={styles.page}>
                <div className={styles.contents}>
                    <div className={styles.signUpTitle}>生徒登録</div>
                    <div className={styles.stepperWrapper}>
                        <ThemeProvider theme={theme}>
                            <Stepper alternativeLabel activeStep={activeStep}>
                                {STEPS.map((label) => (
                                    <Step key={label}>
                                        <StepLabel>{label}</StepLabel>
                                    </Step>
                                ))}
                            </Stepper>
                        </ThemeProvider>
                        <Button
                            onClick={previousStep}
                            className={styles.previousButton}
                            style={{ display: activeStep === 0 ? "none" : "" }}
                        >
                            「{STEPS[activeStep - 1]}」へ戻る
                        </Button>
                    </div>
                    <div className={styles.stepperContents}>
                        {/* {activeStep === 100 && (
                                            <LinkingZoomAccount
                                                processing={processing}
                                                isAuthenticated={isAuthenticated}
                                                nextStep={nextStep}
                                            />
                                        )} */}
                        {activeStep === 0 && (
                            <ProfileInput
                                createUserRequestBody={createUserRequestBody}
                                setCreateUserRequestBody={setCreateUserRequestBody}
                                nextStep={nextStep}
                            />
                        )}
                        {activeStep === 1 && (
                            <StudentProfileInput
                                userId={userId}
                                createStudentRequestBody={createStudentRequestBody}
                                createUserRequestBody={createUserRequestBody}
                                deleteUser={deleteUser}
                                handleStudentParamsChange={handleStudentParamsChange}
                                handleHasErrorChange={handleHasErrorChange}
                                setIsCompleted={setIsCompleted}
                            />
                        )}
                    </div>
                </div>
            </div>
        </>
    );
});
