import { memo, useCallback, useEffect } from "react";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import styles from "@/pages/Teacher/CreateCourse/index.module.scss";
import { Mandatory } from "@/components/Tag/Mandatory";
import { ErrorMessage } from "@/components/ErrorMessage";

interface Props {
    value: string;
    isChecked: boolean;
    validation: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    courseType: string;
}

export const ContentDescription: React.VFC<Props> = memo(function ContentDescription(props) {
    const fixPlaceholder = useCallback(() => {
        const element: HTMLInputElement | null = document.getElementById("descriptionTextArea") as HTMLInputElement;
        if (element) {
            element.placeholder = element.placeholder.replace(/\\n/g, "\n");
        }
    }, []);

    useEffect(() => {
        fixPlaceholder();
    }, []);

    const getExample = useCallback(() => {
        if (props.courseType === "short") {
            return "例）微積分は難しそうに思えますが、コツさえ掴んでしまえば得点源にできる分野です。大学受験の経験を元に微積分の肝をわかりやすく解説します。...";
        } else if (props.courseType === "regular") {
            return "例）生徒さんにヒアリングを行った上で最適な授業内容を考えていきます。また、成績の管理も行うため、伸び悩んでいる分野を重点的に補強することができます。...";
        } else {
            return "例）誰もが一度は悩む、進路選びのポイントをお伝えします。また、効率的な勉強方法についても解説します。...";
        }
    }, [props.courseType]);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>講座内容の詳細</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value}</div>
                ) : (
                    <>
                        <TextareaAutosize
                            required
                            placeholder={`講座内容の詳細(必須1,000字以内)\n\n${getExample()}`}
                            className={styles.textArea}
                            name="contentDescription"
                            onChange={props.handleChange}
                            minRows={7}
                            id="descriptionTextArea"
                            value={props.value}
                        />
                        <div className={styles.inputFeedbackContainer}>
                            <ErrorMessage
                                when={props.isChecked && !props.validation}
                                content=" この項目は必須です。1000字以内で入力してください。"
                                style={{ marginRight: "auto", marginLeft: "0" }}
                            />
                            <div className={styles.textLength}>{props.value.length}/1000</div>
                        </div>
                    </>
                )}
            </div>
        </li>
    );
});
