import { CreateCourseRequestBody, CreateClassRequestBody, UpdateCourseRequestBody } from "@/store/autogenApi";

export type ConfigKey =
    | keyof (CreateCourseRequestBody | UpdateCourseRequestBody)
    | keyof CreateClassRequestBody
    | "applicationPeriod"
    | "howManyDays"
    | "price";

type Config = Record<ConfigKey, number[]>;

export const config: Partial<Config> = {
    title: [1, 30],
    teachingMaterial: [1, 1000],
    courseDescription: [1, 1000],
    targetDescription: [1, 40],
    additionalDescription: [0, 300],
    maxParticipants: [1, 20],
    minParticipants: [1, 10],
    maxMinutesPerLesson: [5, 300],
    maxDaysInAWeek: [1, 7],
    applicationPeriod: [1, 10],
    howManyDays: [1, 10],
};
