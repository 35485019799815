import { TextField } from "@material-ui/core";
import { memo, useCallback, useState } from "react";
import { ListItem } from ".";
import { ErrorMessage } from "@/components/ErrorMessage";
import styles from "../index.module.scss";
import { AboutFeeModal } from "@/components/AboutFeeModal";
import { PlainButton } from "@/components/PlainButton";
import { getHowMuchTime } from "@/utils/ClassUtils";
import { CreateBaseScheduleParams, CreateLessonRequestParams } from "@/store/autogenApi";
import { getTotalTimeInAMonth } from "@/utils/BaseScheduleUtils";

interface Props {
    courseType: string;
    pricePerHour: number;
    pricePerPayment: number;
    lessons: CreateLessonRequestParams[];
    baseSchedules: CreateBaseScheduleParams[];
    isChecked: boolean;
    isConfirmMode?: boolean;
    getUnit: () => "講座全体で" | "1時間あたり" | undefined;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
}

const MIN_PRICE_PER_HOUR = 1000;
const MAX_PRICE_PER_HOUR = 100000;

export const PricePerHour: React.VFC<Props> = memo(function PricePerHour(props) {
    const [aboutFeeModalOpen, setAboutFeeModalOpen] = useState<boolean>(false);
    const handleClick = useCallback(() => {
        setAboutFeeModalOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setAboutFeeModalOpen(false);
    }, []);
    return (
        <ListItem title="時間単価" isConfirmMode={!!props.isConfirmMode}>
            <>
                {props.isConfirmMode ? (
                    <div className={styles.isConfirmModeTrue}>{`¥${new Intl.NumberFormat("ja-JP").format(
                        props.pricePerHour,
                    )}`}</div>
                ) : (
                    <>
                        <div className={styles.priceInputWrapper}>
                            <div className={styles.price}>
                                <span className={styles.yen}>￥</span>
                                <TextField
                                    variant="outlined"
                                    required
                                    placeholder="0"
                                    fullWidth
                                    margin="normal"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    className={styles.textField}
                                    onChange={props.handleChange}
                                    type="number"
                                    value={props.pricePerHour ? props.pricePerHour : ""}
                                    size="small"
                                />
                            </div>
                            <div className={styles.range}>（￥1,000 ~ ￥100,000）</div>
                        </div>
                        <div className={styles.priceInfoWrapper}>
                            <div className={styles.howManyMinutesWrapper}>
                                <div className={styles.howManyMinutesTitle}>合計授業時間：</div>
                                <div className={styles.howManyMinutes}>
                                    {props.courseType === "short"
                                        ? getHowMuchTime(props.lessons)
                                        : getTotalTimeInAMonth(props.baseSchedules)}
                                </div>
                            </div>
                            <div className={styles.pricePerPaymentWrapper}>
                                <div className={styles.pricePerPaymentTitle}>講座全体で：</div>
                                <div className={styles.pricePerPayment}>
                                    {`¥${new Intl.NumberFormat("ja-JP").format(props.pricePerPayment)}`}
                                </div>
                            </div>
                        </div>
                        <PlainButton
                            text="手数料について確認"
                            handleClick={handleClick}
                            className={styles.aboutFeeButton}
                        />
                        <AboutFeeModal open={aboutFeeModalOpen} handleClose={handleClose} />
                        <ErrorMessage content="入力してください。" when={props.isChecked && props.pricePerHour === 0} />
                        <ErrorMessage
                            content="1000以上100000以下で入力してください。"
                            when={
                                props.isChecked &&
                                (props.pricePerHour < MIN_PRICE_PER_HOUR || MAX_PRICE_PER_HOUR < props.pricePerHour)
                            }
                        />
                    </>
                )}
            </>
        </ListItem>
    );
});
