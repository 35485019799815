import { memo } from "react";

import styles from "./index.module.scss";

interface Props {
    contentDescription: string;
}

export const ContentDescription: React.VFC<Props> = memo(function ContentDescription(props) {
    return (
        <>
            <section className={styles.contentDescriptionWrapper}>
                <h2 className={styles.contentDescriptionTitle}>こんなことを教えています</h2>
                <div className={styles.contentDescription}>{props.contentDescription}</div>
            </section>
        </>
    );
});
