import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { TeacherCard } from "@/components/TeacherCard";
import { TeacherDetailInfoModal } from "@/components/TeacherDetailInfoModal";
import { ClickableHandIcon } from "@/globalIcons";
import styles from "@/pages/Common/LP/index.module.scss";
import { PublicTeacherResponse } from "@/store/autogenApi";
import { useGetLatestTeachersQuery } from "@/store/hooks/teachers";
import { Box, useMediaQuery } from "@mui/material";
import { memo, useCallback, useMemo, useState } from "react";

export const Teachers: React.VFC = memo(function Teachers() {
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [openedTeacher, setOpenedTeacher] = useState<PublicTeacherResponse | undefined>(undefined);

    const smUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:960px)");

    const rowNumber = useMemo<number>(() => (mdUp ? 3 : smUp ? 4 : 5), [smUp, mdUp]);
    const columnNumber = useMemo<number>(() => (mdUp ? 5 : smUp ? 4 : 3), [smUp, mdUp]);

    const teachersQueryState = useGetLatestTeachersQuery();

    const handleTeacherCardClick = useCallback((teacher: PublicTeacherResponse) => {
        setOpenedTeacher(teacher);
        setModalOpen(true);
    }, []);

    const handleModalClose = useCallback(() => {
        setModalOpen(false);
    }, []);
    return (
        <div className={styles.teachersWrapper}>
            <div className={styles.teachersTitle}>こんな先生が在籍しています！</div>
            <Box
                sx={{
                    borderRadius: "8px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "4px",
                    marginLeft: "auto",
                }}
            >
                <Box
                    sx={{
                        color: "#333",
                        fontSize: "20px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                    }}
                >
                    <ClickableHandIcon />
                </Box>
                <Box
                    sx={{
                        color: "#333",
                        fontSize: "12px",
                        fontWeight: "bold",
                    }}
                >
                    クリックで詳細を確認！
                </Box>
            </Box>
            <QueryLoadingWrapper {...teachersQueryState}>
                {(teachers) => {
                    const filteredTeachers = teachers
                        .filter((teacher) => teacher.iconImageUrl)
                        .slice(0, rowNumber * columnNumber);

                    return (
                        <div className={styles.teachers}>
                            {[...Array(rowNumber)].map((_, i) => {
                                const firstTeacher = filteredTeachers[i * columnNumber];
                                if (!firstTeacher) return null;
                                return (
                                    <div className={styles.teacherRow} key={i}>
                                        {[...Array(columnNumber)].map((_, j) => {
                                            const idx = i * columnNumber + j;
                                            const teacher = filteredTeachers[idx];
                                            return teacher ? (
                                                <TeacherCard
                                                    key={teacher.teacherId}
                                                    teacher={teacher}
                                                    isFlex
                                                    handleClick={handleTeacherCardClick}
                                                />
                                            ) : (
                                                <TeacherCard key={idx} isHidden isFlex />
                                            );
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    );
                }}
            </QueryLoadingWrapper>
            <TeacherDetailInfoModal open={modalOpen} teacher={openedTeacher} handleClose={handleModalClose} />
        </div>
    );
});
