import { PaymentMethod } from "../autogenApi";
import { defaultApi } from "../defaultApi";

import { useMutationFunctionWrapper } from "./utils/mutationFunction";
import { useQueryResult } from "./utils/parseResult";
import { UseMutationOptions } from "./utils/types";

export const useCreateStandardAccountMutation = (options?: UseMutationOptions) => {
    const [createStandardAccount] = defaultApi.endpoints.createStandardAccount.useMutation();
    return useMutationFunctionWrapper(createStandardAccount, options?.errorMessages);
};

export const useCreateSetUpIntentMutation = (options?: UseMutationOptions) => {
    const [createSetUpIntent] = defaultApi.endpoints.createSetUpIntent.useMutation();
    return useMutationFunctionWrapper(createSetUpIntent, options?.errorMessages);
};

export const useGetPaymentMethodsQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getPaymentMethods.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: any[]) => response);
};

export const useUpdateDefaultPaymentMethodMutation = (options?: UseMutationOptions) => {
    const [updateDefaultPaymentMethod] = defaultApi.endpoints.updateDefaultPaymentMethod.useMutation();
    return useMutationFunctionWrapper(updateDefaultPaymentMethod, options?.errorMessages);
};

export const useDetachPaymentMethodMutation = (options?: UseMutationOptions) => {
    const [detachPaymentMethod] = defaultApi.endpoints.detachPaymentMethod.useMutation();
    return useMutationFunctionWrapper(detachPaymentMethod, options?.errorMessages);
};

export const useGetDefaultPaymentMethodQuery = (studentId: string) => {
    const result = defaultApi.endpoints.getDefaultPaymentMethod.useQuery({ studentId: studentId });
    return useQueryResult(result, (response: PaymentMethod | null) => response);
};

export const useMakeLatestCreditCardDefaultMutation = (options?: UseMutationOptions) => {
    const [makeLatestCreditCardDefault] = defaultApi.endpoints.makeLatestCreditCardDefault.useMutation();
    return useMutationFunctionWrapper(makeLatestCreditCardDefault, options?.errorMessages);
};

export const useCreateBankAccountMutation = (options?: UseMutationOptions) => {
    const [createBankAccount] = defaultApi.endpoints.createBankAccount.useMutation();
    return useMutationFunctionWrapper(createBankAccount, options?.errorMessages);
};

export const useGetBankAccountListQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getBankAccountList.useQuery({ teacherId: teacherId });
    return useQueryResult(result, (response: any[]) => response);
};

export const useDeleteBankAccountMutation = (options?: UseMutationOptions) => {
    const [deleteBankAccount] = defaultApi.endpoints.deleteBankAccount.useMutation();
    return useMutationFunctionWrapper(deleteBankAccount, options?.errorMessages);
};

export const useUpdateDefaultBankAccountMutation = (options?: UseMutationOptions) => {
    const [updateDefaultBankAccount] = defaultApi.endpoints.updateDefaultBankAccount.useMutation();
    return useMutationFunctionWrapper(updateDefaultBankAccount, options?.errorMessages);
};

export const usePayoutMutation = (options?: UseMutationOptions) => {
    const [payout] = defaultApi.endpoints.payout.useMutation();
    return useMutationFunctionWrapper(payout, options?.errorMessages);
};

export const useGetStripeAccountQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getStripeAccount.useQuery({ teacherId: teacherId });
    return useQueryResult(result, (response: any) => response);
};

export const useUpdateStripeAccountForConnectMutation = (options?: UseMutationOptions) => {
    const [updateStripeAccountForConnect] = defaultApi.endpoints.updateStripeAccountForConnect.useMutation();
    return useMutationFunctionWrapper(updateStripeAccountForConnect, options?.errorMessages);
};

export const useResubmitVerificationDocumentMutation = (options?: UseMutationOptions) => {
    const [resubmitVerificationDocument] = defaultApi.endpoints.resubmitVerificationDocument.useMutation();
    return useMutationFunctionWrapper(resubmitVerificationDocument, options?.errorMessages);
};

export const useGetBalanceTransactionsQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getBalanceTransactions.useQuery({ teacherId });
    return useQueryResult(result, (response: any[]) => response);
};

export const useGetBalanceQuery = (teacherId: string) => {
    const result = defaultApi.endpoints.getBalance.useQuery({ teacherId });
    return useQueryResult(result, (response: any) => response);
};

export const useGetPaymentIntentQuery = (paymentIntentId: string) => {
    const result = defaultApi.endpoints.getPaymentIntent.useQuery({ paymentIntentId });
    return useQueryResult(result, (response: any) => response);
};
