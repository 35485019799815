import { Button } from "@material-ui/core";

import { CreateBaseScheduleParams } from "@/store/autogenApi";

import styles from "@/components/BaseSchedulePopover/index.module.scss";
import { memo, useCallback, useMemo } from "react";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";
import { ErrorMessage } from "../ErrorMessage";
import { add, isBefore, isAfter, differenceInHours, differenceInMinutes } from "date-fns";

interface Props {
    isNewBaseScheduleChecked: boolean;
    newBaseSchedule: Partial<CreateBaseScheduleParams> | undefined;
    daysValidation: boolean;
    timeValidation: boolean;
    handleStartTimeChange: (hour: number, minute: number) => void;
    handleHowManyMinutesChange: (howManyMinutes: number) => void;
    handleDayChange: (
        e: React.ChangeEvent<{
            checked: boolean;
        }>,
        dayIdx: number,
    ) => void;
    confirmWeeklyBaseSchedule: () => void;
}

const BASE_DATE = new Date(1970, 1, 1, 0, 0);

const getStartTimeList = () => {
    const baseDate = BASE_DATE;
    const endDate = new Date(1970, 1, 2, 0, 0);
    const startTimeList: Date[] = [];
    for (let currentDate = baseDate; isBefore(currentDate, endDate); currentDate = add(currentDate, { minutes: 15 })) {
        startTimeList.push(currentDate);
    }
    return startTimeList;
};

const MINUTES_IN_HOUR = 60;

const formatDate = (date: Date) => {
    return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
};

const getDateDiff = (leftDate: Date, rightDate: Date) => {
    const diffMinutes = differenceInMinutes(leftDate, rightDate);
    const diffHours = differenceInHours(leftDate, rightDate);
    return {
        diff: {
            floorHours: diffHours,
            totalMinutes: diffMinutes,
        },
    };
};

const formatDiffDate = (leftDate: Date, rightDate: Date) => {
    const {
        diff: { floorHours, totalMinutes },
    } = getDateDiff(leftDate, rightDate);
    return `${floorHours}時間${totalMinutes - floorHours * MINUTES_IN_HOUR}分`;
};

export const WeeklyScheduleSelector: React.VFC<Props> = memo(function WeeklyScheduleSelector(props) {
    const selectedStartTime = useMemo(() => {
        return add(BASE_DATE, {
            hours: props.newBaseSchedule.startHour ?? 0,
            minutes: props.newBaseSchedule.startMinute ?? 0,
        });
    }, [props.newBaseSchedule.startHour, props.newBaseSchedule.startMinute]);

    const startTimeList = useMemo(() => getStartTimeList(), []);

    const selectedEndTime = useMemo(() => {
        return add(selectedStartTime, {
            minutes: props.newBaseSchedule.howManyMinutes ?? 0,
        });
    }, [selectedStartTime, props.newBaseSchedule.howManyMinutes]);

    const endTimeList = useMemo(() => {
        return startTimeList.filter((time) => isAfter(time, selectedStartTime));
    }, [startTimeList, selectedStartTime]);

    const handleStartTimeChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const value = e.target.value;
            const date = new Date(value);
            props.handleStartTimeChange(date.getHours(), date.getMinutes());
        },
        [props.handleStartTimeChange],
    );

    const handleEndTimeChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const value = e.target.value;
            const date = new Date(value);
            const {
                diff: { totalMinutes },
            } = getDateDiff(date, selectedStartTime);
            props.handleHowManyMinutesChange(totalMinutes);
        },
        [props.handleHowManyMinutesChange, selectedStartTime],
    );

    return (
        <>
            <div className={styles.daysWrapper}>
                <div className={styles.daysTitle}>曜日</div>
                <div className={styles.days}>
                    {[...Array(7)].map((_, idx) => (
                        <div className={styles.day}>
                            <input
                                type="checkbox"
                                id={`dayCheckbox${idx}`}
                                className={styles.dayInput}
                                checked={props.newBaseSchedule?.dayOfWeekIndexes?.includes(idx) ?? false}
                                onChange={(e) => props.handleDayChange(e, idx)}
                            />
                            <label htmlFor={`dayCheckbox${idx}`} className={styles.dayLabel}>
                                {WHAT_DAY_LIST[idx]}
                            </label>
                        </div>
                    ))}
                </div>
                <ErrorMessage
                    when={props.isNewBaseScheduleChecked && !props.daysValidation}
                    content="曜日は「頻度」で選んだ回数分選択してください"
                />
            </div>

            <div className={styles.timeWrapper}>
                <div className={styles.timeTitle}>時間</div>
                <div className={styles.timeFlex}>
                    <select
                        className={styles.timeSelect}
                        onChange={handleStartTimeChange}
                        defaultValue={BASE_DATE.toLocaleString()}
                        value={selectedStartTime.toLocaleString()}
                    >
                        {startTimeList.map((time) => (
                            <option className={styles.time} value={time.toLocaleString()}>
                                {formatDate(time)}
                            </option>
                        ))}
                    </select>
                    〜
                    <select
                        className={styles.timeSelect}
                        onChange={handleEndTimeChange}
                        defaultValue={BASE_DATE.toLocaleString()}
                        value={selectedEndTime.toLocaleString()}
                    >
                        {endTimeList.map((time) => (
                            <option className={styles.time} value={time.toLocaleString()}>
                                {`${formatDate(time)} (${formatDiffDate(time, selectedStartTime)})`}
                            </option>
                        ))}
                    </select>
                </div>
                <ErrorMessage
                    when={props.isNewBaseScheduleChecked && !props.timeValidation}
                    content="有効な時間を選択してください"
                />
            </div>

            <div className={styles.scheduleButtonsWrapper}>
                <Button className={styles.scheduleConfirmButton} onClick={props.confirmWeeklyBaseSchedule}>
                    確定
                </Button>
            </div>
        </>
    );
});
