import { Bold } from "@/components/atoms/Bold";
import styles from "@/pages/Common/LP/index.module.scss";
import { Button } from "@material-ui/core";
import { useMediaQuery } from "@mui/material";
import { useCallback } from "react";
import { useHistory } from "react-router-dom";

export const PostScript: React.VFC = () => {
    const mdUp = useMediaQuery("(min-width:960px)");
    const history = useHistory();
    const handleSignUpButtonClick = useCallback(() => {
        history.push("/SignUp?type=student");
    }, []);
    return (
        <div className={styles.postScriptWrapper}>
            <div className={styles.postScriptTitle}>最後に</div>
            <div className={styles.postScript}>
                Trailのご説明をここまで読んでくださってありがとうございます。
                <br />
                <br />
                今回、Trailで自由に先生として活動してほしいという思いを熱くお伝えしました。
                <br />
                <br />
                テレビCMで見るような大手の塾などではありませんので、{mdUp && <br />}
                あなたも少なからずTrailを利用することに不安があるかもしれません。
                <br />
                <br />
                ただ、1つ言えるのは、Trailは、
                <Bold text="先生も主役として活躍できることを目指して作られたサービスである" />
                ということです。
                <br />
                <br />
                <Bold text="家にいながら、自分のペースで、自分のやりたいことを教えて稼ぐ" />
                ことができる。
                <br />
                <br />
                Trailに登録して、ぜひそんな最高の体験を楽しんでください。
            </div>
            <Button className={styles.signUpButton} onClick={handleSignUpButtonClick}>
                新規ユーザー登録へ（無料）
            </Button>
        </div>
    );
};
