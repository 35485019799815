import { memo, useCallback, useEffect } from "react";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import styles from "@/pages/Teacher/CreateCourse/index.module.scss";
import { Optional } from "@/components/Tag/Optional";
import { ErrorMessage } from "@/components/ErrorMessage";

interface Props {
    value: string | undefined;
    isChecked: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
    courseType: string;
    validation: boolean;
}

export const AdditionalDescription: React.VFC<Props> = memo(function AdditionalDescription(props) {
    const fixPlaceholder = useCallback(() => {
        const element: HTMLInputElement | null = document.getElementById("remarksTextArea") as HTMLInputElement;
        if (element) {
            element.placeholder = element.placeholder.replace(/\\n/g, "\n");
        }
    }, []);

    useEffect(() => {
        fixPlaceholder();
    }, []);

    const getExample = useCallback(() => {
        if (props.courseType === "short") {
            return "例）反応を確認しながら授業を行いたいため、カメラオンでの参加をお願いします。";
        } else if (props.courseType === "regular") {
            return "例）大学のテスト前は授業を振り替えていただく場合がありますので、予めご了承ください。";
        } else {
            return "例）質問のための時間を設けていますので、事前に質問を考えておいていただけると良いかと思います。";
        }
    }, [props.courseType]);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>備考</div>
                    {!props.open && <Optional />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value === "" ? "-" : props.value}</div>
                ) : (
                    <>
                        <TextareaAutosize
                            required
                            placeholder={`備考(任意300字以内)\n\n${getExample()}`}
                            className={styles.textArea}
                            name="additionalDescription"
                            onChange={props.handleChange}
                            minRows={5}
                            id="remarksTextArea"
                            value={props.value}
                        />
                        <div className={styles.inputFeedbackContainer}>
                            <ErrorMessage
                                when={props.isChecked && !props.validation}
                                content="この項目は任意ですが、入力する場合は300字以内で入力してください。"
                                style={{ marginRight: "auto", marginLeft: "0" }}
                            />
                            <div className={styles.textLength}>{props.value.length}/300</div>
                        </div>
                    </>
                )}
            </div>
        </li>
    );
});
