import { memo } from "react";

import styles from "./index.module.scss";
import { ButtonPair } from "@/components/ButtonPair";
import { DraftPublicRequestResponse } from "@/store/autogenApi";
import { FormItem } from "@/components/FormItem";
import { getCourseType } from "@/utils/DraftPublicRequestUtils";

interface Props {
    existPublicRequest: boolean;
    draftPublicRequest: DraftPublicRequestResponse | undefined;
    handleDiscardDraftButtonClick: () => void;
    handleUseDraftButtonClick: (draftPublicRequest: DraftPublicRequestResponse | undefined) => void;
}

export const UseDraftPublicRequestModalContents: React.VFC<Props> = memo(function UseDraftPublicRequestModalContents(
    props,
) {
    return (
        <div className={styles.useDraftModalContents}>
            <div className={styles.noticeMessage}>
                下書きがあります。下書きを使用して講座リクエスト{props.existPublicRequest ? "編集" : "作成"}
                を再開しますか？
            </div>
            <ul className={styles.itemList}>
                <FormItem
                    itemTitle="講座形態"
                    itemDescription={props.draftPublicRequest ? getCourseType(props.draftPublicRequest) : "-"}
                    isFirstItem
                />
                <FormItem
                    itemTitle="タイトル"
                    itemDescription={props.draftPublicRequest?.title === "" ? "-" : props.draftPublicRequest?.title}
                />
                <FormItem itemTitle="科目">
                    {props.draftPublicRequest?.subjects || props.draftPublicRequest?.subjects.length === 0 ? (
                        "-"
                    ) : (
                        <ul className={styles.subjects}>
                            {props.draftPublicRequest?.subjects.map((subject) => {
                                return <li className={styles.subject}>{subject}</li>;
                            })}
                        </ul>
                    )}
                </FormItem>
                <FormItem
                    itemTitle="講座リクエストの詳細"
                    itemDescription={
                        props.draftPublicRequest?.publicRequestDescription === ""
                            ? "-"
                            : props.draftPublicRequest?.publicRequestDescription
                    }
                />
                <FormItem
                    itemTitle="日程"
                    itemDescription={
                        props.draftPublicRequest?.scheduleDescription === ""
                            ? "-"
                            : props.draftPublicRequest?.scheduleDescription
                    }
                />
                <FormItem
                    itemTitle="希望価格"
                    itemDescription={
                        props.draftPublicRequest?.minPrice == undefined ||
                        props.draftPublicRequest?.maxPrice == undefined
                            ? "-"
                            : `¥${new Intl.NumberFormat("ja-JP").format(
                                  props.draftPublicRequest?.minPrice,
                              )} ~ ¥${new Intl.NumberFormat("ja-JP").format(props.draftPublicRequest?.maxPrice)}`
                    }
                />
                <FormItem
                    itemTitle="備考"
                    itemDescription={
                        props.draftPublicRequest?.additionalDescription == null
                            ? "-"
                            : props.draftPublicRequest?.additionalDescription
                    }
                />
            </ul>
            <ButtonPair
                buttonColor="navy"
                leftButtonText="下書きを破棄"
                rightButtonText="下書きを使用"
                handleLeftButtonClick={props.handleDiscardDraftButtonClick}
                handleRightButtonClick={() => {
                    props.handleUseDraftButtonClick(props.draftPublicRequest);
                }}
            />
        </div>
    );
});
