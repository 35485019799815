import { memo, useCallback, useEffect } from "react";
import { useState } from "react";

import { useSelector } from "react-redux";

import { RouterPrompt } from "@/components/RouterPrompt";
import { FileInfo } from "@/components/TrimmingModal";
import { RootState } from "@/ducks";
import { UpdateStripeAccountForConnectRequestBody } from "@/store/autogenApi";
import { useUpdateStripeAccountForConnectMutation } from "@/store/hooks/stripe";
import { useUploadFileToStripe } from "@/utils/UploadFiles";

import { FormContents } from "./FormContents";

interface Props {
    addressInfoList: AddressInfo[];
}

export interface AddressInfo {
    postalCode: string;
    state: string;
    city: string;
    line1: string;
    stateKana: string;
    cityKana: string;
    line1Kana: string;
}

export const IdentificationForStripeComponent: React.VFC<Props> = memo(function IdentificationForStripeComponent(
    props,
) {
    const [idCardFileInfo, setIdCardInfo] = useState<FileInfo | undefined>(undefined);
    const [checked, setChecked] = useState<boolean>(false);
    const [open, setOpen] = useState<boolean>(false);
    const [complete, setComplete] = useState<boolean>(false);
    const [postalCode, setPostalCode] = useState<string>("");
    const [state, setState] = useState<string>("");
    const [city, setCity] = useState<string>("");
    const [town, setTown] = useState<string>("");
    const [line1, setLine1] = useState<string>("");
    const [line2, setLine2] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const [modalHeight, setModalHeight] = useState<number>(0);

    const [isEdited, setIsEdited] = useState<boolean>(false);
    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);

    const updateStripeAccountForConnect = useUpdateStripeAccountForConnectMutation();

    const changeModalHeight = () => {
        const innerHeight = window.innerHeight;
        setModalHeight(innerHeight * 0.75);
    };

    useEffect(() => {
        changeModalHeight();
        window.addEventListener("resize", () => {
            changeModalHeight();
        });
        return () => {
            window.removeEventListener("resize", () => {
                changeModalHeight();
            });
        };
    }, []);

    const addPhoto = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setIsEdited(true);
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setIdCardInfo({
                file: file,
                url: URL.createObjectURL(file),
            });
        }
    }, []);

    const deletePhoto = useCallback(() => {
        setIsEdited(true);
        setIdCardInfo(undefined);
    }, []);

    const confirm = useCallback(async () => {
        if (!idCardFileInfo?.file) return;
        setIsProcessing(true);
        const fileId = await useUploadFileToStripe(idCardFileInfo.file);
        const modifiedPhone = "+81" + phone.slice(1);
        const requestBody: UpdateStripeAccountForConnectRequestBody = {
            teacherId,
            postalCode: postalCode,
            state: state,
            city: city,
            town: town,
            line1: line1,
            line2: line2,
            email: email,
            phone: modifiedPhone,
            fileId: fileId,
        };
        const result = await updateStripeAccountForConnect({
            updateStripeAccountForConnectRequestBody: requestBody,
        });
        if (result.isSuccess) {
            setIsProcessing(false);
            setComplete(true);
            setIsEdited(false);
        } else {
            setIsProcessing(false);
            setIsError(true);
            setIsEdited(false);
        }
        window.scrollTo(0, 0);
        setOpen(false);
    }, [
        idCardFileInfo,
        postalCode,
        state,
        city,
        town,
        line1,
        line2,
        email,
        phone,
        teacherId,
        updateStripeAccountForConnect,
    ]);

    const handleOpen = useCallback(() => {
        if (!idCardFileInfo?.file) return;
        const condition =
            idCardFileInfo.file && postalCode.length === 7 && state && city && town && line1 && email && phone;
        condition ? setOpen(true) : setChecked(true);
    }, [idCardFileInfo, postalCode, state, city, town, line1, email, phone]);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const handlePostalCodeChange = useCallback(
        (value: string) => {
            setIsEdited(true);
            setPostalCode(value);
            const targetAddressInfo = props.addressInfoList.find((addressInfo) => addressInfo.postalCode === value);
            if (targetAddressInfo) {
                setState(targetAddressInfo.state);
                setCity(targetAddressInfo.city);
                if (targetAddressInfo.line1 === "以下に掲載がない場合") {
                    setTown("");
                } else {
                    setTown(targetAddressInfo.line1);
                }
            } else {
                setState("");
                setCity("");
                setTown("");
            }
        },
        [props.addressInfoList, town],
    );

    const handleStateChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsEdited(true);
        const value = e.target.value as string;
        setState(value);
    }, []);

    const handleCityChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsEdited(true);
        const value = e.target.value as string;
        setCity(value);
    }, []);

    const handleTownChange = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsEdited(true);
        const value = e.target.value as string;
        setTown(value);
    }, []);

    const handleLine1Change = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsEdited(true);
        const value = e.target.value as string;
        setLine1(value);
    }, []);

    const handleLine2Change = useCallback((e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setIsEdited(true);
        const value = e.target.value as string;
        setLine2(value);
    }, []);

    const handleOKButtonClick = useCallback(() => {
        return true;
    }, []);

    const handleCancelButtonClick = useCallback(() => {
        return false;
    }, []);

    const handleEmailChange = useCallback((value: string) => {
        setEmail(value);
    }, []);

    const handlePhoneChange = useCallback((value: string) => {
        setPhone(value);
    }, []);

    return (
        <>
            <RouterPrompt
                when={!complete && isEdited}
                message="Stripe用情報入力が未完了です。現在の入力内容は失われますが、このページを終了してもよろしいですか？"
                onOK={handleOKButtonClick}
                onCancel={handleCancelButtonClick}
            />
            <FormContents
                checked={checked}
                open={open}
                idCardFileInfo={idCardFileInfo}
                complete={complete}
                state={state}
                city={city}
                town={town}
                line1={line1}
                line2={line2}
                email={email}
                postalCode={postalCode}
                phone={phone}
                modalHeight={modalHeight}
                isProcessing={isProcessing}
                isError={isError}
                handleStateChange={handleStateChange}
                handleCityChange={handleCityChange}
                handleTownChange={handleTownChange}
                handleLine1Change={handleLine1Change}
                handleLine2Change={handleLine2Change}
                handlePostalCodeChange={handlePostalCodeChange}
                handleEmailChange={handleEmailChange}
                handlePhoneChange={handlePhoneChange}
                handleOpen={handleOpen}
                handleClose={handleClose}
                addPhoto={addPhoto}
                deletePhoto={deletePhoto}
                confirm={confirm}
            />
        </>
    );
});
