import styles from "@/pages/Common/LP/index.module.scss";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import { AiOutlineCaretRight } from "react-icons/ai";

export const ThreeStepsSignUp: React.VFC = () => {
    return (
        <div className={styles.threeStepsSignUpWrapper}>
            <div className={styles.threeStepsSignUpTitle}>授業までわずか3ステップ！</div>
            <div className={styles.steps}>
                <div className={styles.step}>
                    リクエスト
                    <br />
                    検索
                </div>
                <AiOutlineCaretRight />
                <div className={styles.step}>応募</div>
                <AiOutlineCaretRight />
                <div className={styles.step}>授業</div>
            </div>
            <div className={styles.signUpWrapper}>
                <Button className={styles.signUpButton} component={Link} to="/SignUp?type=student">
                    まずは新規ユーザー登録！（無料）
                </Button>
            </div>
        </div>
    );
};
