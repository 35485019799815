import { memo } from "react";
import { Modal } from "@/components/Modal";
import { DraftCourseResponse } from "@/store/autogenApi";
import { UseDraftCourseModalContents } from "./ModalContents";
import { useGetDraftCourseQuery } from "@/store/hooks/draftCourses";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";

interface Props {
    existCourse: boolean;
    draftCourseId: string;
    handleDiscardDraftButtonClick: () => void;
    handleUseDraftButtonClick: (draftCourse: DraftCourseResponse | undefined) => void;
}

export const ExistDraftCourse: React.VFC<Props> = memo(function ExistDraftCourse(props) {
    const draftCourseQueryState = useGetDraftCourseQuery(props.draftCourseId);

    return (
        <QueryLoadingWrapper {...draftCourseQueryState}>
            {(draftCourse) => (
                <UseDraftCourseModalContents
                    existCourse={Boolean(props.existCourse)}
                    draftCourse={draftCourse}
                    handleDiscardDraftButtonClick={props.handleDiscardDraftButtonClick}
                    handleUseDraftButtonClick={props.handleUseDraftButtonClick}
                />
            )}
        </QueryLoadingWrapper>
    );
});
