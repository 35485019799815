import { memo } from "react";
import styles from "@/pages/Common/CourseDetails/index.module.scss";

interface Props {
    additionalDescription: string;
}

export const AdditionalDescription: React.VFC<Props> = memo(function AdditionalDescription(props) {
    return (
        <div className={styles.itemWrapper}>
            <div className={styles.itemHeadingWrapper}>
                <span className={styles.itemHeading}>備考</span>
            </div>
            <div className={styles.item} style={{ overflowY: "auto", maxHeight: "none" }} id="additionalDescription">
                {props.additionalDescription}
            </div>
        </div>
    );
});
