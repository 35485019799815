import { memo } from "react";
import Modal from "@material-ui/core/Modal";
import styles from "../userInfoInput.module.scss";

import { BirthDate } from "./Items/BirthDate";
import { Gender } from "./Items/Gender";
import { Grade } from "./Items/Grade";
import { Prefecture } from "./Items/Prefecture";
import { RealName } from "./Items/RealName";
import { ButtonPair } from "@/components/ButtonPair";

interface Props {
    open: boolean;
    checked: boolean;
    firstName: string;
    lastName: string;
    firstNameKana: string;
    lastNameKana: string;
    birthDate: Date | undefined;
    isBirthDatePublic: boolean;
    grade: string | undefined;
    isGradePublic: boolean;
    gender: string | undefined;
    isGenderPublic: boolean;
    prefecture: string | undefined;
    isPrefecturePublic: boolean;
    // handleFirstNameCompositionEnd: () => void;
    // handleLastNameCompositionEnd: () => void;
    handleClose: () => void;
    handleNextStepButtonClick: () => void;
}

export const InfoInputModal: React.VFC<Props> = memo(function InfoInputModal(props) {
    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={styles.modal}
            >
                <div className={styles.modalDiv}>
                    <div className={styles.bottomMessage}>
                        内容を確認して、よろしければ「次へ」を押してください。次のステップに進みます。
                    </div>
                    <div className={styles.inputList}>
                        <RealName
                            firstName={props.firstName}
                            lastName={props.lastName}
                            firstNameKana={props.firstNameKana}
                            lastNameKana={props.lastNameKana}
                            checked={props.checked}
                            open={props.open}
                        />
                        <Grade
                            value={props.grade}
                            checked={props.checked}
                            open={props.open}
                            isPublic={props.isGradePublic}
                        />
                        <Gender
                            value={props.gender}
                            checked={props.checked}
                            open={props.open}
                            isPublic={props.isGenderPublic}
                        />
                        <BirthDate
                            birthDate={props.birthDate ? new Date(props.birthDate) : undefined}
                            checked={props.checked}
                            open={props.open}
                            isPublic={props.isBirthDatePublic}
                        />
                        <Prefecture
                            value={props.prefecture}
                            checked={props.checked}
                            open={props.open}
                            isPublic={props.isPrefecturePublic}
                        />
                    </div>
                    <ButtonPair
                        buttonColor="navy"
                        leftButtonText="戻る"
                        rightButtonText="次へ"
                        handleLeftButtonClick={props.handleClose}
                        handleRightButtonClick={props.handleNextStepButtonClick}
                    />
                </div>
            </Modal>
        </>
    );
});
