import { memo, useCallback, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { useHistory } from "react-router";
import { StripeAccount } from "@/store/autogenApi";
import { Link } from "react-router-dom";
import { ResubmitModal } from "./ResubmitModal";

interface Props {
    stripeAccount: StripeAccount;
}

// NOTE: CurrentlyDueは型定義上stringなので、可能性のある値を列挙しておく
type CurrentlyDue = "external_account" | "individual.verification.document";

export const Error: React.VFC<Props> = memo(function Error(props) {
    const [resubmitModalOpen, setResubmitModalOpen] = useState<boolean>(false);
    const showAddButton = useMemo<boolean>(
        () => !!props.stripeAccount?.requirements?.currently_due?.includes("external_account"),
        [props.stripeAccount],
    );
    const showResubmitButton = useMemo<boolean>(
        () => !!props.stripeAccount?.requirements?.currently_due?.includes("individual.verification.document"),
        [props.stripeAccount],
    );
    const currentlyDueArray = useMemo<CurrentlyDue[]>(
        () =>
            Array.isArray(props.stripeAccount?.requirements?.currently_due)
                ? (props.stripeAccount.requirements.currently_due as CurrentlyDue[])
                : [],
        [props.stripeAccount],
    );
    const hasExternalAccountError = useMemo<boolean>(
        () => currentlyDueArray.includes("external_account"),
        [currentlyDueArray],
    );
    const errors = useMemo(() => {
        return props.stripeAccount?.requirements?.errors.map((error) => {
            switch (error.code) {
                case "invalid_value_other":
                    return (
                        <>
                            Stripeの審査が何らかの理由で通りませんでした。Trail運営事務局に
                            <Link to="/teacher/MyPage/Contact">お問い合わせ</Link>
                            ください。
                        </>
                    );
                case "verification_document_address_mismatch":
                    return "住所が本人確認書類と一致しません。";
                case "verification_document_address_missing":
                    return "住所が本人確認書類に記載されていません。";
                case "verification_document_corrupt":
                    return "本人確認書類が破損しています。";
                case "verification_document_country_not_supported":
                    return "本人確認書類の発行国がサポートされていません。";
                case "verification_document_dob_mismatch":
                    return "生年月日が本人確認書類と一致しません。";
                case "verification_document_duplicate_type":
                    return "本人確認書類が重複しています。";
                case "verification_document_expired":
                    return "本人確認書類の有効期限が切れています。";
                case "verification_document_failed_copy":
                    return "本人確認書類のコピーが提出されました。写真撮影したものをそのまま提出してください。";
                case "verification_document_failed_greyscale":
                    return "本人確認書類がモノクロで提出されました。カラーで撮影して提出してください。";
                case "verification_document_failed_other":
                    return "本人確認書類の提出に失敗しました。";
                case "verification_document_failed_test_mode":
                    return "本人確認書類の提出に失敗しました。";
                case "verification_document_fraudulent":
                    return "本人確認書類が不正であると判断されました。";
                case "verification_document_id_number_mismatch":
                    return "ID番号が本人確認書類と一致しません。";
                case "verification_document_id_number_missing":
                    return "ID番号が本人確認書類に記載されていません。";
                case "verification_document_incomplete":
                    return "本人確認書類が不完全です。";
                case "verification_document_invalid":
                    return "本人確認書類が無効です。";
                case "verification_document_issue_or_expiry_date_missing":
                    return "本人確認書類に発行日または有効期限が記載されていません。";
                case "verification_document_manipulated":
                    return "本人確認書類が改ざんされています。";
                case "verification_document_missing_front":
                    return "本人確認書類の表面が提出されていません。";
                case "verification_document_missing_back":
                    return "本人確認書類の裏面が提出されていません。";
                case "verification_document_name_mismatch":
                    return "名前が本人確認書類と一致しません。";
                case "verification_document_name_missing":
                    return "名前が本人確認書類に記載されていません。";
                case "verification_document_nationality_mismatch":
                    return "国籍が本人確認書類と一致しません。";
                case "verification_document_not_readable":
                    return "本人確認書類が読み取れません。";
                case "verification_document_not_signed":
                    return "本人確認書類に署名がありません。";
                case "verification_document_not_uploaded":
                    return "本人確認書類が提出されていません。";
                case "verification_document_photo_mismatch":
                    return "写真が本人確認書類と一致しません。";
                case "verification_document_too_large":
                    return "本人確認書類が大きすぎます。";
                case "verification_document_type_not_supported":
                    return "本人確認書類の種類はサポートされていません。";
                default:
                    return "";
            }
        });
    }, [props.stripeAccount]);
    const getErrorMessage = useCallback((currentlyDue: CurrentlyDue) => {
        switch (currentlyDue) {
            case "individual.verification.document":
                return "本人確認書類に不備がありました。";
            default:
                return (
                    <>
                        エラーが発生しました。Trail運営事務局に
                        <Link to="/teacher/MyPage/Contact">お問い合わせ</Link>
                        ください。
                    </>
                );
        }
    }, []);
    const history = useHistory();
    const handleAddButtonClick = useCallback(() => {
        history.push("/teacher/MyPage/BankAccountSetting");
    }, [history]);
    const handleResubmitButtonClick = useCallback(() => {
        setResubmitModalOpen(true);
    }, []);
    const handleResubmitModalClose = useCallback(() => {
        setResubmitModalOpen(false);
    }, []);
    return (
        <Box sx={{ padding: "0 20px", textAlign: "left" }}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                {hasExternalAccountError ? (
                    <Box sx={{ width: "100%", textAlign: "center" }}>
                        <Box sx={{ fontSize: "14px" }}>振込用銀行口座が設定されていません。設定してください。</Box>
                    </Box>
                ) : (
                    <>
                        {currentlyDueArray.map((currentlyDue) => (
                            <Box key={currentlyDue}>
                                <Box sx={{ fontSize: "14px" }}>{getErrorMessage(currentlyDue)}</Box>
                                {currentlyDue === "individual.verification.document" && (
                                    <Box>
                                        <Box sx={{ fontSize: "14px" }}>
                                            不備の内容は下記の通りです。内容を踏まえて再提出してください。
                                        </Box>
                                        <Box component="ul" sx={{ margin: "0", paddingLeft: "24px" }}>
                                            {errors.map((error) => (
                                                <Box
                                                    component="li"
                                                    sx={{ fontSize: "14px", listStyleType: "disc !important" }}
                                                >
                                                    {error}
                                                </Box>
                                            ))}
                                        </Box>
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </>
                )}
            </Box>
            {showAddButton && (
                <Box sx={{ textAlign: "center", width: "100%" }}>
                    <NavyButton handleClick={handleAddButtonClick} style={{ margin: "10px auto 0" }}>
                        銀行口座追加
                    </NavyButton>
                </Box>
            )}
            {showResubmitButton && (
                <>
                    <Box sx={{ textAlign: "center", width: "100%" }}>
                        <NavyButton handleClick={handleResubmitButtonClick} style={{ margin: "10px auto 0" }}>
                            本人確認書類再提出
                        </NavyButton>
                    </Box>
                    <ResubmitModal
                        stripeAccount={props.stripeAccount}
                        modalOpen={resubmitModalOpen}
                        handleModalClose={handleResubmitModalClose}
                    />
                </>
            )}
        </Box>
    );
});
