import { Button } from "@material-ui/core";

import { CreateBaseScheduleParams } from "@/store/autogenApi";

import styles from "@/components/BaseSchedulePopover/index.module.scss";
import { memo, useCallback } from "react";
import { ErrorMessage } from "../ErrorMessage";

interface Props {
    isNewBaseScheduleChecked: boolean;
    newBaseSchedule: Partial<CreateBaseScheduleParams> | undefined;
    timeValidation: boolean;
    handleHowManyMinutesChange: (howManyMinutes: number) => void;
    confirmMultiWeekBaseSchedule: () => void;
}

const MINUTES_INTERVAL = 15;
const TOTAL_MINUTES = 12 * 60; // 12時間
const COURSE_TIME_LIST_IN_15_MINITE_INTERVALS = [...Array(TOTAL_MINUTES / MINUTES_INTERVAL + 1)].map(
    (_, idx) => idx * MINUTES_INTERVAL,
);

export const MultiWeekScheduleSelector: React.VFC<Props> = memo(function MultiWeekScheduleSelector(props) {
    const handleHowManyMinutesChange = useCallback(
        (e: React.ChangeEvent<HTMLSelectElement>) => {
            const value = e.target.value;
            const howManyMinutes = parseInt(value);
            props.handleHowManyMinutesChange(howManyMinutes);
        },
        [props.handleHowManyMinutesChange],
    );

    return (
        <>
            <div className={styles.timeWrapper}>
                <div className={styles.timeTitle}>時間</div>
                <div className={styles.timeFlex}>
                    各
                    <select
                        className={styles.minutesSelect}
                        onChange={handleHowManyMinutesChange}
                        defaultValue="0"
                        value={props.newBaseSchedule?.howManyMinutes}
                    >
                        {COURSE_TIME_LIST_IN_15_MINITE_INTERVALS.map((minutes) => (
                            <option className={styles.time} value={minutes}>
                                {minutes}
                            </option>
                        ))}
                    </select>
                    分
                </div>
                <ErrorMessage
                    when={props.isNewBaseScheduleChecked && !props.timeValidation}
                    content="有効な時間を選択してください"
                />
            </div>
            <div className={styles.scheduleButtonsWrapper}>
                <Button className={styles.scheduleConfirmButton} onClick={props.confirmMultiWeekBaseSchedule}>
                    確定
                </Button>
            </div>
        </>
    );
});
