import { memo, useCallback, useEffect } from "react";

import TextareaAutosize from "@material-ui/core/TextareaAutosize";

import styles from "@/pages/Teacher/CreateCourse/index.module.scss";
import { Mandatory } from "@/components/Tag/Mandatory";
import { ErrorMessage } from "@/components/ErrorMessage";

interface Props {
    value: string;
    isChecked: boolean;
    validation: boolean;
    handleChange: (e: React.ChangeEvent<{ name?: string; value: unknown }>) => void;
    open?: boolean;
}

export const TeachingMaterial: React.VFC<Props> = memo(function TeachingMaterial(props) {
    const fixPlaceholder = useCallback(() => {
        const element: HTMLInputElement | null = document.getElementById(
            "teachingMaterialTextArea",
        ) as HTMLInputElement;
        if (element) {
            element.placeholder = element.placeholder.replace(/\\n/g, "\n");
        }
    }, []);

    useEffect(() => {
        fixPlaceholder();
    }, []);

    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>教材・必要な準備</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value}</div>
                ) : (
                    <>
                        <TextareaAutosize
                            required
                            placeholder={`教材(必須1,000字以内)\n\n私が作成したスライドを使って授業を行います。授業前に共有しますので、一読しておいていただけますと助かります。...`}
                            className={styles.textArea}
                            name="teachingMaterial"
                            onChange={props.handleChange}
                            minRows={7}
                            id="teachingMaterialTextArea"
                            value={props.value}
                        />
                        <div className={styles.inputFeedbackContainer}>
                            <ErrorMessage
                                when={props.isChecked && !props.validation}
                                content="この項目は必須です。1000字以内で入力してください。"
                                style={{ marginRight: "auto", marginLeft: "0" }}
                            />
                            <div className={styles.textLength}>{props.value.length}/1000</div>
                        </div>
                    </>
                )}
            </div>
        </li>
    );
});
