import { Accordion, AccordionDetails, AccordionSummary, Button, useMediaQuery } from "@material-ui/core";
import { GiCheckMark } from "react-icons/gi";
import { VscTriangleDown } from "react-icons/vsc";
import { useHistory } from "react-router";

import { SubjectConfig } from "@/SubjectConfig";
import styles from "./index.module.scss";

import { SubjectDetailButton } from "./SubjectDetailButton";
import { memo, useCallback, useState } from "react";

interface Props {
    title: string;
    level: string;
    subjectConfig: SubjectConfig;
    mode: "student" | "teacher";
}

export const SchoolCategory: React.VFC<Props> = memo(function SchoolCategory(props) {
    const [expandedLevel, setExpandedLevel] = useState<string | undefined>(undefined);
    const [expandedCategory, setExpandedCategory] = useState<string | undefined>(undefined);
    const history = useHistory();

    const handleSearchButtonClick = useCallback(
        (category: string) => {
            if (props.mode === "teacher") {
                history.push(
                    `/SearchResults?searchType=publicRequest&keyword=&courseType=&level=${props.level}&category=${category}&subCategory=&rankNumber=&university=&faculty=&department=&orderBy=latest`,
                );
            } else {
                history.push(
                    `/SearchResults?searchType=course&keyword=&courseType=&level=${props.level}&category=${category}&subCategory=&rankNumber=&university=&faculty=&department=&orderBy=latest`,
                );
            }
        },
        [history, props.level, props.mode],
    );

    const handleExpandedLevelChange = useCallback(
        (panel: string) => (event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
            setExpandedLevel(newExpanded ? panel : undefined);
        },
        [],
    );

    const handleExpandedCategoryChange = useCallback(
        (panel: string) => (event: React.ChangeEvent<unknown>, newExpanded: boolean) => {
            setExpandedCategory(newExpanded ? panel : undefined);
        },
        [],
    );

    const smUp = useMediaQuery("(min-width:960px)");

    return (
        <div className={styles.categoriesWrapper} style={{ flex: props.title === "高校生" ? 2 : 1 }}>
            {/* <div className={styles.categoriesLevel}>{props.title}</div> */}
            <Accordion
                square
                expanded={expandedLevel === props.level}
                onChange={handleExpandedLevelChange(props.level)}
                className={styles.firstAccordion}
                style={{
                    margin: "0",
                    boxShadow: "none",
                    borderTop: props.level !== "university" ? "1px solid #CCC" : "none",
                }}
            >
                <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    // style={{
                    //     breakBefore: subjectKey === "basicChemistry" ? "column" : "auto",
                    //     // borderTop: subjectKey === "basicChemistry" ? "1px solid #F3F3F3" : "auto",
                    //     margin: subjectKey === "basicChemistry" ? "0 0 -1px 0" : "0",
                    //     borderTop:
                    //         idx === 0 || (props.level === "highSchool" && idx === Math.round(self.length / 2))
                    //             ? "none"
                    //             : "1px solid #CCC",
                    //     borderRight:
                    //         props.level === "highSchool" && idx < Math.round(self.length / 2)
                    //             ? "1px solid #CCC"
                    //             : "none",
                    //     borderLeft:
                    //         props.level === "highSchool" && idx >= Math.round(self.length / 2)
                    //             ? "1px solid #CCC"
                    //             : "none",
                    //     borderBottom:
                    //         props.level === "highSchool" &&
                    //         ((subjectKey === "physics" &&
                    //             expanded &&
                    //             !HIGH_SCHOOL_LEFT_CATEGORIES.includes(expanded)) ||
                    //             (subjectKey === "question" &&
                    //                 expanded &&
                    //                 HIGH_SCHOOL_LEFT_CATEGORIES.includes(expanded)))
                    //             ? "1px solid #CCC"
                    //             : "none",
                    // }}
                    className={styles.firstAccordionSummary}
                >
                    <div className={styles.categoriesLevelWrapper}>
                        <div className={styles.categoriesLevel}>{props.title}</div>
                        <VscTriangleDown className={styles.triangle} />
                    </div>
                </AccordionSummary>
                <AccordionDetails
                    style={{
                        display: "block",
                        padding: "0",
                        backgroundColor: "#EEE",
                        borderTop: expandedLevel === props.level ? "1px solid #CCC" : "none",
                    }}
                    className={styles.firstAccordionDetails}
                >
                    {Object.entries(props.subjectConfig).map(([subjectKey, subject], idx, self) => {
                        return (
                            <div key={subjectKey}>
                                {/* {SP版} */}
                                <Accordion
                                    square
                                    expanded={expandedCategory === subjectKey}
                                    onChange={handleExpandedCategoryChange(subjectKey)}
                                    className={styles.accordion}
                                    style={{
                                        margin: "0",
                                        breakBefore: subjectKey === "basicChemistry" ? "column" : "auto",
                                        boxShadow: "none",
                                    }}
                                >
                                    <AccordionSummary
                                        aria-controls="panel1d-content"
                                        id="panel1d-header"
                                        style={{
                                            breakBefore: subjectKey === "basicChemistry" ? "column" : "auto",
                                            margin: subjectKey === "basicChemistry" ? "0 0 -1px 0" : "0",
                                            borderTop: idx === 0 ? "none" : "1px solid #CCC",
                                        }}
                                        className={styles.secondAccordionSummary}
                                    >
                                        {subject.icon}
                                        <span>{subject.label}</span>
                                        <VscTriangleDown className={styles.triangle} />
                                    </AccordionSummary>
                                    <AccordionDetails
                                        style={{
                                            display: "block",
                                            padding: "0",
                                            backgroundColor: "#EEE",
                                        }}
                                        className={styles.secondAccordionDetails}
                                    >
                                        <Button
                                            key={subjectKey}
                                            onClick={() => {
                                                handleSearchButtonClick(subjectKey);
                                            }}
                                            className={styles.subjectDetailButton}
                                            style={{
                                                fontWeight: "bold",
                                            }}
                                        >
                                            <GiCheckMark className={styles.icon} />
                                            <div className={styles.title}>すべて</div>
                                        </Button>
                                        {subject.sub &&
                                            Object.entries(subject?.sub).map(([subjectDetailKey, subjectDetail]) => {
                                                return (
                                                    <SubjectDetailButton
                                                        idx={idx}
                                                        key={subjectDetailKey}
                                                        howManySubjects={self.length}
                                                        level={props.level}
                                                        category={subjectKey}
                                                        subjectDetail={subjectDetail}
                                                        subCategory={subjectDetailKey}
                                                    />
                                                );
                                            })}
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        </div>
    );
});
