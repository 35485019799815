import { useCallback, useEffect, useState } from "react";
import { Modal } from "../../../Modal";
import styles from "../index.module.scss";
import { WhiteButton } from "../../../Buttons/WhiteButton";
import { NavyButton } from "../../../Buttons/NavyButton";
import { useAddLessonToClassMutation, useDeleteLessonMutation, useUpdateLessonMutation } from "@/store/hooks/lessons";
import { useHistory, useParams } from "react-router";
import { getExistLessonConflict, getLessonPeriod } from "@/utils/LessonUtils";
import { dateToString } from "@/utils/DateUtils";
import { toast } from "react-toastify";
import { ToastContents } from "../../../Toast";
import { Processing } from "../../../Processing";
import { CreateLessonRequestParams, LessonResponse } from "@/store/autogenApi";
import { AiOutlineArrowDown } from "react-icons/ai";

interface Props {
    modalOpen: boolean;
    startHour: number | undefined;
    startMinute: number | undefined;
    endHour: number | undefined;
    endMinute: number | undefined;
    newLessonXIdx: number | undefined;
    lessonCalendarStartDate: Date;
    targetLesson: LessonResponse | CreateLessonRequestParams | undefined;
    isDeleteMode: boolean;
    lessons: (LessonResponse | CreateLessonRequestParams)[];
    handleLessonModalClose: () => void;
}

export const LessonModal: React.VFC<Props> = (props) => {
    const [startTime, setStartTime] = useState<Date | undefined>(undefined);
    const [endTime, setEndTime] = useState<Date | undefined>(undefined);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [existLessonConflict, setExistLessonConflict] = useState<boolean>(false);

    const { courseId, classId } = useParams<{ courseId: string; classId?: string }>();

    const updateLesson = useUpdateLessonMutation();
    const deleteLesson = useDeleteLessonMutation();

    const history = useHistory();

    useEffect(() => {
        if (
            props.startHour == undefined ||
            props.startMinute == undefined ||
            props.endHour == undefined ||
            props.endMinute == undefined
        )
            return;
        if (props.newLessonXIdx == undefined) return;

        const lessonCalendarStartDate = new Date(props.lessonCalendarStartDate);
        lessonCalendarStartDate.setDate(lessonCalendarStartDate.getDate() + props.newLessonXIdx);

        const lessonCalendarStartDateTime = new Date(lessonCalendarStartDate);
        lessonCalendarStartDateTime.setHours(props.startHour);
        lessonCalendarStartDateTime.setMinutes(props.startMinute);

        const endDateTime = new Date(lessonCalendarStartDate);
        endDateTime.setHours(props.endHour);
        endDateTime.setMinutes(props.endMinute);

        setStartTime(lessonCalendarStartDateTime);
        setEndTime(endDateTime);

        // 自分自身を除く
        const targetLessons = props.lessons.filter((lesson) => {
            if (
                props.targetLesson &&
                props.targetLesson.hasOwnProperty("lessonId") &&
                lesson.hasOwnProperty("lessonId")
            ) {
                const targetLessonResponse = props.targetLesson as LessonResponse;
                const lessonResponse = lesson as LessonResponse;
                return targetLessonResponse.lessonId !== lessonResponse.lessonId;
            } else {
                return true;
            }
        });
        const existConflict = getExistLessonConflict(
            [{ startTime: dateToString(lessonCalendarStartDateTime), endTime: dateToString(endDateTime) }],
            targetLessons,
        );
        setExistLessonConflict(existConflict);
    }, [
        props.startHour,
        props.startMinute,
        props.endHour,
        props.endMinute,
        props.newLessonXIdx,
        props.lessonCalendarStartDate,
    ]);

    const addLessonToClass = useAddLessonToClassMutation();

    const handleConfirmButtonClick = useCallback(async () => {
        if (!startTime || !endTime) return;
        if (!classId) return;
        if (props.isDeleteMode && !props.targetLesson?.hasOwnProperty("lessonId")) return;
        const targetLesson = props.targetLesson as LessonResponse;
        setIsProcessing(true);
        const { isSuccess } = props.targetLesson
            ? props.isDeleteMode
                ? await deleteLesson({
                      lessonId: targetLesson.lessonId,
                  })
                : await updateLesson({
                      updateLessonRequestBody: {
                          lessonId: targetLesson.lessonId,
                          startTime: dateToString(startTime),
                          endTime: dateToString(endTime),
                      },
                  })
            : await addLessonToClass({
                  addLessonToClassRequestBody: {
                      classId,
                      startTime: dateToString(startTime),
                      endTime: dateToString(endTime),
                  },
              });
        setIsProcessing(false);
        props.handleLessonModalClose();
        const action = props.isDeleteMode ? "削除" : props.targetLesson ? "変更" : "追加";
        if (isSuccess) {
            toast(<ToastContents title={`授業${action}完了`} isCompleted />);
            const start = `${startTime.getFullYear()}-${startTime.getMonth() + 1}-${startTime.getDate()}`;
            history.push(`/CourseManagement/${courseId}/${classId}?tag=lessonCalendar&start=${start}`);
        } else {
            toast(<ToastContents title={`授業${action}失敗`} isFailed />);
        }
    }, [
        addLessonToClass,
        courseId,
        classId,
        props.targetLesson,
        updateLesson,
        startTime,
        endTime,
        props.handleLessonModalClose,
    ]);

    return (
        <Modal open={props.modalOpen} onClose={props.handleLessonModalClose} style={{ maxWidth: "450px" }} autoResize>
            <div className={styles.lessonModalContents}>
                <div className={styles.lessonModalTitle}>授業予定の{props.isDeleteMode ? "削除" : "追加・変更"}</div>
                <div className={styles.confirmationWrapper}>
                    {existLessonConflict && !props.isDeleteMode ? (
                        <div className={styles.conflictError}>
                            他の授業と被っているため、この授業時間では授業を{props.targetLesson ? "追加" : "更新"}
                            できません
                        </div>
                    ) : (
                        <div className={styles.confirmation}>
                            {props.isDeleteMode
                                ? "下記の授業を削除します。よろしいですか？"
                                : props.targetLesson
                                ? "授業日程を下記のように変更します。よろしいですか？"
                                : "下記の授業を追加します。料金は発生しませんがよろしいですか？"}
                        </div>
                    )}
                    {(!existLessonConflict || props.isDeleteMode) && (
                        <>
                            {props.targetLesson && !props.isDeleteMode ? (
                                <div className={styles.updatePeriodWrapper}>
                                    <div className={styles.period}>
                                        {props.targetLesson != undefined &&
                                            getLessonPeriod(
                                                new Date(props.targetLesson.startTime),
                                                new Date(props.targetLesson.endTime),
                                            )}
                                    </div>
                                    <AiOutlineArrowDown className={styles.arrow} />
                                    <div className={styles.period}>{getLessonPeriod(startTime, endTime)}</div>
                                </div>
                            ) : (
                                <div className={styles.periodWrapper}>
                                    <div className={styles.period}>{getLessonPeriod(startTime, endTime)}</div>
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div
                    className={styles.buttonsWrapper}
                    style={{
                        display: !existLessonConflict || props.isDeleteMode ? "flex" : "block",
                        width: !existLessonConflict || props.isDeleteMode ? "auto" : "fit-content",
                    }}
                >
                    <WhiteButton handleClick={props.handleLessonModalClose} isFlex={!existLessonConflict}>
                        戻る
                    </WhiteButton>
                    {(!existLessonConflict || props.isDeleteMode) && (
                        <NavyButton handleClick={handleConfirmButtonClick} isFlex>
                            {isProcessing ? <Processing /> : "確定"}
                        </NavyButton>
                    )}
                </div>
            </div>
        </Modal>
    );
};
