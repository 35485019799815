import { Modal } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import { Link, useHistory } from "react-router-dom";

import styles from "@/pages/Common/SignUp/index.module.scss";

import { SignUpByEmail } from "./SignUpByEmail";

import { MdMail } from "react-icons/md";
import { memo } from "react";

interface Props {
    signUpMode: string;
    byEmail: boolean;
    userId: string | undefined;
    isSentVerificationCode: boolean;
    handleClose: () => void;
    handleGoogleButtonClick: (forStudent: boolean) => void;
    handleEmailButtonClick: () => void;
    handleSignUpAsStudentButtonClick: () => void;
    handleSignUpAsTeacherButtonClick: () => void;
    setSignUpMode: React.Dispatch<React.SetStateAction<string>>;
    setByEmail: React.Dispatch<React.SetStateAction<boolean>>;
    setUserId: React.Dispatch<React.SetStateAction<string | undefined>>;
    setIsSentVerificationCode: React.Dispatch<React.SetStateAction<boolean>>;
}

export const SignUpComponent: React.VFC<Props> = memo(function SignUpComponent(props) {
    const history = useHistory();
    return (
        <div className={styles.signUpWrapper}>
            <div className={styles.signUpContents}>
                <h1 className={styles.signUpTitle}>新規登録</h1>
                <div className={styles.accountType}>登録をご希望のアカウントタイプをクリックしてください。</div>
                <div className={styles.studentAndTeacherWrapper}>
                    <Button className={styles.signUpAsStudentButton} onClick={props.handleSignUpAsStudentButtonClick}>
                        <div className={styles.signUpAsStudent}>
                            <span className={styles.student}>生徒</span>
                            として登録
                        </div>
                    </Button>
                    <Button className={styles.signUpAsTeacherButton} onClick={props.handleSignUpAsTeacherButtonClick}>
                        <div className={styles.signUpAsTeacher}>
                            <span className={styles.teacher}>先生</span>
                            として登録
                        </div>
                    </Button>
                </div>
                <div className={styles.signInLink}>
                    既にアカウントをお持ちの方は
                    <Link className={styles.here} to="/SignIn">
                        こちら
                    </Link>
                </div>
                <Modal open={props.signUpMode !== ""} onClose={props.handleClose}>
                    <div className={styles.modalContents}>
                        <div className={styles.signUpContentsWrapper}>
                            <div className={styles.signUpModeTitle}>
                                {props.signUpMode === "student" ? "生徒" : "先生"}登録
                            </div>
                            <div className={styles.signUpContents}>
                                {props.byEmail ? (
                                    <SignUpByEmail
                                        setSignUpMode={props.setSignUpMode}
                                        setByEmail={props.setByEmail}
                                        signUpMode={props.signUpMode}
                                        userId={props.userId}
                                        setUserId={props.setUserId}
                                        isSentVerificationCode={props.isSentVerificationCode}
                                        setIsSentVerificationCode={props.setIsSentVerificationCode}
                                    />
                                ) : (
                                    <>
                                        <div className={styles.emailSignUpWrapper}>
                                            <Button
                                                className={styles.emailSignUpButton}
                                                onClick={props.handleEmailButtonClick}
                                            >
                                                <MdMail className={styles.emailIcon} />
                                                <div className={styles.emailSignUp}>メールアドレスで登録</div>
                                            </Button>
                                        </div>
                                        {/* <div className={styles.otherSignUpWrapper}>
                                        <div className={styles.or}>OR</div>
                                        <Button
                                            className={styles.googleSignUpButton}
                                            onClick={() =>
                                                props.handleGoogleButtonClick(
                                                    props.signUpMode === "student" ? true : false,
                                                )
                                            }
                                        >
                                            <img src={googleButtonImage} alt="" className={styles.googleButtonImage} />
                                        </Button>
                                    </div> */}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
});
