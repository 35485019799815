import styles from "@/pages/Common/LP/index.module.scss";
import { WhatsTrail } from "./WhatsTrail";
import { EasyToStart } from "./EasyToStart";
import { ThreeStepsSignUp } from "./ThreeStepsSignUp";
import { Achievements } from "./Achievements";
import { Background } from "./Background";
import { PostScript } from "./PostScript";
import backgroundImage from "@/images/background.jpg";
import { OverWrapper } from "@/components/OverWrapper";
import { Teachers } from "../LP/Teachers";
import { useMediaQuery } from "@mui/material";
import { LPTopContents } from "../LP/Top";

export const AboutTeacherContents: React.VFC = () => {
    const smUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:960px)");
    return (
        <OverWrapper
            className={styles.aboutContentsWrapper}
            style={{
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: "repeat",
                backgroundPosition: "center",
                margin: mdUp ? "0 auto" : smUp ? "0 -16px" : "0 -10px",
                padding: mdUp ? "0 100px 30px" : smUp ? "0 16px" : "0 10px",
                width: mdUp ? "calc(100% - 200px)" : "100%",
            }}
        >
            <LPTopContents
                subCatchCopy="で始める自由な先生活動"
                strongPoints={[
                    {
                        emphasizedDescription: "完全オンライン",
                        normalDescription: "なので家にいながら働ける",
                    },
                    {
                        emphasizedDescription: "時給は自由に設定",
                        normalDescription: "できるため、納得感のある報酬が得られる",
                    },
                    {
                        emphasizedDescription: "実績が溜まる",
                        normalDescription: "仕組みでどんどん有利に",
                    },
                ]}
            />
            <WhatsTrail />
            <Teachers />
            <EasyToStart />
            <Background />
            <ThreeStepsSignUp />
            <Achievements />
            {/* <Soon /> */}
            <PostScript />
        </OverWrapper>
    );
};
