import styles from "./index.module.scss";

import { memo, useCallback, useState } from "react";
import { Modal } from "@/components/Modal";
import { ButtonPair } from "@/components/ButtonPair";
import { Processing } from "@/components/Processing";
import { reportToCompleteClassMutation } from "@/store/hooks/classes";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";

interface Props {
    modalOpen: boolean;
    handleModalClose: () => void;
}

export const ReportModal: React.VFC<Props> = memo(function ReportModal(props) {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const { classId } = useParams<{ courseId: string; classId?: string }>();

    const reportToCompleteClass = reportToCompleteClassMutation();

    const handleConfirmButtonClick = useCallback(() => {
        (async () => {
            if (!classId) return;
            setIsProcessing(true);
            const { isSuccess } = await reportToCompleteClass({
                classId: classId,
            });
            setIsProcessing(false);
            if (isSuccess) {
                toast(<ToastContents title={`全授業完了報告完了`} isCompleted />);
            } else {
                toast(<ToastContents title={`全授業完了報告失敗`} isFailed />);
            }
            props.handleModalClose();
        })();
    }, [props.handleModalClose, classId]);

    return (
        <Modal open={props.modalOpen} onClose={props.handleModalClose}>
            <div className={styles.reportModalContents}>
                <div className={styles.reportDescription}>
                    全授業完了報告をします。以降は授業の作成・編集ができなくなりますが、よろしいですか？
                </div>
                <ButtonPair
                    buttonColor="navy"
                    loading={isProcessing}
                    leftButtonText="キャンセル"
                    rightButtonText="確定"
                    handleLeftButtonClick={props.handleModalClose}
                    handleRightButtonClick={handleConfirmButtonClick}
                />
            </div>
        </Modal>
    );
});
