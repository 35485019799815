import styles from "@/pages/Common/TeacherDetails/index.module.scss";

interface Props {
    background: string;
}

export const Background: React.VFC<Props> = (props) => {
    return (
        <>
            <section className={styles.backgroundWrapper}>
                <h2 className={styles.backgroundTitle}>経歴</h2>
                <div className={styles.background} style={{ overflowY: "auto", maxHeight: "none" }} id="background">
                    {props.background}
                </div>
            </section>
        </>
    );
};
