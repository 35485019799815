import { memo, useCallback, useState } from "react";
import { Box } from "@mui/material";
import { StripeAccount } from "@/store/autogenApi";
import { Modal } from "@/components/Modal";
import { PictureUpload } from "@/components/PictureUpload";
import { ButtonPair } from "@/components/ButtonPair";
import { Processing } from "@/components/Processing";
import { useResubmitVerificationDocumentMutation } from "@/store/hooks/stripe";
import { RootState } from "@/ducks";
import { useSelector } from "react-redux";
import { useUploadFileToStripe } from "@/utils/UploadFiles";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";

interface Props {
    stripeAccount: StripeAccount;
    modalOpen: boolean;
    handleModalClose: () => void;
}

const listItemSx = {
    width: "fit-content",
};

export const ResubmitModal: React.VFC<Props> = memo(function ResubmitModal(props) {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [file, setFile] = useState<File | undefined>(undefined);
    const [temporaryUrl, setTemporaryUrl] = useState<string | undefined>(undefined);

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId);

    const resubmitVerificationDocument = useResubmitVerificationDocumentMutation();

    const handleIdCardChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files;
        if (files && files.length > 0) {
            const file = files[0];
            setFile(file);
            const temporaryUrl = URL.createObjectURL(file);
            setTemporaryUrl(temporaryUrl);
        }
    }, []);
    const initializeFileInfo = useCallback(() => {
        setFile(undefined);
        setTemporaryUrl(undefined);
    }, []);
    const handleConfirmButtonClick = useCallback(async () => {
        try {
            setIsProcessing(true);
            if (!file) throw new Error("ファイルが選択されていません");
            const fileId = await useUploadFileToStripe(file);
            const { isSuccess } = await resubmitVerificationDocument({
                resubmitVerificationDocumentRequestBody: {
                    teacherId,
                    fileId,
                },
            });
            if (!isSuccess) throw new Error("エラーが発生しました");
            setIsCompleted(true);
            toast(<ToastContents title="本人確認書類再提出完了" isCompleted />);
        } catch (e) {
            setHasError(true);
            toast(<ToastContents title="本人確認書類再提出失敗" isFailed />);
        } finally {
            setIsProcessing(false);
        }
    }, [file, teacherId, resubmitVerificationDocument]);
    return (
        <Modal
            open={props.modalOpen}
            existCloseButton
            autoResize
            style={{ padding: "0 20px" }}
            onClose={props.handleModalClose}
        >
            <Box sx={{ margin: "20px 0", textAlign: "center" }}>
                {isCompleted ? (
                    <Box>提出が完了しました。審査が完了するまでお待ちください。</Box>
                ) : (
                    <>
                        {hasError ? (
                            <Box>エラーが発生しました。もう一度お試しください。</Box>
                        ) : (
                            <>
                                {file && temporaryUrl ? (
                                    <Box>
                                        <Box
                                            component="img"
                                            src={temporaryUrl}
                                            sx={{ width: "100%", height: "auto" }}
                                        />
                                        <Box sx={{ margin: "20px 0", textAlign: "center" }}>
                                            <Box>この画像を提出してよろしければ、「確定」を押してください。</Box>
                                            <Box sx={{ margin: "20px 0" }}>
                                                <ButtonPair
                                                    buttonColor="navy"
                                                    loading={isProcessing}
                                                    leftButtonText="戻る"
                                                    rightButtonText="確定"
                                                    handleLeftButtonClick={initializeFileInfo}
                                                    handleRightButtonClick={handleConfirmButtonClick}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Box
                                            sx={{
                                                fontSize: "20px",
                                                fontWeight: "bold",
                                                textAlign: "center",
                                                color: "#305077",
                                            }}
                                        >
                                            本人確認書類再提出
                                        </Box>
                                        <Box sx={{ margin: "10px 0" }}>
                                            <Box sx={{ fontWeight: "bold" }}>※提示可能な本人確認書類 </Box>
                                            <Box
                                                component="ul"
                                                sx={{
                                                    margin: "0 auto",
                                                    padding: "5px 0 5px 30px",
                                                    width: "fit-content",
                                                }}
                                            >
                                                <Box component="li" sx={listItemSx}>
                                                    日本旅券
                                                </Box>
                                                <Box component="li" sx={listItemSx}>
                                                    運転免許証
                                                </Box>
                                                <Box component="li" sx={listItemSx}>
                                                    在留カード・特別永住者証明書
                                                </Box>
                                                <Box component="li" sx={listItemSx}>
                                                    マイナンバーカード (顔写真付き)
                                                </Box>
                                                <Box component="li" sx={listItemSx}>
                                                    住民票
                                                </Box>
                                            </Box>
                                        </Box>
                                        <PictureUpload
                                            handleChange={handleIdCardChange}
                                            handleDeleteButtonClick={initializeFileInfo}
                                            temporaryUrl={temporaryUrl ?? ""}
                                            isChecked={false}
                                            mandatory
                                            sx={{ textAlign: "center" }}
                                        />
                                    </Box>
                                )}
                            </>
                        )}
                    </>
                )}
            </Box>
        </Modal>
    );
});
