import { useMediaQuery } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import { useHistory } from "react-router";

import bronzeBadge from "@/images/B.png";
import goldBadge from "@/images/G.png";
import platinumBadge from "@/images/P.png";
import regularBadge from "@/images/R.png";
import silverBadge from "@/images/S.png";
import { PublicTeacherResponse } from "@/store/autogenApi";

import styles from "./index.module.scss";

import { memo, useCallback } from "react";

import { getSubject } from "@/utils/SubjectUtils";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";
import { SchoolIcon } from "@/globalIcons";

interface Props {
    teacher?: PublicTeacherResponse;
    isHidden?: boolean;
    isFlex?: boolean;
    handleClick?: (teacher: PublicTeacherResponse) => void;
}

export const TeacherCard: React.VFC<Props> = memo(function TeacherCard(props) {
    const smUp = useMediaQuery("(min-width:600px)");
    const getSrc = useCallback((rankNumber: number) => {
        if (rankNumber === 1) {
            return regularBadge;
        } else if (rankNumber === 2) {
            return bronzeBadge;
        } else if (rankNumber === 3) {
            return silverBadge;
        } else if (rankNumber === 4) {
            return goldBadge;
        } else if (rankNumber === 5) {
            return platinumBadge;
        } else {
            return "";
        }
    }, []);

    const history = useHistory();
    const detail = useCallback(
        (teacherId?: string) => {
            if (props.handleClick) {
                props.handleClick(props.teacher);
                return;
            }
            if (!teacherId) return;
            history.push(`/TeacherDetails/${teacherId}`);
        },
        [history],
    );

    const getEducationalBackground = useCallback(() => {
        if (props.teacher?.educationalBackground == undefined) return "未登録";
        const educationalBackground = props.teacher?.educationalBackground;
        return `${educationalBackground.university} ${educationalBackground.faculty}`;
    }, [props.teacher?.educationalBackground]);

    return (
        <Card
            className={styles.card}
            style={{
                visibility: props.isHidden ? "hidden" : "visible",
                flex: props.isFlex ? 1 : "none",
                width: props.isFlex ? "auto" : smUp ? "130px" : "110px",
            }}
            onClick={() => {
                detail(props.teacher?.teacherId);
            }}
        >
            <CardContent className={styles.cardContent}>
                <div className={styles.avatarWrapper}>
                    <AvatarFromS3
                        url={props.teacher?.iconImageUrl}
                        objectKey={props.teacher?.iconImageObjectKey}
                        className={styles.avatar}
                    />
                    {props.teacher != undefined &&
                        props.teacher?.rankNumber != undefined &&
                        Boolean(getSrc(props.teacher?.rankNumber)) && (
                            <img src={getSrc(props.teacher?.rankNumber)} alt="" className={styles.rankBadge} />
                        )}
                </div>
                <div className={styles.nickname}>
                    <span className={styles.strong}>{props.teacher?.nickname}</span>先生
                </div>
                <div className={styles.educationalBackgroundWrapper}>
                    <SchoolIcon
                        className={
                            props.teacher?.isEducationalBackgroundVerified
                                ? styles.identifiedSchoolIcon
                                : styles.schoolIcon
                        }
                    />
                    <div className={styles.educationalBackground}>{getEducationalBackground()}</div>
                </div>
                {props.teacher?.teachableSubjects !== undefined && props.teacher.teachableSubjects[0] && (
                    <div className={styles.teachableSubjects}>
                        <div className={styles.subject}>
                            {props.teacher.teachableSubjects.length >= 2
                                ? `${getSubject(props.teacher.teachableSubjects[0])} 他`
                                : getSubject(props.teacher.teachableSubjects[0])}
                        </div>
                    </div>
                )}
                {/* <div className={styles.bottomWrapper}>
                    <div className={styles.totalAchievementsWrapper}>
                        <GiTeacher className={styles.achievementIcon} />
                        <div className={styles.totalAchievements}>{props.teacher?.totalAchievements ?? 0}</div>
                    </div>
                    <div className={styles.ratingWrapper}>
                        <AiFillStar className={styles.starIcon} />
                        <div className={styles.rating}>{props.teacher?.stats?.averageReviewScore ?? 0}</div>
                    </div>
                </div> */}
            </CardContent>
        </Card>
    );
});
