import { memo } from "react";
import styles from "@/pages/Teacher/Identification/index.module.scss";
import { PictureUpload } from "@/components/PictureUpload";

interface Props {
    temporaryUrl: string | undefined;
    handleIdCardChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    handleIdCardDeleteButtonClick: () => void;
    isChecked: boolean;
}

export const IdCard: React.VFC<Props> = memo(function IdCard(props) {
    return (
        <div className={styles.formItem} style={{ borderBottom: "1px solid #CCC" }}>
            <div className={styles.leftWrapper}>
                <div className={styles.itemTitle}>身分証</div>
                <div className={styles.mandatory}>必須</div>
            </div>
            <div className={styles.rightWrapper}>
                <section className={styles.message}>※提示可能な本人確認書類 </section>
                <ol className={styles.smallItems}>
                    <li className={styles.smallItem}>a) 運転免許証</li>
                    <li className={styles.smallItem}>b) パスポート</li>
                    <li className={styles.smallItem}>c) マイナンバーカード</li>
                    <li className={styles.smallItem}>d) 住民基本台帳</li>
                    <li className={styles.smallItem}>e) 在留カード</li>
                    <li className={styles.smallItem}>f) 運転経歴証明書</li>
                    <li className={styles.smallItem}>g) 特別永住者証明書</li>
                </ol>
                <PictureUpload
                    handleChange={props.handleIdCardChange}
                    handleDeleteButtonClick={props.handleIdCardDeleteButtonClick}
                    temporaryUrl={props.temporaryUrl}
                    isChecked={props.isChecked}
                    mandatory
                />
            </div>
        </div>
    );
});
