import styles from "./index.module.scss";

import { memo, useCallback, useState } from "react";
import { Modal } from "@/components/Modal";
import { ButtonPair } from "@/components/ButtonPair";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";
import { Processing } from "@/components/Processing";
import { useCloseApplicationMutation } from "@/store/hooks/classes";

interface Props {
    modalOpen: boolean;
    targetClassId: string;
    courseId: string;
    handleModalClose: () => void;
}

export const CloseApplicationModal: React.VFC<Props> = memo(function CloseApplicationModal(props) {
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const closeApplication = useCloseApplicationMutation();

    const handleConfirmButtonClick = useCallback(async () => {
        setIsProcessing(true);
        const result = await closeApplication({
            closeApplicationRequestBody: {
                classId: props.targetClassId,
                courseId: props.courseId,
            },
        });
        const isSuccess = result.isSuccess;
        setIsProcessing(false);
        if (isSuccess) {
            toast(<ToastContents title={`募集締切完了`} isCompleted />);
        } else {
            toast(<ToastContents title={`募集締切失敗`} isFailed />);
        }
        props.handleModalClose();
    }, [props.targetClassId, props.handleModalClose, props.courseId]);

    return (
        <Modal open={props.modalOpen} onClose={props.handleModalClose} style={{ maxWidth: "400px" }}>
            <div className={styles.CloseApplicationModalContents}>
                <div className={styles.noticeMessage}>
                    このクラスの再募集はできませんが、募集を締め切ってよろしいですか？
                </div>
                <ButtonPair
                    buttonColor="red"
                    loading={isProcessing}
                    leftButtonText="キャンセル"
                    rightButtonText="締切確定"
                    handleLeftButtonClick={props.handleModalClose}
                    handleRightButtonClick={handleConfirmButtonClick}
                />
            </div>
        </Modal>
    );
});
