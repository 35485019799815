import { Popover } from "@material-ui/core";

import { CreateBaseScheduleParams } from "@/store/autogenApi";

import styles from "./index.module.scss";
import { memo } from "react";
import { WeeklyScheduleSelector } from "./WeeklyScheduleSelector";
import { MultiWeekScheduleSelector } from "./MultiWeekScheduleSelector";

interface Props {
    open: boolean;
    isNewBaseScheduleChecked: boolean;
    newBaseSchedule: Partial<CreateBaseScheduleParams> | undefined;
    anchorEl: HTMLButtonElement | null;
    daysValidation: boolean;
    timeValidation: boolean;
    handleClose: () => void;
    handleStartTimeChange: (hour: number, minute: number) => void;
    handleHowManyMinutesChange: (howManyMinutes: number) => void;
    handleDayChange: (
        e: React.ChangeEvent<{
            checked: boolean;
        }>,
        dayIdx: number,
    ) => void;
    handleRepetitionUnitNumberChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleRepetitionUnitChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    handleHowManyTimesChange: (
        e: React.ChangeEvent<{
            value: unknown;
        }>,
    ) => void;
    confirmWeeklyBaseSchedule: () => void;
    confirmMultiWeekBaseSchedule: () => void;
}

export const BaseSchedulePopover: React.VFC<Props> = memo(function BaseSchedulePopover(props) {
    // NOTE: 暫定的に「○週に○回」とすると、1週間〜4週間しかないのでコメントアウト
    // const getValidRepetitionUnitNumberList = useCallback(() => {
    //     if (!props.newBaseSchedule) return [1];
    //     const repetitionUnit = props.newBaseSchedule.repetitionUnit ?? "week";
    //     switch (repetitionUnit) {
    //         case "day":
    //             return [...Array(6)].map((_, i) => i + 1);
    //         case "week":
    //             return [...Array(4)].map((_, i) => i + 1);
    //         case "month":
    //             return [1];
    //     }
    // }, [props.newBaseSchedule]);

    // const getValidHowManyTimesList = useCallback(() => {
    //     if (!props.newBaseSchedule) return [1];
    //     const repetitionUnitNumber = props.newBaseSchedule.repetitionUnitNumber ?? 1;
    //     const repetitionUnit = props.newBaseSchedule.repetitionUnit ?? "week";
    //     const dayArrayBaseNumber = repetitionUnitNumber === 1 ? 1 : repetitionUnitNumber - 1;
    //     const weekArrayBaseNumber = repetitionUnitNumber * 7 - 1;
    //     switch (repetitionUnit) {
    //         case "day":
    //             return [...Array(dayArrayBaseNumber)].map((_, i) => i + 1);
    //         case "week":
    //             return [...Array(weekArrayBaseNumber)].map((_, i) => i + 1);
    //         case "month":
    //             return [...Array(27)].map((_, i) => i + 1);
    //     }
    // }, [props.newBaseSchedule]);

    return (
        <Popover
            open={props.open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: "top",
                horizontal: "right",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "left",
            }}
            className={styles.timePopOver}
        >
            <div className={styles.popOverContents}>
                <div className={styles.intervalWrapper}>
                    <div className={styles.intervalTitle}>頻度</div>
                    <div className={styles.intervalFlex}>
                        <select
                            className={styles.repetitionUnitNumber}
                            defaultValue={1}
                            value={props.newBaseSchedule?.repetitionUnitNumber}
                            onChange={props.handleRepetitionUnitNumberChange}
                        >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                        </select>
                        週間に
                        <select
                            className={styles.howManyTimes}
                            defaultValue={1}
                            value={props.newBaseSchedule?.howManyTimes}
                            onChange={props.handleHowManyTimesChange}
                        >
                            <option value={1}>1</option>
                            <option value={2}>2</option>
                            <option value={3}>3</option>
                            <option value={4}>4</option>
                            <option value={5}>5</option>
                            <option value={6}>6</option>
                            <option value={7}>7</option>
                        </select>
                        回
                        {/* NOTE: 暫定的に「○週に○回」とする                      
                         <select
                            className={styles.repetitionUnitNumber}
                            defaultValue={1}
                            value={props.newBaseSchedule?.repetitionUnitNumber}
                            onChange={props.handleRepetitionUnitNumberChange}
                        >
                            {getValidRepetitionUnitNumberList().map((num) => (
                                <option value={num}>{num}</option>
                            ))}
                        </select>
                        <select
                            className={styles.repetitionUnit}
                            defaultValue="week"
                            value={props.newBaseSchedule?.repetitionUnit}
                            onChange={props.handleRepetitionUnitChange}
                        >
                            <option value="day">日</option>
                            <option value="week">週間</option>
                            <option value="month">か月</option>
                        </select>
                        に
                        <select
                            className={styles.howManyTimes}
                            defaultValue={1}
                            value={props.newBaseSchedule?.howManyTimes}
                            onChange={props.handleHowManyTimesChange}
                        >
                            {getValidHowManyTimesList().map((num) => (
                                <option value={num}>{num}</option>
                            ))}
                        </select>
                        回 */}
                    </div>
                </div>
                {props.newBaseSchedule?.repetitionUnit === "week" &&
                props.newBaseSchedule.repetitionUnitNumber === 1 ? (
                    <WeeklyScheduleSelector
                        isNewBaseScheduleChecked={props.isNewBaseScheduleChecked}
                        newBaseSchedule={props.newBaseSchedule}
                        daysValidation={props.daysValidation}
                        timeValidation={props.timeValidation}
                        handleStartTimeChange={props.handleStartTimeChange}
                        handleHowManyMinutesChange={props.handleHowManyMinutesChange}
                        handleDayChange={props.handleDayChange}
                        confirmWeeklyBaseSchedule={props.confirmWeeklyBaseSchedule}
                    />
                ) : (
                    <MultiWeekScheduleSelector
                        isNewBaseScheduleChecked={props.isNewBaseScheduleChecked}
                        newBaseSchedule={props.newBaseSchedule}
                        timeValidation={props.timeValidation}
                        handleHowManyMinutesChange={props.handleHowManyMinutesChange}
                        confirmMultiWeekBaseSchedule={props.confirmMultiWeekBaseSchedule}
                    />
                )}
            </div>
        </Popover>
    );
});
