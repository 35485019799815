import { Button } from "@material-ui/core";
import { memo, useCallback, useEffect, useState } from "react";
import { AiOutlineQuestionCircle, AiOutlineSchedule } from "react-icons/ai";
import { CgUserList } from "react-icons/cg";
import styles from "./index.module.scss";
import { ClassResponse, LessonResponse, PublicStudentResponse } from "@/store/autogenApi";
import { MdSchedule } from "react-icons/md";
import { UserInfoModal } from "@/components/UserInfoModal";
import { getBaseScheduleInfo } from "@/utils/BaseScheduleUtils";
import { getLessonTime } from "@/utils/LessonUtils";
import { getStatus } from "@/utils/ClassUtils";
import { FeeIcon, StatusIcon } from "@/globalIcons";
import { getTime } from "@/utils/DateUtils";
import { CloseApplicationModal } from "./CloseApplicationModal";
import { PlainButton } from "@/components/PlainButton";
import { UpdateMonthlyFeeModal } from "./UpdateMonthlyFeeModal";
import { UpdateBaseSchedulesModal } from "./UpdateBaseSchedulesModal";
import { ReportModal } from "./ReportModal";
import { LessonDetailsModal } from "./LessonDetailsModal";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    classResponse: ClassResponse;
}

export const ClassInfo: React.VFC<Props> = memo(function ClassInfo(props) {
    const [modalTargetStudent, setModalTargetStudent] = useState<PublicStudentResponse | null>(null);
    const [validLessons, setValidLessons] = useState<LessonResponse[]>([]);
    const [status, setStatus] = useState<string | undefined>(undefined);
    const [targetLesson, setTargetLesson] = useState<LessonResponse | undefined>(undefined);
    const [closeApplicationModalOpen, setCloseApplicationModalOpen] = useState<boolean>(false);
    const [updateMonthlyFeeModalOpen, setUpdateMonthlyFeeModal] = useState<boolean>(false);
    const [updateBaseSchedulesModalOpen, setUpdateBaseSchedulesModalOpen] = useState<boolean>(false);
    const [reportModalOpen, setReportModalOpen] = useState<boolean>(false);

    const [currentLessonsEl, setCurrentLessonsEl] = useState<HTMLElement | null>(null);

    const lessonsRef = useCallback((bodyEl: HTMLElement | null) => {
        setCurrentLessonsEl(bodyEl);
    }, []);

    useEffect(() => {
        const newStatus = getStatus(props.classResponse);
        setStatus(newStatus);
        const newValidLessons = props.classResponse.lessons.filter((lesson) => {
            const now = new Date();
            const endTime = new Date(lesson.endTime);
            return now < endTime;
        });
        setValidLessons(newValidLessons);
    }, [props.classResponse]);

    const getFormattedDate = useCallback((date: Date) => {
        const modifiedDate = new Date(date);
        const formatted_date =
            modifiedDate.getFullYear() +
            "-" +
            (modifiedDate.getMonth() + 1) +
            "-" +
            modifiedDate.getDate() +
            " " +
            ("00" + modifiedDate.getHours()).slice(-2) +
            ":" +
            ("00" + modifiedDate.getMinutes()).slice(-2);
        return formatted_date;
    }, []);

    const getJapaneseCourseType = useCallback((courseType: string) => {
        if (courseType === "short") {
            return "単発・短期講座";
        } else {
            return "定期講座";
        }
    }, []);

    const handleParticipantButtonClick = useCallback((student: PublicStudentResponse) => {
        setModalTargetStudent(student);
    }, []);

    const handleParticipantInfoModalClose = useCallback(() => {
        setModalTargetStudent(null);
    }, []);

    const handleLessonButtonClick = useCallback((lesson: LessonResponse) => {
        setTargetLesson(lesson);
    }, []);
    const handleLessonDetailsModalClose = useCallback(() => {
        setTargetLesson(undefined);
    }, []);

    const handleNoLongerAvailableButtonClick = useCallback(() => {
        window.open("/FAQDetails?type=cancelCourse");
    }, []);

    const handleCloseApplicationButtonClick = useCallback(() => {
        setCloseApplicationModalOpen(true);
    }, []);

    const handleCloseApplicationModalClose = useCallback(() => {
        setCloseApplicationModalOpen(false);
    }, []);

    const handleUpdateFeeButtonClick = useCallback(() => {
        setUpdateMonthlyFeeModal(true);
    }, []);

    const handleUpdateMonthlyFeeModalClose = useCallback(() => {
        setUpdateMonthlyFeeModal(false);
    }, []);

    const handleUpdateBaseSchedulesButtonClick = useCallback(() => {
        setUpdateBaseSchedulesModalOpen(true);
    }, []);

    const handleUpdateBaseSchedulesModalClose = useCallback(() => {
        setUpdateBaseSchedulesModalOpen(false);
    }, []);

    const handleReportButtonClick = useCallback(() => {
        setReportModalOpen(true);
    }, []);

    const handleReportModalClose = useCallback(() => {
        setReportModalOpen(false);
    }, []);

    return (
        <div className={styles.classInfo}>
            <div className={styles.statusWrapper}>
                <div className={styles.statusTitleWrapper}>
                    <StatusIcon className={styles.statusIcon} />
                    <div className={styles.statusTitle}>ステータス</div>
                </div>
                <div className={styles.statusBodyWrapper}>
                    <div className={styles.status}>{status}</div>
                    {status === "募集中" && (
                        <div className={styles.applyingDeadline}>
                            （締切：{getTime(props.classResponse.applyingDeadline)}）
                        </div>
                    )}
                </div>
                {status === "募集中" && (
                    <PlainButton noHorizontalPadding text="締め切る" handleClick={handleCloseApplicationButtonClick} />
                )}
                <CloseApplicationModal
                    modalOpen={closeApplicationModalOpen}
                    targetClassId={props.classResponse.classId}
                    courseId={props.classResponse.course?.courseId as string}
                    handleModalClose={handleCloseApplicationModalClose}
                />
            </div>
            {props.classResponse.course?.courseType === "regular" && props.classResponse.baseSchedules && (
                <div className={styles.baseScheduleWrapper}>
                    <div className={styles.baseScheduleTitleWrapper}>
                        <AiOutlineSchedule className={styles.baseScheduleIcon} />
                        <div className={styles.baseScheduleTitle}>基本スケジュール</div>
                    </div>
                    <div className={styles.baseSchedule}>
                        {props.classResponse.baseSchedules.map((baseSchedule) => (
                            <div className={styles.baseScheduleItemWrapper} key={baseSchedule.baseScheduleId}>
                                <div className={styles.baseScheduleItem}>{getBaseScheduleInfo(baseSchedule)}</div>
                            </div>
                        ))}
                    </div>
                    <div className={styles.baseScheduleDescription}>
                        {props.classResponse.baseSchedules.length === 0
                            ? "基本スケジュールは設定されていません。"
                            : "基本スケジュールに従って毎日1か月後の授業予定が自動で生成されます。実際の要望と差分がある場合には、手動で授業予定を修正してください。"}
                    </div>
                    <PlainButton noHorizontalPadding text="変更" handleClick={handleUpdateBaseSchedulesButtonClick} />
                    <UpdateBaseSchedulesModal
                        modalOpen={updateBaseSchedulesModalOpen}
                        targetClassId={props.classResponse.classId}
                        existingBaseSchedules={props.classResponse.baseSchedules ?? []}
                        handleModalClose={handleUpdateBaseSchedulesModalClose}
                    />
                </div>
            )}
            {props.classResponse.participants && (
                <div className={styles.participantsWrapper}>
                    <div className={styles.participantsTitleWrapper}>
                        <CgUserList className={styles.participantsIcon} />
                        <div className={styles.participantsTitle}>参加者</div>
                    </div>
                    {props.classResponse.participants.length === 0 ? (
                        <div className={styles.emptyParticipants}>参加者がいません</div>
                    ) : (
                        <>
                            <ul className={styles.participants}>
                                {props.classResponse.participants.map((participant) => {
                                    return (
                                        <li
                                            className={styles.participant}
                                            key={participant.studentId}
                                            onClick={() => {
                                                participant && handleParticipantButtonClick(participant);
                                            }}
                                        >
                                            <div className={styles.participantContentsWrapper}>
                                                <AvatarFromS3
                                                    className={styles.participantAvatar}
                                                    url={participant.iconImageUrl}
                                                    objectKey={participant.iconImageObjectKey}
                                                />
                                                <div
                                                    className={styles.participantNickname}
                                                    onClick={() => {
                                                        participant && handleParticipantButtonClick(participant);
                                                    }}
                                                >
                                                    {participant.nickname} さん
                                                </div>
                                                {participant.isWithdrawn && (
                                                    <div className={styles.isWithdrawn}>受講停止</div>
                                                )}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            {modalTargetStudent && (
                                <UserInfoModal
                                    openedTarget={modalTargetStudent}
                                    isChatVisible
                                    zIndex={1000}
                                    handleClose={handleParticipantInfoModalClose}
                                />
                            )}
                        </>
                    )}
                </div>
            )}
            <div className={styles.scheduleWrapper}>
                <div className={styles.scheduleTitleWrapper}>
                    <MdSchedule className={styles.scheduleIcon} />
                    <div className={styles.scheduleTitle}>授業予定</div>
                    <Button className={styles.noLongerAvailableButton} onClick={handleNoLongerAvailableButtonClick}>
                        <AiOutlineQuestionCircle className={styles.questionIcon} />
                        <div className={styles.noLongerAvailable}>予定していた授業ができなくなった場合</div>
                    </Button>
                </div>
                {validLessons.length > 0 ? (
                    <ul
                        className={styles.lessons}
                        ref={lessonsRef}
                        style={{
                            overflowY: "auto",
                            maxHeight: "none",
                        }}
                    >
                        {validLessons.map((lesson) => {
                            return (
                                <li
                                    className={styles.lesson}
                                    key={lesson.lessonId}
                                    onClick={() => {
                                        handleLessonButtonClick(lesson);
                                    }}
                                >
                                    <div className={styles.time}>{getLessonTime(lesson)}</div>
                                </li>
                            );
                        })}
                    </ul>
                ) : (
                    <div className={styles.noCourse}>授業予定はありません</div>
                )}
                {targetLesson && (
                    <LessonDetailsModal
                        lessonDetailsModalOpen={targetLesson != undefined}
                        targetLesson={targetLesson}
                        handleLessonDetailsModalClose={handleLessonDetailsModalClose}
                    />
                )}
            </div>

            {props.classResponse.pricePerPayment ? (
                <div className={styles.feeWrapper}>
                    <div className={styles.feeTitleWrapper}>
                        <FeeIcon className={styles.feeIcon} />
                        <div className={styles.feeTitle}>授業料</div>
                    </div>
                    <div className={styles.fee}>
                        {props.classResponse.course?.courseType === "short" && "（授業全体で）"}¥
                        {new Intl.NumberFormat("ja-JP").format(props.classResponse.pricePerPayment)}
                        {props.classResponse.course?.courseType === "regular" && "/月"}
                    </div>
                    {props.classResponse.course?.courseType === "regular" && (
                        <>
                            <PlainButton noHorizontalPadding text="変更" handleClick={handleUpdateFeeButtonClick} />
                            <UpdateMonthlyFeeModal
                                modalOpen={updateMonthlyFeeModalOpen}
                                targetClassId={props.classResponse.classId}
                                courseType={props.classResponse.course?.courseType}
                                pricePerPayment={props.classResponse.pricePerPayment}
                                handleModalClose={handleUpdateMonthlyFeeModalClose}
                            />
                        </>
                    )}
                </div>
            ) : (
                // 定期講座において支払い前の授業料が未定のとき
                <>
                    {props.classResponse.pricePerHour != undefined && (
                        <div className={styles.feeWrapper}>
                            <div className={styles.feeTitleWrapper}>
                                <FeeIcon className={styles.feeIcon} />
                                <div className={styles.feeTitle}>授業料（基準）</div>
                            </div>
                            <div className={styles.fee}>
                                ¥{new Intl.NumberFormat("ja-JP").format(props.classResponse.pricePerHour)}/時間
                            </div>
                        </div>
                    )}
                </>
            )}
            {props.classResponse.status === "ongoing" && props.classResponse.course?.courseType === "short" && (
                <div className={styles.reportWrapper}>
                    <div className={styles.reportDescription}>
                        短期・単発講座で予定されていた全ての授業が完了した場合、下記のボタンから全授業完了報告をしてください。その後生徒さんからレビューが届いた時点で売上金を獲得できます。
                    </div>
                    <PlainButton noHorizontalPadding text="全授業完了報告" handleClick={handleReportButtonClick} />
                    <ReportModal modalOpen={reportModalOpen} handleModalClose={handleReportModalClose} />
                </div>
            )}
        </div>
    );
});
