import styles from "@/pages/Common/TeacherDetails/index.module.scss";

interface Props {
    contentDescription: string;
}

export const ContentDescription: React.VFC<Props> = (props) => {
    return (
        <>
            <section className={styles.contentDescriptionWrapper}>
                <h2 className={styles.contentDescriptionTitle}>こんなことを教えています</h2>
                <div
                    className={styles.contentDescription}
                    style={{ overflowY: "auto", maxHeight: "none" }}
                    id="contentDescription"
                >
                    {props.contentDescription}
                </div>
            </section>
        </>
    );
};
