import styles from "@/pages/Common/LP/index.module.scss";
import createRequestImage from "@/images/createRequest.png";
import proposalImage from "@/images/proposal.png";
import zoomImage from "@/images/zoom.png";
import motherImage from "@/images/mother.png";
import boyImage from "@/images/boy.png";
import { useCallback, useState } from "react";
import { Box, Step, StepContent, StepIcon, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import { ArrowIcon } from "@/globalIcons";
import { Bold } from "@/components/atoms/Bold";
import { Modal } from "@/components/Modal";
import { ClickableButton } from "../../LP/ClickableButton";

const steps = [
    {
        label: "講座リクエストの作成",
        description: (
            <Box>
                入力フォームに沿ってリクエストを作成します。「数学の成績がまずいのでどうにかしてください...」など
                <Bold text="ラフにあなたの悩みをリクエストに" />
                してみましょう。リクエストを作成するとそれに対応できる先生から提案が届きます。
            </Box>
        ),
        flexDirection: "row",
        image: createRequestImage,
    },
    {
        label: "授業の依頼",
        description: (
            <Box>
                先生から提案が届いたら、その中から先生を吟味します。先生の経歴等も公開されているので、それらの情報をもとに
                <Bold text="あなた自身が先生を選んで" />
                授業を依頼します。無料面談もお願いすれば可能なので、自分にぴったりの先生を見つけましょう。
            </Box>
        ),
        flexDirection: "row",
        image: proposalImage,
    },
    {
        label: "受講",
        description: (
            <Box>
                授業を受けるための準備が整ったら、授業を
                <Bold text="オンライン会議ツールZoom" />
                を使って授業を受けます。ホワイトボード機能などもあるので有効に活用しましょう。
            </Box>
        ),
        flexDirection: "column",
        image: zoomImage,
    },
];

export const WhatsTrail: React.VFC = () => {
    const [targetImage, setTargetImage] = useState<string | undefined>(undefined);
    const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);

    const smUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:960px)");

    const handleImageClick = useCallback((image: string) => {
        setTargetImage(image);
        setDetailModalOpen(true);
    }, []);
    const handleModalClose = useCallback(() => {
        setDetailModalOpen(false);
    }, []);
    return (
        <div className={styles.whatsTrailWrapper}>
            <Box>
                <Box
                    sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "20px",
                    }}
                >
                    例えば、こんな悩みはありませんか？
                </Box>
                <div className={styles.worryExamples}>
                    <div className={styles.worryExample}>
                        <Box
                            component="img"
                            src={boyImage}
                            sx={{
                                width: "100px",
                            }}
                        />
                        <Box>
                            <Bold text="推薦" />
                            を取るために今度の
                            <Bold text="期末テスト" />
                            でなんとしても良い点を取りたい...
                        </Box>
                    </div>
                    <div className={styles.worryExample}>
                        <Box
                            component="img"
                            src={motherImage}
                            sx={{
                                width: "100px",
                            }}
                        />
                        <Box>
                            娘が最近
                            <Bold text="学校の数学" />
                            についていけなくなってきたのでどうにかしたい...
                        </Box>
                    </div>
                </div>
            </Box>
            <div className={styles.publicRequestWrapper}>
                <div className={styles.letsCreateRequestWrapper}>
                    <div>その悩み、Trailで</div>
                    <div className={styles.publicRequestTitle}>講座リクエスト</div>
                    <Box sx={{ marginTop: "10px" }}>にしてみましょう！</Box>
                </div>
                <div className={styles.aboutRequestWrapper}>
                    <div className={styles.aboutRequest}>
                        「講座リクエスト」とは、
                        <Bold text="あなたが抱える学習上の悩みを解決するための講座を依頼" />
                        することです。リクエストを作成するとそれに対応できる先生から提案が届きます。届いた複数の提案の中からあなたのニーズを満たしてくれる先生（授業）を
                        <Bold text="あなた自身で選ぶ" />
                        ことができます。しかも、
                        <Bold text="予算を自分で設定" />
                        できるため、限られた予算の中でも最大限の効果を得ることができます。
                    </div>
                </div>
                <div className={styles.requestStepsWrapper}>
                    <div className={styles.requestStepsTitle}>
                        受講までのかんたん
                        <Box component="span" sx={{ fontSize: "28px", padding: "0 4px" }}>
                            3
                        </Box>
                        STEP
                    </div>
                    <Stepper activeStep={steps.length} orientation="vertical" sx={{ marginTop: "10px" }}>
                        {steps.map((step) => (
                            <Step key={step.label} active>
                                <StepLabel
                                    sx={{
                                        "& .MuiStepLabel-label": {
                                            fontSize: "16px",
                                            lineHeight: "auto",
                                            fontWeight: "bold",
                                        },
                                    }}
                                    StepIconComponent={(props) => (
                                        <StepIcon
                                            {...props}
                                            icon={props.icon}
                                            active={props.active || props.completed}
                                            completed={false}
                                            sx={{
                                                color: "#305077 !important",
                                                fontWeight: "bold",
                                                "& .MuiStepIcon-text": {
                                                    fontSize: "14px",
                                                },
                                            }}
                                        />
                                    )}
                                >
                                    {step.label}
                                </StepLabel>
                                <StepContent
                                    sx={{
                                        paddingRight: 0,
                                        "& .MuiCollapse-wrapperInner": {
                                            display: "flex",
                                            flexDirection: step.flexDirection,
                                            alignItems: { xs: "flex-start", md: "center" },
                                            gap: { xs: "10px", md: "20px" },
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width:
                                                step.flexDirection === "row"
                                                    ? mdUp
                                                        ? "30%"
                                                        : smUp
                                                        ? "35%"
                                                        : "45%"
                                                    : mdUp
                                                    ? "60%"
                                                    : smUp
                                                    ? "70%"
                                                    : "100%",
                                            margin: "0 auto",
                                            position: "relative",
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            sx={{
                                                width: "100%",
                                                cursor: "pointer",
                                                "&:hover": {
                                                    opacity: 0.7,
                                                },
                                            }}
                                            alt="step image"
                                            src={step.image}
                                            onClick={() => handleImageClick(step.image)}
                                        />
                                        <ClickableButton handleClick={() => handleImageClick(step.image)} />
                                    </Box>
                                    <Box sx={{ flex: 1, fontSize: "16px", textAlign: "left" }}>{step.description}</Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    <Modal
                        open={detailModalOpen}
                        existCloseButton
                        autoResize
                        fade
                        scrollable
                        style={{ borderRadius: "30px", padding: "0 20px" }}
                        onClose={handleModalClose}
                    >
                        <Box
                            component="img"
                            src={targetImage}
                            sx={{ display: "block", width: { xs: "100%", md: "400px" }, margin: "20px auto" }}
                        />
                    </Modal>
                </div>
                <div className={styles.priceWrapper}>
                    <div className={styles.priceTitle}>授業料の目安</div>
                    <div className={styles.priceDescription}>
                        まず、講座の種類には「
                        <Bold text="単発・短期講座" />
                        」と「
                        <Bold text="定期講座" />
                        」の2種類があります。
                        <br />
                        いずれの場合も、
                        <Bold text="予算をあなた自身で設定" />
                        することができます。予算や目的に合わせて条件を整えて最適な依頼をしてみましょう。
                        <br />
                        <br />
                        <Bold text="単発・短期講座" />
                        は「来週の期末テストのための要点解説」のような1日〜数日程度の短いスパンで短期集中的に行う講座です。
                        単発・短期講座の授業料は講座全体で何円、というように決定されます。
                        <br />
                        <br />
                        一方の
                        <Bold text="定期講座" />
                        は「毎週土曜日に数学の授業フォロー」のような長いスパンで定期的に行う講座です。
                        定期講座の授業料は「時間単価」「週の授業回数」「各回の授業時間」によって月額で決定されます。
                        下に定期講座の授業料の目安をいくつか例示していますので参考にしてください。
                    </div>
                    <div className={styles.cases}>
                        <div className={styles.case}>
                            <div className={styles.caseTitle}>- 例1 -</div>
                            <div className={styles.caseContents}>
                                <div className={styles.conditionsWrapper}>
                                    <div className={styles.conditions}>
                                        <div className={styles.condition}>・時間単価：¥2,500</div>
                                        <div className={styles.condition}>・各授業1時間</div>
                                        <div className={styles.condition}>・週1回</div>
                                    </div>
                                </div>
                                <ArrowIcon />
                                <span className={styles.total}>
                                    ¥10,863<span className={styles.unit}>円/月</span>
                                </span>
                            </div>
                        </div>
                        <div className={styles.case}>
                            <div className={styles.caseTitle}>- 例2 -</div>
                            <div className={styles.caseContents}>
                                <div className={styles.conditionsWrapper}>
                                    <div className={styles.conditions}>
                                        <div className={styles.condition}>・時間単価：¥3,000</div>
                                        <div className={styles.condition}>・各授業1時間</div>
                                        <div className={styles.condition}>・週2回</div>
                                    </div>
                                </div>
                                <ArrowIcon />
                                <span className={styles.total}>
                                    ¥26,181<span className={styles.unit}>円/月</span>
                                </span>
                            </div>
                        </div>
                        <div className={styles.case}>
                            <div className={styles.caseTitle}>- 例3 -</div>
                            <div className={styles.caseContents}>
                                <div className={styles.conditionsWrapper}>
                                    <div className={styles.conditions}>
                                        <div className={styles.condition}>・時間単価：¥3,500</div>
                                        <div className={styles.condition}>・各授業1.5時間</div>
                                        <div className={styles.condition}>・週1回</div>
                                    </div>
                                </div>
                                <ArrowIcon />
                                <span className={styles.total}>
                                    ¥22,776<span className={styles.unit}>円/月</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={styles.averageWeeks}>
                        ※ ひと月の平均週数 = 365日 / 12ヶ月 / 7日 ≒ 4.345週として計算
                    </div>
                </div>
            </div>
        </div>
    );
};
