import { memo, useMemo } from "react";
import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { BankAccount, ErrorCode, PublicTeacherResponse } from "@/store/autogenApi";
import { Active } from "./Status/Active";
import { UnderReview } from "./Status/UnderReview";
import { Before } from "./Status/Before";
import { Error } from "./Status/Error";
import { StripeAccount } from "@/store/autogenApi";

interface Props {
    teacher: PublicTeacherResponse;
    balance: number;
    bankAccountList: BankAccount[];
    stripeAccount: StripeAccount;
}

type StripeStatus = "before" | "active" | "insufficientBalance" | "error" | "underReview";

export const ApplicationForTransferContents: React.VFC<Props> = memo(function ApplicationForTransferContents(props) {
    const hasValidBalance = useMemo<boolean>(() => props.balance != undefined && props.balance >= 100, [props.balance]);

    const isAcceptedStripeTerms = useMemo<boolean>(() => {
        const errors = props.stripeAccount.requirements.errors;
        if (errors.length === 0) return true;
        return !!errors.some((error) => error.code === ("tos_acceptance.date" as ErrorCode));
    }, [props.stripeAccount]);

    const hasRequirements = useMemo<boolean>(
        () => props.stripeAccount.requirements.currently_due.length >= 1,

        [props.stripeAccount],
    );

    const isTransferActive = useMemo<boolean>(
        () => props.stripeAccount?.capabilities?.transfers === "active",
        [props.stripeAccount],
    );

    const stripeStatus = useMemo<StripeStatus>(() => {
        // 同意前
        if (!isAcceptedStripeTerms) {
            return "before";
        }
        // 以降同意済
        if (isTransferActive) {
            if (hasValidBalance) {
                return "active";
            } else {
                return "insufficientBalance";
            }
        }
        if (hasRequirements) {
            return "error";
        }
        return "underReview";
    }, [isAcceptedStripeTerms, hasRequirements, isTransferActive]);

    return (
        <div className={styles.applicationForTransferWrapper}>
            <>
                {stripeStatus === "before" && <Before />}
                {stripeStatus === "insufficientBalance" && (
                    <div className={styles.cannotRequest}>
                        現在の保有売上金は{props.balance}ptです。100pt未満なので振込申請できません。
                    </div>
                )}
                {stripeStatus === "active" && (
                    <Active
                        teacher={props.teacher}
                        balance={props.balance}
                        bankAccountList={props.bankAccountList}
                        stripeAccount={props.stripeAccount}
                    />
                )}
                {stripeStatus === "underReview" && <UnderReview />}
                {stripeStatus === "error" && <Error stripeAccount={props.stripeAccount} />}
            </>
        </div>
    );
});
