import { useCallback, useState } from "react";

import { Button, CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

import { FileInfo } from "@/components/TrimmingModal";
import styles from "@/pages/Student/InfoInput/index.module.scss";
import { CreateStudentRequestBody, SubjectResponse } from "@/store/autogenApi";

import { FavoriteSubjectsComponent } from "@/pageComponents/Student/InfoInput/StudentInfoInput/Items/FavoriteSubjects";
import { IconImage } from "@/pageComponents/Student/InfoInput/StudentInfoInput/Items/IconImage";
import { NickName } from "@/pageComponents/Student/InfoInput/StudentInfoInput/Items/NickName";
import { TermsOfServiceAgreement } from "@/components/TermsOfServiceAgreement";

interface Props {
    createStudentRequestBody: CreateStudentRequestBody["studentBody"] | undefined;
    fileInfo: FileInfo | undefined;
    open: boolean;
    checked: boolean;
    favoriteSubjects: SubjectResponse[];
    isProcessing: boolean;
    setFavoriteSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
    handleClose: () => void;
    confirm: () => void;
}

export const InfoInputModal: React.VFC<Props> = (props) => {
    const [termsOfServiceIsChecked, setTermsOfServiceIsChecked] = useState<boolean>(false);

    const changeTermsOfServiceIsChecked = useCallback(() => {
        setTermsOfServiceIsChecked((prevState) => !prevState);
    }, [setTermsOfServiceIsChecked]);

    const handleConfirmButtonClick = () => {
        props.confirm();
    };

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={styles.modal}
            >
                <div className={styles.modalDiv}>
                    <div className={styles.infoInputTitle}>入力確認</div>
                    <div className={styles.bottomMessage}>入力内容を確認してください。</div>
                    <ul className={styles.inputList}>
                        <NickName
                            value={props.createStudentRequestBody?.nickname ?? ""}
                            open={props.open}
                            checked={props.checked}
                        />
                        <FavoriteSubjectsComponent
                            open={props.open}
                            checked={props.checked}
                            favoriteSubjects={props.favoriteSubjects}
                            setFavoriteSubjects={props.setFavoriteSubjects}
                        />
                        <IconImage fileInfo={props.fileInfo} open={props.open} checked={props.checked} />
                    </ul>
                    <div className={styles.bottomMessage}>
                        上記の内容でよろしければ、利用規約に同意の上、「確定」を押してください。
                    </div>
                    <TermsOfServiceAgreement
                        checked={termsOfServiceIsChecked}
                        changeTermsOfServiceIsChecked={changeTermsOfServiceIsChecked}
                    />
                    <div className={styles.buttonsWrapper}>
                        <Button className={styles.backButton} variant="contained" onClick={props.handleClose}>
                            戻る
                        </Button>
                        {props.isProcessing ? (
                            <Button className={styles.confirmButton} variant="contained">
                                <CircularProgress className={styles.progress} />
                            </Button>
                        ) : (
                            <Button
                                className={
                                    termsOfServiceIsChecked ? styles.confirmButton : styles.disabledConfirmButton
                                }
                                variant="contained"
                                onClick={handleConfirmButtonClick}
                                disabled={!termsOfServiceIsChecked}
                            >
                                確定
                            </Button>
                        )}
                    </div>
                </div>
            </Modal>
        </>
    );
};
