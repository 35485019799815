import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Button } from "@material-ui/core";

import styles from "@/pages/Teacher/MyPage/index.module.scss";
import { TransferConfirmModal } from "./TransferConfirmModal";
import { usePayoutMutation } from "@/store/hooks/stripe";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { BankAccounts } from "./BankAccounts";
import { AboutFeeModal } from "@/components/AboutFeeModal";
import { BankAccount, PublicTeacherResponse } from "@/store/autogenApi";
import { getRankStr } from "@/utils/TeacherUtils";
import { StripeAccount } from "@/store/autogenApi";
import { WhiteButton } from "@/components/Buttons/WhiteButton";

interface Props {
    teacher: PublicTeacherResponse;
    balance: number;
    bankAccountList: BankAccount[];
    stripeAccount: StripeAccount;
}

const TRANSFER_FEE = 0;

export const Active: React.VFC<Props> = memo(function Active(props) {
    const [amountAppliedFor, setAmountAppliedFor] = useState<number | undefined>(undefined);
    const [actualAmount, setActualAmount] = useState<number>(0);
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [checked, setChecked] = useState<boolean>(false);
    const [checkedBankAccount, setCheckedBankAccount] = useState<any | undefined>(undefined);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [aboutFeeModalOpen, setAboutFeeModalOpen] = useState<boolean>(false);

    const teacherId = useSelector((state: RootState) => state.jwt.teacherId as string);
    const payout = usePayoutMutation();

    useEffect(() => {
        const currentCheckedBankAccount = props.stripeAccount.external_accounts.data.find(
            (bankAccount) => bankAccount.default_for_currency,
        );
        setCheckedBankAccount(currentCheckedBankAccount);
    }, [props.stripeAccount]);

    const feePercentage = useMemo(() => 30 - props.teacher.rankNumber * 2, [props.teacher]);

    useEffect(() => {
        if (amountAppliedFor && amountAppliedFor > TRANSFER_FEE) {
            setActualAmount(amountAppliedFor * (1 - feePercentage / 100) - TRANSFER_FEE);
        } else {
            setActualAmount(0);
        }
    }, [amountAppliedFor, feePercentage]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<{ value: string }>) => {
            const currentCheckedBankAccount = props.stripeAccount.external_accounts.data.find(
                (bankAccount) => bankAccount.id === event.target.value,
            );
            setCheckedBankAccount(currentCheckedBankAccount);
        },
        [props.stripeAccount.external_accounts.data],
    );
    const handleAmountAppliedForChange = useCallback((e: React.ChangeEvent<{ value: unknown }>) => {
        if (e.target.value && !Number.isNaN(Number(e.target.value))) {
            setAmountAppliedFor(Number(e.target.value));
        } else {
            setAmountAppliedFor(undefined);
        }
    }, []);
    const handleCheckButtonClick = useCallback(() => {
        setChecked(true);
        if (
            amountAppliedFor &&
            props.balance &&
            amountAppliedFor > TRANSFER_FEE &&
            amountAppliedFor >= 100 &&
            amountAppliedFor <= props.balance &&
            amountAppliedFor <= 100000
        ) {
            setModalOpen(true);
        }
    }, [amountAppliedFor, props.balance, TRANSFER_FEE]);
    const handleModalClose = useCallback(() => {
        setModalOpen(false);
    }, []);
    const handleConfirmButtonClick = useCallback(async () => {
        setIsProcessing(true);
        const result = await payout({
            payoutRequestBody: {
                teacherId: teacherId,
                bankAccountId: checkedBankAccount.id,
                amount: amountAppliedFor as number,
            },
        });
        setIsProcessing(false);
        setAmountAppliedFor(undefined);
        setActualAmount(0);
        scrollTo(0, 0);
        if (result.isSuccess) {
            setIsCompleted(true);
        } else {
            setIsError(true);
        }
    }, [amountAppliedFor, checkedBankAccount, payout]);

    const handleClick = useCallback(() => {
        setAboutFeeModalOpen(true);
    }, []);
    const handleClose = useCallback(() => {
        setAboutFeeModalOpen(false);
    }, []);

    const handleBackButtonClick = useCallback(() => {
        setChecked(false);
        setIsError(false);
        setIsCompleted(false);
        setModalOpen(false);
    }, []);

    return (
        <>
            {isCompleted ? (
                <div className={styles.completeWrapper}>
                    <div className={styles.completeMessage}>振込申請が完了しました。</div>
                    <Button className={styles.completeBackButton} onClick={handleBackButtonClick}>
                        戻る
                    </Button>
                </div>
            ) : (
                <>
                    {isError ? (
                        <div className={styles.errorWrapper}>
                            <div className={styles.errorMessage}>処理中にエラーが発生しました。</div>
                            <Button className={styles.errorBackButton} onClick={handleBackButtonClick}>
                                戻る
                            </Button>
                        </div>
                    ) : (
                        <>
                            <BankAccounts
                                bankAccountList={props.bankAccountList}
                                checkedBankAccount={checkedBankAccount}
                                handleChange={handleChange}
                            />
                            <div className={styles.pointsAndAmountAppliedForWrapper}>
                                <div className={styles.inputTitle}>振込申請金額を入力</div>
                                <div className={styles.pointsWrapper}>
                                    <div className={styles.pointsTitle}>現在の売上金</div>
                                    <div className={styles.points}>{`¥${
                                        props.balance ? new Intl.NumberFormat("ja-JP").format(props.balance) : 0
                                    }`}</div>
                                </div>
                                <div className={styles.amountAppliedForWrapper}>
                                    <div className={styles.amountAppliedFor}>振込申請金額(¥100~100,000)</div>
                                    <div className={styles.textFieldWrapper}>
                                        <div className={styles.yen}>¥</div>
                                        <input
                                            placeholder="例)1234"
                                            className={styles.textField}
                                            required
                                            value={amountAppliedFor}
                                            onChange={handleAmountAppliedForChange}
                                        />
                                    </div>
                                </div>
                                {checked &&
                                    (!amountAppliedFor ||
                                        (amountAppliedFor != undefined &&
                                            (amountAppliedFor < 100 || amountAppliedFor > 100000))) && (
                                        <div className={styles.inputError}>
                                            100~{props.balance}
                                            の範囲で入力してください。
                                        </div>
                                    )}
                                {checked &&
                                    amountAppliedFor != undefined &&
                                    props.balance &&
                                    amountAppliedFor > props.balance && (
                                        <div className={styles.inputError}>売上金以下の値を入力してください。</div>
                                    )}
                            </div>
                            <div className={styles.feeAndActualPriceWrapper}>
                                <div className={styles.feeDescription}>先生ランクに応じた手数料が発生します。</div>
                                <WhiteButton handleClick={handleClick} className={styles.aboutFeeButton}>
                                    手数料について確認
                                </WhiteButton>
                                <AboutFeeModal open={aboutFeeModalOpen} handleClose={handleClose} />
                                <div className={styles.feeWrapper}>
                                    <div className={styles.feeTitle}>先生ランクに基づく手数料率</div>
                                    <div className={styles.fee}>
                                        {feePercentage}%（ランク：
                                        {getRankStr(props.teacher.rankNumber)}）
                                    </div>
                                </div>
                                <div className={styles.actualAmountWrapper}>
                                    <div className={styles.actualAmountTitle}>振込金額</div>
                                    <div className={styles.actualAmount}>
                                        {actualAmount > 0
                                            ? `¥${new Intl.NumberFormat("ja-JP").format(actualAmount)}`
                                            : "-"}
                                    </div>
                                </div>
                            </div>
                            <Button className={styles.checkButton} onClick={handleCheckButtonClick}>
                                確認
                            </Button>
                            {actualAmount != undefined && (
                                <TransferConfirmModal
                                    modalOpen={modalOpen}
                                    checkedBankAccount={checkedBankAccount}
                                    actualAmount={actualAmount}
                                    isProcessing={isProcessing}
                                    handleModalClose={handleModalClose}
                                    handleConfirmButtonClick={handleConfirmButtonClick}
                                />
                            )}
                        </>
                    )}
                </>
            )}
        </>
    );
});
