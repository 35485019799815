import { memo } from "react";
import { Modal } from "@/components/Modal";
import { DraftPublicRequestResponse } from "@/store/autogenApi";
import { UseDraftPublicRequestModalContents } from "./ModalContents";
import { useGetDraftPublicRequestQuery } from "@/store/hooks/draftPublicRequests";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";

interface Props {
    existPublicRequest: boolean;
    draftPublicRequestId: string;
    handleDiscardDraftButtonClick: () => void;
    handleUseDraftButtonClick: (draftPublicRequest: DraftPublicRequestResponse | undefined) => void;
}

export const ExistDraftPublicRequest: React.VFC<Props> = memo(function ExistDraftPublicRequest(props) {
    const draftPublicRequestQueryState = useGetDraftPublicRequestQuery(props.draftPublicRequestId);

    return (
        <QueryLoadingWrapper {...draftPublicRequestQueryState}>
            {(draftPublicRequest) => (
                <UseDraftPublicRequestModalContents
                    existPublicRequest={Boolean(props.existPublicRequest)}
                    draftPublicRequest={draftPublicRequest}
                    handleDiscardDraftButtonClick={props.handleDiscardDraftButtonClick}
                    handleUseDraftButtonClick={props.handleUseDraftButtonClick}
                />
            )}
        </QueryLoadingWrapper>
    );
});
