import { memo } from "react";
import styles from "@/pages/Common/CourseDetails/index.module.scss";

interface Props {
    detail: string;
}

export const CourseDetail: React.VFC<Props> = memo(function CourseDetail({ detail }) {
    return (
        <div className={styles.itemWrapper}>
            <div className={styles.itemHeadingWrapper}>
                <span className={styles.itemHeading}>こんなことを学べます</span>
            </div>
            <div className={styles.item} style={{ overflowY: "auto", maxHeight: "none" }} id="detail">
                {detail}
            </div>
        </div>
    );
});
