import styles from "@/pages/Common/LP/index.module.scss";
import shockImage from "@/images/shock.png";
import { Bold } from "@/components/atoms/Bold";

export const Background: React.VFC = () => {
    return (
        <div className={styles.backgroundWrapper}>
            <div className={styles.background}>
                <div className={styles.backgroundTitle}>Trailに込められた思い</div>
                <div className={styles.messages}>
                    多くの方々に支えられてリリースされた、【学び】のフリマアプリ Trail ですが、その背景にあったのは
                    <Bold text="今の塾業界に対する違和感" />
                    でした。
                    <br />
                    <br />
                    塾と聞くと、多くの親御さんが子どもの学習をサポートし成績を上げてくれる場所として、安心して利用していることでしょう。
                    しかし、本当に塾は子どもたちにとって最適な学習環境なのでしょうか...？
                    <br />
                    <br />
                    特に個別指導塾では、入塾を希望すると、
                    <Bold text="たまたまその塾にいた空いている先生が担当で割り当てられる" />
                    ことになります。 人生の分岐点である「受験」を任せる先生。そんな選び方をして大丈夫でしょうか...？
                    <br />
                    とは言っても、その他に選択肢がないため、多くの方がそのような状況を受け入れている、というより
                    そこに対して何の疑問も抱けていないのではないでしょうか。
                    <br />
                    <br />
                    <div className={styles.shockImageWrapper}>
                        <img src={shockImage} className={styles.shockImage} />
                        <div className={styles.shockImageDescription}>
                            しかしながら、そのような形で先生を選ぶことは、
                            <Bold text="ギャンブル" />
                            と言っても過言ではなく、高い授業料を払ったにもかかわらず、成績が伸びないという最悪の悲劇が生まれてしまいます。
                            親御さんや生徒さんは何も悪くありません。悪いのは形だけの管理を行う塾と、優秀な先生を探せる仕組みが確立されていない社会そのものです。
                        </div>
                    </div>
                    <br />
                    これらのことから、私たちは思いました。
                    <br />
                    <Bold text="生徒と先生を繋ぐ自由なプラットフォーム" />
                    があるべきだ、と。 であるならば、そのような環境を創出しよう。
                    そういう思いから生まれたのがこの「Trail」なのです。
                    <br />
                    <br />
                    開発には2年以上の時間を要しましたが、その間に多くの方々に支えていただき、ようやくリリースに至ることができました。
                    その時間を賭してまで開発を続けられた理由は、
                    <Bold text="Trailによって上に述べたような問題を解決することができると本気で信じている" />
                    からです。 成績に関して悩みや不安があるならぜひ一度で良いのでTrailを試してみてください。
                </div>
            </div>
        </div>
    );
};
