import { memo, useCallback } from "react";

import { FormControlLabel } from "@mui/material";
import { Checkbox } from "@material-ui/core";

import styles from "./index.module.scss";
import { TermsOfServiceContents } from "@/components/TermsOfServiceContents";

interface Props {
    checked: boolean;
    changeTermsOfServiceIsChecked: () => void;
}

export const TermsOfServiceAgreement: React.VFC<Props> = memo(function TermsOfServiceAgreement(props) {
    return (
        <div className={styles.termsOfService}>
            <TermsOfServiceContents className={styles.scroll} />
            <FormControlLabel
                className={styles.formControlLabel}
                control={
                    <Checkbox checked={props.checked} onChange={props.changeTermsOfServiceIsChecked} name="checked" />
                }
                label="利用規約に同意"
            />
        </div>
    );
});
