import styles from "@/pages/Common/LP/index.module.scss";
import requestDetailPageImage from "@/images/requestDetailPage.png";
import proposalImage from "@/images/proposal.png";
import zoomImage from "@/images/zoom.png";
import motherImage from "@/images/mother.png";
import boyImage from "@/images/boy.png";
import { useCallback, useState } from "react";
import { Box, Step, StepContent, StepIcon, StepLabel, Stepper, useMediaQuery } from "@mui/material";
import { ArrowIcon } from "@/globalIcons";
import { Bold } from "@/components/atoms/Bold";
import { Modal } from "@/components/Modal";
import { ClickableButton } from "../../LP/ClickableButton";
import BronzeBadge from "@/images/B.png";
import GoldBadge from "@/images/G.png";
import PlatinumBadge from "@/images/P.png";
import RegularBadge from "@/images/R.png";
import SilverBadge from "@/images/S.png";

const steps = [
    {
        label: "講座リクエストの検索",
        description: (
            <Box>
                トップページあるいは検索ページから講座リクエストを検索します。科目等で絞り込むことも可能です。
                <Bold text="あなたの強みを活かせる" />
                講座リクエストを見つけましょう。
            </Box>
        ),
        flexDirection: "row",
        image: requestDetailPageImage,
    },
    {
        label: "提案の作成",
        description: (
            <Box>
                良さそうな講座リクエストが見つかったら、提案を作成しましょう。自己紹介を含め、どのような授業を行うのかを生徒さんに伝えます。その際、
                <Bold text="時間単価はあなたが決める" />
                ことができます。実績に合わせて時間単価を設定しましょう。
            </Box>
        ),
        flexDirection: "row",
        image: proposalImage,
    },
    {
        label: "開講",
        description: (
            <Box>
                提案を受けて生徒さんから依頼があれば、授業を行います。 授業は
                <Bold text="オンライン会議ツールZoom" />
                を使って行います。ホワイトボード機能などもあるので有効に活用しましょう。
            </Box>
        ),
        flexDirection: "column",
        image: zoomImage,
    },
];

export const WhatsTrail: React.VFC = () => {
    const [targetImage, setTargetImage] = useState<string | undefined>(undefined);
    const [detailModalOpen, setDetailModalOpen] = useState<boolean>(false);

    const smUp = useMediaQuery("(min-width:600px)");
    const mdUp = useMediaQuery("(min-width:960px)");

    const handleImageClick = useCallback((image: string) => {
        setTargetImage(image);
        setDetailModalOpen(true);
    }, []);
    const handleModalClose = useCallback(() => {
        setDetailModalOpen(false);
    }, []);
    return (
        <div className={styles.whatsTrailWrapper}>
            <Box sx={{ background: "radial-gradient(rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%)" }}>
                <Box
                    sx={{
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "20px",
                    }}
                >
                    生徒ユーザーが抱える悩み
                </Box>
                <div className={styles.worryExamples}>
                    <div className={styles.worryExample}>
                        <Box
                            component="img"
                            src={boyImage}
                            sx={{
                                width: "100px",
                            }}
                        />
                        <Box>
                            <Bold text="推薦" />
                            を取るために今度の
                            <Bold text="期末テスト" />
                            でなんとしても良い点を取りたい...
                        </Box>
                    </div>
                    <div className={styles.worryExample}>
                        <Box
                            component="img"
                            src={motherImage}
                            sx={{
                                width: "100px",
                            }}
                        />
                        <Box>
                            娘が最近
                            <Bold text="学校の数学" />
                            についていけなくなってきたのでどうにかしたい...
                        </Box>
                    </div>
                </div>
            </Box>
            <div className={styles.publicRequestWrapper}>
                <div className={styles.letsCreateRequestWrapper}>
                    <div>Trailでは、それらが</div>
                    <div className={styles.publicRequestTitle}>講座リクエスト</div>
                    <Box sx={{ marginTop: "10px" }}>として公開されています</Box>
                </div>
                <div className={styles.aboutRequestWrapper}>
                    <div className={styles.aboutRequest}>
                        「講座リクエスト」とは、
                        <Bold text="生徒ユーザーが抱える学習上の悩みを解決するための講座依頼" />
                        のことです。リクエストに対して提案を送ると、生徒ユーザーは届いた提案の中から良いものを選びその先生に授業をお願いします。提案の際、
                        <Bold text="時給を自分で設定" />
                        できるため、実績に合わせて最大限の効果を得ることができます。
                    </div>
                </div>
                <div className={styles.requestStepsWrapper}>
                    <div className={styles.requestStepsTitle}>
                        開講までのかんたん
                        <Box component="span" sx={{ fontSize: "28px", padding: "0 4px" }}>
                            3
                        </Box>
                        STEP
                    </div>
                    <Stepper activeStep={steps.length} orientation="vertical" sx={{ marginTop: "10px" }}>
                        {steps.map((step) => (
                            <Step key={step.label} active>
                                <StepLabel
                                    sx={{
                                        "& .MuiStepLabel-label": {
                                            fontSize: "16px",
                                            lineHeight: "auto",
                                            fontWeight: "bold",
                                        },
                                    }}
                                    StepIconComponent={(props) => (
                                        <StepIcon
                                            {...props}
                                            icon={props.icon}
                                            active={props.active || props.completed}
                                            completed={false}
                                            sx={{
                                                color: "#305077 !important",
                                                fontWeight: "bold",
                                                "& .MuiStepIcon-text": {
                                                    fontSize: "14px",
                                                },
                                            }}
                                        />
                                    )}
                                >
                                    {step.label}
                                </StepLabel>
                                <StepContent
                                    sx={{
                                        paddingRight: 0,
                                        "& .MuiCollapse-wrapperInner": {
                                            display: "flex",
                                            flexDirection: step.flexDirection,
                                            alignItems: { xs: "flex-start", md: "center" },
                                            gap: { xs: "10px", md: "20px" },
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{
                                            width:
                                                step.flexDirection === "row"
                                                    ? mdUp
                                                        ? "30%"
                                                        : smUp
                                                        ? "35%"
                                                        : "45%"
                                                    : mdUp
                                                    ? "60%"
                                                    : smUp
                                                    ? "70%"
                                                    : "100%",
                                            margin: "0 auto",
                                            position: "relative",
                                        }}
                                    >
                                        <Box
                                            component="img"
                                            sx={{
                                                width: "100%",
                                                cursor: "pointer",
                                                "&:hover": {
                                                    opacity: 0.7,
                                                },
                                            }}
                                            alt="step image"
                                            src={step.image}
                                            onClick={() => handleImageClick(step.image)}
                                        />
                                        <ClickableButton handleClick={() => handleImageClick(step.image)} />
                                    </Box>
                                    <Box sx={{ flex: 1, fontSize: "16px", textAlign: "left" }}>{step.description}</Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                    <Modal
                        open={detailModalOpen}
                        existCloseButton
                        autoResize
                        fade
                        style={{ borderRadius: "30px", padding: "0 20px" }}
                        onClose={handleModalClose}
                    >
                        <Box
                            component="img"
                            src={targetImage}
                            sx={{ display: "block", width: { xs: "100%", md: "400px" }, margin: "20px auto" }}
                        />
                    </Modal>
                </div>
                <div className={styles.priceWrapper}>
                    <div className={styles.priceTitle}>授業料の目安</div>
                    <div className={styles.priceDescription}>
                        まず、講座の種類には「
                        <Bold text="単発・短期講座" />
                        」と「
                        <Bold text="定期講座" />
                        」の2種類があります。
                        <br />
                        いずれの場合も、
                        <Bold text="授業料はあなたが決める" />
                        ことができ、
                        <Bold text="先生ランクが高くなればなるほど手数料も低く" />
                        なります。
                    </div>
                    <table className={styles.feeTable}>
                        <tbody>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>ランク</th>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>レギュラー</td>
                                <td className={styles.tableData}>ブロンズ</td>
                                <td className={styles.tableData}>シルバー</td>
                                <td className={styles.tableData}>ゴールド</td>
                                <td className={styles.tableData}>プラチナ</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>バッジ</th>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>
                                    <img src={RegularBadge} alt="" className={styles.badgeImage} />
                                </td>
                                <td className={styles.tableData}>
                                    <img src={BronzeBadge} alt="" className={styles.badgeImage} />
                                </td>
                                <td className={styles.tableData}>
                                    <img src={SilverBadge} alt="" className={styles.badgeImage} />
                                </td>
                                <td className={styles.tableData}>
                                    <img src={GoldBadge} alt="" className={styles.badgeImage} />
                                </td>
                                <td className={styles.tableData}>
                                    <img src={PlatinumBadge} alt="" className={styles.badgeImage} />
                                </td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>累計授業数</th>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>1以上</td>
                                <td className={styles.tableData}>10以上</td>
                                <td className={styles.tableData}>30以上</td>
                                <td className={styles.tableData}>50以上</td>
                                <td className={styles.tableData}>100以上</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>直近3か月の売上金</th>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>¥30,000以上</td>
                                <td className={styles.tableData}>¥50,000以上</td>
                                <td className={styles.tableData}>¥100,000以上</td>
                                <td className={styles.tableData}>¥200,000以上</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>レビューの平均点</th>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>-</td>
                                <td className={styles.tableData}>3.0以上</td>
                                <td className={styles.tableData}>3.5以上</td>
                                <td className={styles.tableData}>4.0以上</td>
                                <td className={styles.tableData}>4.5以上</td>
                            </tr>
                            <tr className={styles.tableRow}>
                                <th className={styles.tableHeader}>先生の授業手数料</th>
                                <td className={styles.tableData}>30%</td>
                                <td className={styles.tableData}>28%</td>
                                <td className={styles.tableData}>26%</td>
                                <td className={styles.tableData}>24%</td>
                                <td className={styles.tableData}>22%</td>
                                <td className={styles.tableData}>20%</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={styles.priceDescription}>
                        <Bold text="単発・短期講座" />
                        は「来週の期末テストのための要点解説」のような1日〜数日程度の短いスパンで短期集中的に行う講座です。
                        単発・短期講座の授業料は講座全体で何円、というように決定されます。
                        <br />
                        <br />
                        一方の
                        <Bold text="定期講座" />
                        は「毎週土曜日に数学の授業フォロー」のような長いスパンで定期的に行う講座です。
                        定期講座の授業料は「時間単価」「週の授業回数」「各回の授業時間」によって月額で決定されます。
                        下に定期講座の授業料の目安をいくつか例示していますので参考にしてください。
                    </div>
                    <div className={styles.cases}>
                        <div className={styles.case}>
                            <div className={styles.caseTitle}>- 例1 -</div>
                            <div className={styles.caseContents}>
                                <div className={styles.conditionsWrapper}>
                                    <div className={styles.conditions}>
                                        <div className={styles.condition}>・ランク：なし</div>
                                        <div className={styles.condition}>・時間単価：¥3,000</div>
                                        <div className={styles.condition}>・各授業2時間</div>
                                        <div className={styles.condition}>・週2回</div>
                                    </div>
                                </div>
                                <ArrowIcon />
                                <Box sx={{ flex: 1 }}>
                                    <Box
                                        sx={{
                                            background:
                                                "linear-gradient(transparent 64%, rgba(255, 235, 125, 0.86) 0%)",
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "center",
                                            width: "fit-content",
                                            margin: "0 auto",
                                        }}
                                    >
                                        <Box sx={{ fontWeight: "bold" }}>¥36,498</Box>
                                        <Box sx={{ fontSize: "12px" }}>円/月</Box>
                                    </Box>
                                    <Box sx={{ marginTop: "10px" }}>
                                        <Box sx={{ fontSize: "12px" }}>
                                            この条件で2人の生徒さんを
                                            <br />
                                            担当した場合、
                                        </Box>
                                        <Box
                                            sx={{
                                                background:
                                                    "linear-gradient(transparent 64%, rgba(255, 235, 125, 0.86) 0%)",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "center",
                                                width: "fit-content",
                                                margin: "0 auto",
                                            }}
                                        >
                                            <Box sx={{ fontWeight: "bold" }}>¥72,996</Box>
                                            <Box sx={{ fontSize: "12px" }}>円/月</Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                        <div className={styles.case}>
                            <div className={styles.caseTitle}>- 例2 -</div>
                            <div className={styles.caseContents}>
                                <div className={styles.conditionsWrapper}>
                                    <div className={styles.conditions}>
                                        <div className={styles.condition}>・ランク：シルバーランク</div>
                                        <div className={styles.condition}>・時間単価：¥3,500</div>
                                        <div className={styles.condition}>・各授業1.5時間</div>
                                        <div className={styles.condition}>・週2回</div>
                                    </div>
                                </div>
                                <ArrowIcon />
                                <Box sx={{ flex: 1 }}>
                                    <Box
                                        sx={{
                                            background:
                                                "linear-gradient(transparent 64%, rgba(255, 235, 125, 0.86) 0%)",
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "center",
                                            width: "fit-content",
                                            margin: "0 auto",
                                        }}
                                    >
                                        <Box sx={{ fontWeight: "bold" }}>¥34,673</Box>
                                        <Box sx={{ fontSize: "12px" }}>円/月</Box>
                                    </Box>
                                    <Box sx={{ marginTop: "10px" }}>
                                        <Box sx={{ fontSize: "12px" }}>
                                            この条件で3人の生徒さんを
                                            <br />
                                            担当した場合、
                                        </Box>
                                        <Box
                                            sx={{
                                                background:
                                                    "linear-gradient(transparent 64%, rgba(255, 235, 125, 0.86) 0%)",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "center",
                                                width: "fit-content",
                                                margin: "0 auto",
                                            }}
                                        >
                                            <Box sx={{ fontWeight: "bold" }}>¥104,019</Box>
                                            <Box sx={{ fontSize: "12px" }}>円/月</Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                        <div className={styles.case}>
                            <div className={styles.caseTitle}>- 例3 -</div>
                            <div className={styles.caseContents}>
                                <div className={styles.conditionsWrapper}>
                                    <div className={styles.conditions}>
                                        <div className={styles.condition}>・ランク：プラチナランク</div>
                                        <div className={styles.condition}>・時間単価：¥5,000</div>
                                        <div className={styles.condition}>・各授業1時間</div>
                                        <div className={styles.condition}>・週2回</div>
                                    </div>
                                </div>
                                <ArrowIcon />
                                <Box sx={{ flex: 1 }}>
                                    <Box
                                        sx={{
                                            background:
                                                "linear-gradient(transparent 64%, rgba(255, 235, 125, 0.86) 0%)",
                                            display: "flex",
                                            alignItems: "flex-end",
                                            justifyContent: "center",
                                            width: "fit-content",
                                            margin: "0 auto",
                                        }}
                                    >
                                        <Box sx={{ fontWeight: "bold" }}>¥34,760</Box>
                                        <Box sx={{ fontSize: "12px" }}>円/月</Box>
                                    </Box>
                                    <Box sx={{ marginTop: "10px" }}>
                                        <Box sx={{ fontSize: "12px" }}>
                                            この条件で4人の生徒さんを
                                            <br />
                                            担当した場合、
                                        </Box>
                                        <Box
                                            sx={{
                                                background:
                                                    "linear-gradient(transparent 64%, rgba(255, 235, 125, 0.86) 0%)",
                                                display: "flex",
                                                alignItems: "flex-end",
                                                justifyContent: "center",
                                                width: "fit-content",
                                                margin: "0 auto",
                                            }}
                                        >
                                            <Box sx={{ fontWeight: "bold" }}>¥139,040</Box>
                                            <Box sx={{ fontSize: "12px" }}>円/月</Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                    <div className={styles.averageWeeks}>
                        ※ ひと月の平均週数 = 365日 / 12ヶ月 / 7日 ≒ 4.345週として計算
                    </div>
                </div>
            </div>
        </div>
    );
};
