import { memo, useCallback, useState } from "react";

import { Button, CircularProgress } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

import styles from "@/pages/Teacher/InfoInput/index.module.scss";

import { teacherConfig } from "@/pageComponents/Teacher/InfoInput/TeacherProfileInput/Config";
import { Background } from "@/pageComponents/Teacher/InfoInput/TeacherProfileInput/Items/Background";
import { BackgroundImage } from "@/pageComponents/Teacher/InfoInput/TeacherProfileInput/Items/BackgroundImage";
import { CatchCopy } from "@/pageComponents/Teacher/InfoInput/TeacherProfileInput/Items/CatchCopy";
import { ContentDescription } from "@/pageComponents/Teacher/InfoInput/TeacherProfileInput/Items/ContentDescription";
import { IconImage } from "@/pageComponents/Teacher/InfoInput/TeacherProfileInput/Items/IconImage";
import { NickName } from "@/pageComponents/Teacher/InfoInput/TeacherProfileInput/Items/NickName";
import { TeachableSubjectsComponent } from "@/pageComponents/Teacher/InfoInput/TeacherProfileInput/Items/TeachableSubjects";
import { SubjectResponse } from "@/store/autogenApi";
import { FileInfo } from "@/components/TrimmingModal";
import { TermsOfServiceAgreement } from "@/components/TermsOfServiceAgreement";

interface Props {
    iconImageFileInfo: FileInfo | undefined;
    backgroundImageFileInfo: FileInfo | undefined;
    confirmModalOpen: boolean;
    checked: boolean;
    teachableSubjects: SubjectResponse[];
    nickname: string;
    catchCopy: string;
    backgroundDescription: string;
    contentDescription: string;
    isProcessing: boolean;
    setTeachableSubjects: React.Dispatch<React.SetStateAction<SubjectResponse[]>>;
    handleClose: () => void;
    confirm: () => void;
}

export const InfoInputModal: React.VFC<Props> = memo(function InfoInputModal(props) {
    const [termsOfServiceIsChecked, setTermsOfServiceIsChecked] = useState<boolean>(false);

    const changeTermsOfServiceIsChecked = useCallback(() => {
        setTermsOfServiceIsChecked((prevState) => !prevState);
    }, [setTermsOfServiceIsChecked]);

    const confirmButtonClick = useCallback(() => {
        props.confirm();
    }, [props.confirm]);

    return (
        <>
            <Modal
                open={props.confirmModalOpen}
                onClose={props.handleClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={styles.modal}
            >
                <div className={styles.modalDiv}>
                    <div className={styles.infoInputTitle}>入力確認</div>
                    <div className={styles.bottomMessage}>入力内容を確認してください。</div>
                    <div className={styles.inputList}>
                        <NickName value={props.nickname} checked={props.checked} open={props.confirmModalOpen} />
                        <TeachableSubjectsComponent
                            open={props.confirmModalOpen}
                            checked={props.checked}
                            teachableSubjects={props.teachableSubjects}
                            setTeachableSubjects={props.setTeachableSubjects}
                        />
                        <CatchCopy value={props.catchCopy} checked={props.checked} open={props.confirmModalOpen} />
                        <Background
                            value={props.backgroundDescription}
                            checked={props.checked}
                            open={props.confirmModalOpen}
                        />
                        <ContentDescription
                            value={props.contentDescription}
                            checked={props.checked}
                            upperLimit={teacherConfig.contentDescription}
                            open={props.confirmModalOpen}
                        />
                        <IconImage
                            checked={props.checked}
                            confirmModalOpen={props.confirmModalOpen}
                            iconImageFileInfo={props.iconImageFileInfo}
                        />
                        <BackgroundImage
                            checked={props.checked}
                            confirmModalOpen={props.confirmModalOpen}
                            backgroundImageFileInfo={props.backgroundImageFileInfo}
                        />
                    </div>
                    <div className={styles.bottomMessage}>
                        {props.isProcessing
                            ? "登録処理中です。この処理は時間がかかることがあります。しばらくそのままお待ちください。"
                            : "上記の内容でよろしければ、利用規約に同意の上、「確定」を押してください。"}
                    </div>
                    <TermsOfServiceAgreement
                        checked={termsOfServiceIsChecked}
                        changeTermsOfServiceIsChecked={changeTermsOfServiceIsChecked}
                    />
                    <Button className={styles.backButton} variant="contained" onClick={props.handleClose}>
                        戻る
                    </Button>
                    {props.isProcessing ? (
                        <Button className={styles.confirmButton} variant="contained">
                            <CircularProgress className={styles.progress} />
                        </Button>
                    ) : (
                        <Button
                            className={termsOfServiceIsChecked ? styles.confirmButton : styles.disabledConfirmButton}
                            variant="contained"
                            onClick={confirmButtonClick}
                            disabled={!termsOfServiceIsChecked}
                        >
                            確定
                        </Button>
                    )}
                </div>
            </Modal>
        </>
    );
});
