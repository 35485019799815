import { memo } from "react";
import { Modal as MuiModal } from "@mui/material";
import { Fade } from "@mui/material";
import { ModalContents } from "./ModalContents";

interface Props {
    open: boolean;
    onClose: () => void;
    children: React.ReactNode;
    style?: React.CSSProperties;
    existCloseButton?: boolean;
    autoResize?: boolean;
    fade?: boolean;
    scrollable?: boolean;
    // 親側でmaxModalContentsHeightを取得したい場合に使用（LessonCalendar等）
    handleMaxModalContentsHeight?: (maxModalHeight: number) => void;
}

export const Modal: React.VFC<Props> = memo(function Modal(props) {
    const { fade, ...others } = props;

    return (
        <MuiModal open={props.open} onClose={props.onClose}>
            {props.fade ? (
                <Fade
                    in={props.open}
                    timeout={{
                        enter: 1000,
                        exit: 500,
                    }}
                >
                    <div>
                        <ModalContents {...others} />
                    </div>
                </Fade>
            ) : (
                <ModalContents {...others} />
            )}
        </MuiModal>
    );
});
