import { memo, useCallback } from "react";

import { Button } from "@material-ui/core";
import { useHistory } from "react-router";

import styles from "@/pages/Teacher/MyPage/index.module.scss";

export const Before: React.VFC = memo(function Before() {
    const history = useHistory();
    const handleInfoInputButtonClick = useCallback(() => {
        history.push("/IdentificationForStripe");
    }, [history]);

    return (
        <div className={styles.stripeWrapper}>
            <div className={styles.description}>
                Trailでは先生の登録口座に入金するための仕組みとして世界25カ国以上で導入されている決済サービス「
                <a href="https://stripe.com/jp">Stripe</a>
                」を導入しています。振込申請を行うには、Stripeの定める情報入力が必要です。
            </div>
            <Button className={styles.infoInputButton} onClick={handleInfoInputButtonClick}>
                情報入力へ
            </Button>
        </div>
    );
});
