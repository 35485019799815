import { memo } from "react";
import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";

interface Props {
    remarks?: string;
}

export const Remarks: React.VFC<Props> = memo(function Remarks(props) {
    return (
        <div className={styles.detailWrapper}>
            <div className={styles.detailHeadingWrapper}>
                <span className={styles.detailHeading}>備考</span>
            </div>
            <div className={styles.detail} style={{ overflowY: "auto", maxHeight: "none" }}>
                {props.remarks ?? "-"}
            </div>
        </div>
    );
});
