import { memo } from "react";
import styles from "@/pages/Common/CourseDetails/index.module.scss";

interface Props {
    teachingMaterial: string;
}

export const TeachingMaterial: React.VFC<Props> = memo(function TeachingMaterial(props) {
    return (
        <div className={styles.itemWrapper}>
            <div className={styles.itemHeadingWrapper}>
                <span className={styles.itemHeading}>教材・必要な準備</span>
            </div>
            <div className={styles.item} style={{ overflowY: "auto", maxHeight: "none" }} id="teachingMaterial">
                {props.teachingMaterial}
            </div>
        </div>
    );
});
