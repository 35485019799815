import { memo } from "react";
import { Mandatory } from "@/components/Tag/Mandatory";
import styles from "@/pages/Teacher/CreateCourse/index.module.scss";
import { ErrorMessage } from "@/components/ErrorMessage";

interface Props {
    handleChange: (
        e: React.ChangeEvent<{
            name?: string;
            value: unknown;
        }>,
    ) => void;
    isChecked: boolean;
    value: string | undefined;
    open?: boolean;
    validation: boolean;
}

export const TargetDescription: React.VFC<Props> = memo(function TargetDescription(props) {
    return (
        <li className={styles.listItem}>
            <div className={styles.leftWrapper}>
                <div className={styles.titleWrapper}>
                    <div className={styles.title}>こんな人におすすめ</div>
                    {!props.open && <Mandatory />}
                </div>
            </div>
            <div className={styles.rightWrapper}>
                {props.open ? (
                    <div className={styles.openTrue}>{props.value}</div>
                ) : (
                    <>
                        <input
                            type="text"
                            placeholder="こんな人におすすめ(必須40字以内)"
                            className={styles.input}
                            onChange={props.handleChange}
                            name="targetDescription"
                            autoComplete="off"
                            value={props.value}
                        />
                        <div className={styles.inputFeedbackContainer}>
                            <ErrorMessage
                                when={props.isChecked && !props.validation}
                                content="この項目は必須です。40字以内で入力してください。"
                                style={{ marginRight: "auto", marginLeft: "0" }}
                            />
                            <div className={styles.textLength}>{props.value.length}/40</div>
                        </div>
                    </>
                )}
            </div>
        </li>
    );
});
