import { Box } from "@mui/material";
import { memo } from "react";
import { Link } from "react-router-dom";

export const UnderReview: React.VFC = memo(function UnderReview() {
    return (
        <Box
            sx={{
                textAlign: "left",
                padding: "0 20px",
                fontSize: "14px",
            }}
        >
            現在、Stripeの情報審査中です。
            <br />
            審査結果をお待ちいただいている時間が予想外に長いと思われる場合は、
            <Link to="/inquiry">お問い合わせ</Link>ください。
        </Box>
    );
});
