import { memo, useCallback } from "react";
import styles from "@/pages/Common/TeacherDetails/index.module.scss";
import { SubjectResponse } from "@/store/autogenApi";
import { useHistory } from "react-router";
import {
    ELEMENTARY_SCHOOL_SUBJECT_CONFIG,
    HIGH_SCHOOL_SUBJECT_CONFIG,
    JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG,
    UNIVERSITY_SUBJECT_CONFIG,
} from "@/SubjectConfig";

interface Props {
    subjects: SubjectResponse[];
}

export const Subjects: React.VFC<Props> = memo(function Subjects(props) {
    const history = useHistory();

    const handleSubjectButtonClick = useCallback((subject: SubjectResponse) => {
        const { level, category, subCategory } = subject;
        history.push(`/SearchResults?searchType=course&level=${level}&category=${category}&subCategory=${subCategory}`);
    }, []);

    const getConfig = useCallback((level?: string) => {
        switch (level) {
            case "university":
                return UNIVERSITY_SUBJECT_CONFIG;
            case "highSchool":
                return HIGH_SCHOOL_SUBJECT_CONFIG;
            case "juniorHighSchool":
                return JUNIOR_HIGH_SCHOOL_SUBJECT_CONFIG;
            case "elementarySchool":
                return ELEMENTARY_SCHOOL_SUBJECT_CONFIG;
        }
    }, []);

    const getLevelName = useCallback((level?: string) => {
        switch (level) {
            case "university":
                return "大学";
            case "highSchool":
                return "高校";
            case "juniorHighSchool":
                return "中学校";
            case "elementarySchool":
                return "小学校";
        }
    }, []);

    const getSubjectString = useCallback(
        (subject: SubjectResponse) => {
            const config = getConfig(subject.level);
            const schoolName = getLevelName(subject.level);
            if (config && schoolName && subject.category) {
                const categoryObject = config[subject.category];
                const categoryName = categoryObject.label;
                const subCategoryName =
                    categoryObject.sub && subject.subCategory
                        ? categoryObject.sub[subject.subCategory].label
                        : undefined;
                if (subCategoryName) {
                    return `${schoolName} - ${categoryName} - ${subCategoryName}`;
                } else {
                    return `${schoolName} - ${categoryName}`;
                }
            }
        },
        [getConfig, getLevelName],
    );

    return (
        <>
            <section className={styles.subjectsWrapper}>
                <h2 className={styles.subjectsTitle}>対応可能科目</h2>
                <div className={styles.subjects} style={{ overflowY: "auto", maxHeight: "none" }} id="subjects">
                    {props.subjects.map((subject) => (
                        <button
                            className={styles.subject}
                            key={subject.subjectId}
                            onClick={() => {
                                handleSubjectButtonClick(subject);
                            }}
                        >
                            {getSubjectString(subject)}
                        </button>
                    ))}
                </div>
            </section>
        </>
    );
});
