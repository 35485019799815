import { useState, useEffect, memo } from "react";
import styles from "@/pages/Common/CourseDetails/index.module.scss";

interface Props {
    target: string;
}

export const Target: React.VFC<Props> = memo(function Target(props) {
    return (
        <div className={styles.itemWrapper}>
            <div className={styles.itemHeadingWrapper}>
                <span className={styles.itemHeading}>受講対象</span>
            </div>
            <div className={styles.item} style={{ overflowY: "auto", maxHeight: "none" }} id="target">
                {props.target}
            </div>
        </div>
    );
});
