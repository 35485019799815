import { memo, useCallback, useEffect, useState } from "react";
import { AnnouncementResponse } from "@/store/autogenApi";
import { Button, useMediaQuery } from "@material-ui/core";
import styles from "./index.module.scss";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import { PageNumbers } from "@/components/PageNumbers";
import trailLogo from "@/images/logo-v2.jpg";
import { useUpdateTeacherCheckedAnnouncementsMutation } from "@/store/hooks/teachers";
import { useSelector } from "react-redux";
import { RootState } from "@/ducks";
import { useUpdateStudentCheckedAnnouncementsMutation } from "@/store/hooks/students";
import { useHistory, useParams } from "react-router";
import { ButtonPair } from "@/components/ButtonPair";
import parse from "html-react-parser";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    announcements: AnnouncementResponse[];
    checkedAnnouncementIds: string[];
    handlePreviousCheckedAnnouncementIdsChange: (announcements: string[]) => void;
}

const ITEMS_PER_PAGE = 20;

export const AnnouncementsContents: React.VFC<Props> = memo(function TeacherAnnouncementsContents(props) {
    const [detailIdx, setDetailIdx] = useState<number | undefined>(undefined);
    const [pageNumber, setPageNumber] = useState(1);

    const updateStudentCheckedAnnouncements = useUpdateStudentCheckedAnnouncementsMutation();
    const updateTeacherCheckedAnnouncements = useUpdateTeacherCheckedAnnouncementsMutation();
    const { studentId, teacherId, userMode } = useSelector((state: RootState) => state.jwt);

    const { announcementId } = useParams<{ announcementId?: string }>();

    const sm = useMediaQuery("(min-width:960px)");

    const smallThresholdUp = useMediaQuery("(min-width:450px)");
    const mediumThresholdUp = useMediaQuery("(min-width:600px)");
    const largeThresholdUp = useMediaQuery("(min-width:1000px)");

    const thresholdNumber = largeThresholdUp ? 10 : mediumThresholdUp ? 8 : smallThresholdUp ? 6 : 4;

    const history = useHistory();

    useEffect(() => {
        if (!announcementId) return;
        const targetAnnouncementIdx = props.announcements.findIndex(
            (announcement) => announcement.announcementId === announcementId,
        );
        if (targetAnnouncementIdx === -1) return;
        setDetailIdx(targetAnnouncementIdx);
    }, [props.announcements, announcementId]);

    useEffect(() => {
        props.handlePreviousCheckedAnnouncementIdsChange(props.checkedAnnouncementIds);
    }, [props.checkedAnnouncementIds, props.handlePreviousCheckedAnnouncementIdsChange]);

    const getDiffTime = useCallback((createdAt: Date) => {
        const currentTime = new Date();
        const fixedTime = new Date(createdAt);
        const diffTimeByMillisecond = currentTime.getTime() - fixedTime.getTime();
        const diffDate = Math.floor(diffTimeByMillisecond / (1000 * 60 * 60 * 24));
        const diffHours = Math.floor(diffTimeByMillisecond / (1000 * 60 * 60)) - diffDate * 24;
        const diffMinutes = Math.floor(diffTimeByMillisecond / (1000 * 60)) - diffHours * 60;
        if (diffDate === 0) {
            if (diffHours === 0) {
                if (diffMinutes === 0) {
                    return "ちょうど今";
                } else {
                    return diffMinutes + "分前";
                }
            } else {
                return diffHours + "時間前";
            }
        } else {
            return diffDate + "日前";
        }
    }, []);

    const getDateWithString = useCallback((createdAt: Date | undefined) => {
        if (createdAt) {
            const date = new Date(createdAt);
            const month = ("00" + (date.getMonth() + 1)).slice(-2);
            const day = ("00" + date.getDate()).slice(-2);
            const hour = ("00" + date.getHours()).slice(-2);
            const minute = ("00" + date.getMinutes()).slice(-2);
            const result = month + "月" + day + "日 " + hour + ":" + minute;
            return result;
        }
    }, []);

    const handleDetailButtonClick = useCallback(
        (idx: number) => {
            setDetailIdx(idx);
            (async () => {
                if (userMode === "student") {
                    await updateStudentCheckedAnnouncements({
                        studentId: studentId as string,
                        announcementId: props.announcements[idx].announcementId,
                    });
                } else if (userMode === "teacher") {
                    await updateTeacherCheckedAnnouncements({
                        teacherId: teacherId as string,
                        announcementId: props.announcements[idx].announcementId,
                    });
                }
            })();
        },
        [props.announcements, userMode, studentId, teacherId],
    );

    const handleRightButtonClick = useCallback(() => {
        if (detailIdx == undefined) return;
        const targetAnnouncement = props.announcements[detailIdx];
        if (targetAnnouncement.linkTo == undefined) return;
        history.push(targetAnnouncement.linkTo);
    }, [props.announcements, detailIdx]);

    return (
        <div className={styles.announcementsContents}>
            {props.announcements.length === 0 ? (
                <div className={styles.emptyAnnouncements}>お知らせはありません。</div>
            ) : (
                <>
                    {detailIdx == undefined ? (
                        <>
                            {props.announcements.length > ITEMS_PER_PAGE && (
                                <div className={styles.displayedAnnouncementsInfo}>
                                    全{props.announcements.length}件中
                                    {(pageNumber - 1) * ITEMS_PER_PAGE}~
                                    {(pageNumber - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE}
                                    件を表示
                                </div>
                            )}
                            <ul className={styles.announcementsList}>
                                {props.announcements
                                    .slice(
                                        (pageNumber - 1) * ITEMS_PER_PAGE,
                                        (pageNumber - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE,
                                    )
                                    .map((announcement, idx) => {
                                        return (
                                            <li
                                                key={idx}
                                                className={styles.listItem}
                                                style={{
                                                    borderTop:
                                                        idx === 0 && props.announcements.length > ITEMS_PER_PAGE
                                                            ? "1px solid #AAA"
                                                            : "none",
                                                }}
                                            >
                                                <Button
                                                    className={styles.detailButton}
                                                    onClick={() => {
                                                        handleDetailButtonClick(
                                                            (pageNumber - 1) * ITEMS_PER_PAGE + idx,
                                                        );
                                                    }}
                                                >
                                                    <AvatarFromS3
                                                        className={styles.avatar}
                                                        url={announcement.iconImageUrl ?? trailLogo}
                                                        objectKey={announcement.iconImageObjectKey}
                                                    />
                                                    <div className={styles.middleWrapper}>
                                                        <div className={styles.title}>{announcement.title}</div>
                                                        <div className={styles.diff}>
                                                            {announcement.createdAt &&
                                                                getDiffTime(new Date(announcement.createdAt))}
                                                        </div>
                                                    </div>
                                                    <ArrowForwardIosIcon className={styles.nextIcon} />
                                                </Button>
                                            </li>
                                        );
                                    })}
                            </ul>
                        </>
                    ) : (
                        <div className={styles.detailWrapper}>
                            <div className={styles.title}>{props.announcements[detailIdx].title}</div>
                            <div className={styles.timeWrapper}>
                                <AccessTimeIcon className={styles.timeIcon} />
                                <span className={styles.time}>
                                    {getDateWithString(new Date(props.announcements[detailIdx].createdAt))}
                                </span>
                            </div>
                            <div className={styles.message}>{parse(props.announcements[detailIdx].body ?? "")}</div>
                            {props.announcements[detailIdx].buttonContent && props.announcements[detailIdx].linkTo ? (
                                <ButtonPair
                                    buttonColor="navy"
                                    leftButtonText="お知らせ一覧に戻る"
                                    rightButtonText={props.announcements[detailIdx].buttonContent}
                                    handleLeftButtonClick={() => {
                                        setDetailIdx(undefined);
                                    }}
                                    handleRightButtonClick={handleRightButtonClick}
                                />
                            ) : (
                                <Button
                                    className={styles.backButton}
                                    onClick={() => {
                                        setDetailIdx(undefined);
                                    }}
                                >
                                    <ArrowBackIosIcon className={styles.icon} />
                                    <span className={styles.text}>お知らせ一覧に戻る</span>
                                </Button>
                            )}
                        </div>
                    )}
                </>
            )}
            {detailIdx == undefined && props.announcements.length > ITEMS_PER_PAGE && (
                <PageNumbers
                    pageNumber={pageNumber}
                    setPageNumber={setPageNumber}
                    items={props.announcements}
                    itemsPerPage={ITEMS_PER_PAGE}
                    thresholdNumber={thresholdNumber}
                />
            )}
        </div>
    );
});
