import { memo } from "react";
import { Button, ButtonColor } from "../Button";
import { Box } from "@mui/material";

interface Props {
    buttonColor: ButtonColor;
    leftButtonText: string;
    rightButtonText: string;
    loading?: boolean;
    disabled?: boolean;
    sx?: React.CSSProperties;
    handleLeftButtonClick: () => void;
    handleRightButtonClick: () => void;
}

export const ButtonPair: React.VFC<Props> = memo(function ButtonPair(props) {
    return (
        <Box
            sx={{
                marginTop: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
                ...props.sx,
            }}
        >
            <Button
                color={props.buttonColor}
                colorInverted
                text={props.leftButtonText}
                handleClick={props.handleLeftButtonClick}
            />
            <Button
                color={props.buttonColor}
                loading={props.loading}
                disabled={props.disabled}
                text={props.rightButtonText}
                handleClick={props.handleRightButtonClick}
            />
        </Box>
    );
});
