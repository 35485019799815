import { memo, useCallback } from "react";
import styles from "@/pages/Common/PublicRequestDetails/index.module.scss";
import { WHAT_DAY_LIST } from "@/utils/WhatDayList";

interface Props {
    deadline?: Date;
}

export const Deadline: React.VFC<Props> = memo((props) => {
    const getTime = useCallback(() => {
        if (props.deadline) {
            const fixedData = new Date(props.deadline);
            const month = fixedData.getMonth() + 1;
            const date = fixedData.getDate();
            const hour = ("0" + fixedData.getHours()).slice(-2);
            const minute = ("0" + fixedData.getMinutes()).slice(-2);
            const day = WHAT_DAY_LIST[fixedData.getDay()];
            const result = `${month}月${date}日(${day}) ${hour}:${minute}`;
            return result;
        } else {
            return "";
        }
    }, [props.deadline]);

    return (
        <div className={styles.detailWrapper}>
            <div className={styles.detailHeadingWrapper}>
                <span className={styles.detailHeading}>募集締め切り日時</span>
            </div>
            <div className={styles.detail} style={{ overflowY: "auto", maxHeight: "none" }} id="detail">
                <div>{props.deadline && getTime()}</div>
            </div>
        </div>
    );
});
