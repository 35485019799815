import { Button } from "@material-ui/core";

import styles from "./index.module.scss";
import { BaseScheduleResponse, CreateBaseScheduleParams, RepetitionUnit } from "@/store/autogenApi";

import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Modal } from "@/components/Modal";
import { BiEdit, BiPlusCircle } from "react-icons/bi";
import { RiDeleteBin6Line } from "react-icons/ri";
import { WhiteButton } from "@/components/Buttons/WhiteButton";
import { getBaseScheduleInfo } from "@/utils/BaseScheduleUtils";
import { BaseSchedulePopover } from "@/components/BaseSchedulePopover";
import { ButtonPair } from "@/components/ButtonPair";
import { useUpdateBaseSchedulesMutation } from "@/store/hooks/baseSchedules";
import { toast } from "react-toastify";
import { ToastContents } from "@/components/Toast";

interface Props {
    modalOpen: boolean;
    targetClassId: string;
    existingBaseSchedules: BaseScheduleResponse[];
    handleModalClose: () => void;
}

export const UpdateBaseSchedulesModal: React.VFC<Props> = memo(function UpdateBaseSchedulesModal(props) {
    const [isConfirmMode, setIsConfirmMode] = useState<boolean>(false);
    const [baseSchedules, setBaseSchedules] = useState<CreateBaseScheduleParams[]>([]);
    const [newBaseSchedule, setNewBaseSchedule] = useState<Partial<CreateBaseScheduleParams> | undefined>(undefined);
    const [isNewBaseScheduleChecked, setIsNewBaseScheduleChecked] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const [activePopoverIdx, setActivePopoverIdx] = useState<number | undefined>(undefined);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const updateBaseSchedules = useUpdateBaseSchedulesMutation();

    const daysValidation = useMemo(() => {
        if (!newBaseSchedule) return false;
        const isWeekly = newBaseSchedule.repetitionUnit === "week" && newBaseSchedule.repetitionUnitNumber === 1;
        if (!isWeekly) return true;
        return newBaseSchedule.howManyTimes === newBaseSchedule.dayOfWeekIndexes?.length;
    }, [
        newBaseSchedule?.repetitionUnit,
        newBaseSchedule?.repetitionUnitNumber,
        newBaseSchedule?.howManyTimes,
        newBaseSchedule?.dayOfWeekIndexes,
    ]);

    const timeValidation = useMemo(
        () => newBaseSchedule && newBaseSchedule.howManyMinutes >= 0,
        [newBaseSchedule?.howManyMinutes],
    );

    useEffect(() => {
        if (!props.modalOpen) return;
        const newBaseSchedules = props.existingBaseSchedules.map((baseSchedule) => {
            return {
                isActive: true,
                howManyMinutes: baseSchedule.howManyMinutes,
                startMinute: baseSchedule.startMinute,
                startHour: baseSchedule.startHour,
                dayOfWeekIndexes: baseSchedule.dayOfWeekIndexes,
                howManyTimes: baseSchedule.howManyTimes,
                repetitionUnit: baseSchedule.repetitionUnit,
                repetitionUnitNumber: baseSchedule.repetitionUnitNumber,
            };
        });
        setBaseSchedules(newBaseSchedules);
    }, [props.existingBaseSchedules, props.modalOpen]);

    const handleStartTimeChange = useCallback(
        (hour: number, minute: number) => {
            setNewBaseSchedule({ ...newBaseSchedule, startHour: hour, startMinute: minute });
        },
        [newBaseSchedule],
    );

    const handleHowManyMinutesChange = useCallback(
        (howManyMinutes: number) => {
            setNewBaseSchedule({ ...newBaseSchedule, howManyMinutes });
        },
        [newBaseSchedule],
    );

    const validateWeeklyBaseSchedule = useCallback(
        (baseSchedule: Partial<CreateBaseScheduleParams>): baseSchedule is CreateBaseScheduleParams => {
            return (
                baseSchedule.startHour !== undefined &&
                baseSchedule.startMinute !== undefined &&
                baseSchedule.howManyMinutes !== undefined &&
                baseSchedule.howManyMinutes > 0 &&
                baseSchedule.repetitionUnitNumber !== undefined &&
                baseSchedule.repetitionUnit !== undefined &&
                baseSchedule.dayOfWeekIndexes !== undefined &&
                baseSchedule.dayOfWeekIndexes.length === baseSchedule.howManyTimes &&
                baseSchedule.howManyTimes !== undefined &&
                baseSchedule.isActive !== undefined
            );
        },
        [newBaseSchedule],
    );

    const validateMultiWeekBaseSchedule = useCallback(
        (baseSchedule: Partial<CreateBaseScheduleParams>): baseSchedule is CreateBaseScheduleParams => {
            return (
                baseSchedule.howManyMinutes !== undefined &&
                baseSchedule.howManyMinutes > 0 &&
                baseSchedule.repetitionUnitNumber !== undefined &&
                baseSchedule.repetitionUnit !== undefined &&
                baseSchedule.dayOfWeekIndexes !== undefined &&
                baseSchedule.howManyTimes !== undefined &&
                baseSchedule.isActive !== undefined
            );
        },
        [newBaseSchedule],
    );

    const handlePopoverOpen = useCallback(
        (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, idx?: number) => {
            setPopoverOpen(true);
            setActivePopoverIdx(idx);
            setAnchorEl(event.currentTarget);
            if (idx === undefined) {
                setNewBaseSchedule({
                    isActive: false,
                    dayOfWeekIndexes: [],
                    repetitionUnitNumber: 1,
                    repetitionUnit: "week",
                    howManyTimes: 1,
                    startHour: 17,
                    startMinute: 0,
                    howManyMinutes: 60,
                });
            } else {
                setNewBaseSchedule(baseSchedules[idx]);
            }
        },
        [baseSchedules],
    );

    const handlePopoverClose = useCallback(() => {
        setPopoverOpen(false);
        setActivePopoverIdx(undefined);
        setAnchorEl(null);
        setNewBaseSchedule(undefined);
        setIsNewBaseScheduleChecked(false);
    }, []);

    const confirmWeeklyBaseSchedule = useCallback(() => {
        setIsNewBaseScheduleChecked(true);
        // 弾かれた場合はモーダル上にエラーが出る
        if (!newBaseSchedule) return;
        const baseSchedule = validateWeeklyBaseSchedule(newBaseSchedule) ? newBaseSchedule : undefined;
        // 弾かれた場合はモーダル上にエラーが出る
        if (!baseSchedule) return;
        // 新規追加の場合
        if (activePopoverIdx === undefined) {
            const modifiedBaseSchedule = {
                ...baseSchedule,
                dayOfWeekIndexes: baseSchedule.dayOfWeekIndexes,
                startHour: baseSchedule.startHour,
                startMinute: baseSchedule.startMinute,
            } as CreateBaseScheduleParams;
            const newBaseSchedules = [...baseSchedules, modifiedBaseSchedule];
            setBaseSchedules(newBaseSchedules);
            handlePopoverClose();
            return;
        }
        // 編集の場合
        const newBaseSchedules = baseSchedules.map((targetBaseSchedule, idx) => {
            if (idx === activePopoverIdx) {
                return baseSchedule;
            } else {
                return targetBaseSchedule;
            }
        });
        setBaseSchedules(newBaseSchedules);
        handlePopoverClose();
    }, [
        baseSchedules,
        activePopoverIdx,
        newBaseSchedule,
        setBaseSchedules,
        validateWeeklyBaseSchedule,
        handlePopoverClose,
    ]);

    const confirmMultiWeekBaseSchedule = useCallback(() => {
        setIsNewBaseScheduleChecked(true);
        // 弾かれた場合はモーダル上にエラーが出る
        if (!newBaseSchedule) return;
        const baseSchedule = validateMultiWeekBaseSchedule(newBaseSchedule) ? newBaseSchedule : undefined;
        // 弾かれた場合はモーダル上にエラーが出る
        if (!baseSchedule) return;
        // 新規追加の場合
        if (activePopoverIdx === undefined) {
            const modifiedBaseSchedule = {
                ...baseSchedule,
                dayOfWeekIndexes: [],
                startHour: undefined,
                startMinute: undefined,
            } as CreateBaseScheduleParams;
            const newBaseSchedules = [...baseSchedules, modifiedBaseSchedule];
            setBaseSchedules(newBaseSchedules);
            handlePopoverClose();
            return;
        }
        // 編集の場合
        const newBaseSchedules = baseSchedules.map((targetBaseSchedule, idx) => {
            if (idx === activePopoverIdx) {
                return { ...baseSchedule, dayOfWeekIndexes: [], startHour: undefined, startMinute: undefined };
            } else {
                return targetBaseSchedule;
            }
        });
        setBaseSchedules(newBaseSchedules);
        handlePopoverClose();
    }, [
        baseSchedules,
        activePopoverIdx,
        newBaseSchedule,
        setBaseSchedules,
        validateMultiWeekBaseSchedule,
        handlePopoverClose,
    ]);

    const handleDayChange = useCallback(
        (e: React.ChangeEvent<{ checked: boolean }>, dayIdx: number) => {
            const isChecked = e.target.checked;
            const dayIndexes = [...(newBaseSchedule?.dayOfWeekIndexes ?? [])];
            if (isChecked) {
                dayIndexes.push(dayIdx);
                dayIndexes.sort((a, b) => a - b);
                setNewBaseSchedule({ ...newBaseSchedule, dayOfWeekIndexes: dayIndexes });
            } else {
                const newDayIndexes = dayIndexes.filter((dayIndex) => dayIndex !== dayIdx);
                setNewBaseSchedule({ ...newBaseSchedule, dayOfWeekIndexes: newDayIndexes });
            }
        },
        [newBaseSchedule],
    );

    const handleRepetitionUnitNumberChange = useCallback(
        (e: React.ChangeEvent<{ value: unknown }>) => {
            const value = Number(e.target.value);
            setNewBaseSchedule({ ...newBaseSchedule, repetitionUnitNumber: value });
        },
        [newBaseSchedule],
    );

    const handleRepetitionUnitChange = useCallback(
        (e: React.ChangeEvent<{ value: unknown }>) => {
            const value = e.target.value as RepetitionUnit;
            setNewBaseSchedule({ ...newBaseSchedule, repetitionUnit: value, repetitionUnitNumber: 1, howManyTimes: 1 });
        },
        [newBaseSchedule],
    );

    const handleHowManyTimesChange = useCallback(
        (e: React.ChangeEvent<{ value: unknown }>) => {
            const value = Number(e.target.value);
            setNewBaseSchedule({ ...newBaseSchedule, howManyTimes: value });
        },
        [newBaseSchedule],
    );

    const handleDeleteButtonClick = useCallback(
        (idx: number) => {
            const newBaseSchedules = baseSchedules.filter((_, index) => index !== idx);
            setBaseSchedules(newBaseSchedules);
        },
        [baseSchedules],
    );

    const handleCheckButtonClick = useCallback(() => {
        setIsConfirmMode(true);
    }, []);

    const handleModalClose = useCallback(() => {
        setIsConfirmMode(false);
        props.handleModalClose();
    }, [props.handleModalClose]);

    const handleConfirmButtonClick = useCallback(async () => {
        setIsProcessing(true);
        const { isSuccess } = await updateBaseSchedules({
            updateBaseSchedulesRequestBody: {
                classId: props.targetClassId,
                baseSchedules,
            },
        });
        setIsProcessing(false);
        if (isSuccess) {
            toast(<ToastContents title={`基本スケジュール更新完了`} isCompleted />);
        } else {
            toast(<ToastContents title={`基本スケジュール更新失敗`} isFailed />);
        }
        handleModalClose();
    }, [updateBaseSchedules, baseSchedules, props.targetClassId, handleModalClose]);

    return (
        <Modal open={props.modalOpen} onClose={handleModalClose}>
            <div className={styles.updateBaseSchedulesModalContents}>
                {isConfirmMode ? (
                    <div className={styles.confirmModeContents}>
                        <div className={styles.confirmMessage}>
                            {baseSchedules.length === 0
                                ? "基本スケジュールの生成をOFFにします。よろしいですか？"
                                : "下記の内容で基本スケジュールを更新します。過去に基本スケジュールによって自動生成された授業は削除され、新しい基本スケジュールに従って直近1か月分の授業が自動生成されますが、よろしいですか？"}
                        </div>
                        {baseSchedules.length > 0 && (
                            <ul className={styles.confirmedBaseSchedules}>
                                {baseSchedules.map((baseSchedule, idx) => (
                                    <li className={styles.confirmedBaseSchedule} key={idx}>
                                        {getBaseScheduleInfo(baseSchedule)}
                                    </li>
                                ))}
                            </ul>
                        )}
                        {isProcessing && (
                            <div className={styles.pleaseWait}>
                                この処理には時間がかかることがあります。しばらくそのままお待ちください。
                            </div>
                        )}
                        <ButtonPair
                            buttonColor="navy"
                            loading={isProcessing}
                            leftButtonText="キャンセル"
                            rightButtonText="確定"
                            handleLeftButtonClick={handleModalClose}
                            handleRightButtonClick={handleConfirmButtonClick}
                        />
                    </div>
                ) : (
                    <div className={styles.editModeContents}>
                        <div className={styles.messageWrapper}>
                            <p className={styles.message}>
                                基本スケジュールを入力してください。自動生成させたくない場合は入力されている基本スケジュールを全て削除してください。
                            </p>
                        </div>
                        {baseSchedules.length > 0 && (
                            <ul className={styles.baseSchedules}>
                                {baseSchedules.map((baseSchedule, idx) => {
                                    return (
                                        <li key={idx} className={styles.baseSchedule}>
                                            <div className={styles.baseScheduleInfo}>
                                                {getBaseScheduleInfo(baseSchedule)}
                                            </div>
                                            <Button
                                                className={styles.editButton}
                                                onClick={(e) => {
                                                    handlePopoverOpen(e, idx);
                                                }}
                                            >
                                                <BiEdit />
                                            </Button>
                                            <Button
                                                className={styles.deleteButton}
                                                onClick={() => {
                                                    handleDeleteButtonClick(idx);
                                                }}
                                            >
                                                <RiDeleteBin6Line />
                                            </Button>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                        <BaseSchedulePopover
                            open={popoverOpen}
                            isNewBaseScheduleChecked={isNewBaseScheduleChecked}
                            newBaseSchedule={newBaseSchedule}
                            anchorEl={anchorEl}
                            daysValidation={daysValidation}
                            timeValidation={timeValidation}
                            handleClose={handlePopoverClose}
                            handleDayChange={handleDayChange}
                            handleRepetitionUnitNumberChange={handleRepetitionUnitNumberChange}
                            handleRepetitionUnitChange={handleRepetitionUnitChange}
                            handleHowManyTimesChange={handleHowManyTimesChange}
                            handleStartTimeChange={handleStartTimeChange}
                            handleHowManyMinutesChange={handleHowManyMinutesChange}
                            confirmWeeklyBaseSchedule={confirmWeeklyBaseSchedule}
                            confirmMultiWeekBaseSchedule={confirmMultiWeekBaseSchedule}
                        />
                        <WhiteButton
                            border={false}
                            handleClick={(e) => {
                                handlePopoverOpen(e);
                            }}
                        >
                            <BiPlusCircle />
                            <div>追加</div>
                        </WhiteButton>
                        <ButtonPair
                            buttonColor="navy"
                            leftButtonText="キャンセル"
                            rightButtonText="確認"
                            handleLeftButtonClick={handleModalClose}
                            handleRightButtonClick={handleCheckButtonClick}
                        />
                    </div>
                )}
            </div>
        </Modal>
    );
});
