import { useMediaQuery } from "@material-ui/core";
import { memo, useCallback } from "react";
import styles from "./index.module.scss";
import { DraftCourseResponse } from "@/store/autogenApi";
import { WhiteButton } from "@/components/Buttons/WhiteButton";
import { NavyButton } from "@/components/Buttons/NavyButton";
import { useHistory } from "react-router";
import { getCourseType } from "@/utils/DraftCourseUtils";
import { FormItem } from "@/components/FormItem";
import { Modal } from "@/components/Modal";

interface Props {
    targetDraftCourse: DraftCourseResponse;
    handleCourseDetailsModalClose: () => void;
}

export const CourseDetailsModal: React.VFC<Props> = memo(function CourseDetailsModal(props: Props) {
    const history = useHistory();

    const mdUp = useMediaQuery("(min-width:960px)");

    const handleCourseDetailsButtonClick = useCallback(() => {
        props.handleCourseDetailsModalClose();
        history.push(`/CourseDetails/${props.targetDraftCourse.course?.courseId}`);
    }, [props.targetDraftCourse]);

    const handleEditButtonClick = useCallback(() => {
        props.handleCourseDetailsModalClose();
        const draftCourseQuery = props.targetDraftCourse
            ? `?draftCourseId=${props.targetDraftCourse.draftCourseId}`
            : "";
        if (props.targetDraftCourse.course?.courseId) {
            history.push(`/CreateCourse/${props.targetDraftCourse.course?.courseId}${draftCourseQuery}`);
            return;
        }
        history.push(`/CreateCourse${draftCourseQuery}`);
    }, [props.targetDraftCourse, props.handleCourseDetailsModalClose]);

    return (
        <Modal open={true} autoResize onClose={props.handleCourseDetailsModalClose} style={{ maxWidth: "400px" }}>
            <div className={styles.courseDetailsModalContents}>
                <ul className={styles.itemList}>
                    <FormItem
                        itemTitle="講座形態"
                        itemDescription={props.targetDraftCourse ? getCourseType(props.targetDraftCourse) : "-"}
                        isFirstItem
                    />
                    <FormItem
                        itemTitle="タイトル"
                        itemDescription={props.targetDraftCourse?.title === "" ? "-" : props.targetDraftCourse?.title}
                    />
                    <FormItem itemTitle="科目">
                        {props.targetDraftCourse?.subjects || props.targetDraftCourse?.subjects.length === 0 ? (
                            "-"
                        ) : (
                            <ul className={styles.subjects}>
                                {props.targetDraftCourse?.subjects.map((subject) => {
                                    return <li className={styles.subject}>{subject}</li>;
                                })}
                            </ul>
                        )}
                    </FormItem>
                    <FormItem
                        itemTitle="講座内容の詳細"
                        itemDescription={
                            props.targetDraftCourse?.courseDescription === ""
                                ? "-"
                                : props.targetDraftCourse?.courseDescription
                        }
                    />
                    <FormItem
                        itemTitle="教材・必要な準備"
                        itemDescription={
                            props.targetDraftCourse?.teachingMaterial === ""
                                ? "-"
                                : props.targetDraftCourse?.teachingMaterial
                        }
                    />
                    <FormItem
                        itemTitle="こんな人におすすめ"
                        itemDescription={
                            props.targetDraftCourse?.targetDescription === ""
                                ? "-"
                                : props.targetDraftCourse?.targetDescription
                        }
                    />
                    <FormItem itemTitle="関連画像">
                        {!props.targetDraftCourse?.thumbnailUrl ? (
                            "-"
                        ) : (
                            <img src={props.targetDraftCourse.thumbnailUrl} className={styles.thumbnail} />
                        )}
                    </FormItem>
                    <FormItem
                        itemTitle="備考"
                        itemDescription={
                            props.targetDraftCourse?.additionalDescription === ""
                                ? "-"
                                : props.targetDraftCourse?.additionalDescription
                        }
                    />
                </ul>
                <div className={styles.buttonsWrapper}>
                    {props.targetDraftCourse.course?.courseId && (
                        <WhiteButton handleClick={handleCourseDetailsButtonClick} fontSize={mdUp ? 15 : 14} isFlex>
                            現在の講座を確認
                        </WhiteButton>
                    )}
                    <NavyButton
                        handleClick={handleEditButtonClick}
                        fontSize={mdUp ? 15 : 14}
                        isFlex
                        style={{ maxWidth: "200px" }}
                    >
                        編集する
                    </NavyButton>
                </div>
            </div>
        </Modal>
    );
});
