import { BaseScheduleResponse, CreateBaseScheduleParams } from "@/store/autogenApi";
import { add } from "date-fns";
import { HOW_MANY_DAYS_IN_A_MONTH } from "./DateUtils";
import { WHAT_DAY_LIST } from "./WhatDayList";

const isBaseScheduleResponse = (value: any): value is BaseScheduleResponse => {
    return Boolean(value.daysOfWeek);
};

const formatTime = (date: Date) => {
    return `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
};

export const getBaseScheduleInfo = (
    baseSchedule: BaseScheduleResponse | CreateBaseScheduleParams | undefined,
): string => {
    if (!baseSchedule) return "";
    const repetitionUnitNumber = baseSchedule.repetitionUnitNumber ?? 1;
    const repetitionUnit = baseSchedule.repetitionUnit ?? "week";
    const howManyTimes = baseSchedule.howManyTimes ?? 1;
    let repetitionInfo = "";
    if (repetitionUnit === "day") {
        if (repetitionUnitNumber === 1 && howManyTimes === 1) {
            repetitionInfo = "毎日";
        } else {
            repetitionInfo = `（${repetitionUnitNumber}日に${howManyTimes}回）`;
        }
    } else if (repetitionUnit === "week") {
        if (repetitionUnitNumber === 1) {
            repetitionInfo = "毎週";
            const BASE_DATE = new Date(1970, 0, 1, 0, 0);
            const startTime = add(BASE_DATE, {
                hours: baseSchedule.startHour ?? 0,
                minutes: baseSchedule.startMinute ?? 0,
            });
            const endTime = add(startTime, { minutes: baseSchedule.howManyMinutes ?? 0 });
            const formattedTime = `${formatTime(startTime)}〜${formatTime(endTime)}`;
            let dayIndexes: number[] = [];
            if (isBaseScheduleResponse(baseSchedule)) {
                dayIndexes = baseSchedule.dayOfWeekIndexes;
            } else {
                dayIndexes = baseSchedule.dayOfWeekIndexes;
            }
            const days = dayIndexes.reduce((acc, cur, idx) => {
                const day = WHAT_DAY_LIST[cur];
                if (idx === 0) return acc + day;
                return acc + "・" + day;
            }, "");
            return `（${repetitionInfo} ${days}） ${formattedTime}`;
        } else {
            repetitionInfo = `${repetitionUnitNumber}週間に${howManyTimes}回`;
        }
    } else {
        repetitionInfo = `月1`;
    }
    const howManyMinutes = baseSchedule.howManyMinutes;
    return `（${repetitionInfo}）各${howManyMinutes}分 時間は都度相談`;
};

export const getDaysAndTime = (baseSchedule: BaseScheduleResponse | CreateBaseScheduleParams): string => {
    let dayIndexes: number[] = [];
    if (isBaseScheduleResponse(baseSchedule)) {
        dayIndexes = baseSchedule.dayOfWeekIndexes;
    } else {
        dayIndexes = baseSchedule.dayOfWeekIndexes;
    }
    const days = dayIndexes.reduce((acc, cur, idx) => {
        const day = WHAT_DAY_LIST[cur];
        if (idx === 0) return acc + day;
        return acc + "・" + day;
    }, "");
    const startHour = baseSchedule.startHour;
    const startMinute = baseSchedule.startMinute;
    if (startHour === undefined || startMinute === undefined) return "";
    const endHour = startHour + Math.floor((startHour + baseSchedule.howManyMinutes) / 60);
    const endMinute = (startMinute + baseSchedule.howManyMinutes) % 60;
    const formattedStartHour = ("00" + startHour).slice(-2);
    const formattedStartMinute = ("00" + startMinute).slice(-2);
    const formattedEndHour = ("00" + endHour).slice(-2);
    const formattedEndMinute = ("00" + endMinute).slice(-2);
    const time = `${formattedStartHour}:${formattedStartMinute}〜${formattedEndHour}:${formattedEndMinute}`;
    return `${days} ${time}`;
};

export const getOnlyDaysFromBaseSchedule = (baseSchedule: BaseScheduleResponse): string => {
    const days = baseSchedule.dayOfWeekIndexes.reduce((acc, cur, idx) => {
        const day = WHAT_DAY_LIST[cur];
        if (idx === 0) return acc + day;
        return acc + "・" + day;
    }, "");
    return `${days}`;
};

export const getOnlyTimeFromBaseSchedule = (baseSchedule: BaseScheduleResponse): string => {
    const startHour = baseSchedule.startHour;
    const startMinute = baseSchedule.startMinute;
    if (startHour === undefined || startMinute === undefined) return "";
    const endHour = startHour + Math.floor((startHour + baseSchedule.howManyMinutes) / 60);
    const endMinute = (startMinute + baseSchedule.howManyMinutes) % 60;
    const formattedStartHour = ("00" + startHour).slice(-2);
    const formattedStartMinute = ("00" + startMinute).slice(-2);
    const formattedEndHour = ("00" + endHour).slice(-2);
    const formattedEndMinute = ("00" + endMinute).slice(-2);
    const time = `${formattedStartHour}:${formattedStartMinute}〜${formattedEndHour}:${formattedEndMinute}`;
    return `${time}`;
};

export const getTotalMinutesInAMonth = (baseSchedules: (BaseScheduleResponse | CreateBaseScheduleParams)[]) => {
    const totalMinutes = baseSchedules.map((baseSchedule) => {
        const dayOfWeekIndexes = baseSchedule.dayOfWeekIndexes;
        const repetitionUnit = baseSchedule.repetitionUnit ?? "week";
        if (repetitionUnit === "day") {
            return Math.round(
                (baseSchedule.howManyMinutes * HOW_MANY_DAYS_IN_A_MONTH) / baseSchedule.repetitionUnitNumber,
            );
        }
        if (repetitionUnit === "month") {
            return Math.round(baseSchedule.howManyMinutes * dayOfWeekIndexes.length);
        }
        if (repetitionUnit === "week" && baseSchedule.repetitionUnitNumber === 1) {
            return Math.round(
                (baseSchedule.howManyMinutes * (dayOfWeekIndexes.length * HOW_MANY_DAYS_IN_A_MONTH)) /
                    (baseSchedule.repetitionUnitNumber * 7),
            );
        }
        return Math.round(
            (baseSchedule.howManyMinutes * HOW_MANY_DAYS_IN_A_MONTH) / (baseSchedule.repetitionUnitNumber * 7),
        );
    });
    return totalMinutes.reduce((a, b) => a + b, 0);
};

export const getTotalTimeInAMonth = (baseSchedules: (BaseScheduleResponse | CreateBaseScheduleParams)[]) => {
    const totalMinutes = getTotalMinutesInAMonth(baseSchedules);
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}時間${minutes}分`;
};

export const getPricePerMonth = (baseSchedules: (BaseScheduleResponse | CreateBaseScheduleParams)[], price: number) => {
    const totalMinutesPerMonth = getTotalMinutesInAMonth(baseSchedules);
    const pricePerMonth = Math.ceil((totalMinutesPerMonth * price) / 60);
    return pricePerMonth;
};
