import { memo, useCallback } from "react";

import styles from "./index.module.scss";
import { useGetNewsRecordsQuery } from "@/store/hooks/newsRecords";
import { QueryLoadingWrapper } from "@/components/QueryLoadingWrapper";
import { useHistory } from "react-router";
import { ArrowIcon } from "@/globalIcons";
import { Button } from "@material-ui/core";
import { Box } from "@mui/material";

export const NewsList = memo(function News() {
    const newsRecordsQueryState = useGetNewsRecordsQuery();

    const getDate = useCallback((date: string | undefined) => {
        if (!date) return "";
        const dateObj = new Date(date);
        if (isNaN(dateObj.getTime())) return "";
        const year = dateObj.getFullYear();
        const month = dateObj.getMonth() + 1;
        const day = dateObj.getDate();
        return `${year}.${month}.${day}`;
    }, []);

    const history = useHistory();

    const handleNewsRecordClick = useCallback((bannerId: string) => {
        history.push(`/News/${bannerId}`);
    }, []);

    const handleNewsListButtonClick = useCallback(() => {
        history.push("/NewsList");
    }, []);
    return (
        <div className={styles.newsListWrapper}>
            <div className={styles.headingAndButton}>
                <div className={styles.heading}>ニュース</div>
                <Button className={styles.moreButton} onClick={handleNewsListButtonClick}>
                    <span className={styles.text}>もっと見る</span>
                    <ArrowIcon className={styles.icon} />
                </Button>
            </div>
            <QueryLoadingWrapper {...newsRecordsQueryState}>
                {(newsRecords) => (
                    <ul className={styles.newsRecordList}>
                        {newsRecords
                            .filter((_, idx) => idx <= 4)
                            .map((newsRecord, idx) => (
                                <Box
                                    component="li"
                                    sx={{ borderTop: idx === 0 ? "none" : "1px dashed #CCC" }}
                                    className={styles.newsRecord}
                                    onClick={() => {
                                        handleNewsRecordClick(newsRecord.newsRecordId);
                                    }}
                                >
                                    <div className={styles.date}>{getDate(newsRecord.createdAt)}</div>
                                    <div className={styles.newsTitle}>{newsRecord.title}</div>
                                </Box>
                            ))}
                    </ul>
                )}
            </QueryLoadingWrapper>
        </div>
    );
});
