import { memo, useCallback, useState } from "react";
import { ChatInfo } from "../ChatsContents";
import { Modal } from "@/components/Modal";
import styles from "../index.module.scss";
import { UserInfoModal } from "@/components/UserInfoModal";
import { ChatType, PublicStudentResponse, PublicTeacherResponse } from "@/store/autogenApi";
import { RootState } from "@/ducks";
import { useSelector } from "react-redux";
import { AvatarFromS3 } from "@/components/atoms/images/AvatarFromS3";

interface Props {
    chatInfo: ChatInfo;
    open: boolean;
    chatType: ChatType;
    onClose: () => void;
}

export const ChatInfoModal = memo(function ChatInfoModal(props: Props) {
    const [target, setTarget] = useState<PublicStudentResponse | PublicTeacherResponse | undefined>(undefined);

    const userMode = useSelector((state: RootState) => state.jwt.userMode);

    const handleStudentButtonClick = useCallback((student: PublicStudentResponse) => {
        setTarget(student);
    }, []);

    const handleTeacherButtonClick = useCallback((teacher: PublicTeacherResponse) => {
        setTarget(teacher);
    }, []);

    const handleUserInfoModalClose = useCallback(() => {
        setTarget(undefined);
    }, []);

    return (
        <Modal open={props.open} autoResize onClose={props.onClose} style={{ maxWidth: "200px" }}>
            <div className={styles.chatInfoModalContents}>
                {props.chatType === "group" && (
                    <div className={styles.chatInfoModalContentsTitle}>クラスのメンバー</div>
                )}
                {props.chatInfo.teachers.length > 0 &&
                    (props.chatType === "group" || (props.chatType === "individual" && userMode === "student")) && (
                        <div className={styles.itemWrapper}>
                            <div className={styles.itemTitle}>- 先生 -</div>
                            <ul className={styles.itemList}>
                                {props.chatInfo.teachers.map((teacher, idx) => (
                                    <li
                                        className={styles.listItem}
                                        onClick={() => {
                                            handleTeacherButtonClick(teacher);
                                        }}
                                        style={{
                                            borderTop: idx === 0 ? "none" : "1px solid #CCC",
                                        }}
                                    >
                                        <AvatarFromS3
                                            url={teacher.iconImageUrl}
                                            objectKey={teacher.iconImageObjectKey}
                                            className={styles.itemAvatar}
                                        />
                                        <div className={styles.itemName}>{teacher.nickname}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                {props.chatInfo.students.length > 0 &&
                    (props.chatType === "group" || (props.chatType === "individual" && userMode === "teacher")) && (
                        <div className={styles.itemWrapper}>
                            <div className={styles.itemTitle}>- 生徒 -</div>
                            <ul className={styles.itemList}>
                                {props.chatInfo.students.map((student, idx) => (
                                    <li
                                        className={styles.listItem}
                                        onClick={() => {
                                            handleStudentButtonClick(student);
                                        }}
                                        style={{
                                            borderTop: idx === 0 ? "none" : "1px solid #CCC",
                                        }}
                                    >
                                        <AvatarFromS3
                                            url={student.iconImageUrl}
                                            objectKey={student.iconImageObjectKey}
                                            className={styles.itemAvatar}
                                        />
                                        <div className={styles.itemName}>{student.nickname}</div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                {target && (
                    <UserInfoModal openedTarget={target} zIndex={10000} handleClose={handleUserInfoModalClose} />
                )}
            </div>
        </Modal>
    );
});
